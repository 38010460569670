import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n.js";
import { useNavigate } from "react-router-dom";
import { RiveAnimationComponent } from '../../../components/animations/RiveAnimationComponent.jsx';
import { ButtonHoverGradiant } from '../../../components/buttons/ButtonHoverGradiant.jsx';

export const DashboardContent = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.home.SalesHome.DashboardContent',
    });
    return (
        <Grid
            container
            direction="column"
            sx={{ marginTop: { sm: '15vh' }, marginBottom: '20vh' }}
        >
            <Grid
                container
                item
                direction={{ md: 'column', xl: 'row' }}
                justifyContent="center"
                alignItems={{ md: 'center', xl: 'flex-start' }}
            >
                <Grid
                    container
                    item
                    sx={{
                        width: { xs: '80%', md: '25%' },
                        margin: { xs: '5vh 0', md: '0 5vh' },
                    }}
                    alignItems="center"
                    direction="column"
                >
                    <RiveAnimationComponent
                        src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/star_bright.riv"
                        stateMachines="State Machine 1"
                        width="300px"
                        height="300px"
                    />
                    <Typography align="center" variant="h3">
                        {t('title1')}
                    </Typography>
                    <Typography align="center" variant="body" sx={{ marginTop: '5vh' }}>
                        {t('description1')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    sx={{
                        width: { xs: '80%', md: '25%' },
                        margin: { xs: '5vh 0', md: '0 5vh' },
                    }}
                    alignItems="center"
                    direction="column"
                >
                    <RiveAnimationComponent
                        src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/Real_Time_Tracking_&_Reporting.riv"
                        stateMachines="State Machine 1"
                        width="300px"
                        height="300px"
                    />
                    <Typography align="center" variant="h3">
                        {t('title2')}
                    </Typography>
                    <Typography align="center" variant="body" sx={{ marginTop: '5vh' }}>
                        {t('description2')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    sx={{
                        width: { xs: '80%', md: '25%' },
                        margin: { xs: '5vh 0', md: '0 5vh' },
                    }}
                    alignItems="center"
                    direction="column"
                >
                    <RiveAnimationComponent
                        src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/support.riv"
                        stateMachines="State Machine 1"
                        width="300px"
                        height="300px"
                    />
                    <Typography align="center" variant="h3">
                        {t('title3')}
                    </Typography>
                    <Typography align="center" variant="body" sx={{ marginTop: '5vh' }}>
                        {t('description3')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item mt={14}>
                <ButtonHoverGradiant
                    label={i18n.t('join_now', { ns: 'buttons' })}
                    onClick={() => navigate('/account/signup')}
                />
            </Grid>
        </Grid>
    );
};