import React from 'react';
import { Grid } from '@mui/material';
import ReactPlayer from 'react-player';

const BackgroundVideo = ({ videoSource, children, blur }) => {
  return (
    <Grid container>
      <ReactPlayer
        className="react-player"
        url={videoSource}
        width="100vw"
        height="100vh"
        controls={false}
        muted={true}
        playing={true}
        loop={true}
        style={{
          filter: `blur(${blur}px)`,
          WebkitFilter: `blur(${blur}px)`,
        }}
      />
      {children}
    </Grid>
  );
};

const Video = ({
  videoSource,
  controls,
  muted,
  playing,
  loop,
  blur,
  width,
  height,
}) => {
  if (!videoSource) {
    return;
  }

  return (
    <ReactPlayer
      className="react-player"
      url={videoSource}
      width={width ? width : '100%'}
      height={height ? height : '100%'}
      controls={controls ? controls : true}
      muted={muted ? muted : false}
      playing={playing ? playing : false}
      loop={loop ? loop : true}
      style={{ filter: `blur(${blur}px)`, WebkitFilter: `blur(${blur}px)` }}
    />
  );
};

export { BackgroundVideo, Video };
