import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { SignUpForm } from '../../../components/account/forms/signup/SignUpForm.jsx';
import { useTranslation } from 'react-i18next';
import { useIntuTheme } from '../../../context/providers/IntuThemeProvider';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';

const SignUp = () => {
  // Lead Context
  const { leadInfo } = useContext(LeadContext);

  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.lead_context',
  });

  // Theme
  const { mode } = useIntuTheme();

  // Navigate
  const navigate = useNavigate();

  return (
    <Grid container direction="column" sx={{ padding: '50px' }}>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          textAlign="center"
          className={
            mode === 'light'
              ? 'grey-text header-centered'
              : 'white-text header-centered'
          }
        >
          {t('sign_up_title')}
        </Typography>
      </Grid>
      <Grid item container>
        <SignUpForm
          formTitle={i18n.t('form.early_access.title')}
          formTitlePending={i18n.t('form.early_access.title_pending')}
          formType="accCreate"
          showVendorType={true}
          showFirstName={true}
          showLastName={true}
          showEmail={true}
          showDefinePassword={true}
          showSigninButton={true}
          showPrimaryButton={true}
          primaryButtonLabel={i18n.t('buttons.sign_up')}
          primaryButtonAction="accSignup"
          showSecondaryButton={false}
          secondaryButtonLabel={i18n.t('buttons.sign_in')}
          secondaryButtonAction={(e) => {
            navigate('/account/signin', { replace: true });
          }}
          showAdditionalButton={false}
          additionalButtonLabel={i18n.t('buttons.confirm_acct')}
          additionalButtonAction={(e) => {
            navigate('/account/confirm', { replace: true });
          }}
          firstName={leadInfo.first_name}
          lastName={leadInfo.last_name}
          email={leadInfo.email}
          type={leadInfo.lead_type}
        />
      </Grid>
    </Grid>
  );
};

export { SignUp };
