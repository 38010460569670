import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import { LiveChatWidget } from '@livechat/widget-react';

function AuthLayout() {
  return (
    <>
      <Container maxWidth="xxl" disableGutters>
        <Outlet />
      </Container>
      {/* <LiveChatWidget
        license={process.env.REACT_APP_LIVECHAT_ID}
        visibility="minimized"
      /> */}
    </>
  );
}

export default AuthLayout;
