import { Button, Fade, Skeleton, Typography, useTheme } from '@mui/material';
import { IntuRichTextEditor } from '../../IntuRichTextEditor/IntuRichtTextEditor';
import { useFormikContext } from 'formik';
import { convertToRaw } from 'draft-js';
import { useTranslation } from 'react-i18next';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider';
import { useContext, useEffect } from 'react';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import GenerateProfileIntroDialog from '../GenerateProfileIntroDialog';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  ProfileIntroProvider,
  ProfileIntroductionContext,
} from '../../../context/providers/ProfileIntroContextProvider';

const IndividualIntroFieldContent = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
  showIntroAssistant = false, // Whether or not to show theIntro Assistant Button
  initialSetUpComplete = true,
}) => {
  //SetUp Trnaslation
  const { t } = useTranslation('fields', {
    keyPrefix: 'account.individual.IndividualIntroField',
  });

  const theme = useTheme(); // Access the theme

  const { transitionInterval } = useContext(IntuThemeContext);
  const { setIntroDialog } = useContext(ProfileIntroductionContext);

  const { isLoading } = useContext(ProcessingContext);

  const { touched, setFieldTouched, values, setFieldValue, errors } =
    useFormikContext();

  return (
    <>
      {fieldTitle && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
            {fieldTitle}
          </Typography>
        </Fade>
      )}
      {fieldDescription && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography
            className="form-note"
            textAlign={{ xs: 'center', md: 'left' }}
          >
            {fieldDescription}
          </Typography>
        </Fade>
      )}
      {isLoading.status || initialSetUpComplete === false ? (
        <Skeleton variant="rectangular" width={'100%'} height={100} />
      ) : (
        <>
          <IntuRichTextEditor
            id="intro"
            name="intro"
            required={required}
            disabled={disabled}
            controls={[
              'title',
              'bold',
              'my-style',
              'italic',
              'underline',
              'fontColor',
              'highlight',
              'undo',
              'redo',
              'media',
              'numberList',
              'bulletList',
              'quote',
              'clear',
            ]}
            className="form-select-field"
            aria-describedby="uidnote"
            variant="standard"
            label={t('label', { type: 'Me' })}
            placeholder={t('placeholder')}
            defaultValue={JSON.stringify(values?.intro_rte)}
            onChange={(event) => {
              const rteContent = convertToRaw(event.getCurrentContent());
              rteContent && setFieldValue('intro', rteContent);
              setFieldTouched('intro', true);
            }}
            onBlur={() => setFieldTouched('intro', true)}
            inlineToolbar={true}
            error={errors?.intro && touched?.intro ? true : false}
            helperText={errors?.intro && touched?.intro ? errors?.intro : null}
          />

          {showIntroAssistant && (
            <Button
              variant="outlined"
              color="info"
              onClick={() => setIntroDialog(true)}
              startIcon={
                <AutoFixHighIcon
                  sx={{
                    color: disabled
                      ? theme.palette.action.disabled
                      : theme.palette.info.main,
                  }}
                />
              }
            >
              {t('buttons.assistant')}
            </Button>
          )}

          {/* Personal IntroDialog */}
          <GenerateProfileIntroDialog mode="individual" />
        </>
      )}
    </>
  );
};

const IndividualIntroField = (props) => {
  return (
    <ProfileIntroProvider>
      <IndividualIntroFieldContent {...props} />
    </ProfileIntroProvider>
  );
};

export default IndividualIntroField;
