import React, { useContext } from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import {
  Typography,
  Checkbox,
  Link,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { useFormikContext } from 'formik';
import { TermsOfServiceContext } from '../../../context/providers/TermsOfServicecontextProvider';
import useUser from '../../../hooks/useUser';

const PrivacyPolicyAgreement = ({ fieldTitle, transition, required }) => {
  // Translation
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.account.SignUpComplete.TermsOfServiceForm.ppa',
  });

  // Context
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { setDialogOpen, setDialogType, setDialogTitle } = useContext(
    TermsOfServiceContext,
  );
  const { user } = useUser();

  // Variables
  const userIP = user.location.ip;

  return (
    <>
      <FormikFieldWrapper fieldTitle={fieldTitle} transition={transition}>
        <Typography level="body-sm">
          <Trans
            i18nKey="context.account.SignUpComplete.TermsOfServiceForm.ppa.description"
            components={[
              <Link
                className="link"
                component="button"
                onClick={() => {
                  setDialogOpen(true);
                  setDialogType('ppa');
                  setDialogTitle(t('title'));
                }}
              />,
            ]}
          />
        </Typography>
      </FormikFieldWrapper>

      <FormikFieldWrapper required={required} transition={transition}>
        <FormGroup>
          <FormControlLabel
            checked={values.ppa_accepted?.accepted}
            control={
              <Checkbox
                name="ppa_accepted"
                onChange={(e) => {
                  let consent = e.target.checked;
                  setFieldValue('ppa_accepted', {
                    accepted: consent,
                    ip: consent ? userIP : '',
                    date: consent ? new Date() : '',
                    unixTimeStamp: consent ? Math.floor(Date.now() / 1000) : '',
                    agent: consent ? navigator.userAgent : '',
                  });
                  setTimeout(() => {
                    setFieldTouched('ppa_accepted', true);
                  }, 300);
                }}
                inputProps={{
                  'aria-label': 'controlled',
                }}
              />
            }
            label={<Typography level="body-sm">{t('label')}</Typography>}
          />
        </FormGroup>
      </FormikFieldWrapper>
    </>
  );
};

export default PrivacyPolicyAgreement;
