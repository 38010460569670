import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
} from 'react';

// Mui
import {
  Grid,
  Typography,
  Button,
  Stack,
  Skeleton,
  TextField,
  FormControl,
  MenuItem,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Avatar,
  Divider,
  Container,
  InputAdornment,
  Box,
  CircularProgress,
  createFilterOptions,
  ImageList,
  ImageListItem,
  ListSubheader,
  ImageListItemBar,
  IconButton,
  Modal,
} from '@mui/material';
import StyledInput from '../../mui/styledInput.js';

import { DataGrid } from '@mui/x-data-grid';
import {
  GridToolbar,
  GridRow,
  GridColumnHeaders,
} from '@mui/x-data-grid-premium';
import { classes } from '../../mui/theme.js';

// Axios
import {
  useAxiosPrivate,
  useAxiosPrivateData,
} from '../../hooks/axios/useAxiosPrivate.js';

// PlaceHolder Image
import PlaceHolderImage from '../../assets/core/intuPlaceHolder.png';
import { toTitleCase } from '../../helpers/toTitleCase.js';

// Mui Icons
import InfoIcon from '@mui/icons-material/Info';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

// Formik
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';

import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import useResponseHandling from '../../hooks/useResponseHandler.js';

// Product Context
import { useProductContext } from '../providers/ProductContextProvider';

// Import API Endpoints
import { getIndustries } from '../../routes/industriesRoutes.js';
import { getApplications } from '../../routes/applicationsRoutes.js';
import {
  getProductCategories,
  getProductGroups,
  getProductTypes,
} from '../../routes/productsRoutes.js';

// Translator
import { useTranslation } from 'react-i18next';

// Components
// import AddNewProductType from '../../components/product-categories/NewProductType.jsx';

// Create ProductFormContext
const ProductFormContext = createContext();

const ErrorDialog = () => {
  // Error Dialog States
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMsg, setDialogMsg] = useState('');

  // handle Error Dialog
  function handleConfirmError() {
    setDialogTitle('');
    setDialogMsg('');
    setDialogOpen(false);
  }

  return <></>;
};

const NewProductTypeDialog = () => {
  const { newProductTypes, setNewProductTypes, productCategOptions, formik } =
    useState(ProductFormContext);
  const { t } = useTranslation();

  var validationSchema = Yup.object();
  validationSchema = Yup.object().shape({
    product_type: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(/^[\w\-\s].{2,50}$/, t('validation.product.name_matches'))
      .required(t('validation.product.name_required')),
    mpn: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .required(t('validation.product.mpn_required')),
    list_price: Yup.number().required(t('validation.product.price_required')),
    attributes: Yup.string()
      .min(1, t('validation.product.attr_min'))
      .required(t('validation.product.attr_required')),
    applications: Yup.array()
      .min(1, t('validation.product.app_min'))
      .required(t('validation.product.app_required')),
    status: Yup.string().required(t('validation.product.status_required')),
  });

  return (
    <>
      <Formik
        values={formik.values}
        initialValues={formik.initialValues}
        errors={formik.errors}
        validationSchema={validationSchema}
      >
        {(formik) => {
          const {
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            isValid,
            setFieldTouched,
            setTouched,
            resetForm,
            setValues,
          } = formik;
          return (
            <>
              <Dialog open={newProductTypes.open}>
                <form>
                  <DialogTitle>{t('form.products.dialog_title')}</DialogTitle>
                  <DialogContent sx={{ width: 600, height: 500 }}>
                    <DialogContentText>
                      {t('form.products.dialog_content_alt')}
                    </DialogContentText>

                    {/* Product Type */}
                    <TextField
                      autoFocus
                      disabled
                      margin="dense"
                      id="product_type"
                      name="product_type"
                      value={values?.product_new?.product_name}
                      onChange={(e) => {
                        let properOption = toTitleCase(e.target.value);
                        let existingProductInfo = values?.product_new;
                        setFieldValue('product_new', {
                          product_name: properOption,
                          product_group: existingProductInfo.product_group,
                        });
                      }}
                      label={t('form.products.group_label')}
                      type="text"
                      variant="standard"
                    />

                    {/* Product Category */}
                    <Autocomplete
                      id="product_category"
                      name="product_category"
                      options={
                        productCategOptions ? productCategOptions?.sort() : []
                      }
                      getOptionLabel={(option) => option}
                      onChange={(e, options, reason, element) => {
                        if (reason === 'selectOption') {
                          let existingProductInfo = values?.product_new;

                          // Set Product Category Value
                          setFieldValue('product_new', {
                            ...existingProductInfo,
                            product_group: null,
                            product_family: element.option,
                          });

                          // Create Unique List of Product Groups

                          // Filter Product Groups based on selection
                          // const filteredProductGroups = productGroups.filter((el) => {
                          //     return el.product_family === element.option
                          // })
                          // console.log(`Filtered Product Groups ${JSON.stringify(filteredProductGroups)}`)

                          // Create uniue array of product options
                          let key = 'product_group';

                          // const arrayUniqueProductGroups = [...new Map(filteredProductGroups.map(item =>
                          //     [item[key], item.product_group])).values()];

                          // // console.log(`Unique List of Product Groups to choose from ${JSON.stringify(arrayUniqueProductGroups)}`);
                          // setFieldValue('product_group_options', arrayUniqueProductGroups)
                        } else if (reason === 'clear') {
                          let existingProductInfo = values?.product_new;

                          setFieldValue('product_new', {
                            ...existingProductInfo,
                            product_family: null,
                            product_group: null,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          id="name"
                          value={values.product_new?.product_family}
                          onChange={(event) => {
                            let existingProductInfo = values?.product_new;

                            setFieldValue('product_new', {
                              ...existingProductInfo,
                              product_family: null,
                              product_group: null,
                            });
                          }}
                          label={t('form.products.cat_label')}
                          type="text"
                          variant="standard"
                        />
                      )}
                    />

                    {/* Product Group */}
                    <Autocomplete
                      id="product_group"
                      name="product_group"
                      disabled={!values.product_new?.product_family}
                      options={
                        values?.product_group_options
                          ? values.product_group_options?.sort()
                          : []
                      }
                      value={values?.product_new?.product_group}
                      getOptionLabel={(option) => option}
                      isOptionEqualToValue={
                        values.product_new?.product_group > ''
                          ? (option, value) => option?.code === value.code
                          : () => {
                            '';
                          }
                      }
                      onChange={(e, options, reason, element) => {
                        let existingProductInfo = values.product_new;

                        if (reason === 'selectOption') {
                          setFieldValue('product_new', {
                            ...existingProductInfo,
                            product_group: element.option,
                          });
                        } else if (reason === 'clear') {
                          setFieldValue('product_new', {
                            ...existingProductInfo,
                            product_group: null,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          id="name"
                          value={values?.product_new?.product_group}
                          label={t('form.products.group_label')}
                          type="text"
                          variant="standard"
                        />
                      )}
                    />
                  </DialogContent>
                  <DialogActions>
                    {/* Cancel */}
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setValues({
                          ...values,
                          product_new: {
                            product_family: '',
                            product_group: '',
                            product_name: '',
                          },
                          toggleProductOpen: false,
                        });
                      }}
                    >
                      {t('buttons.cancel')}
                    </Button>

                    {/* Add */}
                    <Button
                      variant="contained"
                      disabled={
                        !values?.product_new?.product_group ||
                          !values?.product_new?.product_name
                          ? true
                          : false
                      }
                      type="submit"
                      onClick={(e) => {
                        let options = values?.products;
                        let newOption = values.product_new;
                        options.push(newOption);
                        setFieldValue('products', options);
                        setTimeout(
                          () => setFieldTouched('products', true, true),
                          200,
                        );

                        let newProducts = values?.products_new;
                        newProducts.push(newOption);
                        setFieldValue('products_new', newProducts);
                        setFieldValue('toggleProductOpen', false);
                      }}
                    >
                      {t('buttons.add')}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const ManageProductContext = () => {
  // Subscribe to context
  const {
    productInfo,
    setProductInfo,
    productDialog,
    setProductDialog,
    navigate,
  } = useProductContext();

  // Process Handling
  const { handleErrorResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  // Axios
  const axios = useAxiosPrivate();
  const axiosData = useAxiosPrivateData();

  // Product Certification Dialog State
  const [productAttributeDialog, setProductAttributeDialog] = useState({
    open: false,
    productCertDialogTitle: '',
    productCertDialogText: '',
    productCertDialogPrimaryButtonText: '',
    productCertDialogSecondaryButtonText: '',
    productCertDialogAction: '',
  });

  // New Product Options
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [productCategOptions, setProductCategOptions] = useState([]);

  // New Products
  const [newProductTypes, setNewProductTypes] = useState({
    open: false,
    product_type_new: {
      product_name: '',
      product_family: '',
      product_group: '',
    },
    product_types_new: [],
  });
  const [attributeCategoryOptions, setProductAttributeOptions] = useState([
    'General Data',
    'Mechanical',
    'Electrtical',
    'Standards & Certifications',
  ]);

  // Translator
  const { t } = useTranslation();

  // Set Local States
  const [applicationOptions, setApplicationOptions] = useState([]);
  const [productImages, setProductImages] = useState([]);

  const filter = createFilterOptions();

  // Initial Values
  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    _id: '',
    product_name: '',
    product_type: [],
    product_type_options: [],
    mpn: '',
    currency: '',
    list_price: '',
    attributes: [],
    recommended_attributes: [],
    application: '',
    applications: [],
    images: [],
    documents: [],
    files: [],
    status: '',
    group_search: '',
    image: '',
    imageBlob: '',
    image_url: '',
    file: '',
    fileBlob: '',
    file_url: '',
    document: '',
    documentBlob: '',
    document_url: '',
    product_attribute: {
      category: '',
      type: '',
      value: '',
    },
    file_type: '',
    product_types: [],
    product_categories: [],
    ...productInfo,
  };

  // Validation
  const supported_file_formats = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/svg',
    'document/pdf',
  ];

  var validationSchema = Yup.object();
  validationSchema = Yup.object().shape({
    product_name: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(/^[\w\-\s].{2,50}$/, t('validation.products.name_matches'))
      .required(t('form.products.name_required')),
    product_type: Yup.array()
      .min(1, t('validation.products.type_min'))
      .required(t('validation.products.required')),
    mpn: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .required(t('validation.products.mpn_required')),
    list_price: Yup.number().required(t('validation.products.price_required')),
    attributes: Yup.string()
      .min(1, t('validation.products.attr_min'))
      .required(t('validation.products.attr_required')),
    applications: Yup.array()
      .min(1, t('validation.products.app_min'))
      .required(t('validation.products.app_required')),
    status: Yup.string().required(t('validation.products.status_required')),
  });

  // Pre-load Info
  useEffect(() => {
    // console.log(productInfo);

    let isMounted = false;
    setIsLoading({ status: true, type: 'skeleton', text: '' });

    async function getProductImages() {
      // Initialize an empty array to hold the image objects
      let imagesArray = [];

      // Check if the post has images
      if (productInfo.images?.length > 0) {
        for (const imageInfo of productInfo.images.sort(
          (a, b) => a.index - b.index,
        )) {
          imagesArray.push({
            dataUrl: imageInfo.img,
            title: imageInfo.title,
            index: imageInfo.index,
          });

          // If all images are processed, sort and set the product images
          if (imagesArray.length === productInfo.images.length) {
            // Sort images by index before setting state
            imagesArray.sort((a, b) => a.index - b.index);
            // Set the product images with sorted array
            setProductImages(imagesArray);
          }
        }
      }
    }

    //Get Product Types from database
    async function handleGetProductTypes() {
      try {
        let productTypeList = await getProductTypes();

        if (productTypeList.status === 'success') {
          setProductTypeOptions(productTypeList.data);
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    //Get Product Categories from database
    async function handleGetProductCategories() {
      try {
        const productCategoryList = await getProductCategories();
        if (productCategoryList.status === 'success') {
          setProductGroups(productCategoryList.data);

          // Create a Unique List of Product Categories
          let key = 'product_family';

          const arrayUniqueProductCategories = [
            ...new Map(
              productCategoryList.data.map((item) => [
                item[key],
                item.product_family,
              ]),
            ).values(),
          ];

          // console.log(arrayUniqueProductCategories);
          setProductCategOptions(arrayUniqueProductCategories);
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    //Get Applications from database
    async function handleGetApplications() {
      try {
        const applicationsList = await getApplications();
        if (applicationsList.status === 'success') {
          setApplicationOptions(applicationsList.data);
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    // Preload form options and Values
    async function preLoadformInfo() {
      setIsLoading({ status: true, type: 'skeleton', text: '' });
      await handleGetApplications();
      await getProductImages();
      await handleGetProductTypes();
      await handleGetProductCategories();
    }

    preLoadformInfo().then(() => {
      setIsLoading({ status: false, type: 'skeleton', text: '' });
      isMounted = true;
    });
  }, []);

  // Handle Form Submission
  async function submitForm(action, values, formik) { }

  // handle Dialog Close
  function handleDialogClose() {
    setProductInfo({
      _id: '',
      product_name: '',
      product_type: [],
      product_type_options: [],
      mpn: '',
      currency: '',
      list_price: '',
      attributes: [],
      recommended_attributes: [],
      application: '',
      applications: [],
      images: [],
      documents: [],
      files: [],
      status: '',
      group_search: '',
      image: '',
      imageBlob: '',
      image_url: '',
      file: '',
      fileBlob: '',
      file_url: '',
      document: '',
      documentBlob: '',
      document_url: '',
      product_attribute: {
        category: '',
        type: '',
        value: '',
      },
      file_type: '',
      product_types: [],
      product_categories: [],
    });
  }

  // Handle Product Picture Enlarge
  const [openProductImage, setOpenProductImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleProductImageOpen = (img) => {
    setSelectedImage(img);
    setOpenProductImage(true);
  };

  const handleProductImageClose = () => setOpenProductImage(false);

  const handleProductImageDelete = () => {
    setProductImages(
      productImages.filter((item) => item.dataUrl !== selectedImage.dataUrl),
    );
    handleProductImageOpen();
  };

  const handleProductImageTitleChange = (event) => {
    const newTitle = event.target.value;
    setProductImages(
      productImages.map((item) => {
        if (item.dataUrl === selectedImage.dataUrl) {
          return { ...item, title: newTitle };
        }
        return item;
      }),
    );
  };

  const handleProductImageDescriptionChange = (event) => {
    const newDescription = event.target.value;
    setProductImages(
      productImages.map((item) => {
        if (item.dataUrl === selectedImage.dataUrl) {
          return { ...item, description: newDescription };
        }
        return item;
      }),
    );
  };

  return (
    <ProductFormContext.Provider
      value={{
        attributeCategoryOptions,
        setProductCategOptions,
        productGroups,
        setProductGroups,
        productCategOptions,
        setProductCategOptions,
        productAttributeDialog,
        setProductAttributeDialog,
        formik,
        newProductTypes,
        setNewProductTypes,
      }}
    >
      <Formik
        values={formik.values}
        initialValues={formik.initialValues}
        errors={formik.errors}
        validationSchema={validationSchema}
      >
        {(formik) => {
          const {
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            isValid,
            setFieldTouched,
            setTouched,
            resetForm,
          } = formik;
          return (
            <>
              <Typography variant="h5">{productDialog.title}</Typography>
              <Form role="form">
                <FormControl fullWidth>
                  <Grid container spacing={2} direction="column">
                    <Grid
                      item
                      container
                      xs={12}
                      direction={{ xs: 'column-reverse', md: 'row' }}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Typography variant="body">
                          {t('context.product.basic_info')}
                        </Typography>
                      </Grid>

                      {/* Basic Item Info */}
                      <Grid item xs={12} md={7}>
                        <Stack direction="column" spacing={2}>
                          {/* Product Name */}
                          <TextField
                            required
                            id="name"
                            name="name"
                            aria-invalid={errors.name ? 'true' : 'false'}
                            area-aria-describedby="uidnote"
                            className="form-select-field"
                            variant="outlined"
                            label="Name"
                            type="text"
                            placeholder={t('form.products.name_placeholder')}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={values?.name}
                            error={errors.name && touched.name ? true : false}
                          />

                          {/* Product Type */}
                          <Autocomplete
                            multiple
                            freeSolo
                            filterSelectedOptions
                            selectOnFocus
                            handleHomeEndKeys
                            disabled={formik.isSubmitting}
                            popupIcon={false}
                            id="products"
                            options={productTypeOptions?.sort(
                              (a, b) => -b.type_name.localeCompare(a.type_name),
                            )}
                            getOptionLabel={(option) => option.product_name}
                            groupBy={(option) => option.type_name}
                            value={values?.product_type}
                            className="form-select-field"
                            onChange={(e, options, reason, element) => {
                              if (reason === 'createOption') {
                                // Add new product by pressing return
                                let properOption = toTitleCase(element.option);
                                setTimeout(() => {
                                  setNewProductTypes({
                                    open: true,
                                    product_type_new: {
                                      product_name: properOption,
                                      product_family: '',
                                      product_group: '',
                                    },
                                  });
                                });
                              } else if (
                                reason === 'selectOption' &&
                                element.option.product_name.search('Add ') >= 0
                              ) {
                                // Add new product by selecting Add
                                let properOption = toTitleCase(
                                  element.option.inputValue,
                                );
                                setTimeout(() => {
                                  setNewProductTypes({
                                    open: true,
                                    product_type_new: {
                                      product_name: properOption,
                                      product_family: '',
                                      product_group: '',
                                    },
                                  });
                                });
                              } else if (reason === 'removeOption') {
                                // Remove an Product Option
                                const elementToRemove = element.option;
                                let currentProductList =
                                  newProductTypes?.product_types_new;
                                let filtered = currentProductList.filter(
                                  (item) =>
                                    item.product_name !==
                                    elementToRemove.product_name,
                                );
                                setNewProductTypes(filtered);
                                setFieldValue('product_type', options);
                              } else {
                                setFieldValue('product_type', options);
                              }
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              if (params.inputValue !== '') {
                                filtered.push({
                                  inputValue: params.inputValue,
                                  product_name: `Add "${params.inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            onBlur={handleBlur}
                            sx={{ paddingBottom: '50px' }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={t('form.products.type_label')}
                                placeholder={t(
                                  'form.products.type_placeholder',
                                )}
                                error={
                                  touched?.product_type && errors?.product_type
                                    ? true
                                    : false
                                }
                                helperText={
                                  touched?.product_type && errors?.product_type
                                    ? errors?.product_type
                                    : null
                                }
                              />
                            )}
                          />

                          <Stack direction="row" spacing={2}>
                            {/* MPN */}
                            <TextField
                              required
                              id="mpn"
                              name="mpn"
                              aria-invalid={errors.mpn ? 'true' : 'false'}
                              area-aria-describedby="uidnote"
                              className="form-select-field"
                              variant="outlined"
                              label={t('form.products.mpn_label')}
                              type="text"
                              placeholder={t('form.products.mpn_placeholder')}
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values?.mpn}
                              error={errors.mpn && touched.mpn ? true : false}
                            />

                            {/* Price */}
                            {/* Commission per Order % */}
                            <StyledInput
                              id="list_price"
                              name="list_price"
                              autoComplete="off"
                              className="form-select-field"
                              aria-describedby="uidnote"
                              variant="outlined"
                              label={t('form.products.price_label')}
                              type="text"
                              defaultValue={0}
                              value={values.list_price}
                              inputProps={{
                                autoComplete: 'off',
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Grid>

                      {/* Product Pictures */}
                      <Grid
                        item
                        container
                        alignContent="flex-start"
                        justifyContent="flex-end"
                        xs={12}
                        md={5}
                      >
                        {/* Main Product Image */}
                        <Grid item xs={12} sx={{ pb: '20px' }}>
                          {' '}
                          {/* Add bottom padding */}
                          <Box
                            component="img"
                            onClick={() =>
                              handleProductImageOpen(productImages[0])
                            }
                            sx={{
                              height: '100%', // Adjust height to 100% of the container
                              width: '100%', // Adjust width to 100% of the container
                              objectFit: 'cover', // This will cover the entire area, cropping the image if necessary
                              maxHeight: { xs: 233, md: '100%' }, // Remove the fixed maxHeight for larger screens
                              maxWidth: { xs: 350, md: '100%' }, // Remove the fixed maxWidth for larger screens
                            }}
                            alt={t('context.product.product_img_alt')}
                            src={productImages[0]?.dataUrl || PlaceHolderImage}
                          />
                        </Grid>

                        {/* Additional Product Images */}
                        {productImages?.length > 1 && (
                          <Grid item xs={12} sx={{ padding: 0, margin: 0 }}>
                            <ImageList
                              sx={{
                                width: '100%',
                                marginLeft: '0px !important',
                              }}
                              cols={4}
                            >
                              {productImages.slice(1).map((item) => (
                                <ImageListItem
                                  key={item.dataUrl}
                                  sx={{ width: '100px', height: '100px' }}
                                >
                                  <img
                                    src={item.dataUrl}
                                    alt={item.title}
                                    loading="lazy"
                                    onClick={() => handleProductImageOpen(item)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </ImageListItem>
                              ))}
                            </ImageList>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {/* Modal for displaying selected image */}
                    <Modal
                      open={openProductImage}
                      onClose={handleProductImageClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <div>
                        {selectedImage && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '55%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              bgcolor: 'background.paper',
                              boxShadow: 24,
                              p: 4,
                              outline: 'none',
                              width: 'auto', // Adjusted for auto width
                              maxWidth: '80vw', // Maximum width is 80% of the viewport width
                              maxHeight: '80vh', // Maximum height is 80% of the viewport height
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <img
                                  alt={selectedImage.title}
                                  src={selectedImage.dataUrl}
                                  style={{
                                    maxWidth: '100%', // Maximum width relative to its container
                                    maxHeight: '50vh', // Adjusted maximum height relative to the viewport height
                                    marginBottom: '20px', // Adds margin at the bottom
                                  }}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sx={{ width: '100%', paddingBottom: '20px' }}
                              >
                                <Stack direction="column" spacing={2}>
                                  <TextField
                                    defaultValue={selectedImage.title}
                                    onChange={handleProductImageTitleChange}
                                    label={t('form.products.product_img_title')}
                                    variant="standard"
                                  />
                                  <TextField
                                    defaultValue={selectedImage.description}
                                    onChange={
                                      handleProductImageDescriptionChange
                                    }
                                    label={t('form.products.product_img_desc')}
                                    variant="standard"
                                  />
                                </Stack>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                justifyContent="space-between"
                                sx={{ width: '100%' }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  justifyContent="space-between" // This pushes the buttons to the outer edges
                                  sx={{ width: '100%' }} // Ensure the Stack takes full width
                                >
                                  <Button
                                    variant="contained"
                                    color="tertiary"
                                    onClick={handleProductImageClose}
                                    endIcon={<CancelIcon />}
                                  >
                                    {t('buttons.close')}
                                  </Button>

                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleProductImageDelete}
                                    endIcon={<DeleteIcon />}
                                  >
                                    {t('buttons.cancel')}
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      </div>
                    </Modal>

                    {/* Advanced Item Info */}
                    <Grid item xs={12}>
                      {/* Product Price */}
                      <Typography>{t('context.product.price')}</Typography>
                      {/* Custom Field */}
                      <Typography>
                        {t('context.product.product_attr')}
                      </Typography>
                      <Typography>
                        {t('context.product.product_attr_content')}
                      </Typography>
                      {/* Recommended Attributes */}
                      {/* <Autocomplete
                                            multiple 
                                            id="recommended_attributes"
                                            options={values?.recommended_attributes}
                                            getOptionLabel={(option) => option?.name}
                                            className="form-select-field"
                                            onBlur={handleBlur}
                                            clearOnBlur={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Recommended Attributes"
                                                    // value={[]}
                                                />
                                            )}
                                        /> */}
                      {t('context.product.attr_input')}
                      {/* <Autocomplete 
                                            multiple
                                            freesolo
                                            id="attributes"
                                            name="attributes"
                                            className="form-select-field"
                                            onBLur={handleBlur}
                                            clearOnBlur={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Product Attributes"
                                                    // value={[]}
                                                />
                                            )}
                                            // onChange={}
                                        /> */}
                      {/* Selected Attributes */}
                      <Typography>
                        {t('context.product.assigned_attr')}
                      </Typography>
                      {/* <Autocomplete 
                                            multiple
                                            id="selected_attributes"
                                            name="selected_attributes"
                                            className="form-select-field"
                                            onBlur={handleBlur}
                                            options={values?.recommended_attributes}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    value={values?.attributes}
                                                    label="Selected Attributes"
                                                    error={errors?.attributes && touched.attributes ? true : false}
                                                    helperText={errors?.attributes && touched?.attributes ? errors.attributes: null}
                                                />
                                            )}
                                        /> */}
                      {/* Applications */}
                      <Typography>
                        {t('context.product.area_app_title')}
                      </Typography>
                      <Typography>
                        {t('context.product.area_app_content')}
                      </Typography>
                      {/* <Autocomplete 
                                            multiple
                                            freesolo
                                            name="applications"
                                            id="applications"
                                            options={applicationOptions}
                                            className="form-select-field"
                                            onBlur={handleBlur}
                                            onChange={(e, options, reason, element) => {
                                                setFieldValue(e.target.name, options)
                                            }}
                                            clearOnBlur={false}
                                            renderInput={(params) => {
                                                <TextField
                                                    {...params}
                                                    required
                                                    margin="dense"
                                                    value={values.application}
                                                    label="Product Applications"
                                                    type="text"
                                                    variant="standard"
                                                    placeholder="Please select or type in a product application and hit Enter"
                                                    error={errors?.applications && touched.applications ? true : false}
                                                    helperText={errors?.applications && touched?.applications ? errors.applications: null}
                                                />
                                            }}
                                        /> */}
                      {/* Selected Applications */}
                      <Typography>
                        {t('context.product.assigned_app')}
                      </Typography>
                      {/* <Autocomplete 
                                            multiple
                                            options={values?.applications}
                                            name="selected_applications"
                                            id="selected_applications"
                                            className="form-select-field"
                                            onBlur={handleBlur}
                                            clearOnBlur={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    values={values?.applications}
                                                />
                                            )}
                                        /> */}
                      {/* Product Description */}

                      {/* Side Bar */}
                      <Grid item>
                        {/* Product Image Large Preview */}
                        <Box component="img" alt="" src="" />
                        {/* Product Images Small Preview */}
                        <Grid></Grid>
                        {/* Picture Upload */}
                        <TextField
                          type="file"
                          variant="outlined"
                          inputProps={{
                            multiple: true,
                          }}
                          id="file-upload"
                          name="file-upload"
                        />
                        <Button
                          onClick={() => {
                            // handleUpload();
                          }}
                        >
                          Add Files
                        </Button>
                        {/* Document Upload */}
                        {/* <Button
                                                onClick={() => {
                                                    setFieldValue("file_type", "document");
                                                    handleUpload();
                                                }}
                                            >
                                                Add Product Document
                                            </Button> */}
                        {/* File Upload */}
                        {/* <Button
                                                onClick={() => {
                                                    setFieldValue("file_type", "file");
                                                    handleUpload();
                                                }}
                                            >
                                                Add 3D File
                                            </Button> */}
                        {/* Status + Buttons */}
                        <TextField
                          required
                          select
                          type="text"
                          id="status"
                          name="status"
                          label={t('form.products.page_status.label')}
                          variant="outlined"
                          value={values?.status ? values.status : ''}
                          onBlur={handleBlur}
                          error={errors.status && touched.status ? true : false}
                        >
                          <MenuItem key={0} value="">
                            {t('form.products.page_status.select')}
                          </MenuItem>
                          <MenuItem key={1} value="draft">
                            {t('form.products.page_status.draft')}
                          </MenuItem>
                          <MenuItem key={2} value="published">
                            {t('form.products.page_status.publish')}
                          </MenuItem>
                          <MenuItem key={3} value="archived">
                            {t('form.products.page_status.archive')}
                          </MenuItem>
                        </TextField>

                        {/* CTA Buttons */}
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="contained"
                            // disabled={}
                            color="info"
                            onClick={() => { }}
                          >
                            {t('buttons.preview')}
                          </Button>
                          <Button
                            variant="contained"
                            // disabled={ }
                            color="primary"
                            onClick={() => { }}
                          >
                            {t('buttons.update')}
                          </Button>
                          <Button
                            variant="contained"
                            // disabled={ }
                            color="error"
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            {t('buttons.back')}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormControl>
              </Form>
              {/* Debugging */}
              {/* InitialValues:<br />
                            {JSON.stringify(formik.initialValues)}<br /><br />

                            Values:<br />
                            {JSON.stringify(formik.values)}<br /><br />

                            Errors:<br />
                            {JSON.stringify(formik.errors)}<br /><br /> */}

              {/* Product Info:<br />
                            {JSON.stringify(productInfo)}<br /><br />

                            Produt Dialog:<br />
                            {JSON.stringify(productDialog)}<br /><br /> */}
            </>
          );
        }}
      </Formik>
    </ProductFormContext.Provider>
  );
};

export { ManageProductContext };
