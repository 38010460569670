import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { SignInForm } from '../../components/account/forms/signin/SignInForm';
import IntuPage from '../../components/layout/IntuPage';

function AccountSignIn() {
  const { t } = useTranslation();

  return (
    <IntuPage
      pageTitle={t('pages.account.SignIn.title')}
      hCentered={true}
      vCentered={true}
    >
      <IntuForm hCentered={true} vCentered={false}>
        <SignInForm />
      </IntuForm>
    </IntuPage>
  );
}
export default AccountSignIn;
