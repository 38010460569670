import React, { useState, useContext, useRef } from 'react';
import ImageIcon from '@mui/icons-material/Image';

import { connectField } from 'uniforms';
import useResponseHandling from '../../hooks/useResponseHandler';
import { useAxiosPrivateData } from '../../hooks/axios/useAxiosPrivate';
import AuthContext from '../../context/providers/AuthProvider';
import {
  Box,
  Button,
  Grid,
  Stack,
  CircularProgress,
  Typography,
  Modal,
} from '@mui/material';

const ImageUploadField = connectField(({ value, onChange }) => {
  // States for Loading and Progress
  const [loading, setLoading] = useState(false);

  // Contexts and Hooks
  const { auth } = useContext(AuthContext);
  const axiosData = useAxiosPrivateData();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const uploadImage = async (file) => {
    // Guard clauses and pre-upload checks
    if (!file || !file.name || !auth?.user_info?._id) {
      handleRegularResponse({
        open: true,
        status: 'error',
        message: 'Parameter missing',
      });
      return '';
    } else if (file.size > 5120000) {
      let fileSize = Math.round(file.size / 1000);
      handleRegularResponse({
        open: true,
        status: 'warning',
        message: `File size is too large (${fileSize} MB). Maximum limit is 5MB.`,
      });
      return '';
    }

    let url = '/api/aws/content/add';
    const reader = new FileReader();

    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = async () => {
        try {
          const base64File = reader.result.split(',')[1];
          const requestData = {
            file: base64File,
            file_type: file.type,
            file_name: file.name,
            folder: `posts/content/${auth?.user_info?._id}`,
          };

          setLoading(true);
          const response = await axiosData.post(
            url,
            JSON.stringify(requestData),
            {
              headers: { 'Content-Type': 'application/json' },
            },
          );

          if (response.status === 200) {
            resolve(response.data.url);
          } else {
            handleErrorResponse({ message: 'Upload failed', response });
            resolve('');
          }
        } catch (err) {
          handleErrorResponse(err);
        } finally {
          setLoading(false);
        }
      };

      reader.onerror = (error) => {
        handleRegularResponse({
          open: true,
          status: 'error',
          message: 'Error in file conversion',
        });
        reject(error);
      };
    });
  };

  const inputRef = useRef(null);

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Grid container sx={{ width: '100%' }}>
      <Stack direction="column" spacing={4}>
        {value ? (
          <img style={{ width: 150 }} src={value} alt="Uploaded" />
        ) : null}

        <input
          ref={inputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={async (e) => {
            const url = await uploadImage(e.target.files[0]);
            onChange(url);
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleButtonClick}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {loading ? `Uploading...` : 'Upload Image'}
        </Button>
      </Stack>
    </Grid>
  );
});

const imagePlugin = {
  Renderer: (props) => {
    // State to control modal visibility
    const [open, setOpen] = useState(false);

    // Function to handle modal open
    const handleOpen = () => setOpen(true);

    // Function to handle modal close
    const handleClose = () => setOpen(false);

    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Box
              onClick={handleOpen}
              style={{ cursor: 'pointer' }}
              display="flex"
              alignItems="center"
              height="100%"
              sx={{
                marginTop: '12px',
                // Additional styling for the image
                '& img': {
                  height: `${props.data.height}${props.data.unit}`,
                  width: `${props.data.width}${props.data.unit}`,
                  maxHeight: {
                    xs: 233,
                    md: `${props.data.height}${props.data.unit}`,
                  },
                  maxWidth: {
                    xs: 350,
                    md: `${props.data.width}${props.data.unit}`,
                  },
                  objectFit: 'contain',
                },
              }}
            >
              <img alt={props.data.name} src={props.data.imageUrl} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="body2">
              {props.data.description}
            </Typography>
          </Grid>
        </Grid>

        {/* Modal for Image View */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="enlarged-image"
          aria-describedby="enlarged-image-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              outline: 'none',
              minWidth: '80vw',
              maxWidth: '95vw',
              maxHeight: '95vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              alt={props.data.name}
              src={props.data.imageUrl}
              style={{
                width: 'auto', // Base width of the image
                height: 'auto', // Maintain aspect ratio
                maxWidth: '100%', // Maximum width relative to its container
                maxHeight: '80vh', // Maximum height relative to the viewport height
              }}
            />
            <Typography align="center" variant="body2" sx={{ mt: 2 }}>
              {props.data.description}
            </Typography>
          </Box>
        </Modal>
      </>
    );
  },
  id: 'contentImage',
  title: 'Image',
  description: 'Image Plugin',
  icon: <ImageIcon />,
  isInlineable: true,
  version: 1,
  controls: [
    {
      title: 'Image',
      controls: {
        type: 'autoform',
        columnCount: 1,
        schema: {
          properties: {
            name: {
              type: 'string',
              uniforms: {
                label: 'Image Name (Alt text)',
                placeholder: 'Give your Image a Name',
              },
            },
            imageUrl: {
              type: 'string',
              uniforms: {
                component: (props) => <ImageUploadField {...props} />,
              },
            },
            description: {
              type: 'string',
              uniforms: {
                label: 'Image Description',
                placeholder: 'Describe or Label your image.',
                multiline: true,
                rows: 4,
              },
            },
          },
        },
      },
    },
    {
      title: 'Size',
      controls: {
        type: 'autoform',
        columnCount: 1,
        schema: {
          properties: {
            unit: {
              type: 'string',
              default: '%',
              enum: ['%', 'px'],
            },
            width: {
              type: 'integer',
              default: 100,
              uniforms: {
                label: 'Width',
              },
            },
            height: {
              type: 'integer',
              default: 100,
              uniforms: {
                label: 'Height',
              },
            },
          },
        },
      },
    },
  ],
};

export default imagePlugin;
