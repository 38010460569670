import { Avatar, Box, Skeleton, Tooltip } from '@mui/material';
import { useContext, useRef } from 'react';
import { useAxiosPrivateData } from '../../../hooks/axios/useAxiosPrivate';
import ResponseContext from '../../../context/providers/ResponseProvider';
import { useTranslation } from 'react-i18next';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { CompanyProfileContext } from '../../../context/providers/CompanyProfileProvider';
import DomainAddIcon from '@mui/icons-material/DomainAdd';

const CompanyProfilePicture = ({
  disabled = false, // Whether the Field is disables or not
}) => {
  const axiosData = useAxiosPrivateData();
  const { values } = useFormikContext();
  const { setErrorDialog } = useContext(ResponseContext);
  const { companyProfile, setCompanyProfile } = useContext(
    CompanyProfileContext,
  );
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { isLoading, setIsLoading } = useContext(ProcessingContext);

  const { t } = useTranslation();

  const errRef = useRef();

  async function handleCompanyLogoUpload(e, values) {
    const [file] = e.target.files;
    if (!file) return;

    const formData = new FormData();
    formData.append('_id', values._id);
    formData.append('file', file);
    // formData.append("file_name", fileName)

    //check if filesize is less than 1mb
    if (file && file.size > 1000000) {
      setErrorDialog({
        open: true,
        title: t('validation.file.file_size_title'),
        message: t('validation.file.file_size_msg', {
          fileSize: Math.round(file.size / 1000),
          maxFileSize: '1MB',
        }),
      });
      return;
    } else {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: 'Updating Company Logo...',
      });
      try {
        const url = '/api/companies/company/update/company-logo';

        const response = await axiosData.put(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const { data, status } = response;
        if (status === 201) {
          const image = `data:${data.data.profile_picture.mimetype};base64,${data.data.profile_picture.buffer.toString('base64')}`;
          setCompanyProfile({
            ...companyProfile,
            profile_picture: file,
            profile_picture_view_url: image,
          });
        }

        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      } catch (error) {
        handleErrorResponse(error);
        errRef.current?.focus();
        console.error('error', error);
      } finally {
        setIsLoading({ status: false, type: 'skeleton', text: '' });
      }
    }
  }

  return (
    <>
      {isLoading.status ? (
        <Skeleton
          animation="wave"
          variant="circular"
          width={150}
          height={150}
        />
      ) : disabled ? (
        <Avatar
          variant="square"
          sx={{
            width: '300px',
            height: '200px',
          }}
        >
          <DomainAddIcon sx={{ fontSize: 80 }} />
        </Avatar>
      ) : (
        <label htmlFor="companyLogo">
          <input
            hidden
            accept="image/*"
            type="file"
            id="companyLogo"
            onChange={(e) => handleCompanyLogoUpload(e, values)}
          />
          <Tooltip
            title={t(
              'components.account.company.CompanyProfilePicture.tooltip',
            )}
          >
            {companyProfile?.profile_picture_view_url?.length ? (
              <>
                {/* Company Logo  */}
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    maxWidth: { xs: 350, md: 300, lg: 250 },
                  }}
                  alt={companyProfile.name}
                  src={companyProfile?.profile_picture_view_url}
                />
              </>
            ) : (
              <Avatar
                variant="square"
                sx={{
                  width: '300px',
                  height: '200px',
                }}
              >
                <DomainAddIcon sx={{ fontSize: 80 }} />
              </Avatar>
            )}
          </Tooltip>
        </label>
      )}
    </>
  );
};

export default CompanyProfilePicture;
