import { useContext } from 'react';
import Spinner, { PendingSpinner } from '../components/spinner/Spinner';
import ProcessingContext from '../context/providers/ProcessingProvider';

const LoadingSpinner = ({ isLoading }) => {
  if (isLoading.status === true && isLoading.type === 'spinner') {
    return <Spinner text={isLoading.text} />;
  } else if (isLoading.type === 'pending') {
    return <PendingSpinner {...isLoading} />;
  } else {
    return null;
  }
};

const useProcessingHandler = () => {
  const { isLoading, setIsLoading } = useContext(ProcessingContext);
  return { isLoading, setIsLoading };
};

export { LoadingSpinner, useProcessingHandler };
