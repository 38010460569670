import React from 'react';
import { Container, Grid } from '@mui/material';
import { HomeHeaderContent } from './HomeHeaderContent.jsx';

const HomeHeaderContainer = () => {
  return (
    <Container maxWidth="auto" disableGutters>
      <Grid className="intu__divider">
        <HomeHeaderContent />
      </Grid>
    </Container>
  );
};

export default HomeHeaderContainer;
