import { useContext, useDebugValue } from 'react';
import IntuThemeContext from '../context/providers/IntuThemeProvider';
import AuthContext from '../context/providers/AuthProvider';

const useIntuTheme = () => {
  const { auth } = useContext(AuthContext);
  const { mode } = useContext(IntuThemeContext);

  useDebugValue(auth, (auth) =>
    auth?.user_info?.theme_mode ? auth?.user_info?.theme_mode : mode,
  );
  return useContext(IntuThemeContext);
};

export default useIntuTheme;
