import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import {
  LoadingSpinner,
  useProcessingHandler,
} from '../../hooks/useProcessingHandler';
import StatusBarComponent from '../status/StatusBar';
import ErrorDialog from '../status/ErrorDialog';

function GlobalLayout() {
  const theme = useTheme();
  const { isLoading } = useProcessingHandler();

  const navBarHeight = parseInt(
    theme.components.MuiAppBar.styleOverrides.root.height,
    10,
  );

  const small = useMediaQuery(theme.breakpoints.down('sm'));

  const footerHeight = small
    ? parseInt(theme.components.IntuNavFooter.height.mobile, 10)
    : parseInt(theme.components.IntuNavFooter.height.desktop, 10);

  const paddingTop = `${navBarHeight}px`;
  const marginBottom = `${footerHeight}px`;

  return (
    <Container
      maxWidth="auto"
      component="main"
      disableGutters
      sx={{
        margin: `${paddingTop} 0 ${marginBottom} 0`,
        flexGrow: 1,
      }}
    >
      <Outlet />
      <LoadingSpinner isLoading={isLoading} />
      <StatusBarComponent />
      <ErrorDialog />
    </Container>
  );
}

export default GlobalLayout;
