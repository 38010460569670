import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ReactPlayer from 'react-player';

const VideoPlayerEmbedded = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="80%"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

VideoPlayerEmbedded.propTypes = {
  embedId: PropTypes.string.isRequired,
};

function VideoPlayer({ videoSource, blur, controls, muted, playing, loop }) {
  // Assume a 16:9 aspect ratio
  const aspectRatioPadding = '56.25%';

  return (
    <Grid
      container
      className="hero-video"
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        paddingTop: aspectRatioPadding,
      }}
    >
      <ReactPlayer
        className="react-player"
        url={videoSource}
        width="100%"
        height="100%"
        controls={controls}
        muted={muted}
        playing={playing}
        loop={loop}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          filter: `blur(${blur}px)`,
          WebkitFilter: `blur(${blur}px)`,
        }}
      />
    </Grid>
  );
}

export { VideoPlayerEmbedded, VideoPlayer };
