import { useContext, useState } from 'react';
import {
  Autocomplete,
  Button,
  TextField,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { LeadContext } from '../../context/providers/leads/LeadContextProvider.jsx';
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import useUser from '../../hooks/useUser.js';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsTracker.jsx';
import LanguagesData from '../../data/LanguagesData.json';
import axios from '../../hooks/axios/useAxios.js';
import { classes } from '../../mui/theme.js';
import { toTitleCase } from '../../helpers/toTitleCase.js';
import { ApplicationsContext } from '../../context/providers/ApplicationsContextProvider.jsx';
import ResponseContext from '../../context/providers/ResponseProvider.jsx';
import { StatusContent } from '../../components/status/StatusContent.jsx';
import UserContext from '../../context/providers/UserInfoProvider.jsx';

const HowItWorksSignUpForm = ({
  initialStep = 0,
  initialType = '',
  title = 'components.howItworksSignUpForm.title',
}) => {
  const { leadInfo, setLeadInfo } = useContext(LeadContext);
  const { host_url } = useContext(UserContext);

  const { statusContext, setStatusContext } = useContext(ResponseContext);
  const { industryOptions } = useContext(ApplicationsContext);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();
  const { user, setUser } = useUser();
  const { t } = useTranslation();
  const { gaEventTracker } = useAnalyticsEventTracker();

  const submitForm = async (values, actions) => {
    try {
      setIsLoading({
        status: true,
        type: 'pending',
        text: t('form.message.custom_page'),
        cta: t('buttons.loading'),
        callback: null,
      });
      const url = '/api/intutec/leads/signup';
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        lead_type: values.lead_type,
        company: values.company,
        industries: values.industries.map((industry) => industry.industry_id),
        host_url,
        origin: 'website',
        coordinates: {
          latitude: user.location.latitude,
          longitude: user.location.longitude,
        },
        language: {
          code: i18n.language,
          name: LanguagesData.find(
            (language) => language.code === i18n.language,
          ).name,
        },
        state: user.location.state,
      };
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      const { data, status } = response;
      const { data: leadData } = data;
      if (status === 200) {
        // Lead Successfully Generated

        gaEventTracker({
          category: 'Lead Management',
          action: 'Event',
          label: 'New Lead Created',
        });

        // Reset Form
        actions.resetForm();

        // Update User Info
        setLeadInfo({ ...leadInfo, data: leadData });

        // Update URI
        setUser({
          ...user,
          industries: values.industries,
        });

        setIsLoading({
          status: true,
          type: 'pending',
          text: t('postload.lead'),
          cta: t('buttons.visit_page'),
          callback: () => {
            // TODO: using a router here does not work
            // We are wiping away our industries on user state because of this redirect
            window.location.href =
              leadData.lead_type === 'investor'
                ? `https://${host_url}/investor?referralID=${leadData.code}`
                : `https://${host_url}/how-it-works?referralID=${leadData.code}`;
            // navigate(`${url.pathname}${url.search}`, { replace: true })
          },
        });
      } else if (status === 241) {
        // Lead found but it is set to active
        setLeadInfo({ data: leadData });
        setStatusContext({
          open: true,
          title: t(
            'context.howItWorks.learn_more_context.status_context_title',
            { name: leadData.first_name },
          ),
          content: t(
            'context.howItWorks.learn_more_context.status_context_content',
            {
              first_name: leadData.first_name,
              last_name: leadData.last_name,
              type: leadData.lead_type,
            },
          ),
          action: (
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              sx={{ width: '200px' }}
              href={
                leadData.lead_type === 'investor'
                  ? `https://${host_url}/investor?referralID=${leadData.code}`
                  : `https://${host_url}/how-it-works?referralID=${leadData.code}`
              }
            >
              {t('buttons.visit_page')}
            </Button>
          ),
        });
        setIsLoading({ status: false, type: '', text: '' });
      } else if (status === 231) {
        // Existing Lead found but is locked/in-active
        setLeadInfo({ data: leadData });
        setStatusContext({
          open: true,
          title: t(
            'context.howItWorks.how_it_works_context.inactive_lead_title',
          ),
          content: t(
            'context.howItWorks.how_it_works_context.inactive_lead_content',
            {
              firstName: leadData.first_name,
              lastName: leadData.last_name,
            },
          ),
        });
        setIsLoading({ status: false, type: '', text: '' });
      } else {
        // Lead exists but is not active
        setIsLoading({ status: false, type: '', text: '' });
        return handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (error) {
      console.error('error', error);
      setIsLoading({ status: false, type: '', text: '' });
      handleErrorResponse(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    emailConf: '',
    lead_type: initialType,
    industries: [],
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        t('validation.name.first_matches'),
      )
      .required(t('validation.name.first_required')),
    last_name: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        t('validation.name.last_matches'),
      )
      .required(t('validation.name.last_required')),
    company: Yup.string().when('type', {
      is: 'manufacturer',
      then: () => Yup.string().required(t('validation.name.company_required')),
    }),
    industries: Yup.array()
      .min(1, t('validation.industry_min'))
      .required(t('validation.industry_required')),
    email: Yup.string()
      .required(t('validation.email.required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        i18n.t('validation.email.invalid'),
      ),
    emailConf: Yup.string()
      .required(t('validation.email.comf_required'))
      .oneOf([Yup.ref('email'), null], t('validation.email.conf_match')),
    lead_type: Yup.string().required(t('validation.lead.type_required')),
  });

  const [activeStep, setActiveStep] = useState(initialStep);

  const steps = [
    {
      label: t('context.howItWorks.learn_more_context.step1_label'),
      description: t('context.howItWorks.learn_more_context.step1_desc'),
    },
    {
      label: t('context.howItWorks.learn_more_context.step2_label'),
      description: t('context.howItWorks.learn_more_context.step2_desc'),
    },
    {
      label: t('context.howItWorks.learn_more_context.step3_label'),
      description: t('context.howItWorks.learn_more_context.step3_desc'),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [type, setType] = useState(initialType);

  const LearnMoreSteps = ({ formik }) => {
    const {
      values,
      setFieldValue,
      setValues,
      errors,
      touched,
      handleBlur,
      initialValues,
    } = formik;

    if (activeStep === 0) {
      // Type selection
      return (
        <TextField
          select
          required
          label={t('form.role.label')}
          placeholder={t('form.role.placeholder')}
          type="text"
          id="lead_type"
          name="lead_type"
          variant="outlined"
          value={values?.lead_type}
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.value);
            setType(e.target.value);
          }}
          onBlur={handleBlur}
          error={errors?.lead_type && touched?.lead_type ? true : false}
          helperText={
            errors?.lead_type && touched?.lead_type ? errors?.lead_type : null
          }
        >
          <MenuItem key={1} value="manufacturer">
            {t('form.global.label.manufacturer')}
          </MenuItem>
          <MenuItem key={2} value="salesrep">
            {t('form.global.label.sales_professional')}
          </MenuItem>
          <MenuItem key={3} value="influencer">
            {t('form.global.label.influencer')}
          </MenuItem>
        </TextField>
      );
    } else if (activeStep === 1) {
      // Industry Selection
      return (
        <Autocomplete
          multiple
          filterSelectedOptions
          id="industries"
          name="industries"
          value={values?.industries}
          options={industryOptions?.sort(
            (a, b) => -b.industry_name.localeCompare(a.industry_name),
          )}
          getOptionLabel={(option) => option.industry_name}
          className="form-select-field"
          onChange={(e, options) => {
            setFieldValue('industries', options);
          }}
          onBlur={handleBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              variant="outlined"
              label={t('form.industry.label')}
              error={touched?.industries && errors?.industries ? true : false}
              helperText={
                touched?.industries && errors?.industries
                  ? errors?.industries
                  : null
              }
            />
          )}
        />
      );
    } else if (activeStep === 2) {
      // Company Details
      return (
        <>
          {type === 'manufacturer' && (
            <TextField
              required={values.lead_type === 'manufacturer'}
              id="company"
              name="company"
              className="form-select-field"
              aria-invalid={errors.company ? 'true' : 'false'}
              aria-describedby="uidnote"
              variant="outlined"
              label={t(
                'components.account.company.CompanyNameField.name.label',
              )}
              margin="dense"
              type="text"
              placeholder={t(
                'components.account.company.CompanyNameField.name.placeholder',
              )}
              onChange={(e) => {
                setFieldValue(e.target.name, e.target.value);
              }}
              onBlur={handleBlur}
              value={values?.company}
              error={errors?.company && touched?.company ? true : false}
              helperText={
                errors?.company && touched?.company ? errors.company : null
              }
            />
          )}
          <Stack
            spacing={{ xs: 1, sm: 3, md: 1 }}
            direction="row"
            sx={{ paddingTop: '10px' }}
            style={classes.root}
            alignItems="stretch"
          >
            <TextField
              required
              id="first_name"
              name="first_name"
              className="form-select-field"
              aria-invalid={errors.first_name ? 'true' : 'false'}
              aria-describedby="uidnote"
              variant="outlined"
              label={t('form.first_name')}
              type="text"
              placeholder={t('form.first_name')}
              onChange={(e) => {
                setFieldValue(e.target.name, toTitleCase(e.target.value));
              }}
              inputProps={{
                autoComplete: 'given-name',
              }}
              onBlur={handleBlur}
              value={values?.first_name}
              error={errors.first_name && touched.first_name ? true : false}
              helperText={
                errors.first_name && touched.first_name
                  ? errors.first_name
                  : null
              }
            />
            <TextField
              required
              id="last_name"
              name="last_name"
              className="form-select-field"
              aria-invalid={errors.last_name ? 'true' : 'false'}
              aria-describedby="uidnote"
              variant="outlined"
              label={t('form.last_name')}
              type="text"
              placeholder={t('form.last_name')}
              onChange={(e) => {
                setFieldValue(e.target.name, toTitleCase(e.target.value));
              }}
              inputProps={{
                autoComplete: 'family-name',
              }}
              onBlur={handleBlur}
              value={values?.last_name}
              error={errors.last_name && touched.last_name ? true : false}
              helperText={
                errors.last_name && touched.last_name ? errors.last_name : null
              }
            />
          </Stack>
          <TextField
            required
            id="email"
            name="email"
            autoComplete="off"
            className="form-select-field"
            aria-invalid={errors.email ? 'true' : 'false'}
            aria-describedby="uidnote"
            variant="outlined"
            label={t('form.email.label')}
            type="text"
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
            onBlur={handleBlur}
            placeholder={t('form.email.placeholder')}
            inputProps={{
              autoComplete: 'off',
            }}
            value={values.email}
            error={errors.email && touched.email ? true : false}
            helperText={errors.email && touched.email ? errors.email : null}
          />
          <TextField
            required
            id="emailConf"
            name="emailConf"
            autoComplete="off"
            className="form-select-field"
            aria-invalid={errors.emailConf ? 'true' : 'false'}
            aria-describedby="uidnote"
            variant="outlined"
            label={t('form.email.conf')}
            type="text"
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
            onBlur={handleBlur}
            placeholder={t('form.email.placeholder')}
            inputProps={{
              autoComplete: 'off',
            }}
            value={values.emailConf}
            error={errors.emailConf && touched.emailConf ? true : false}
            helperText={
              errors.emailConf && touched.emailConf ? errors.emailConf : null
            }
          />
        </>
      );
    }
  };

  return (
    <Formik
      values={formik.values}
      initialValues={formik.initialValues}
      errors={formik.errors}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={(values, actions) => submitForm(values, actions)}
    >
      {(formik) => {
        const { isValid } = formik;
        return (
          <>
            {statusContext.open === true ? (
              <>
                <StatusContent />
              </>
            ) : (
              <Grid container item alignItems="flex-start" direction="column">
                <Grid item xs={12} width="100%">
                  <Grid
                    item
                    // className="intu__page_content"
                    sx={{ marginBottom: '30px' }}
                  >
                    <Grid item>
                      <Typography
                        variant="h4"
                        textAlign={{ xs: 'center', md: 'left' }}
                      >
                        {t(title)}
                      </Typography>
                    </Grid>
                    {/* Stepper */}
                    <Form role="form" noValidate="novalidate">
                      <FormControl>
                        <Box
                          width="100%"
                          textAlign={{ xs: 'center', md: 'left' }}
                        >
                          <Typography variant="body" mt={2} mb={1}>
                            {steps[activeStep].description}
                          </Typography>
                          <Grid>
                            <Grid item xs={12}>
                              <Stack
                                spacing={2}
                                style={classes.root}
                                sx={{ marginBottom: '20px', marginTop: '30px' }}
                              >
                                <LearnMoreSteps formik={formik} />
                              </Stack>
                            </Grid>
                          </Grid>
                          <Box display="flex" flexDirection="row" pt={2}>
                            <Button
                              color="secondary"
                              variant="contained"
                              disabled={
                                activeStep === 0 ||
                                (initialStep === 1 && activeStep === 1)
                              }
                              onClick={handleBack}
                              mr={1}
                            >
                              {t('buttons.back')}
                            </Button>
                            <Box flex="1 1 auto" />
                            {activeStep === steps.length - 1 ? (
                              <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={!isValid}
                                sx={{ width: '200px' }}
                              >
                                {t('buttons.learn_more')}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={handleNext}
                              >
                                {t('buttons.confirm')}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </FormControl>
                    </Form>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default HowItWorksSignUpForm;
