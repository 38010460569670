import { useContext, useEffect } from 'react';
import { Container } from '@mui/material';
import './home.css';
import HomeHeaderContainer from '../../components/home/headers/HomeHeaderContainer.jsx';
import { LayoutBodyContext } from '../../context/providers/LayoutContextProvider.jsx';
import { useTranslation } from 'react-i18next';

function Home() {
  const { setLayoutTitle } = useContext(LayoutBodyContext);

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home',
  });

  useEffect(() => {
    // Set the Page Title
    setLayoutTitle(t('title'));
  }, []);

  return (
    <Container maxWidth="auto" disableGutters sx={{ position: 'relative' }}>
      <HomeHeaderContainer />
    </Container>
  );
}

export default Home;
