import { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import useAuth from '../../../../hooks/useAuth.js';
import useUser from '../../../../hooks/useUser.js';
import ApplicationsField from '../../../applications/ApplicationsField.jsx';
import { CompanyProfileContext } from '../../../../context/providers/CompanyProfileProvider.jsx';
import ProductTypeField from '../../../product-type/ProductTypeField.jsx';

const ApplicationsAndProductsForm = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.account.SignUpComplete.ApplicationsAndProducts.form',
  });

  const { t: transFields } = useTranslation('fields');
  const { t: transTypes } = useTranslation('types');

  const { auth } = useAuth();
  const { user } = useUser();

  const { newCompany } = useContext(CompanyProfileContext);

  const { errors, touched } = useFormikContext();

  const [titleIndustries, setTitleIndustries] = useState('');
  const [titleProducts, setTitleProducts] = useState('');
  const [descriptionIndustries, setDescriptionIndustries] = useState('');
  const [descriptionProducts, setDescriptionProducts] = useState('');

  useEffect(() => {
    // Set Form Fabels
    let userLabel;
    if (auth.user_info.account_type === 'manufacturer') {
      // User Label
      userLabel = t('form.role.manufacturer');

      // Form subtitled
      setTitleIndustries(t('applications.manufacturer.title'));
      setTitleProducts(t('products.manufacturer.description'));
    } else if (auth.user_info.account_type === 'salesrep') {
      // User Label
      userLabel = t('form.global.label.salesrep');

      // Form subtitled
      setTitleIndustries(t('applications.salesrep.title'));
      setTitleProducts(t('products.salesrep.title'));
    } else if (auth.user_info.account_type === 'influencer') {
      // User Label
      userLabel = t('form.global.label.influencer');

      // Form subtitled
      setTitleIndustries(t('applications.influencer.title'));
      setTitleProducts(t('products.influencer.description'));
    }

    setDescriptionIndustries(
      transFields('global.info.multiSelect', {
        type: transTypes('applications'),
      }),
    );

    setDescriptionProducts(
      transFields('global.info.multiSelect', {
        type: transTypes('products'),
      }),
    );
  }, [auth?.user_info?.account_type, user.location, i18n.language]);

  return (
    <Stack spacing={4} sx={{ paddingBottom: '50px' }}>
      <ApplicationsField
        fieldTitle={titleIndustries}
        fieldDescription={descriptionIndustries}
        disabled={
          auth.user_info?.account_type === 'manufacturer' && !newCompany
            ? true
            : false
        }
      />
      <ProductTypeField
        fieldTitle={titleProducts}
        fieldDescription={descriptionProducts}
        disabled={
          auth.user_info?.account_type === 'manufacturer' && !newCompany
            ? true
            : false
        }
      />
    </Stack>
  );
};

export default ApplicationsAndProductsForm;
