import React from 'react';
import Rocket from '../../../../assets/accelerate.png';
import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SignUpSuccessForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid className="content" sx={{ marginBottom: '8rem' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ paddingBottom: '50px' }}>
          <h1 className="content-centered">{t('form.success')}</h1>
        </Grid>
        <Grid item xs={12} md={8} sx={{ paddingBottom: '100' }}>
          <h3>{t('form.account.success.completed_acct')}</h3>
          <h3>{t('form.account.success.waiting_list')}</h3>
          <h3>{t('form.account.success.complete_profile')}</h3>
          <Button
            variant="contained"
            type="submit"
            onClick={(e) => {
              navigate('/user/dashboard');
            }}
          >
            {t('buttons.complete_profile')}
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            component="img"
            sx={{
              display: 'block',
              marginTop: 'auto',
              marginLeft: 'auto',
              marginRight: 'auto',
              position: 'fixed',
              bottom: 0,
              right: { md: 20, xs: 200 },
              top: { md: 0, xs: 50 },

              width: 250,

              maxWidth: { md: 250, xs: 150 },
            }}
            alt={t('form.account.success.img_alt')}
            src={Rocket}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUpSuccessForm;
