import { useTranslation } from 'react-i18next';
import { CompanyProfileForm } from '../../context/company/CompanyProfileForm';
import { IntuForm } from '../../components/layout/IntuForm';
import IntuPage from '../../components/layout/IntuPage';
import { BrandsProvider } from '../../context/providers/BrandsProvider';

function CompanyProfile() {
  const { t } = useTranslation();

  return (
    <IntuPage
      pageTitle={t('pages.company.companyProfile.title')}
      pageDescription={t('pages.users.UserProfilePage.description')}
      hCentered={true}
    >
      <IntuForm>
        <CompanyProfileForm />
      </IntuForm>
    </IntuPage>
  );
}

export default CompanyProfile;
