const NavContainer = {
    visible: {
        opacity: 1,
        transition: {
            y: { velocity: 10 },
            duration: 0.5
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            y: { velocity: 10 },
            duration: 0.5
        }
    }
}

const NavList = {
    visible: {
        opacity: 1,
        transition: {
            delayChildren: 0.1,
            staggerChildren: 0.5
        }
    },
    hidden: { opacity: 0 }
}

const NavItem = {
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            x: { stiffness: 500, velocity: 100 },
            duration: 2,
            type: "spring"
        }
    },
    hidden: {
        x: -150,
        opacity: 0,
        transition: {
            x: { stiffness: 500, velocity: 100 }
        }
    }
}

const LanguageItem = {
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
            duration: 2,
            type: "spring"
        }
    },
    hidden: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
}

const ProductListContainer = {
    visible: {
        opacity: 1,
        transition: {
            ease: "easeOut",
            duration: 1
        }
    },
    hidden: {
        opacity: 0
    }
}

const ProductListItem = {
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            x: { stiffness: 500, velocity: 100 },
            duration: 2,
            type: "spring"
        }
    },
    hidden: {
        x: -150,
        opacity: 0,
        transition: {
            x: { stiffness: 500, velocity: 100 }
        }
    }
}

export { NavContainer, NavList, NavItem, LanguageItem, ProductListContainer, ProductListItem }