import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Alert, Snackbar } from '@mui/material';
import ResponseContext from '../../context/providers/ResponseProvider';

const StatusBarComponent = () => {
  const { statusMsg, setStatusMsg } = useContext(ResponseContext);

  return (
    <Snackbar
      open={statusMsg?.open}
      autoHideDuration={6000}
      onClose={() => setStatusMsg({ open: false, status: 'info', message: '' })}
    >
      <Alert
        onClose={() => setStatusMsg({ open: false, status: '', message: '' })}
        severity={!statusMsg?.status === 'info' ? 'info' : statusMsg?.status}
        sx={{ width: '100%' }}
      >
        {ReactHtmlParser(statusMsg?.message)}
      </Alert>
    </Snackbar>
  );
};

export default StatusBarComponent;
