import IntuPage from '../../components/layout/IntuPage.jsx';
import { CreditsExplainerContent } from '../../context/general/CreditsExplained.jsx';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n.js';

const CreditsExplainer = () => {
  const { t } = useTranslation();
  return (
    <IntuPage
      pageTitle={i18n.format(t('credits.title'), 'uppercase')}
      hCentered
    >
      <CreditsExplainerContent />
    </IntuPage>
  );
};

export default CreditsExplainer;
