// import { useAxiosPrivate } from "../hooks/axios/useAxiosPrivate";
import axios from '../hooks/axios/useAxios';
import i18n from '../i18n.js';
import { axiosPrivate } from '../hooks/axios/axios.js';

async function getProductCategories() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/families/list';

  try {
    let request = await axios.get(url, {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    let response = request?.data;

    if (request?.status === 200) {
      return response;
    }
  } catch (err) {
    return err;
  }
}

async function getProductGroups() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/groups/list';

  try {
    let request = await axios.get(url, {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    let response = request?.data;
    // console.log(response)

    if (request?.status === 200) {
      return response;
    }
  } catch (err) {
    return err;
  }
}

async function getProductTypes() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/types/list';

  try {
    let response = await axios.get(url, { signal });
    if (response?.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

async function addProductTypes(data) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/types/add';

  let req_fields = [];
  !data &&
    req_fields.push(
      i18n.t('types.new', { type: i18n.t('types.product_types') }),
    );
  !Array.isArray(data) &&
    req_fields.push(
      i18n.t('validation.req_data_type', {
        dataType: i18n.t('types.data.array'),
      }),
    );

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validation.missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    const payload = {
      data: data.map((productType) => {
        const { family_name, ...newProductTypeObj } = Object.assign(
          {},
          productType,
        );
        return newProductTypeObj;
      }),
    };
    let request = await axiosPrivate.post(url, JSON.stringify(payload), {
      signal,
    });

    let response = request?.data;
    // console.log(response)

    if (request?.status) {
      return response;
    }
  } catch (err) {
    return err;
  }
}

async function searchProductTypes(payload) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/types/search';

  // Guard Clause
  let req_fields = [];
  !payload && req_fields.push(i18n.t('routes.req_fields'));

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validation.missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    let request = await axios.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    let response = request?.data;

    if (request?.status) {
      return response;
    }
  } catch (err) {
    return err;
  }
}

async function searchProductsByMPN(data) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/products/search';
  try {
    const payload = {
      mpn: data,
    };

    let request = await axios.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    let response = request?.data;

    if (request?.status) {
      return response;
    }
  } catch (err) {
    return err;
  }
}

async function getAllProducts() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/products';

  try {
    let response = await axios.get(url, { signal });

    if (response?.statusCode === 200) {
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

async function addSingleProductType({
  productGroup,
  productFamily,
  newProductType,
}) {
  const payload = {
    productGroup,
    productFamily,
    newProductType,
  };

  try {
    const response = await axiosPrivate.post(
      '/api/product-categories/types/add-single',
      JSON.stringify(payload),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
  } catch (error) {
    console.error('error', error);
  }
}

export {
  getProductCategories,
  getProductGroups,
  getProductTypes,
  addProductTypes,
  searchProductTypes,
  getAllProducts,
  addSingleProductType,
};
