import React, { useContext } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import ResponseContext from '../../context/providers/ResponseProvider';

const StatusContent = () => {
  const { statusContext } = useContext(ResponseContext);

  return (
    <Container maxWidth="auto" className="intu__section">
      <Grid
        container
        className="intu__section intu__page_content"
        spacing={4}
        alignContent="center"
        direction="column"
        sx={{
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} textAlign="center">
          <Typography variant="h1">{statusContext.title}</Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="body1">{statusContext.content}</Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="body1">{statusContext.action}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export { StatusContent };
