import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAnalyticsPageViewTracker } from '../../hooks/useAnalyticsTracker';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthContext from './AuthProvider';
import {
  useAxiosPrivate,
  useAxiosPrivateData,
} from '../../hooks/axios/useAxiosPrivate';

export const PostContext = createContext({});

export const PostProvider = ({ children }) => {
  // Get Context
  const { auth, isAdmin } = useContext(AuthContext);

  // Navigation
  const navigate = useNavigate();

  // Set States
  const [postInfo, setPostInfo] = useState({
    _id: '',
    link: '',
    description: '',
    tags: [],
    activity: [],
    clicked: null,
    content: {},
    title: '',
    created: '',
    modified: '',
    image_url: '',
    full_url: '',
    author: {
      _id: '',
      email: '',
      name: '',
    },
    status: 'draft',
    inEditMode: false,
  });

  const [postImage, setPostImage] = useState({
    image: '',
    imageBlob: '',
  });

  // Viewport
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 767) {
        setMobileView(false);
      } else if (window.innerWidth < 767) {
        setMobileView(true);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
  });

  // Edit Post Handler
  const handleEditPost = (edit, values) => {
    if (edit === false) {
      // setIsEditMode(false)
      setPostInfo({
        ...values,
        inEditMode: false,
      });
    } else if (edit === true) {
      if (auth?.user_info?.full_name === postInfo?.author?.name || isAdmin) {
        // setIsEditMode(true)
        setPostInfo({
          ...postInfo,
          inEditMode: true,
        });
      }
    }
  };

  // SetUp Axios
  const axios = useAxiosPrivate();
  const axiosData = useAxiosPrivateData();

  // Location hook
  const location = useLocation();

  // Navigation
  const host_url = window.location.host;

  // google page view tracking tracking
  let { gaPageViewTracker } = useAnalyticsPageViewTracker();

  gaPageViewTracker({
    type: 'pageview',
    page: document.location.pathname,
    name: 'How it works Page (Sutomized)',
  });

  return (
    <PostContext.Provider
      value={{
        host_url,
        location,
        postInfo,
        setPostInfo,
        postImage,
        setPostImage,
        mobileView,
        setMobileView,
        handleEditPost,
        axios,
        axiosData,
        auth,
        isAdmin,
        navigate,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
