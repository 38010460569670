import {
  Autocomplete,
  Skeleton,
  TextField,
  createFilterOptions,
  Paper,
  ListSubheader,
  Grid,
  IconButton,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { toTitleCase } from '../../helpers/toTitleCase.js';
import FormikFieldWrapper from '../formik/FormikFieldWrapper.jsx';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import { useContext, useState } from 'react';
import { getNestedValue } from '../../helpers/getNestedValuesIn.js';
import NewProductTypeDialog from './NewProductTypeDialog.jsx';

// Response & Loading Handlers
import { ProductTypeContext } from '../../context/providers/ProductTypeContextProvider.jsx';
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';

const CustomPaperComponent = ({
  children,
  className,
  transNS,
  transPrefix,
  onClose,
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const theme = useTheme();

  return (
    <Paper className={className} sx={{ margin: 0, padding: 0 }}>
      <ListSubheader sx={{ padding: 0 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: theme.palette.green.main,
            padding: '0 1rem 0 1rem',
          }}
        >
          <Grid item>{t('select')}</Grid>
          <Grid item sx={{ marginLeft: 'auto' }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </ListSubheader>
      {children}
    </Paper>
  );
};

const ProductTypefieldContent = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Descrription
  fieldID = 'products', // Define the Fields Name and ID
  autocompleteID = 'products', // Define the Fields Name and ID
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'ProductTypesField', // KeyPrefix which holds label and placeholder
  transition = true, // Transition true = fade in, false = fade out
  disabled = false, // Disable the Field
  required = false, // Field Required
}) => {
  // Context
  const { values, errors, touched, setFieldValue, setValues, handleBlur } =
    useFormikContext();

  const { isLoading } = useProcessingHandler();

  const {
    newProductList,
    setNewProductList,
    setNewProductDialogOpen,
    setProductNew,
    productOptions,
  } = useContext(ProductTypeContext);

  // Mui Options
  const filter = createFilterOptions();

  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  // Handle Product Type Selection / Change
  const handleProductsChange = (e, options, reason, element) => {
    if (reason === 'createOption') {
      const properOption = toTitleCase(element.option);
      setNewProductDialogOpen(true);
      setProductNew({
        type_name: properOption,
        product_family: { family_id: '', family_name: '' },
        product_group: { group_id: '', group_name: '' },
      });
    } else if (
      reason === 'selectOption' &&
      element.option.type_name?.search('Add ') >= 0
    ) {
      // Add new product by selecting Add
      const properOption = toTitleCase(element.option.inputValue);
      setNewProductDialogOpen(true);
      setProductNew({
        type_name: properOption,
        product_family: { family_id: '', family_name: '' },
        product_group: { group_id: '', group_name: '' },
      });
    } else if (reason === 'removeOption') {
      const elementToRemove = element.option;
      const filtered = newProductList.filter(
        (item) =>
          item.type_name !== elementToRemove.type_name &&
          item.group_id !== elementToRemove.group_id,
      );
      setFieldValue('products', options);
      setNewProductList(filtered);
    } else {
      setFieldValue('products', options);
    }
  };

  // Handle Selection List
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <>
      <FormikFieldWrapper
        fieldTitle={fieldTitle}
        fieldDescription={fieldDescription}
        transition={transition}
      >
        <Autocomplete
          multiple
          freeSolo
          disableCloseOnSelect
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          id={fieldID}
          name={fieldID}
          filterSelectedOptions
          value={getNestedValue(values, autocompleteID)}
          required={required}
          disabled={disabled || isLoading?.status ? true : false}
          selectOnFocus
          handleHomeEndKeys
          popupIcon={false}
          options={productOptions?.sort(
            (a, b) => -b.group_name.localeCompare(a.group_name),
          )}
          getOptionLabel={(option) => option?.type_name}
          groupBy={(option) => option.group_name}
          className="form-select-field"
          onChange={handleProductsChange}
          noOptionsText={t('no_option')}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '' && filtered.length === 0) {
              filtered.push({
                inputValue: params.inputValue,
                type_name: `${t('add')} "${params.inputValue}"`,
              });
            }
            return filtered;
          }}
          onBlur={handleBlur}
          sx={{ paddingBottom: '50px' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              required={required}
              label={!disabled ? t('label') : null}
              placeholder={!disabled ? t('placeholder') : null}
              error={errors[fieldID] && touched[fieldID] ? true : false}
              helperText={
                errors[fieldID] && touched[fieldID] ? errors[fieldID] : null
              }
            />
          )}
          PaperComponent={(props) => (
            <CustomPaperComponent
              {...props}
              onClose={handleClose}
              transNS={transNS}
              transPrefix={transPrefix}
            />
          )}
        />
      </FormikFieldWrapper>
      <NewProductTypeDialog />
    </>
  );
};

const ProductTypeField = (props) => {
  return <ProductTypefieldContent {...props} />;
};
export default ProductTypeField;
