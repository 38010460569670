import { createContext, useState } from 'react';

export const NavBarDrawerContext = createContext();

export const NavBarDrawerContextProvider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <NavBarDrawerContext.Provider
      value={{
        handleDrawerOpen,
        handleDrawerClose,
        drawerOpen,
        setDrawerOpen,
      }}
    >
      {children}
    </NavBarDrawerContext.Provider>
  );
};
