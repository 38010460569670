import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { IntuContactList } from '../../components/admin/IntuContactList';
import { IntuCompanyInfoProvider } from '../../context/providers/IntuCompanyInfoProvider';

function IntuContacts() {
  const { t } = useTranslation();
  return (
    <IntuForm
      form="Contacts"
      formTitle={t('pages,IntuContacts.title')}
      formText={t('pages.IntuContacts.description')}
    >
      <IntuCompanyInfoProvider>
        <IntuContactList />
      </IntuCompanyInfoProvider>
    </IntuForm>
  );
}

export default IntuContacts;
