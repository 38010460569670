import { Grid } from '@mui/material';

// The rich text area plugin (Slate)
import '@in2tec/plugins-slate/lib/index.css';

// Video Plugin
import videoPlugin from './plugins/videoPlugin';

// Image Plugin
import imagePlugin from './plugins/imagePlugin';

// Divider & Spacer PLugin
import divider from './plugins/dividerPlugin';
import spacer from '@in2tec/plugins-spacer';

// Slate Plugin
import { customSlate } from './plugins/pageColorPicker';

// Chart PLugins
import pieChartPlugin from './plugins/pieChartPlugin';
import xyChartPlugin from './plugins/xyChartPlugin';

// Content Ceation Plugin
import contentCreationPlugin from './plugins/contentCreationPlugin';

// Page Editor
import Editor from '@in2tec/page-editor';
import '@in2tec/page-editor/lib/index.css';

const IntuPageBuilder = (props) => {

    let formik = props?.formik;
    let values = props?.values;
    let readOnly = props?.readOnly;

    // Cell Plugins
    const cellPlugins = [
        customSlate,
        // divider, 
        spacer,
        pieChartPlugin,
        xyChartPlugin,
        // imagePlugin,
        videoPlugin,
        // contentCreationPlugin
    ];

    return (
        <Grid container direction="column" sx={{ marginBottom: '2rem' }}>
            <Grid item xs={12}>
                <Editor
                    cellPlugins={cellPlugins}
                    value={values.content}
                    onChange={(newContent) => {
                        formik.setFieldValue("content", newContent)
                    }}
                    readOnly={readOnly}
                />
            </Grid>
        </Grid>
    )
}

export { IntuPageBuilder }