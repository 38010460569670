import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogout from '../../../hooks/useLogout';
import { NavBarDrawerContext } from '../../../context/providers/NavBarDrawerContextProvider';

// Components
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import BusinessIcon from '@mui/icons-material/Business';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { List } from '@mui/material';

// Animations
import { motion } from 'framer-motion';
import { NavList, NavItem } from '../../../framer-motion/animations';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import useAuth from '../../../hooks/useAuth';
import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsTracker';

const AllAccountsNavItems = () => {
  const navigate = useNavigate();

  const { auth, isAdmin } = useAuth();

  // Integrate Theme
  const theme = useTheme();

  // Get Drawer Context
  const { handleDrawerClose } = useContext(NavBarDrawerContext);

  const { gaEventTracker } = useAnalyticsEventTracker();

  const { t } = useTranslation('buttons', { keyPrefix: 'navigation' });

  // Load Logout Hook
  const logoutUser = useLogout();

  function handleNavigate(target) {
    if (target === 'Dashboard') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'User Dashboard',
      });
      if (isAdmin === true) {
        return navigate('./admin/Dashboard');
      } else {
        return navigate('./user/Dashboard');
      }
    } else if (target === 'UserProfile') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'User Profile',
      });
      return navigate('./user/profile');
    } else if (target === 'CompanyProfile') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Company Profile',
      });
      return navigate('./company/profile');
    } else if (target === 'CompleteAccount') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Complete Account',
      });
      return navigate('./account/complete');
    } else if (target === 'SignOut') {
      logoutUser();
    }
    handleDrawerClose();
  }

  const generalNavItems = [
    {
      index: 1,
      key: 'Home',
      label: t('home'),
      icon: <HomeRoundedIcon />,
      target: () => handleNavigate('Dashboard'),
      event: 'User Dashboard',
      visible: auth?.user_info?.account_complete,
    },
    {
      index: 2,
      key: 'Profile',
      label: t('user_profile'),
      icon: <PersonIcon />,
      target: () => handleNavigate('UserProfile'),
      event: 'User Profile',
      visible: auth?.user_info?.account_complete,
    },
    {
      index: 3,
      key: 'Company',
      label: t('company_profile'),
      icon: <BusinessIcon />,
      target: () => handleNavigate('CompanyProfile'),
      event: 'Company Profile',
      visible:
        auth?.user_info?.company && auth?.user_info?.account_type !== 'intutec',
    },
    {
      index: 4,
      key: 'Complete Account',
      label: t('complete_account'),
      icon: <CheckCircleOutlineIcon />,
      target: () => handleNavigate('CompleteAccount'),
      event: 'Account Completion',
      visible: auth?.user_info?.account_complete === false,
    },
    {
      index: 5,
      key: 'Logout',
      label: t('sign_out'),
      icon: <LogoutIcon />,
      target: () => handleNavigate('SignOut'),
      event: 'Sign Out',
      visible: true,
    },
  ];
  return (
    <List>
      {generalNavItems.map(
        (menuItem) =>
          menuItem.visible && (
            <motion.div
              variants={NavList}
              initial="hidden"
              animate="visible"
              key={menuItem.key}
            >
              <ListItem key={menuItem.key} sx={{ display: 'block' }}>
                <motion.div variants={NavItem} whileHover={{ scale: 1.1 }}>
                  <ListItemButton
                    sx={{
                      height: '2.5rem',
                      justifyContent: 'initial',
                      px: 2.5,
                    }}
                    onClick={() => {
                      menuItem.target();
                      handleDrawerClose();
                    }}
                  >
                    <ListItemIcon sx={theme.components.DrawerListItem}>
                      {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={menuItem.label} disableTypography />
                  </ListItemButton>
                </motion.div>
              </ListItem>
            </motion.div>
          ),
      )}
    </List>
  );
};

export default AllAccountsNavItems;
