import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import './headers.css';
import IntuPlaceHolderBanner from '../../assets/core/intuPlacerBanner.png';
import { useTranslation } from 'react-i18next';

// Action Icons
import ImageIcon from '@mui/icons-material/Image';

const PostHeaderContent = (props) => {
  const { t } = useTranslation();
  const { formik, title, image, handlePostImageChange, states } = props;

  return (
    <Grid
      item
      className="article"
      sx={{
        backgroundImage: `url(${image > '' ? image : IntuPlaceHolderBanner})`,
        minHeight: '600px',
      }}
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        textAlign="center"
      >
        <Grid item>
          <Typography variant="h1" className="header" sx={{ color: 'white' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          {states?.inEditMode === true && (
            <Button
              variant="contained"
              component="label"
              color="tertiary"
              endIcon={<ImageIcon />}
            >
              {t('buttons.change_img')}
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handlePostImageChange(e, formik.values, formik)
                }
                hidden
              />
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        sx={{
          minWidth: 300,
          width: 'auto',
          height: 'auto',
        }}
      ></Grid>
    </Grid>
  );
};

export { PostHeaderContent };
