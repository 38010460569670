import React, { useEffect, useRef, useState } from 'react';

import ReactMapboxGl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Grid } from '@mui/material';

ReactMapboxGl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

function IntutecLocationsMap() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-74.00818);
  const [lat, setLat] = useState(40.73023);
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new ReactMapboxGl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/intutecio/clmbwv14801ay01pf80c7hkm6',
      projection: 'globe',
      center: [lng, lat],
      zoom: zoom,
      attributionControl: false,
      // dragPan: false,
      // dragRotate: false,
      // scrollZoom: false,
      // touchZoom: false,
      // touchRotate: false,
      // keyboard: false,
      // doubleClickZoom: false
    });

    const locations = [
      {
        name: 'New York',
        address: '609 Greenwich Street<br/>New York, NY 10014',
        coordinates: [-74.00818, 40.73023],
      },
      {
        name: 'Munich',
        address: 'Oskar-von-Miller-Ring 20, 80333 München',
        coordinates: [11.582, 48.1351],
      },
    ];

    // Create default markers
    locations.map((location) =>
      new ReactMapboxGl.Marker({
        color: '#A8C957',
        width: '5rem',
        height: '5rem',
        borderRadius: '50%',
        cursor: 'pointer',
      })
        .setLngLat(location.coordinates)
        .setPopup(
          new ReactMapboxGl.Popup({ offset: 25 }) // add popups
            .setHTML(`<h3>${location.name}</h3><p>${location.address}</p>`),
        )
        .addTo(map.current),
    );
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  return (
    <Grid item xs={12}>
      <div className="sidebar">
        Longitude: {lng} | Latitude: {lat}
      </div>
      <div ref={mapContainer} className="map-container" />
    </Grid>
  );
}
export default IntutecLocationsMap;
