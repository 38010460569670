import { useContext, useEffect } from 'react';
import axios from '../../../hooks/axios/useAxios.js';
import videoManufacturer from '../../../assets/videos/how-it-works-manufacturers.mp4';
import videoInfluencers from '../../../assets/videos/how-it-works-sales-reps.mp4';
import { Formik, useFormik } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import i18n from 'i18next';
import Exposure from '../../../assets/Exposure.png';
import Reward from '../../../assets/how-it-works/Reward-New.png';
import RewardIcon from '../../../assets/how-it-works/reward-icon.png';
import CommissionIcon from '../../../assets/how-it-works/commission-icon.png';
import {
  TextField,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
  Slider,
} from '@mui/material';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ShareIcon from '@mui/icons-material/Share';
import TimelineIcon from '@mui/icons-material/Timeline';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import PercentIcon from '@mui/icons-material/Percent';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { classes } from '../../../mui/theme.js';

import LeadHeaderContainer from '../header/LeadHeaderContainer.jsx';

import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';
import { CommissionCalculator } from '../calculators/CommissionCalculator.jsx';
import { ApplicationsContext } from '../../../context/providers/ApplicationsContextProvider.jsx';
import useIntuTheme from '../../../hooks/useIntuTheme.js';
import { WhatMakesUsDifferent } from '../containers/WhatMakesUsDifferent.jsx';
import { WhySignUp } from '../containers/sales/WhySignUp.jsx';
import { IntroSalesReps } from '../intro/IntroSalesReps.jsx';
import { IntroManufacturers } from '../intro/IntroManufacturers.jsx';
import { YourAdvantages } from '../containers/manufacturers/YourAdvantages.jsx';
import { HowToBecomeASalesRep } from '../containers/sales/HowToBeomeASalesRep.jsx';
import { IndependentButProtected } from '../containers/sales/IndependentButProtected.jsx';
import { HowToSignUpManufacturers } from '../containers/manufacturers/HowToSignUpManufacturers .jsx';
import { SignUp } from '../containers/SignUp.jsx';
import { ExistingCustomersByIndustry } from '../containers/manufacturers/ExistingCustomers.jsx';

const valueText = (value, sign) => {
  let formattedNumber = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${formattedNumber} ${sign}`;
};

const LeadContent = () => {
  const {
    leadInfo,
    setLeadInfo,
    leadContext,
    setLeadContext,
    leadType,
    setLeadType,
  } = useContext(LeadContext);

  const { industryNames } = useContext(ApplicationsContext);

  // SetUp Reward Contants
  const rewardInfo = {
    ctrRate: 0.3,
    ctaRate: 0.65,
    ctrValue: 0.005,
    ctaValue: 0.015,
    productValue: 1000,
    networkSize: 100,
  };

  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.lead_context',
  });

  // Theme
  const theme = useTheme();
  const { mode } = useIntuTheme();

  // Initial Values
  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    ...leadInfo,
    lead_type: '',
    industries: [],
    order_value: 500,
    revenue: 10000,
    commission_order_percent: '20.00 %',
    commission_order: '20.00 US$',
    commission_revenue_percent: '20.00 %',
    commission_revenue: '20,000 US$',
    avgCTRReward: calculateInitialReward().avgCTRReward,
    avgCTAReward: calculateInitialReward().avgCTAReward,
    avgCTRRate: calculateInitialReward().avgCTRRate,
    avgCTRValue: calculateInitialReward().avgCTRValue,
    avgCTARate: calculateInitialReward().avgCTARate,
    avgCTAValue: calculateInitialReward().avgCTAValue,
    avgReward: calculateInitialReward().avgReward,
    networkSize: calculateInitialReward().networkSize,
    productValue: calculateInitialReward().productValue,
  };

  // Set User Role Sepcific Information base on the company type
  useEffect(() => {
    const fetchData = async () => {
      if (leadInfo?.lead_type === 'manufacturer') {
        setLeadType('Manufacturer');
        let companyName = leadInfo.company;

        setLeadContext({
          benefits: [
            {
              title: t('manu.benefits.title1'),
              content: t('manu.benefits.content1', {
                companyName: companyName,
              }),
            },
            {
              title: t('manu.benefits.title2'),
              content: t('manu.benefits.content2', {
                companyName: companyName,
              }),
            },
            {
              title: t('manu.benefits.title3'),
              content: t('manu.benefits.content3', {
                companyName: companyName,
              }),
            },
          ],
          how_it_works: {
            description: <Trans t={t} i18nKey="manu.how_it_works.desc" />,
            steps: [
              {
                icon: (
                  <PersonAddIcon
                    fontSize="inherit"
                    color="primary"
                    sx={{ width: 45, height: 45 }}
                  />
                ),
                header: t('manu.how_it_works.step1_header'),
                content: t('manu.how_it_works.step1_content'),
                seperator: true,
              },
              {
                icon: (
                  <PrecisionManufacturingIcon
                    fontSize="inherit"
                    color="primary"
                    sx={{ width: 45, height: 45 }}
                  />
                ),
                header: t('manu.how_it_works.step2_header'),
                content: t('manu.how_it_works.step2_content'),
                seperator: true,
              },
              {
                icon: (
                  <AddModeratorIcon
                    fontSize="inherit"
                    color="primary"
                    sx={{ width: 45, height: 45 }}
                  />
                ),
                header: t('manu.how_it_works.step3_header'),
                content: t('manu.how_it_works.step3_content'),
                seperator: true,
              },
              {
                icon: (
                  <PercentIcon
                    fontSize="inherit"
                    color="primary"
                    sx={{ width: 45, height: 45, padding: '5px' }}
                  />
                ),
                header: t('manu.how_it_works.step4_header'),
                content: t('manu.how_it_works.step4_content'),
                seperator: true,
              },
              {
                icon: (
                  <AssessmentIcon
                    fontSize="inherit"
                    color="primary"
                    sx={{ width: 45, height: 45 }}
                  />
                ),
                header: t('manu.how_it_works.step5_header'),
                content: t('manu.how_it_works.step5_content'),
                seperator: false,
              },
            ],
            video: videoManufacturer,
          },
          additional_content: [
            {
              theme: 'dark',
              layout: 'single',
              header: t('manu.additional_content.commitment.header'),
              content: (
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="h5">
                      {t('manu.additional_content.commitment.statement1')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">
                      {t('manu.additional_content.commitment.statement2')}
                    </Typography>
                    <Grid
                      container
                      direction={{ xs: 'column', md: 'row' }}
                      alignContent="center"
                      justifyContent="space-around"
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        direction="column"
                        alignItems="center"
                        textAlign="center"
                      >
                        <img src={RewardIcon} width="150" alt="Reward" />
                        <Typography variant="h5">
                          <Trans
                            t={t}
                            i18nKey="manu.additional_content.commitment.statement3"
                          />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        direction="column"
                        alignItems="center"
                        textAlign="center"
                      >
                        <img
                          src={CommissionIcon}
                          width="150"
                          alt="Commission"
                        />
                        <Typography variant="h5">
                          <Trans
                            t={t}
                            i18nKey="manu.additional_content.commitment.statement4"
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              theme: 'light',
              layout: 'single',
              header: t('manu.additional_content.commission.header'),
              content: (
                <Typography variant="h6">
                  <Trans
                    t={t}
                    i18nKey="manu.additional_content.commission.content"
                  />
                </Typography>
              ),
              size_additional_component: [10, 7],
              content_additional_component: <CommissionCalculator />,
              title_additional_component: t(
                'manu.additional_content.commission.title_add',
              ),
            },
          ],
        });
      } else if (leadInfo?.lead_type === 'salesrep') {
        setLeadType('Sales Representative');
        setLeadContext({
          benefits: [
            {
              title: t('sales.benefits.title1'),
              content: t('sales.benefits.content1', { leadType: leadType }),
            },
            {
              title: t('sales.benefits.title2'),
              content: t('sales.benefits.content2', { leadType: leadType }),
            },
            {
              title: t('sales.benefits.title3'),
              content: t('sales.benefits.content3', { leadType: leadType }),
            },
          ],
          additional_content: [
            {
              theme: 'dark',
              layout: 'single',
              header: t('sales.additional_content.header1'),
              content: (
                <Typography variant="body1">
                  <Trans t={t} i18nKey="sales.additional_content.content1" />
                </Typography>
              ),
              footer: (
                <Typography variant="body1" className="green-text ">
                  {t('sales.additional_content.footer1')}
                </Typography>
              ),
            },
            {
              theme: 'light',
              layout: 'single',
              header: t('sales.additional_content.header2'),
              content: (
                <Typography variant="body1">
                  <Trans t={t} i18nKey="sales.additional_content.content2" />
                </Typography>
              ),
              size_additional_component: [10, 7],
              content_additional_component: <CommissionCalculator />,
              title_additional_component: t(
                'sales.additional_content.title_add',
              ),
            },
          ],
        });
      } else if (leadInfo?.lead_type === 'influencer') {
        let firstName = leadInfo.first_name;
        let lastName = leadInfo.last_name;
        setLeadType('Influencer');
        setLeadContext({
          benefits: [
            {
              title: t('influencer.benefits.title1'),
              content: t('influencer.benefits.content1', {
                leadType: leadType,
              }),
            },
            {
              title: t('influencer.benefits.title2'),
              content: t('influencer.benefits.content2', {
                leadType: leadType,
              }),
            },
            {
              title: t('influencer.benefits.title3'),
              content: t('influencer.benefits.content3', {
                leadType: leadType,
              }),
            },
          ],
          how_it_works: {
            description: t('influencer.how_it_works.desc'),
            steps: [
              {
                icon: (
                  <HowToRegIcon
                    fontSize="inherit"
                    color="primary"
                    size="large"
                    sx={{ width: 45, height: 45 }}
                  />
                ),
                header: t('influencer.how_it_works.step1_header'),
                content: t('influencer.how_it_works.step1_content'),
                seperator: true,
              },
              {
                icon: (
                  <AdsClickIcon
                    fontSize="inherit"
                    color="primary"
                    size="large"
                    sx={{ width: 45, height: 45 }}
                  />
                ),
                header: t('influencer.how_it_works.step2_header'),
                content:
                  industryNames?.length > 1
                    ? t('influencer.how_it_works.step2_content_multiple', {
                        industry: industryNames.join(', '),
                      })
                    : t('influencer.how_it_works.step2_content', {
                        industry: industryNames.industry_name,
                      }),
                seperator: true,
              },
              {
                icon: (
                  <ShareIcon
                    fontSize="inherit"
                    color="primary"
                    size="large"
                    sx={{ width: 45, height: 45 }}
                  />
                ),
                header: t('influencer.how_it_works.step3_header'),
                content: t('influencer.how_it_works.step3_content', {
                  firstName: firstName,
                }),
                seperator: true,
              },
              {
                icon: (
                  <AttachMoneyIcon
                    fontSize="inherit"
                    color="primary"
                    size="large"
                    sx={{ width: 45, height: 45 }}
                  />
                ),
                header: t('influencer.how_it_works.step4_header'),
                content: t('influencer.how_it_works.step4_content', {
                  firstName: firstName,
                }),
                seperator: true,
                calculator: false,
              },
              {
                icon: (
                  <TimelineIcon
                    fontSize="inherit"
                    color="primary"
                    size="small"
                    sx={{ width: 45, height: 45 }}
                  />
                ),
                header: t('influencer.how_it_works.step5_header'),
                content: t('influencer.how_it_works.step5_content'),
                seperator: false,
              },
            ],
            video: videoInfluencers,
          },
          additional_content: [
            {
              theme: 'medium',
              layout: 'single',
              header: t('influencer.additional_content.header1'),
              content: (
                <Typography variant="body1">
                  <Trans
                    t={t}
                    i18nKey="influencer.additional_content.content1"
                  />
                </Typography>
              ),
              size_additional_component: [12, 12],
              content_additional_component: (
                <RewardCalculator type="influencer" />
              ),
              title_additional_component: t(
                'influencer.additional_content.title_add',
              ),
            },
            {
              theme: 'dark',
              layout: 'double',
              header: t('influencer.additional_content.header2'),
              size_column_1: [12, 3],
              content_column_1: <img src={Exposure} alt="Exposure" />,
              size_column_2: [12, 8],
              content_column_2: (
                <Trans
                  t={t}
                  i18nKey="influencer.additional_content.content_col"
                  firstName={firstName}
                  lastName={lastName}
                >
                  Your identity as {{ firstName }} {{ lastName }} remains
                  completely anomynous to others including manufacturers! So
                  only people you share content with know that you are promoting
                  a product.
                </Trans>
              ),
            },
          ],
        });
      }
      // Translate Dynamic context
      const controller = new AbortController();
      const signal = controller.signal;
      // Translate Headline Title
      const url = '/api/translations/simple-translation';

      try {
        let payload = {
          textToTranslate: leadInfo.headline_title,
          targetLanguage: i18n.language,
          convertToCamelCase: false,
        };

        let request = await axios.post(url, JSON.stringify(payload), {
          signal,
          headers: { 'Content-Type': 'application/json' },
        });
        let response = request?.data;

        if (request.status === 200) {
          setLeadInfo((prevState) => ({
            ...prevState,
            headline_title: response.data,
          }));
        }
      } catch (err) {
        console.log(err);
      }

      // Translate Headline Body
      try {
        let payload = {
          textToTranslate: leadInfo.headline_body,
          targetLanguage: i18n.language,
          //   Is this necessary??
          //   convertToCamelCase: true,
        };

        let request = await axios.post(url, JSON.stringify(payload), {
          signal,
          headers: { 'Content-Type': 'application/json' },
        });
        let response = request?.data;

        if (request.status === 200) {
          setLeadInfo((prevState) => ({
            ...prevState,
            headline_body: response.data,
          }));
        }
      } catch (err) {
        console.log(err);
      }
    };

    // Call the inner function
    fetchData();

    // Return a cleanup function if necessary
    return () => {
      // Cleanup logic if needed
    };
  }, [i18n.language, industryNames, mode]);

  // Calculate Initial Reward
  function calculateInitialReward() {
    // Basic Details
    const productValue = rewardInfo.productValue;
    const networkSize = rewardInfo.networkSize;

    // Reweard Constants
    const ctrRate = rewardInfo.ctrRate;
    const ctaRate = rewardInfo.ctaRate;
    const ctrValue = rewardInfo.ctrValue;
    const ctaValue = rewardInfo.ctaValue;

    // Calculate Avg. Rewards
    let avgCTRValue = productValue * ctrValue;
    let avgCTAValue = productValue * ctaValue;

    //  Calculate Avg. CTR & CTA Rate
    let avgCTRRate = networkSize * ctrRate;
    let avgCTARate = avgCTRRate * ctaRate;

    // Calculate Avg. CTR & CTA Value
    let avgCTRReward = avgCTRRate * avgCTRValue;
    let avgCTAReward = avgCTARate * avgCTAValue;

    // return Math.round((avgCTRValue + avgCTAValue)* 100) / 100;
    return {
      avgCTRValue: Math.round(avgCTRValue * 100) / 100,
      avgCTAValue: Math.round(avgCTAValue * 100) / 100,
      avgCTRRate: Math.round((avgCTRRate * 100) / 100),
      avgCTARate: Math.round((avgCTARate * 100) / 100),
      avgReward: valueText(
        Math.round((avgCTRReward + avgCTAReward) * 100) / 100,
        'US$',
      ),
      avgCTRReward: valueText(Math.round(avgCTRReward * 100) / 100, 'US$'),
      avgCTAReward: valueText(Math.round(avgCTAReward * 100) / 100, 'US$'),
      productValue,
      networkSize,
    };
  }

  function RewardCalculator({ type }) {
    const productValueMarks = [
      {
        value: 1000,
        label: '1,000 US$',
      },
      {
        value: 5000,
        label: '5,000 US$',
      },
      {
        value: 10000,
        label: '10,000 US$',
      },
    ];

    const avgCTRRewardMarks = [
      {
        value: 0,
        label: '0 US$',
      },
      {
        value: 250,
        label: '250 US$',
      },
      {
        value: 500,
        label: '500 US$',
      },
      {
        value: 750,
        label: '750 US$',
      },
      {
        value: 1000,
        label: '1,000 US$',
      },
    ];

    const avgCTARewardMarks = [
      {
        value: 0,
        label: '0 US$',
      },
      {
        value: 500,
        label: '500 US$',
      },
      {
        value: 1000,
        label: '1,000 US$',
      },
    ];

    function calculateReward(event, value, values) {
      // Reweard Constants
      const ctrRate = rewardInfo.ctrRate;
      const ctaRate = rewardInfo.ctaRate;
      const ctrValue = rewardInfo.ctrValue;
      const ctaValue = rewardInfo.ctaValue;

      // Calculate Avg. Rewards
      let networkSize = values.networkSize;
      let productValue = values.productValue;
      let avgCTRValue = values.avgCTRValue;
      let avgCTAValue = values.avgCTAValue;
      if (event === 'productValue') {
        avgCTRValue = productValue * ctrValue;
        avgCTAValue = productValue * ctaValue;
      }

      //  Calculate Avg. CTR & CTA Rate
      let avgCTRRate = networkSize * ctrRate;
      let avgCTARate = avgCTRRate * ctaRate;

      // Calculate Avg. CTR & CTA Value
      let avgCTRReward = avgCTRRate * avgCTRValue;
      let avgCTAReward = avgCTARate * avgCTAValue;

      let avgReward = Math.round((avgCTRReward + avgCTAReward) * 100) / 100;

      return {
        avgCTRReward: valueText(Math.round((avgCTRReward * 100) / 100), 'US$'),
        avgCTAReward: valueText(Math.round((avgCTAReward * 100) / 100), 'US$'),
        avgCTRRate: Math.round((avgCTRRate * 100) / 100),
        avgCTRValue: Math.round((avgCTRValue * 100) / 100),
        avgCTARate: Math.round((avgCTARate * 100) / 100),
        avgCTAValue: Math.round((avgCTAValue * 100) / 100),
        avgReward: valueText(Math.round((avgReward * 100) / 100), 'US$'),
      };
    }

    return (
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-start"
        alignContent={{ xs: 'center' }}
        direction={{ xs: 'column', md: 'row' }}
        flexGrow={1}
        spacing={4}
      >
        <Formik
          values={formik.values}
          initialValues={formik.initialValues}
          errors={formik.errors}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
          hideFooter={true}
        >
          {(formik) => {
            const { values, initialValues, setFieldValue, setValues } = formik;
            let firstName = leadInfo.first_name;
            return (
              <>
                {/* Reward Calculator */}
                <Grid
                  item
                  xs={12}
                  md={8}
                  alignItems="stretch"
                  width="auto"
                  sx={{ paddingBottom: '50px' }}
                >
                  <Stack spacing={5} sx={{ paddingBottom: '50px' }}>
                    {/* Size of Network Slider */}
                    {type === 'influencer' ? (
                      <Typography
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                        id="network-size-reward"
                        gutterBottom
                      >
                        <Trans
                          t={t}
                          i18nKey="reward_calc.network_slider_influencer"
                          firstName={firstName}
                        >
                          The size of your network {{ firstName }}
                        </Trans>
                      </Typography>
                    ) : (
                      <Typography
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                        id="network-size-reward"
                        gutterBottom
                      >
                        {t('reward_calc.network_slider')}
                      </Typography>
                    )}
                    <Stack
                      spacing={2}
                      direction="row"
                      mb={1}
                      alignItems="center"
                    >
                      <PersonIcon
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                        size="small"
                      />
                      <Slider
                        id="networkSize"
                        name="networkSize"
                        aria-label={t('reward_calc.network_size_label')}
                        defaultValue={initialValues.networkSize}
                        aria-labelledby="network-size-reward"
                        getAriaValueText={(value) =>
                          valueText(value, t('reward_calc.people'))
                        }
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) =>
                          valueText(value, t('reward_calc.people'))
                        }
                        size="medium"
                        onChange={(e, value) =>
                          setFieldValue('networkSize', value)
                        }
                        onChangeCommitted={(e, value) => {
                          let result = calculateReward(
                            'networkSize',
                            value,
                            values,
                          );
                          setValues({
                            ...values,
                            networkSize: value,
                            avgCTRReward: result.avgCTRReward,
                            avgCTAReward: result.avgCTAReward,
                            avgCTRRate: result.avgCTRRate,
                            avgCTARate: result.avgCTARate,
                            avgReward: result.avgReward,
                          });
                        }}
                        step={100}
                        min={0}
                        max={10000}
                      />
                      <PeopleAltIcon
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                        size="large"
                      />
                    </Stack>

                    {/* ø Product Value Slider */}
                    <Typography
                      className={mode === 'light' ? 'grey-text' : 'white-text'}
                      id="product-value-reward"
                      gutterBottom
                    >
                      {t('reward_calc.product_slider')}
                    </Typography>
                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{ mb: 1 }}
                      alignItems="center"
                    >
                      <MoneyOffIcon
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                      />
                      <Slider
                        id="productValue"
                        name="productValue"
                        aria-label={t('reward_calc.slider_label')}
                        defaultValue={initialValues.productValue}
                        aria-labelledby="product-value-reward"
                        getAriaValueText={(value) => valueText(value, 'US$')}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => valueText(value, 'US$')}
                        size="medium"
                        onChange={(e, value) =>
                          setFieldValue('productValue', value)
                        }
                        onChangeCommitted={(e, value) => {
                          let result = calculateReward(
                            'productValue',
                            value,
                            values,
                          );
                          setValues({
                            ...values,
                            avgCTRReward: result.avgCTRReward,
                            avgCTAReward: result.avgCTAReward,
                            avgCTRValue: result.avgCTRValue,
                            avgCTRRate: result.avgCTRRate,
                            avgCTAValue: result.avgCTAValue,
                            avgCTARate: result.avgCTARate,
                            avgReward: result.avgReward,
                          });
                        }}
                        step={100}
                        min={0}
                        max={10000}
                        marks={productValueMarks}
                      />
                      <AttachMoneyIcon
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                      />
                    </Stack>

                    {/* ø CTR Value Slider */}
                    {type === 'influencer' ? (
                      <Typography
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                        id="ctr-value-reward"
                        gutterBottom
                      >
                        {t('reward_calc.ctr_slider_influencer')}
                      </Typography>
                    ) : (
                      <Typography
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                        id="ctr-value-reward"
                        gutterBottom
                      >
                        {t('reward_calc.ctr_slider')}
                      </Typography>
                    )}
                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{ mb: 1 }}
                      alignItems="center"
                    >
                      <MilitaryTechIcon
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                      />
                      <Slider
                        id="avgCTRValue"
                        name="avgCTRValue"
                        aria-label={t('reward_calc.slider_label')}
                        defaultValue={initialValues.avgCTRValue}
                        value={values.avgCTRValue}
                        aria-labelledby="ctr-value-reward"
                        getAriaValueText={(value) => valueText(value, 'US$')}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => valueText(value, 'US$')}
                        size="medium"
                        onChange={(e, value) =>
                          setFieldValue('avgCTRValue', value)
                        }
                        onChangeCommitted={(e, value) => {
                          let result = calculateReward(
                            'avgCTRValue',
                            value,
                            values,
                          );
                          setValues({
                            ...values,
                            avgCTRReward: result.avgCTRReward,
                            avgCTAReward: result.avgCTAReward,
                            avgCTRRate: result.avgCTRRate,
                            avgCTARate: result.avgCTARate,
                            avgReward: result.avgReward,
                          });
                        }}
                        step={1}
                        min={0}
                        max={500}
                        marks={avgCTRRewardMarks}
                      />
                      <EmojiEventsIcon
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                      />
                    </Stack>

                    {/* ø CTA Value Slider */}
                    {type === 'influencer' ? (
                      <Typography
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                        id="cta-value-reward"
                        gutterBottom
                      >
                        {t('reward_calc.cta_slider_influencer')}
                      </Typography>
                    ) : (
                      <Typography
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                        id="cta-value-reward"
                        gutterBottom
                      >
                        {t('reward_calc.cta_slider')}
                      </Typography>
                    )}
                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{ mb: 1 }}
                      alignItems="center"
                    >
                      <MilitaryTechIcon
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                      />
                      <Slider
                        id="avgCTAValue"
                        name="avgCTAValue"
                        aria-label={t('reward_calc.slider_label')}
                        defaultValue={initialValues.avgCTAValue}
                        value={values.avgCTAValue}
                        aria-labelledby="cta-value-reward"
                        getAriaValueText={(value) => valueText(value, 'US$')}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => valueText(value, 'US$')}
                        size="medium"
                        onChange={(e, value) =>
                          setFieldValue('avgCTAValue', value)
                        }
                        onChangeCommitted={(e, value) => {
                          let result = calculateReward(
                            'avgCTAValue',
                            value,
                            values,
                          );
                          setValues({
                            ...values,
                            avgCTRReward: result.avgCTRReward,
                            avgCTAReward: result.avgCTAReward,
                            avgCTRRate: result.avgCTRRate,
                            avgCTARate: result.avgCTARate,
                            avgReward: result.avgReward,
                          });
                        }}
                        step={1}
                        min={0}
                        max={1000}
                        marks={avgCTARewardMarks}
                      />
                      <EmojiEventsIcon
                        className={
                          mode === 'light' ? 'grey-text' : 'white-text'
                        }
                      />
                    </Stack>
                  </Stack>
                </Grid>

                {/* Reward Summary */}
                <Grid item xs={12} md={4} container spacing={3}>
                  <Grid item xs={12}>
                    <img src={Reward} alt="Reward" />
                  </Grid>

                  <Grid item xs={12}>
                    {type === 'influencer' ? (
                      <Typography variant="body1">
                        {t('reward_sum.statement_influencer')}
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        {t('reward_sum.statement')}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} mb={8}>
                    <Stack
                      spacing={2}
                      style={classes.root}
                      sx={{ paddingBottom: '50px' }}
                      direction="row"
                    >
                      {/* Avg. CTR Rate % */}
                      <TextField
                        disabled={true}
                        id="avgCTRRate"
                        name="avgCTRRate"
                        autoComplete="off"
                        className="form-select-field"
                        aria-describedby="uidnote"
                        variant="outlined"
                        label={
                          type === 'influencer'
                            ? t('reward_sum.ctr_rate_label_influencer')
                            : t('reward_sum.ctr_rate_label')
                        }
                        value={valueText(values.avgCTRRate, 'clicks')}
                        type="text"
                        inputProps={{
                          autoComplete: 'off',
                        }}
                      />

                      {/* Avg. CTR Reward % */}
                      <TextField
                        disabled={true}
                        id="avgCTRReward"
                        name="avgCTRReward"
                        autoComplete="off"
                        className="form-select-field"
                        aria-describedby="uidnote"
                        variant="outlined"
                        label={
                          type === 'influencer'
                            ? t('reward_sum.ctr_reward_label_influencer')
                            : t('reward_sum.ctr_reward_label')
                        }
                        value={values.avgCTRReward}
                        type="text"
                        inputProps={{
                          autoComplete: 'off',
                        }}
                      />
                    </Stack>

                    <Stack
                      spacing={2}
                      style={classes.root}
                      sx={{ paddingBottom: '50px' }}
                      direction="row"
                    >
                      {/* Avg. CTA Rate % */}
                      <TextField
                        disabled
                        id="avgCTARate"
                        name="avgCTRAate"
                        autoComplete="off"
                        className="form-select-field"
                        aria-describedby="uidnote"
                        variant="outlined"
                        label={
                          type === 'influencer'
                            ? t('reward_sum.cta_rate_label_influencer')
                            : t('reward_sum.cta_rate_label')
                        }
                        value={valueText(values.avgCTARate, 'Interactions')}
                        type="text"
                        inputProps={{
                          autoComplete: 'off',
                        }}
                      />

                      {/* Avg. CTA Reward % */}
                      <TextField
                        disabled
                        id="avgCTAReward"
                        name="avgCTAReward"
                        autoComplete="off"
                        className="form-select-field"
                        aria-describedby="uidnote"
                        variant="outlined"
                        label={
                          type === 'influencer'
                            ? t('reward_sum.cta_reward_label_influencer')
                            : t('reward_sum.cta_reward_label')
                        }
                        value={values.avgCTAReward}
                        type="text"
                        inputProps={{
                          autoComplete: 'off',
                        }}
                      />
                    </Stack>

                    {/* Total Reward % */}
                    <TextField
                      disabled
                      id="avgReward"
                      name="avgReward"
                      autoComplete="off"
                      className="form-select-field"
                      aria-describedby="uidnote"
                      variant="outlined"
                      label={
                        type === 'influencer'
                          ? t('reward_sum.total_reward_label_influencer')
                          : t('reward_sum.total_reward_label')
                      }
                      value={values.avgReward}
                      type="text"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            );
          }}
        </Formik>
      </Grid>
    );
  }

  return (
    <>
      {/* HERO BANNER */}
      <Grid>
        <LeadHeaderContainer />
      </Grid>

      {/* INTRO */}
      <Grid
        container
        className="intu__divider"
        alignItems="flex-start"
        direction="column"
        sx={{
          padding: { xs: '10px 0px 50px 0px', md: '50px 0px 50px 20px' },
          width: '100%',
        }}
      >
        {/* Manufacturers */}
        {leadInfo?.lead_type === 'manufacturer' && <IntroManufacturers />}
        {/* Sales Reps */}
        {leadInfo?.lead_type === 'salesrep' && <IntroSalesReps />}
      </Grid>

      {/* CONTENT */}
      {/* MANUFACTURERS */}
      <Grid>
        {leadInfo?.lead_type === 'manufacturer' && (
          <>
            {/* What makes us different */}
            <Grid item>
              <WhatMakesUsDifferent
                title={t('manu.what_makes_us_diff_title')}
                description={
                  <Trans t={t} i18nKey="manu.what_makes_us_diff_content" />
                }
              />
            </Grid>

            {/* Advantages */}
            <Grid
              item
              className="intu__divider"
              sx={{ marginBottom: '1rem', paddingBottom: '2rem' }}
            >
              <YourAdvantages />
            </Grid>

            {/* Existing Customers */}
            <Grid item xs={12}>
              <ExistingCustomersByIndustry />
            </Grid>
          </>
        )}

        {/* SALES */}
        {leadInfo?.lead_type === 'salesrep' && (
          <Grid>
            {/* How To Become A Sales Rep */}
            <Grid ite mb={10}>
              <HowToBecomeASalesRep />
            </Grid>

            {/* Independent But Protected */}
            <Grid item>
              <IndependentButProtected />
            </Grid>

            {/* Whi Sign Up */}
            <Grid item>
              <WhySignUp />
            </Grid>

            {/* What makes us different */}
            <Grid item>
              <WhatMakesUsDifferent
                title={i18n.format(
                  t('sales.what_makes_us_diff_title'),
                  'uppercase',
                )}
                description={t('sales.what_makes_us_diff_content')}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* ADDITIONAL CONTENT */}
      {leadContext?.additional_content?.map((section, index) => {
        let content;

        if (section.layout === 'single') {
          content = (
            <Grid
              key={index}
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
              sx={{
                width: 'auto',
                minHeight: '300px',
                padding: '50px 20px 0px 20px',
              }}
            >
              <Grid item xs={12} className="intu__component">
                <Typography
                  variant="h1"
                  sx={{
                    color: mode === 'dark' ? 'white' : 'black',
                    fontWeight: '600',
                  }}
                >
                  {section.header}
                </Typography>
              </Grid>
              <Grid item xs={12} className="">
                <Grid item sx={{ padding: '50px', textAlign: 'center' }}>
                  {section.content}
                  {section.footer}
                </Grid>
              </Grid>
              {section?.content_additional_component && (
                <Grid item xs={12} className="intu__component">
                  <Typography variant="h2" className="green-text">
                    {section?.title_additional_component}
                  </Typography>
                  {section?.content_additional_component}
                </Grid>
              )}
            </Grid>
          );
        } else if (section.layout === 'double') {
          content = (
            <Grid
              key={index}
              container
              alignItems="center"
              direction="column"
              className={
                section.theme === 'dark'
                  ? 'intu__section-dark'
                  : 'intu__section-light'
              }
              sx={{ minHeight: '300px', padding: '50px 20px 0 20px' }}
            >
              <Grid item>
                <Typography
                  variant="h1"
                  className={
                    section.theme === 'dark' ? 'green-text' : 'grey-text'
                  }
                >
                  {section.header}
                </Typography>
              </Grid>
              <Grid
                container
                direction={{ xs: 'column', md: 'row' }}
                alignItems="center"
              >
                <Grid
                  item
                  xs={section?.size_column_1[0]}
                  lg={section.size_column_1[1]}
                  alignContent={{ xs: 'center' }}
                >
                  <Typography
                    variant="h5"
                    className={section.theme === 'light' ? 'grey-text' : null}
                  >
                    {section.content_column_1}
                  </Typography>
                  {section?.content_additional_component && (
                    <Grid
                      item
                      container
                      alignItems={{ xs: 'center', lg: 'flex-start' }}
                      direction="column"
                      justifyContent="stretch"
                    >
                      <Grid
                        item
                        xs={section?.size_additional_component[0]}
                        sm={section?.size_additional_component[1]}
                      >
                        <Typography variant="h2" className="green-text">
                          {section?.title_additional_component}
                        </Typography>
                        {section?.content_additional_component}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  xs={section?.size_column_2[0]}
                  lg={section?.size_column_2[1]}
                  sx={{ textAlign: 'center' }}
                >
                  <Typography
                    variant="h5"
                    className={
                      section.theme === 'light' ? 'grey-text' : 'white-text'
                    }
                  >
                    {section.content_column_2}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        }

        return content;
      })}

      <Divider sx={theme.components.MuiDivider} />

      {/* HOW TO SIGN UP */}
      {leadInfo?.lead_type === 'manufacturer' && <HowToSignUpManufacturers />}
      <Divider sx={theme.components.MuiDivider} />

      {/* SIGNUP */}
      <SignUp />
    </>
  );
};

export default LeadContent;
