import { memo, useState } from 'react';
import { Box, MenuItem, Typography, Select } from '@mui/material';
import { SketchPicker } from 'react-color';
import * as THREE from 'three';
import PaletteIcon from '@mui/icons-material/Palette';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import { useTranslation } from 'react-i18next';

const createHexString = (color) => {
  const { r, g, b } = color;
  const threeJsColor = {
    r,
    g,
    b,
  };
  const hexColor =
    '#' +
    new THREE.Color(
      threeJsColor.r,
      threeJsColor.g,
      threeJsColor.b,
    ).getHexString();
  return hexColor;
};

const options = Array.from({ length: 11 }, (_, i) =>
  Number((i * 0.1).toFixed(1)),
);

const ColorControlsContainer = ({
  meshes,
  setMeshes,
  showingArray,
  setShowingArray,
}) => {
  const { t } = useTranslation();
  const [showingColorOptions, setShowingColorOptions] = useState(false);
  const handleColorChangeComplete = (newColor, index) => {
    const { hex } = newColor;
    setMeshes((prevState) => {
      const newMeshes = prevState.map((m, i) => {
        if (i !== index) return m;
        m.material.color.set(hex);
        return m;
      });
      return newMeshes;
    });

    handleColorBoxClick(index);
  };

  const handleColorBoxClick = (index) => {
    setShowingArray((prevState) => {
      return prevState.map((x, i) => {
        if (i !== index) return false;
        return x === false ? true : false;
      });
    });
  };

  const handleRoughnessChange = (e, index) => {
    setMeshes((prevState) => {
      const newMeshes = prevState.map((m, i) => {
        if (i !== index) return m;
        m.material.roughness = Number(e.target.value);
        return m;
      });
      return newMeshes;
    });
  };

  const handleMetalnessChange = (e, index) => {
    setMeshes((prevState) => {
      const newMeshes = prevState.map((m, i) => {
        if (i !== index) return m;
        m.material.metalness = Number(e.target.value);
        return m;
      });
      return newMeshes;
    });
  };

  const handleShowColorOptionsClick = () => {
    setShowingColorOptions(!showingColorOptions);
  };

  return (
    <Box position="absolute" top={0} left={20}>
      <IntuIconButton
        type="add"
        variant="outlined"
        tooltipTitle={`${showingColorOptions ? 'Hide' : 'Show'} Color Options`}
        IconComponent={PaletteIcon}
        onClick={handleShowColorOptionsClick}
      />
      <Box
        position="absolute"
        top={30}
        left={20}
        maxHeight={400}
        minWidth={320}
        overflow="scroll"
        mt={2}
        display={showingColorOptions ? 'block' : 'none'}
      >
        <Box display="flex" gap={2}>
          <Typography>{t('components.colorControls.mesh')}</Typography>
          <Typography>{t('components.colorControls.color')}</Typography>
          <Typography>{t('components.colorControls.rough')}</Typography>
          <Typography>{t('components.colorControls.metal')}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          {meshes.map((m, i) => (
            <Box
              key={m.uuid}
              display="flex"
              gap={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography flex={0.8}>{m.name}</Typography>
              <Box>
                {showingArray[i] ? (
                  <Box position="absolute" zIndex={100} left={100}>
                    <SketchPicker
                      color={createHexString(m.material.color)}
                      onChangeComplete={(newColor) =>
                        handleColorChangeComplete(newColor, i)
                      }
                    />
                  </Box>
                ) : (
                  <Box
                    style={{
                      backgroundColor: createHexString(m.material.color),
                      width: 35,
                      height: 35,
                      border: '1px solid #000',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleColorBoxClick(i)}
                  />
                )}
              </Box>
              <Box>
                <Select
                  value={m.material.roughness}
                  onChange={(e) => handleRoughnessChange(e, i)}
                >
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Select
                  value={m.material.metalness}
                  onChange={(e) => handleMetalnessChange(e, i)}
                >
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ColorControlsContainer);
