import { Grid, Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RiveAnimationComponent } from '../../../components/animations/RiveAnimationComponent.jsx';

export const OpportunitiesContent = () => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.home.SalesHome.OpportunitiesContent',
    });
    return (
        <Grid
            container
            direction="column"
            alignContent="center"
            justifyContent="center"
        >
            {/* Title */}
            <Grid item>
                <Typography variant="h1" align="center">
                    {t('title')}
                </Typography>
            </Grid>
            {/* Divider */}
            <Divider sx={{ width: '25%', alignSelf: 'center' }} />
            {/* Opportunities */}
            <Grid
                item
                container
                direction={{ xs: 'column', xl: 'row' }}
                alignItems="flex-start"
                justifyContent="space-around"
            >
                <Grid xs={4} item container direction="column">
                    <Typography
                        variant="body"
                        align="center"
                        sx={{ fontSize: 24, marginTop: '5vh' }}
                    >
                        {t('line1')}
                    </Typography>
                    <RiveAnimationComponent
                        src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/search_icon.riv"
                        stateMachines="search"
                        sx={{ marginTop: '5rem' }}
                        width="200px"
                        height="200px"
                    />
                </Grid>
                <Grid xs={4} item container direction="column">
                    <Typography
                        variant="body"
                        align="center"
                        sx={{ fontSize: 24, marginTop: '5vh' }}
                    >
                        {t('line2')}
                    </Typography>
                    <RiveAnimationComponent
                        src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/experience.riv"
                        stateMachines="State Machine 1"
                        sx={{ marginTop: '5rem' }}
                    />
                </Grid>
                <Grid xs={4} item container direction="column">
                    <Typography
                        variant="body"
                        align="center"
                        sx={{ fontSize: 24, marginTop: '5vh' }}
                    >
                        {t('line3')}
                    </Typography>
                    <RiveAnimationComponent
                        src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/robot.riv"
                        stateMachines="State Machine 1"
                        sx={{ marginTop: '5rem' }}
                    />
                </Grid>
            </Grid>
            <Grid
                item
                container
                justifyContent="center"
                align="center"
                sx={{ marginTop: '10vh' }}
            >
                <Typography variant="body" px={4}>
                    {t('description')}
                </Typography>
            </Grid>
        </Grid>
    );
};