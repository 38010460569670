import React, { useState } from 'react';

import { connectField } from 'uniforms';
import useResponseHandling from '../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';

// Mui Components
import {
  Button,
  Grid,
  Stack,
  CircularProgress,
  FormControl,
  Autocomplete,
  TextField,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { Form, Formik, useFormik } from 'formik';

import * as Yup from 'yup';

import languages from '../../data/LanguagesData.json';
import ReactHtmlParser from 'react-html-parser';

// Video Plugin
import videoPlugin from './videoPlugin';

// Image Plugin
import imagePlugin from './imagePlugin';

// Divider & Spacer PLugin
import divider from './dividerPlugin';
import spacer from '@in2tec/plugins-spacer';

// Slate Plugin
import { customSlate } from './pageColorPicker';

// Chart PLugins
import pieChartPlugin from './pieChartPlugin';
import xyChartPlugin from './xyChartPlugin';

import { createValue } from '@in2tec/page-editor';

const partialValue = {
  rows: [
    // a row with two cells:
    [
      {
        plugin: imagePlugin.id,
        data: {
          src: 'http://path/to/image',
        },
      },
      {
        plugin: customSlate.id,
        data: customSlate.createData((def) => ({
          children: [
            {
              plugin: def.plugins.headings.h1,
              children: ['hello'],
            },
            {
              plugin: def.plugins.paragraphs.paragraph,
              children: ['Hello world'],
            },
          ],
        })),
      },
    ],
    // a row with one cell
    [
      // a cell with rich text
      {
        plugin: customSlate.id,
        data: customSlate.createData((def) => ({
          children: [
            {
              plugin: def.plugins.headings.h1,
              children: ['hello'],
            },
            {
              plugin: def.plugins.paragraphs.paragraph,
              children: ['Hello world'],
            },
          ],
        })),
      },
    ],
  ],
};

// Cell Plugins
const cellPlugins = [
  customSlate,
  divider,
  spacer,
  pieChartPlugin,
  xyChartPlugin,
  imagePlugin,
  videoPlugin,
];

const ChatGPTComponent = connectField(({ value, onChange }) => {
  createValue(partialValue, { cellPlugins, lang: 'default' });

  // // States for Loading and Progress
  // const [loading, setLoading] = useState(false);

  // // SetUp Axios
  // const axios = useAxiosPrivate();

  // // Response Handler
  // const {handleErrorResponse, handleRegularResponse} = useResponseHandling();

  // // Get ChatGPT Investor Pitch
  // async function getRecommendation(values) {
  //     setLoading(true)

  //     let chatgptInsructionBody

  //     let addressees = values.addressee.join(', ');
  //     let keywords = values.keywords.join(', ');

  //     if (values.mode === "create" || values.mode === "tweak") {
  //         chatgptInsructionBody = `${values.mode} a ${values.contentType} about the following: ${values.content}.`
  //         if(values.keywords.length > 0) {
  //             chatgptInsructionBody += `Make sure to include the following keywords ${keywords}.`
  //         }
  //         chatgptInsructionBody += `The content ${values.contentType} should address ${addressees}.`
  //     } else {
  //         chatgptInsructionBody = `${values.mode} the ${values.contentType} into ${values.language}: ${values.content}`
  //     }
  //     chatgptInsructionBody += "Make sure to wrap the response in html tags."

  //     // Create the headline body
  //     let payload = {
  //         content: chatgptInsructionBody,
  //         role: "function",
  //         name: "IN2TEC",
  //         model: "gpt-4",
  //         temperature: 0.2
  //     }

  //     let url = "/api/chatgpt/get-sentence"

  //     const controller = new AbortController();
  //     const signal = controller.signal;
  //     try {

  //         const response = await axios.post(url,
  //             JSON.stringify(payload),
  //             {
  //                 signal,
  //                 headers: { 'Content-Type': 'application/json' },
  //                 withCredentials: true
  //             }
  //         )

  //         const data = response?.data

  //         if (response.status === 200) {

  //             // Trim and Convert Content
  //             let messages = data.data[0];
  //             let recommedation = messages.message.content;

  //             let result = recommedation.split("\n\n")
  //             // console.log(result)
  //             return recommedation;
  //         } else {
  //             handleRegularResponse({ open: true, status: data.status, message: data.message })
  //         }
  //     } catch (err) {
  //         handleErrorResponse(err)
  //     } finally {
  //         controller.abort()
  //         setLoading(false)
  //     }
  // }

  // const formik = useFormik({
  //     initialValues: {},
  //     initialTouched: {},
  //     values: {},
  // })

  // formik.initialValues = {
  //     addressee: [],
  //     mode: '',
  //     content: '',
  //     contentType: [],
  //     keywords: [],
  //     language: ''
  // };

  // // Content Typ Options
  // const contentTypeOptions = [
  //     'Post',
  //     'Product Description'
  // ]

  // const addresseeOptions = [
  //     'Sales Professionals',
  //     'Sales Engineers',
  //     "Buyers",
  //     "Consumers",
  // ]

  // // Validation
  // var validationSchema = Yup.object();
  // validationSchema = Yup.object().shape({
  //     regularLeadDialogOpen: Yup.boolean(),
  //     content: Yup.string()
  //         .min(20, ({ value, min }) => {
  //             let characterCount = value.trim().length;
  //             return `Your content is with ${characterCount} characters too short!. Make sure to have at least ${min} characters.`
  //         })
  //         .max(1000, ({ value, min }) => {
  //             let characterCount = value.trim().length;
  //             return `Your content is with ${characterCount} characters too long!. Make sure to stay below ${min} characters.`
  //         })
  //         .required('A post description is required and will be used as a preview.'),
  //     addressee: Yup
  //         .array()
  //         .when("mode", {
  //             is: "create" || "tweak",
  //             then: () => Yup
  //                 .array()
  //                 .required('You must choose a writing tone')
  //         }),
  //     language: Yup
  //         .string()
  //         .when("mode", {
  //             is: "translate",
  //             then: () => Yup
  //                 .string()
  //                 .required('You must choose a language')
  //         }),
  //     mode: Yup
  //         .string()
  //         .required('You need to choose a mode')
  // });

  // return (
  //     <Formik
  //         values={formik.values}
  //         initialValues={formik.initialValues}
  //         errors={formik.errors}
  //         validationSchema={validationSchema}
  //         validateOnMount={true}
  //         validateOnChange={true}
  //         enableReinitialize={true}
  //     >
  //         {(formik) => {
  //             const {
  //                 values,
  //                 setFieldValue,
  //                 errors,
  //                 touched,
  //                 handleBlur,
  //                 setValues,
  //             } = formik;
  //             return (
  //                 <Form role="form">
  //                     <FormControl fullWidth>
  //                         <Grid container spacing={2}>
  //                             <Grid item xs={12} sx={{ marginTop: "20px" }}>

  //                                 <Stack direction="column" spacing={2}>

  //                                     {/* Content Type */}
  //                                     <Autocomplete
  //                                         freeSolo
  //                                         autoComplete
  //                                         required
  //                                         disablePortal
  //                                         options={contentTypeOptions}
  //                                         handleHomeEndKeys
  //                                         id="contentType"
  //                                         name="contentType"
  //                                         value={values?.contentType}
  //                                         clearIcon={<ClearIcon sx={{ color: "var(--intu-lightGrey)" }} />}
  //                                         className="form-select-field"
  //                                         onChange={(e, options) => {
  //                                             setFieldValue("contentType", options)
  //                                         }}
  //                                         onBlur={handleBlur}
  //                                         renderInput={(params) => (
  //                                             <TextField
  //                                                 {...params}
  //                                                 variant="outlined"
  //                                                 InputProps={{
  //                                                     ...params.InputProps,
  //                                                     type: 'text',
  //                                                     autoComplete: 'off',
  //                                                 }}
  //                                                 sx={{
  //                                                     '& label.Mui-focused': {
  //                                                         color: '#a8c957', // Label color when the input is focused
  //                                                     },
  //                                                     '& .MuiInput-underline:after': {
  //                                                         borderBottomColor: '#a8c957', // Underline color when input is focused
  //                                                     },
  //                                                     '& .MuiOutlinedInput-root': {
  //                                                         '& fieldset': {
  //                                                             borderColor: '#a8c957', // Default border color
  //                                                         },
  //                                                         '&:hover fieldset': {
  //                                                             borderColor: '#a8c957', // Border color when hovered (optional)
  //                                                         },
  //                                                         '&.Mui-focused fieldset': {
  //                                                             borderColor: '#a8c957', // Border color when the input is focused
  //                                                         },
  //                                                     },
  //                                                     '& .MuiInputLabel-root': { // This will ensure label color is green always, if you want it only when focused remove this block
  //                                                         color: '#333333'
  //                                                     },

  //                                                 }}
  //                                                 placeholder="Type in your keyword you want to include in your post and hit ↵"
  //                                                 label="Content Type"
  //                                                 error={touched?.contentType && errors?.contentType ? true : false}
  //                                                 helperText={touched?.contentType && errors?.contentType ? errors?.contentType : null}
  //                                             />
  //                                         )}
  //                                     />

  //                                     {/* Mode */}
  //                                     <TextField
  //                                         select
  //                                         type="text"
  //                                         id="mode"
  //                                         label="Mode"
  //                                         name="mode"
  //                                         variant="outlined"
  //                                         value={values?.mode}
  //                                         onBlur={handleBlur}
  //                                         onChange={(e) => {
  //                                             setFieldValue(e.target.name, e.target.value)
  //                                         }}
  //                                         sx={{
  //                                             '& label.Mui-focused': {
  //                                                 color: '#a8c957', // Label color when the input is focused
  //                                             },
  //                                             '& .MuiInput-underline:after': {
  //                                                 borderBottomColor: '#a8c957', // Underline color when input is focused
  //                                             },
  //                                             '& .MuiOutlinedInput-root': {
  //                                                 '& fieldset': {
  //                                                     borderColor: '#a8c957', // Default border color
  //                                                 },
  //                                                 '&:hover fieldset': {
  //                                                     borderColor: '#a8c957', // Border color when hovered (optional)
  //                                                 },
  //                                                 '&.Mui-focused fieldset': {
  //                                                     borderColor: '#a8c957', // Border color when the input is focused
  //                                                 },
  //                                             },
  //                                             '& .MuiInputLabel-root': { // This will ensure label color is green always, if you want it only when focused remove this block
  //                                                 color: '#333333'
  //                                             }
  //                                         }}
  //                                         error={errors.mode && touched.mode ? true : false}
  //                                         helperText={errors?.mode && touched?.mode ? errors?.mode : null}
  //                                     >
  //                                         <MenuItem key={1} value="create">Create Content</MenuItem>
  //                                         <MenuItem key={2} value="tweak">Tweak Content</MenuItem>
  //                                         <MenuItem key={3} value="translate">Translate Content</MenuItem>
  //                                     </TextField>

  //                                     {/* content */}
  //                                     <TextField
  //                                         required
  //                                         multiline
  //                                         aria-invalid={errors.content ? "true" : "false"}
  //                                         aria-describedby="uidnote"
  //                                         id="content"
  //                                         name="content"
  //                                         className="form-select-field"
  //                                         variant="outlined"
  //                                         placeholder="What do you want to write about?"
  //                                         type="input"
  //                                         label="What is the Content about?"
  //                                         minRows={8}
  //                                         onChange={(e) => {
  //                                             setFieldValue(e.target.name, e.target.value)
  //                                         }}
  //                                         sx={{
  //                                             '& label.Mui-focused': {
  //                                                 color: '#a8c957', // Label color when the input is focused
  //                                             },
  //                                             '& .MuiInput-underline:after': {
  //                                                 borderBottomColor: '#a8c957', // Underline color when input is focused
  //                                             },
  //                                             '& .MuiOutlinedInput-root': {
  //                                                 '& fieldset': {
  //                                                     borderColor: '#a8c957', // Default border color
  //                                                 },
  //                                                 '&:hover fieldset': {
  //                                                     borderColor: '#a8c957', // Border color when hovered (optional)
  //                                                 },
  //                                                 '&.Mui-focused fieldset': {
  //                                                     borderColor: '#a8c957', // Border color when the input is focused
  //                                                 },
  //                                             },
  //                                             '& .MuiInputLabel-root': { // This will ensure label color is green always, if you want it only when focused remove this block
  //                                                 color: '#333333'
  //                                             }
  //                                         }}
  //                                         inputProps={{ style: { resize: "vertical" } }}
  //                                         onBlur={handleBlur}
  //                                         value={values?.content}
  //                                         error={errors?.content && touched?.content ? true : false}
  //                                         helperText={errors?.content && touched?.content ? errors.content : null}
  //                                     />

  //                                     {values?.mode === "translate" ?
  //                                         <>
  //                                             {/* Language */}
  //                                             <Autocomplete
  //                                                 freeSolo
  //                                                 autoComplete
  //                                                 required
  //                                                 disablePortal
  //                                                 options={languages}
  //                                                 handleHomeEndKeys
  //                                                 id="language"
  //                                                 name="Language"
  //                                                 value={values?.language}
  //                                                 clearIcon={<ClearIcon sx={{ color: "var(--intu-lightGrey)" }} />}
  //                                                 className="form-select-field"
  //                                                 onChange={(e, options) => {
  //                                                     setFieldValue("language", options)
  //                                                 }}
  //                                                 onBlur={handleBlur}
  //                                                 sx={{ paddingBottom: "50px" }}
  //                                                 renderInput={(params) => (
  //                                                     <TextField
  //                                                         {...params}
  //                                                         variant="outlined"
  //                                                         InputProps={{
  //                                                             ...params.InputProps,
  //                                                             type: 'text',
  //                                                             autoComplete: 'off',
  //                                                         }}
  //                                                         sx={{
  //                                                             '& label.Mui-focused': {
  //                                                                 color: '#a8c957', // Label color when the input is focused
  //                                                             },
  //                                                             '& .MuiInput-underline:after': {
  //                                                                 borderBottomColor: '#a8c957', // Underline color when input is focused
  //                                                             },
  //                                                             '& .MuiOutlinedInput-root': {
  //                                                                 '& fieldset': {
  //                                                                     borderColor: '#a8c957', // Default border color
  //                                                                 },
  //                                                                 '&:hover fieldset': {
  //                                                                     borderColor: '#a8c957', // Border color when hovered (optional)
  //                                                                 },
  //                                                                 '&.Mui-focused fieldset': {
  //                                                                     borderColor: '#a8c957', // Border color when the input is focused
  //                                                                 },
  //                                                             },
  //                                                             '& .MuiInputLabel-root': { // This will ensure label color is green always, if you want it only when focused remove this block
  //                                                                 color: '#333333'
  //                                                             }
  //                                                         }}
  //                                                         placeholder="Type in your keyword you want to include in your post and hit ↵"
  //                                                         label="Language"
  //                                                         error={touched?.language && errors?.language ? true : false}
  //                                                         helperText={touched?.language && errors?.language ? errors?.language : null}
  //                                                     />
  //                                                 )}
  //                                             />
  //                                         </>
  //                                         :
  //                                         <>

  //                                             {/* keywords */}
  //                                             <Autocomplete
  //                                                 freeSolo
  //                                                 autoComplete
  //                                                 multiple
  //                                                 required
  //                                                 disablePortal
  //                                                 options={[]}
  //                                                 handleHomeEndKeys
  //                                                 id="keywords"
  //                                                 name="keywords"
  //                                                 value={values?.keywords}
  //                                                 clearIcon={<ClearIcon sx={{ color: "var(--intu-lightGrey)" }} />}
  //                                                 className="form-select-field"
  //                                                 onChange={(e, options) => {
  //                                                     setFieldValue("keywords", options)
  //                                                 }}
  //                                                 sx={{
  //                                                     '& .MuiInputBase-adornedStart MuiInputBase-adornedEnd MuiAutocomplete-inputRoot': {
  //                                                         border: "none"
  //                                                     },

  //                                                     '& .MuiAutocomplete-tag': {
  //                                                         color: 'var(--color-tag-text)',
  //                                                         backgroundColor: 'var(--color-tag-background)',
  //                                                     },
  //                                                     '& .MuiChip-deleteIcon': {
  //                                                         color: 'var(--color-tag-icon-delete)',
  //                                                         '&:hover': {
  //                                                             color: 'var(--color-tag-icon-delete-hover)'
  //                                                         }
  //                                                     },
  //                                                     '& .MuiAutocomplete-clearIndicator': {
  //                                                         color: 'white',
  //                                                         '&:hover': {
  //                                                             color: 'var(--color-tag-icon-delete-hover)'
  //                                                         }
  //                                                     },
  //                                                     '& .MuiAutocomplete-popupIndicator': {
  //                                                         color: 'var(--color-tag-popup-indicator)',

  //                                                         '&:hover': {
  //                                                             color: 'var(--color-tag-popup-indicator-hover)'
  //                                                         }
  //                                                     },
  //                                                     '& .MuiAutocomplete-popupIndicatorOpen': {
  //                                                         color: 'var(--color-tag-popup-indicator-hover)',
  //                                                         '&:hover': {
  //                                                             color: 'var(--color-tag-popup-indicator)'
  //                                                         }
  //                                                     },
  //                                                     '.MuiOutlinedInput-input': {
  //                                                         color: 'var(----color-autocomplete-input-text)'
  //                                                     }
  //                                                 }}
  //                                                 onBlur={handleBlur}
  //                                                 renderInput={(params) => (
  //                                                     <TextField
  //                                                         {...params}
  //                                                         variant="outlined"
  //                                                         InputProps={{
  //                                                             ...params.InputProps,
  //                                                             type: 'text',
  //                                                             autoComplete: 'off',
  //                                                         }}
  //                                                         sx={{
  //                                                             '& label.Mui-focused': {
  //                                                                 color: '#a8c957', // Label color when the input is focused
  //                                                             },
  //                                                             '& .MuiInput-underline:after': {
  //                                                                 borderBottomColor: '#a8c957', // Underline color when input is focused
  //                                                             },
  //                                                             '& .MuiOutlinedInput-root': {
  //                                                                 '& fieldset': {
  //                                                                     borderColor: '#a8c957', // Default border color
  //                                                                 },
  //                                                                 '&:hover fieldset': {
  //                                                                     borderColor: '#a8c957', // Border color when hovered (optional)
  //                                                                 },
  //                                                                 '&.Mui-focused fieldset': {
  //                                                                     borderColor: '#a8c957', // Border color when the input is focused
  //                                                                 },
  //                                                             },
  //                                                             '& .MuiInputLabel-root': { // This will ensure label color is green always, if you want it only when focused remove this block
  //                                                                 color: '#333333'
  //                                                             }
  //                                                         }}
  //                                                         placeholder="Type in your keyword you want to include in your post and hit ↵"
  //                                                         label="Keywords to include"
  //                                                         error={touched?.keywords && errors?.keywords ? true : false}
  //                                                         helperText={touched?.keywords && errors?.keywords ? errors?.keywords : null}
  //                                                     />
  //                                                 )}
  //                                             />

  //                                     {/* Addressee */}
  //                                     <Autocomplete
  //                                         freeSolo
  //                                         autoComplete
  //                                         required
  //                                         multiple
  //                                         disablePortal
  //                                         options={addresseeOptions}
  //                                         handleHomeEndKeys
  //                                         id="addressee"
  //                                         name="addressee"
  //                                         value={values?.addressee}
  //                                         clearIcon={<ClearIcon sx={{ color: "var(--intu-lightGrey)" }} />}
  //                                         className="form-select-field"
  //                                         onChange={(e, options) => {
  //                                             setFieldValue("addressee", options)
  //                                         }}
  //                                         onBlur={handleBlur}
  //                                         sx={{
  //                                             '& .MuiInputBase-adornedStart MuiInputBase-adornedEnd MuiAutocomplete-inputRoot':{
  //                                                 border: "none"
  //                                             },

  //                                             '& .MuiAutocomplete-tag': {
  //                                                 color: 'var(--color-tag-text)',
  //                                                 backgroundColor: 'var(--color-tag-background)',
  //                                             },
  //                                             '& .MuiChip-deleteIcon': {
  //                                                 color: 'var(--color-tag-icon-delete)',
  //                                                 '&:hover': {
  //                                                     color: 'var(--color-tag-icon-delete-hover)'
  //                                                 }
  //                                             },
  //                                             '& .MuiAutocomplete-clearIndicator': {
  //                                                 color: 'white',
  //                                                 '&:hover': {
  //                                                     color: 'var(--color-tag-icon-delete-hover)'
  //                                                 }
  //                                             },
  //                                             '& .MuiAutocomplete-popupIndicator': {
  //                                                 color: 'var(--color-tag-popup-indicator)',

  //                                                 '&:hover': {
  //                                                     color: 'var(--color-tag-popup-indicator-hover)'
  //                                                 }
  //                                             },
  //                                             '& .MuiAutocomplete-popupIndicatorOpen': {
  //                                                 color: 'var(--color-tag-popup-indicator-hover)',
  //                                                 '&:hover': {
  //                                                     color: 'var(--color-tag-popup-indicator)'
  //                                                 }
  //                                             },
  //                                             '.MuiOutlinedInput-input':{
  //                                               color: 'var(----color-autocomplete-input-text)'
  //                                             },
  //                                             '.MuiAutocomplete-option[data-focus="true"]': {
  //                                                 background: 'red !important'
  //                                             },
  //                                             '& .MuiAutocomplete-option[aria-selected="true"]': {
  //                                                 backgroundColor: '#b6c68f', // Your desired color for selected options
  //                                                 '&:hover': {
  //                                                     backgroundColor: '#b6c68f', // Maintain the same color on hover
  //                                                 },
  //                                             },
  //                                             '& .MuiAutocomplete-option.Mui-focused': {
  //                                                 backgroundColor: 'rgba(182, 198, 143, 0.4)', // Optional: a lighter color or different color when the option is focused but not yet selected
  //                                             },
  //                                         }}
  //                                         renderInput={(params) => (
  //                                             <TextField
  //                                                 {...params}
  //                                                 variant="outlined"
  //                                                 InputProps={{
  //                                                     ...params.InputProps,
  //                                                     type: 'text',
  //                                                     autoComplete: 'off',
  //                                                 }}
  //                                                 sx={{
  //                                                     '& label.Mui-focused': {
  //                                                         color: '#a8c957', // Label color when the input is focused
  //                                                     },
  //                                                     '& .MuiInput-underline:after': {
  //                                                         borderBottomColor: '#a8c957', // Underline color when input is focused
  //                                                     },
  //                                                     '& .MuiOutlinedInput-root': {
  //                                                         '& fieldset': {
  //                                                             borderColor: '#a8c957', // Default border color
  //                                                         },
  //                                                         '&:hover fieldset': {
  //                                                             borderColor: '#a8c957', // Border color when hovered (optional)
  //                                                         },
  //                                                         '&.Mui-focused fieldset': {
  //                                                             borderColor: '#a8c957', // Border color when the input is focused
  //                                                         },
  //                                                     },
  //                                                     '& .MuiInputLabel-root': { // This will ensure label color is green always, if you want it only when focused remove this block
  //                                                         color: '#333333'
  //                                                     }
  //                                                 }}
  //                                                 placeholder="Type in your keyword you want to include in your post and hit ↵"
  //                                                 label="Who you want to address?"
  //                                                 error={touched?.addressee && errors?.addressee ? true : false}
  //                                                 helperText={touched?.addressee && errors?.addressee ? errors?.addressee : null}
  //                                             />
  //                                         )}
  //                                     />

  //                                         </>
  //                                     }

  //                                     <Button
  //                                         variant="contained"
  //                                         color="primary"
  //                                         onClick={async () => {
  //                                             const recommendation = await getRecommendation(values);
  //                                             onChange(recommendation);
  //                                             setValues(values)
  //                                         }}
  //                                         disabled={loading || !formik.isValid}
  //                                         startIcon={loading ? <CircularProgress size={20} /> : null}
  //                                     >
  //                                         {loading ? `Creating Content...` : 'Create Content'}
  //                                     </Button>
  //                                 </Stack>
  //                             </Grid>
  //                         </Grid>
  //                     </FormControl>
  //                 </Form>
  //             )
  //         }}
  //     </Formik>
  // );
});

const contentCreationPlugin = {
  // Renderer: (props) => {
  //     return (
  //         <>
  //             <Grid
  //                 container>
  //                 <Grid item xs={12}>
  //                     {ReactHtmlParser(props.data.recommedation)}
  //                 </Grid>
  //             </Grid>
  //         </>
  //     );
  // },
  Renderer: () => {},
  id: 'chatGPTContent',
  title: 'Content Plugin',
  description: 'Use a little magic to create your content',
  icon: <EditIcon />,
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        recommedation: {
          type: 'string',
          uniforms: {
            component: (props) => <ChatGPTComponent {...props} />,
          },
        },
      },
    },
  },
};

export default contentCreationPlugin;
