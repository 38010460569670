import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import IntuPage from '../../components/layout/IntuPage';
import { IntuLeadsList } from '../../components/howitworks/lists/IntuLeadsList';
import { IntuCompanyInfoProvider } from '../../context/providers/IntuCompanyInfoProvider';
import { LeadProvider } from '../../context/providers/leads/LeadContextProvider';
import MapLocationByAddressProvider from '../../context/providers/maps/MapLocationByAddressProvider';
import { ManageLeadProvider } from '../../context/providers/leads/ManageLeadContextProvider';

const IntuLeads = () => {
  const { t } = useTranslation();

  return (
    <IntuPage
      pageTitle={t('pages.admin.intuLeads.title')}
      pageDescription={t('pages.admin.intuLeads.description')}
      hCentered={true}
    >
      <IntuForm>
        <IntuCompanyInfoProvider>
          <LeadProvider>
            <ManageLeadProvider>
              <MapLocationByAddressProvider>
                <IntuLeadsList />
              </MapLocationByAddressProvider>
            </ManageLeadProvider>
          </LeadProvider>
        </IntuCompanyInfoProvider>
      </IntuForm>
    </IntuPage>
  );
};

export default IntuLeads;
