import * as Yup from 'yup';
import i18next from 'i18next';

export const createCompanyProfileValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    name: Yup.string()
      .min(5, t('short'))
      .required(t('company.name_required_alt')),
    address: Yup.object({
      line1: Yup.string()
        .min(5, t('short'))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short'))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short'))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(3, t('short'))
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
    phone: Yup.object({
      value: Yup.string().required(t('company.phone_required')),
      info: Yup.object({
        countryCallingCode: Yup.number(t('phone.country_code.invalid'))
          .required(t('phone.country_code.required'))
          .typeError(t('phone.country_code.error')),
        nationalNumber: Yup.string()
          .required(t('phone.required'))
          .min(8, t('phone.min'))
          .max(15, t('phone.max')),
      }),
    }),
    email: Yup.string()
      .required(t('email.user_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('email.invalid'),
      ),
    website: Yup.string()
      .min(3, t('short'))
      .required(t('website_required'))
      .matches(
        /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
        t('website_invalid'),
      ),
    employees: Yup.array().min(1, t('employee_min')),
    type: Yup.string().required(t('account.type_required')),

    applications: Yup.array().min(1, t('industry_name_required')),
    products: Yup.array().min(1, t('product_min')),
    // intro: Yup.object({
    //   blocks: Yup.array()
    //     .of(
    //       Yup.object().shape({
    //         text: Yup.string().required(t('intro.text_required')),
    //       }),
    //     )
    //     .min(2, t('intro.text_min'))
    //     .required(t('intro.text_required')),
    // }),
    intro: Yup.object().shape({
      blocks: Yup.array()
        .of(
          Yup.object().shape({
            text: Yup.string().test(
              'is-not-empty-string',
              t('intro.text_required'),
              (value) => {
                return value !== '' || value === null;
              },
            ),
          }),
        )
        .min(2, t('intro.text_min'))
        .required(t('intro.text_required')),
    }),
    // intro: Yup.object({
    //   blocks: Yup.array().when('company.type', {
    //     is: (type) => type === 'manufacturer',
    //     then: Yup.array()
    //       .of(
    //         Yup.object().shape({
    //           text: Yup.string().required(
    //             t('intro.text_required'),
    //           ),
    //         }),
    //       )
    //       .min(2, t('intro.text_min'))
    //       .required(t('intro.text_required')),
    //   }),
    // }),
  });
};
