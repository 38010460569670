import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CardHeader,
  Typography,
} from '@mui/material';

const ProductCard = ({ product }) => {
  const navigate = useNavigate();
  const imagePNG = useMemo(() => {
    if (!product || !Array.isArray(product.images) || !product.images.length) {
      return null;
    }
    return product.images.find((imageFile) => imageFile.endsWith('.png'));
  }, [product]);

  console.log('product', product);
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.3 }}
      layout
    >
      <Card sx={{ maxWidth: 345, width: 345 }}>
        <CardHeader
          title={product.product_name}
          subheader={`${product.list_price} ${product.currency}`}
        />
        {imagePNG ? (
          <CardMedia
            sx={{ height: 140 }}
            image={imagePNG}
            title={product.product_name}
          />
        ) : (
          <Box height={140} />
        )}
        <CardContent>
          {/* <Typography gutterBottom variant="h5" component="div">
            {product.author.name}
          </Typography> */}
          <Typography variant="body2" color="text.secondary">
            {product.description}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* <Button size="small">Share</Button> */}
          <Button
            size="small"
            variant="contained"
            onClick={() => navigate(`/product/${product._id}`)}
          >
            Learn More
          </Button>
        </CardActions>
      </Card>
    </motion.div>
  );
};

export default ProductCard;
