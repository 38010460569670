import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PrivacyContent } from '../../components/privacy/PrivacyContent';
import IntuPage from '../../components/layout/IntuPage';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <IntuPage pageTitle={t('pages.PrivacyPolicy.title')}>
      <PrivacyContent />
    </IntuPage>
  );
};

export default PrivacyPolicy;
