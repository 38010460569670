import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import { useTheme } from '@mui/material';
import { LayoutBodyContext } from '../../context/providers/LayoutContextProvider';
import { useTranslation } from 'react-i18next';

function LandingLayout() {
  const theme = useTheme();

  const { setLayoutTitle } = useContext(LayoutBodyContext);
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home',
  });

  useEffect(() => {
    // Set the Page Title
    setLayoutTitle(t('title'));
  }, []);

  // Loading Spinner Status
  const navBarHeight = parseInt(theme.components.MuiAppBar.height, 10);
  const paddingTop = `${navBarHeight}px`;

  return (
    <Container
      maxWidth="auto"
      component="main"
      disableGutters
      sx={{
        margin: `${paddingTop} 0 0 0`,
      }}
    >
      <Outlet />
    </Container>
  );
}

export default LandingLayout;
