


// Format TimeStamp to MMDDYYY
function formatDateToMMDDYYYY(timestamp) {
    // Convert the timestamp to a Date object
    const dateObj = new Date(timestamp);

    // Get the month, day, and year from the date object
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Adding 1 as getMonth() returns zero-based index
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();

    // Concatenate the formatted date components
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
}


export { formatDateToMMDDYYYY }