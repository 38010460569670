import axios from "../hooks/axios/useAxios";
import { axiosPrivate } from "../hooks/axios/axios";
import i18n from "../i18n";

async function getPosts(auth) {
    const controller = new AbortController();
    const { signal } = controller;
    if (auth?.auth_info) {
        const url = "/api/blog/posts/list";
        try {
            const response = await axiosPrivate.get(url,
                {
                    signal,
                    headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${auth?.auth_info}`,
                                'Accept-Language': i18n.language
                             }
                })
            const { data, status } = response;
            if (status) {
                return data;
            }
        } catch (err) {
            return err;
        }
    }
    else {
        const url = "/api/blog/posts/public/list";
        try {
            const response = await axios.get(url,
                {
                    signal,
                    headers: { 'Content-Type': 'application/json' }
                })
            const { data, status } = response;
            if (status) {
                return data;
            }
        } catch (err) {
            return err;
        }
    }
}

async function searchPosts(link, auth) {
    const controller = new AbortController();
    const { signal } = controller;
    const payload = {
        key: "link",
        value: link
    }

    if (auth?.auth_info) {
        const url = "/api/blog/posts/search";
        try {
            const response = await axiosPrivate.post(url,
                JSON.stringify(payload),
                {
                    signal,
                    headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${auth?.auth_info}`,
                                'Accept-Language': i18n.language
                             }
                })
            const { data, status } = response;
            if (status) {
                return data;
            }
        } catch (err) {
            return err;
        }
    }
    else {
        const url = "/api/blog/posts/public/search";
        try {
            const response = await axios.post(url,
                JSON.stringify(payload),
                {
                    signal,
                    headers: { 'Content-Type': 'application/json' }
                })
            const { data, status } = response;
            if (status) {
                return data;
            }
        } catch (err) {
            return err;
        }
    }
}

async function searchPostTags(input, auth) {
    const controller = new AbortController();
    const { signal } = controller;
    const url = "/api/blog/posts/tags/search"

    try {
        const payload = { value: input }
        const response = await axios.post(url,
            JSON.stringify(payload),
            {
                signal,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth?.auth_info}`,
                    'Accept-Language': i18n.language
                }
            })
        const { data, status } = response;
            if (status) {
                return data
            }
    } catch (err) {
        return err;
    }
}

export { getPosts, searchPosts, searchPostTags }