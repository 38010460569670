import React, { createContext } from 'react';
import { useState, useEffect, useRef, useContext } from 'react';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard.jsx';

// Global Functions
import { toTitleCase } from '../../../helpers/toTitleCase.js';

// Formik
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';

// MUI Compoents
import {
  Typography,
  Skeleton,
  Stack,
  FormControl,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { classes } from '../../../mui/theme.js';
import { stateToHTML } from 'draft-js-export-html';

// Icons
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import SourceIcon from '@mui/icons-material/Source';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// Response & Loading Handlers
import useResponseHandling from '../../../hooks/useResponseHandler.js';

import { Link } from 'react-router-dom';

import { IntuRichTextEditor } from '../../../components/IntuRichTextEditor/IntuRichtTextEditor.js';

// Import Language support
import { useTranslation } from 'react-i18next';

import { axiosPrivate } from '../../../hooks/axios/axios.js';

import { getMuiRichTextEditorContent } from '../../../helpers/muiRichtTextEditor.js';

import IndividualFirstNameField from '../../../components/account/individual/IndividualFirstNameField.jsx';
import IndividualLastNameField from '../../../components/account/individual/IndividualLastNameField.jsx';
import { AssociateActivityDialog } from '../dialogs/AssociateActivityDialog.jsx';
import { AssociateActivity } from '../lists/AssociateActivity.jsx';
import { LeadActivity } from '../lists/LeadActivityList.jsx';
import { IntuCompanyContext } from '../../../context/providers/IntuCompanyInfoProvider.jsx';
import AddressField from '../../../components/address/AddressField.jsx';
import PhoneField from '../../../components/account/PhoneField .jsx';
import EmailField from '../../../components/account/EmailField.jsx';
import { useProcessingHandler } from '../../../hooks/useProcessingHandler.js';
import IntuIconButton from '../../../components/buttons/IntuIconButton.jsx';
import SingleLanguageSelectionfield from '../../../components/language/SingleLanguageSelectionField.jsx';
import { CompanyBanner } from '../../../components/account/company/CompanyBanner.jsx';
import { ManageLeadContext } from '../../../context/providers/leads/ManageLeadContextProvider.jsx';
import useAuth from '../../../hooks/useAuth.js';
import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsTracker.jsx';
import { blankLeadForm } from '../../../context/providers/leads/blankLeadForm.js';
import UserContext from '../../../context/providers/UserInfoProvider.jsx';
import IndustriesField from '../../industries/IndustryField.jsx';
import AssociateSelectField from '../../associate/AssociateSelectField.jsx';
import BusinessNameTextField from '../../account/business/BusinessNameTextField.jsx';

// Context
const MapContext = createContext();

// Lead Form
const LeadDetailsForm = () => {
  // Use Context
  const { leadInfo, setLeadInfo } = useContext(ManageLeadContext);
  const { employeeOptions } = useContext(IntuCompanyContext);

  const { lng } = useContext(UserContext);

  const { gaEventTracker } = useAnalyticsEventTracker();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { isLoading } = useProcessingHandler();
  const {
    setActivityDialog,
    leadDetailsDialog,
    setLeadDetailsDialog,
    saveLead,
  } = useContext(ManageLeadContext);

  // Translator & Language
  const { t } = useTranslation();
  const { t: transTypes } = useTranslation('types');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transDialogs } = useTranslation('dialogs');

  const [mapRendered, setMapRendered] = useState(false);

  const { auth } = useAuth();

  // Copy Link to clipboard
  const { copy } = useCopyToClipboard();

  // Navigation
  const host_url = window.location.host;

  // Fetch Intro
  const [fetchHeadline, setFetchHeadline] = useState(false);
  const [fetchIntro, setFetchIntro] = useState(false);

  // Set Up Form refs
  const errRef = useRef();
  const headlineTitleRef = useRef();
  const introTitleRef = useRef();

  useEffect(() => {
    if (headlineTitleRef.current) {
      headlineTitleRef.current.focus();
    }
  }, [fetchHeadline]);

  // Initial Values
  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    ...leadInfo,
  };

  // Initial Values & Field Validation
  let validationSchema = Yup.object();

  // Modal Form Dependend Validation
  if (leadDetailsDialog?.lead_type === 'investor') {
    // Lead Type Depended Initial Values
    formik.initialValues.lead_type = 'investor';

    // Validation
    validationSchema = Yup.object().shape({
      investorLeadDialogOpen: Yup.boolean(),
      associate: Yup.object({
        _id: Yup.string().required(t('form.associate_required')),
      }),
      company: Yup.string().required(t('validation.company.name_required')),
      lead_type: Yup.string().required(t('validation.lead.type_required')),
      company_type: Yup.string().required(
        t('validation.company.type_required'),
      ),
      headline_title: Yup.string().required(
        t('validation.intro.title_required'),
      ),
      headline_body: Yup.string().required(t('validation.intro.text_required')),
      email: Yup.string().when('intro_type', {
        is: 'Email',
        then: () =>
          Yup.string()
            .required(t('validation.email.intro_required'))
            .matches(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              t('form.email.invalid'),
            ),
      }),
      intro_subject: Yup.string().when('intro_type', {
        is: 'Email',
        then: () =>
          Yup.string().required(t('validation.intro.subject_required')),
      }),
      intro_message: Yup.string().when('intro_type', {
        is: 'Email',
        then: () => Yup.string().required(t('validation.intro.text_required')),
      }),
    });
  } else {
    // Validation
    validationSchema = Yup.object().shape({
      regularLeadDialogOpen: Yup.boolean(),
      first_name: Yup.string()
        .min(2, t('validation.short'))
        .max(50, t('validation.long'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
          t('validation.name.first_matches'),
        )
        .required(t('validation.name.first_required')),
      last_name: Yup.string()
        .min(2, t('validation.short'))
        .max(50, t('validation.long'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
          t('validation.name.last_matches'),
        )
        .required(t('validation.name.last_required')),
      active: Yup.string().required(t('validation.active_required')),
      headline_title: Yup.string().required(
        t('validation.headline_title_required'),
      ),
      headline_body: Yup.string().required(
        t('validation.headline_body_required'),
      ),
      address: Yup.object().when('company', {
        is: (company) => Boolean(company),
        then: () =>
          Yup.object({
            line1: Yup.string()
              .min(5, t('validation.short'))
              .required(t('validation.address.line1_required')),
            city: Yup.string()
              .min(3, t('validation.short'))
              .required(t('validation.address.city_required')),
            state: Yup.string()
              .min(2, t('validation.short'))
              .required(t('validation.address.state_required')),
            postcode: Yup.string()
              .min(3, t('validation.short'))
              .required(t('validation.address.postcode_required')),
            country: Yup.object({
              label: Yup.string().required(t('validation.country.required')),
              dialCode: Yup.number(t('validation.country.dial_code.invalid'))
                .required(t('validation.country.dial_code.required'))
                .typeError(t('validation.country.dial_code.error')),
              code: Yup.string()
                .required(t('validation.country.code.required'))
                .min(2, t('validation.country.code.min')),
            }),
          }),
      }),
      company: Yup.string()
        .nullable()
        .when('lead_type', {
          is: 'manufacturer',
          then: () =>
            Yup.string()
              .label('Company Name')
              .required(t('validation.company.name_required')),
        }),
      email: Yup.string().when('intro_type', {
        is: 'Email',
        then: () =>
          Yup.string()
            .required(t('validation.email.intro_required'))
            .matches(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              t('validation.email.invalid'),
            ),
      }),
      intro_subject: Yup.string().when('intro_type', {
        is: 'Email',
        then: () =>
          Yup.string().required(t('validation.intro.subject_required')),
      }),
      intro_message: Yup.string().when('intro_type', {
        is: 'Email',
        then: () => Yup.string().required(t('validation.intro.text_required')),
      }),
      lead_type: Yup.string().required(t('validation.lead.type_required')),
      associate: Yup.object({
        _id: Yup.string().required(t('validation.associate_required')),
      }),
      industries: Yup.array()
        .min(1, t('validation.industry_min'))
        .required(t('validation.industry_name_required')),
    });
  }

  // Get CHAT GPT Intro Text
  async function getIntroText(values) {
    setFetchIntro(true);
    let chatGptPrompt;
    let industries;
    let industry;
    let voice;

    if (values.lead_type !== 'investor') {
      industries = values.industries;
      industry = industries.join(', ');
    }

    if (values.lead_type === 'manufacturer') {
      chatGptPrompt = `Convince ${values.company} him to advertise and sell his products on IN2TEC, a marketplace for sophisticated technology products, allowing him to advertise and sell ${values.company}'s products as well as providing on site support through a dedicated network of sales reps and influencers. Include a brief descriptions of products and industries. Also point out our expertise within ${industry} and include best regards from ${values.associate.name}`;
      voice = 'Tony Robbins';
    } else if (values.lead_type === 'salesrep') {
      chatGptPrompt = `Convince ${values.first_name} ${values.last_name} to join IN2TEC as an independent sales rep within ${industry} industry. IN2TEC is a new marketplace of sophisticated technology products and offers individuals the opportunity to refer and sell products in return of a commission. Include best regards from ${values.associate.name}`;
      voice = 'Tony Robbins';
    } else if (values.lead_type === 'influencer') {
      chatGptPrompt = `Convince ${values.first_name} ${values.last_name}  to join IN2TEC as an influencer within ${industry} industry. IN2TEC is a new marketplace of sophisticated technology products and offers individuals the opportunity to refer products in return of a fee. Include best regards from ${values.associate.name}`;
      voice = 'Tony Robbins';
    } else if (values.lead_type === 'investor') {
      chatGptPrompt = `Produce an ${values.intro_type} in short using 150 words three paragraphs in the style of Steve Jobs message addressed to ${values.first_name} ${values.last_name}, convincing his ${values.lead_type} ${values.company} to invest in IN2TEC. IN2TEC is a new marketplace of sophisticated technology products and offers individuals the opportunity to refer products in return of a fee. Include best regards from ${values.associate.name}`;
      voice = 'Steve Jobs';
    }

    let payload = {
      messages: [
        {
          role: 'assistant',
          content: `Produce a landing page headline for ${values.intro_type} in short using 150 words three paragraphs in the style of ${voice} message addressed to ${values.first_name} ${values.last_name}. Produce the content in ${values?.language.name}. Make sure that the title is clearly marked as "Subject:" regardless of the language. Talk in the You Style e.g. why should you..`,
        },
        {
          role: 'user',
          content: chatGptPrompt,
          name: 'IN2TEC',
        },
      ],
      temperature: 0.2,
    };

    let url = '/api/chatgpt/get-sentence';

    const controller = new AbortController();
    const signal = controller.signal;
    try {
      const response = await axiosPrivate.post(url, payload, {
        signal,
      });

      const data = response?.data;

      if (response.status === 200) {
        // Track Event on Google
        gaEventTracker({
          category: 'Lead Management',
          action: 'Event',
          label: 'New Chat GPT Recommendation',
        });

        return { status: response.status, data: response.data.data };
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
      errRef.current?.focus();
    } finally {
      controller.abort();
      setFetchIntro(false);
    }
  }

  // Get ChatGPT Headline Text
  async function getHeadlineText(values, formik) {
    setFetchHeadline(true);
    let chatGptPrompt =
      'Create a title in plain text followed by 5 bullet points summarizing the following benefits! Each bullet should have one sentence.';
    let voice;
    let industries = values.industries.join(', ');
    if (values.lead_type === 'investor') {
      // Investor
      chatGptPrompt = `Outline why ${values.company} should accept IN2TEC into their accelerator - an newly created online distribution platform for sophisticated technology products -
          currently focusing on industrial automation sensors (a 12 billion dollar market as of 2022 and an annual growth of almost 10% a year). Outline why we are such a good fit  for ${values.company} (the accelerator).
          IN2TEC allows sales engineers to become  their own independent sales reps and they can earn commission on sales generated. Content creators and industry professionals can become influencers, receiving rewards in return referrals.
          Through that concept we remove unnecessary players in between customers and manufacturers, increase margins as less people in between pocket profits, save cost for own sales people and utilize marketing budgets more efficiently.`;
      voice = 'Tony Robbins';
    } else if (values.lead_type === 'manufacturer') {
      // Manufacturer
      chatGptPrompt = `Outline why ${values.company} should join IN2TEC as a ${values.lead_type} - a newly created online distribution platform for sophisticated technology products - currently focusing on industrial automation sensors.
          - Other than traditional distribution, we are transparent, because we provide opportunity, sales, & marketing data by region, application and customer - allowing ${values.company} to make better decisions in their future market- and product development.
          - We alllow them to scale their sales force without the monthly overheads
          - There is only one person between  ${values.company}  which improves communication & the overall customer experience
          - Also, we allow ${values.company} to advertise their products through a network of influencers, improving the return on marketing dollars spent through direct marketing.
          Overall, our model reduces the amount of players between  ${values.company}  and the customer, improving communication. ${values.company} can gain first hand market data, including opportunities, marketing data of advertising campaigns and sales data of products sold by region & application - which enhances decision making e.g. for market development strategies, product strategy. Ultimately this leads to higher revenue and less cost, increasing efficiency and profit.`;
      voice = 'Tony Robbins';
    } else if (values.lead_type === 'salesrep') {
      // Sales Rep
      voice = 'Tony Robbins';
      if (values.company) {
        chatGptPrompt = `Produce arumgents why ${values.company} should join IN2TEC as a ${values.lead_type} - an newly created online distribution platform for sophisticated technology products -
          Outline why they are such a great fit based on their expertise within ${industries} and include the following beneftis. Increase of exposure as we show customers the closes sales rep by location annd application area. Withus, dales reps can assign paticular products and applications and dont need to take on everything which helps to streamline their efforts,
          Great database of niche technology brands not everyone is after, helping you to stand out and increases profit margins. Increase by onboarding independent sales reps and let them work for you. Monitor your leads, appotrunities and revenue 24/7 and easely run reports on sales reps, regions, applications and more. Benefit from a non capped commission model which is transparent, fair and accomodates low & high cost products.`;
      } else {
        // Individual Sales Rep
        chatGptPrompt = `Outline why ${values.first_name} ${values.last_name} should join IN2TEC as a ${values.lead_type}. IN2TEC is a newly created online distribution platform for sophisticated technology products -
          Outline why ${values.first_name} ${values.last_name} is such a great fit based on his expertise within ${industries} and include the following beneftis. Build your own portfolio with multiple manufacturers based on your product interest, locations and experience in ${industries}, while
          being independent and secure as he/she has lots of choices and dont depend on one company. Establish direct relationshops with the manufacturer with no third part rep in between. Benefit from great commissions which are not capped and can asely produce a 6 digit income!
          By eliminiating unneccessary players in between them and the manufacturer, information flows more direct and quicker - helping the the sales rep to support their customers better!
          IN2TEC allows ${values.lead_type}'s to become their own independent sales reps and let them focus on what he/she is best at - sales & marketing. We do the rest including invoiceing, order management & accounting. We provide a 1099 for their annual income statement.
          Make sure that the title and conent is written in first person to ${values.first_name} ${values.last_name}!`;
      }
    } else if (values.lead_type === 'influencer') {
      // Influencer
      chatGptPrompt = `Produce arguments as of why ${values.first_name} ${values.last_name} should join IN2TEC as a ${values.lead_type} - an newly created online distribution platform for sophisticated technology products -
          currently focusing on industrial automation sensors (a 12 billion dollar market as of 2022 and an annual growth of almost 10% a year).
          Outline the following benefits. We funnel valuable marketing budgets of manufacturers from google and to individuals for their promotion and recommendation. That leads to better qualified leads. Everyone can be an influencer including but not limited to engineers, sales engineers, bloggers and industry professionals with a passion for technology.
          Influencers stay anomynous as their identity is not revealed to other influencers, sales reps, manufacturers or customers - so it can be a perfect entry level for people currently in a job and looking to explore.
          We match advertising opportunities by location, appliction and product settings, helping influencers to receive relevant offers. Influencers get paid by impression or interaction like an inquiry, sample request or sales!`;
    }
    // Create the headline body
    let payload = {
      messages: [
        {
          role: 'assistant',
          content: `Create a title in plain text in ${values?.language.name} followed by 5 bullet points summarizing the following benefits! Each bullet should have one sentence. Format the bullet points using HTML unordered list tags. The title should remain in plain text, but each bullet point should be wrapped with <li> tags and all the bullet points should be enclosed within a single <ul> tag. If a company name is provided, research some facts about the company and write in the voice of ${voice}. Make sure that the title is clearly marked as "Title:" regardless of the langyage. Talk in the You Style e.g. why should you..`,
        },
        {
          role: 'user',
          content: chatGptPrompt,
          name: 'IN2TEC',
        },
      ],
      temperature: 0.2,
    };
    let url = '/api/chatgpt/get-sentence';
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      const request = await axiosPrivate.post(url, payload, { signal });
      const response = request?.data;
      if (request.status === 200) {
        // Trim and Convert Content
        let messages = response.data[0];
        let recommedation = messages.message.content;
        let recommendationTitle = recommedation.split(/\n\s*\n/);
        let pitchRaw = recommendationTitle[0];
        let title = pitchRaw.replace('Title:', '').trim();
        title = title.replace(/["]+/g, '');
        let body = recommedation.replace(pitchRaw, '').trim();
        formik.setFieldValue('headline_title', title);
        let htmlBody = getMuiRichTextEditorContent(body);
        formik.setFieldValue('headline_body_rte', htmlBody);
        setTimeout(
          () =>
            formik.setTouched({
              headline_title: true,
              headline_body_rte: true,
            }),
          400,
        );
      } else {
        handleRegularResponse({
          open: true,
          status: response.status,
          message: response.message,
        });
        setLeadInfo(values);
      }
    } catch (err) {
      console.log(err);
      handleRegularResponse({
        open: true,
        status: 'error',
        message: err.message,
      });
      setLeadInfo(values);
      errRef.current?.focus();
    } finally {
      controller.abort(signal);
      setFetchHeadline(false);
    }
  }

  return (
    <MapContext.Provider value={{ mapRendered, setMapRendered }}>
      <Formik
        values={formik.values}
        initialValues={formik.initialValues}
        errors={formik.errors}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => {
          const {
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            setValues,
            setFieldTouched,
            resetForm,
          } = formik;
          return (
            <>
              <Form role="form">
                <FormControl fullWidth>
                  {/* Add/Edit Lead dialog */}
                  <Dialog maxWidth="md" fullWidth open={leadDetailsDialog.open}>
                    <DialogTitle>
                      <Grid
                        container
                        spacing={{ xs: 3 }}
                        justifyContent="center"
                        direction={{ xs: 'column', sm: 'column', md: 'row' }}
                      >
                        <Grid
                          item
                          xs={12}
                          alignItems="center"
                          justifyContent="flex-start"
                          sx={{ paddingBottom: 0, marginBottom: 0 }}
                        >
                          {leadDetailsDialog.title}
                        </Grid>
                        <Grid
                          item
                          container
                          direction="row"
                          xs={12}
                          spacing={1}
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            fontSize: '1.0rem',
                            color: 'var(--color-highlight)',
                          }}
                        >
                          <Grid item>
                            {values?.created && `Created ${values?.created}`}
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="info"
                              sx={{ color: 'white' }}
                              disabled
                              startIcon={<SourceIcon />}
                            >
                              <Typography variant="body1">
                                {toTitleCase(values.origin)}
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogTitle>
                    <DialogContent>
                      {isLoading?.status === true &&
                      isLoading?.type === 'skeleton' ? (
                        // Skeleton
                        <Stack
                          spacing={2}
                          style={classes.root}
                          sx={{ paddingBottom: '10px' }}
                        >
                          <DialogContentText
                            sx={{ color: 'var(--color-text-error-message)' }}
                          >
                            <Skeleton width="30%" height="30px">
                              {/* <Typography>.</Typography> */}
                            </Skeleton>
                          </DialogContentText>

                          {/* Associate */}
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="30px"
                          />

                          {/* Lead Type */}
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="30px"
                          />

                          {/* Personal Details */}
                          <Grid item xs={12}>
                            <Stack
                              spacing={{ xs: 3, sm: 3, md: 1 }}
                              direction="row"
                              sx={{ paddingTop: '10px' }}
                              style={classes.root}
                              alignItems="stretch"
                            >
                              {/* First Name */}
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="30px"
                              />

                              {/* Last Name */}
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="30px"
                              />
                            </Stack>
                          </Grid>

                          {/* Company */}
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="30px"
                          />

                          {/* Industries */}
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="30px"
                          />

                          {/* Intro Type */}
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="30px"
                          />

                          {/* Email */}
                          {values.intro_type === 'Email' && (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="30px"
                            />
                          )}

                          <Grid item xs={12}>
                            <Stack
                              spacing={{ xs: 3, sm: 3, md: 1 }}
                              direction="column"
                              sx={{ paddingTop: '10px' }}
                              style={classes.root}
                              alignItems="stretch"
                            >
                              {/* Intro Subject */}
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="30px"
                              />

                              {/* Intro Body */}
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="30px"
                              />
                            </Stack>
                          </Grid>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="30px"
                          />

                          {/* Update Fields */}
                          {leadDetailsDialog.dialogAction === 'update' && (
                            <>
                              {/* Lead Status */}
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="30px"
                              />

                              {/* Referal Link */}
                              <Grid item xs={12}>
                                <Stack
                                  spacing={{ xs: 3, sm: 3, md: 1 }}
                                  direction="row"
                                  sx={{ paddingTop: '10px' }}
                                  style={classes.root}
                                  alignItems="stretch"
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height="30px"
                                  />

                                  <Skeleton
                                    variant="rectangular"
                                    width="50px"
                                    height="30px"
                                  />
                                </Stack>
                              </Grid>

                              {/* Intro Page Headline */}
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="200px"
                              />
                            </>
                          )}
                        </Stack>
                      ) : (
                        //  Form
                        <Stack
                          spacing={2}
                          style={classes.root}
                          sx={{ paddingBottom: '10px' }}
                        >
                          <DialogContentText
                            sx={{ color: 'var(--color-text-error-message)' }}
                          >
                            {leadDetailsDialog.text}
                          </DialogContentText>
                          {/* Associate */}
                          <AssociateSelectField
                            disabled={fetchIntro || fetchHeadline}
                            required
                            options={employeeOptions}
                            variant="standard"
                          />

                          {/* Lead Language */}
                          <SingleLanguageSelectionfield
                            required
                            disabled={
                              fetchIntro || fetchHeadline ? true : false
                            }
                            variant="standard"
                            transTypeID="lead"
                          />
                          {/* Lead Type (Lead Only*/}
                          {leadDetailsDialog?.lead_type === 'lead' && (
                            <TextField
                              select
                              disabled={fetchIntro || fetchHeadline}
                              type="text"
                              id="lead_type"
                              labelid="lead-type-label"
                              label={t('form.leads.lead_type.label')}
                              name="lead_type"
                              variant="standard"
                              value={values?.lead_type}
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              onBlur={handleBlur}
                              error={
                                errors.lead_type && touched.lead_type
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.lead_type && touched.lead_type
                                  ? errors.lead_type
                                  : null
                              }
                            >
                              <MenuItem key={0} value="">
                                {t('form.leads.lead_type.select')}
                              </MenuItem>
                              <MenuItem key={1} value="manufacturer">
                                {t('form.leads.lead_type.manu')}
                              </MenuItem>
                              <MenuItem key={2} value="salesrep">
                                {t('form.leads.lead_type.sales')}
                              </MenuItem>
                              <MenuItem key={3} value="influencer">
                                {t('form.leads.lead_type.influ')}
                              </MenuItem>
                            </TextField>
                          )}
                          {values.lead_type > '' && (
                            <>
                              {/* Company Type (For Investor Pitch only) */}
                              {values.lead_type === 'investor' && (
                                <TextField
                                  select
                                  required
                                  disabled={fetchIntro || fetchHeadline}
                                  label={t('form.leads.company_type.label')}
                                  placeholder={t(
                                    'form.leads.company_type.placeholder',
                                  )}
                                  type="text"
                                  name="company_type"
                                  variant="standard"
                                  value={values?.company_type}
                                  onChange={(e) => {
                                    setFieldValue(
                                      e.target.name,
                                      e.target.value,
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    errors?.company_type &&
                                    touched?.company_type
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors?.company_type &&
                                    touched?.company_type
                                      ? errors?.company_type
                                      : null
                                  }
                                >
                                  <MenuItem key={0} value="">
                                    {t('form.leads.company_type.placeholder')}
                                  </MenuItem>
                                  <MenuItem key={1} value="Accelerator">
                                    {t('form.leads.company_type.accelerator')}
                                  </MenuItem>
                                  <MenuItem key={2} value="Private Equity Firm">
                                    {t(
                                      'form.leads.company_type.private_equity',
                                    )}
                                  </MenuItem>
                                  <MenuItem key={3} value="Equity Fund">
                                    {t('form.leads.company_type.fund')}
                                  </MenuItem>
                                  <MenuItem key={4} value="Other">
                                    {t('form.leads.company_type.other')}
                                  </MenuItem>
                                </TextField>
                              )}

                              {/* Industries */}
                              <IndustriesField
                                disabled={fetchIntro || fetchHeadline}
                                transition={values.lead_type !== 'investor'}
                                required={values.lead_type !== 'investor'}
                                variant="standard"
                              />

                              {/* Company */}
                              <BusinessNameTextField
                                fieldTitle={t('form.company.details')}
                                required={
                                  values.lead_type === 'manufacturer' ||
                                  values.lead_type === 'investor'
                                }
                                disabled={fetchIntro || fetchHeadline}
                                variant="standard"
                                transPrefix={
                                  values.lead_type === 'investor'
                                    ? 'FirmNameFieldManual'
                                    : 'BusinessNameFieldManual'
                                }
                              />

                              {/* Company Banner */}
                              {values?.company > '' && <CompanyBanner />}

                              {/* Personal Details */}
                              <Typography variant="h6">
                                {t('form.personal_details', {
                                  type: toTitleCase(values.lead_type),
                                })}
                              </Typography>
                              <Stack
                                spacing={{ xs: 3, sm: 3, md: 1 }}
                                style={classes.root}
                              >
                                {/* First & Last Name */}
                                <Stack
                                  spacing={{ xs: 3, sm: 3, md: 1 }}
                                  direction="row"
                                  sx={{ paddingTop: '10px' }}
                                  style={classes.root}
                                  alignItems="stretch"
                                >
                                  {/* First Name */}
                                  <IndividualFirstNameField
                                    required
                                    fieldID="first_name"
                                    variant="standard"
                                  />

                                  {/* Last Name */}
                                  <IndividualLastNameField
                                    required
                                    fieldID="last_name"
                                    variant="standard"
                                  />
                                </Stack>

                                {/* Phone */}
                                <PhoneField
                                  fieldID="phone"
                                  variant="standard"
                                  transTypeID={toTitleCase(
                                    leadDetailsDialog?.lead_type,
                                  )}
                                />

                                {/* Email */}
                                <EmailField
                                  fieldID="email"
                                  disabled={fetchIntro || fetchHeadline}
                                  required={values.intro_type === 'Email'}
                                  transTypeID={toTitleCase(
                                    leadDetailsDialog?.lead_type,
                                  )}
                                  variant="standard"
                                />
                              </Stack>

                              {/* Lead/Investor Address */}
                              <AddressField
                                fieldTitle={`${toTitleCase(leadDetailsDialog?.lead_type)}'s Address`}
                                fieldID="address"
                                phoneFieldID={'phone'}
                                labelID={
                                  values.company ||
                                  (values?.first_name && values?.last_name)
                                }
                                transition={true}
                                disabled={fetchIntro || fetchHeadline}
                                required={values?.company ? true : false}
                                variant="standard"
                                showLocationMap={true}
                              />

                              {/* Page Headline Title */}
                              {((leadDetailsDialog?.lead_type === 'lead' &&
                                values?.associate &&
                                ((values?.first_name && values?.last_name) ||
                                  values?.company) &&
                                values?.industries?.length > 0 &&
                                values.language) ||
                                (leadDetailsDialog?.lead_type === 'investor' &&
                                  values?.associate &&
                                  values?.company &&
                                  values.language)) && (
                                <Stack
                                  spacing={2}
                                  direction="column"
                                  sx={{ paddingBottom: '100px' }}
                                >
                                  <Typography variant="h6">
                                    {t('form.leads.headline.title')}
                                  </Typography>

                                  {/* Page Headline */}
                                  <LoadingButton
                                    disabled={
                                      fetchIntro ||
                                      fetchHeadline ||
                                      formik.isSubmitting ||
                                      !values.language
                                    }
                                    size="small"
                                    endIcon={<SendIcon />}
                                    loading={fetchHeadline}
                                    loadingPosition="end"
                                    variant="contained"
                                    color="info"
                                    onClick={async () => {
                                      await getHeadlineText(values, formik);
                                    }}
                                  >
                                    {transButtons('ai_headline')}
                                  </LoadingButton>

                                  <TextField
                                    ref={headlineTitleRef}
                                    required
                                    disabled={fetchIntro || fetchHeadline}
                                    rows={10}
                                    id="headline_title"
                                    name="headline_title"
                                    className="form-select-field"
                                    aria-describedby="uidnote"
                                    variant="standard"
                                    label={t('form.leads.headline.title_label')}
                                    type="text"
                                    placeholder={t(
                                      'form.leads.headline.title_placeholder',
                                    )}
                                    onChange={(e) => {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    value={values?.headline_title}
                                    error={
                                      errors.headline_title &&
                                      touched.headline_title
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.headline_title &&
                                      touched.headline_title
                                        ? errors.headline_title
                                        : null
                                    }
                                  />

                                  {/* Page Headline Body */}
                                  <IntuRichTextEditor
                                    required
                                    id="headline_body_rte"
                                    disabled={fetchIntro || fetchHeadline}
                                    name="headline_body_rte"
                                    controls={[
                                      'title',
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strikethrough',
                                      'highlight',
                                      'undo',
                                      'redo',
                                      'link',
                                      'media',
                                      'numberList',
                                      'bulletList',
                                      'quote',
                                      'code',
                                      'clear',
                                    ]}
                                    className="form-select-field"
                                    aria-describedby="uidnote"
                                    variant="standard"
                                    label={t('form.leads.headline.body_label')}
                                    placeholder={t(
                                      'form.leads.headline.body_placeholder',
                                    )}
                                    onChange={(value) => {
                                      const rteContent = stateToHTML(
                                        value.getCurrentContent(),
                                      ); // Convert to HTML
                                      rteContent &&
                                        setFieldValue(
                                          'headline_body',
                                          rteContent,
                                        ); // Store to Values
                                    }}
                                    defaultValue={JSON.stringify(
                                      values.headline_body_rte,
                                    )}
                                    inlineToolbar={true}
                                    error={
                                      errors.headline_body &&
                                      touched.headline_body
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.headline_body &&
                                      touched.headline_body
                                        ? errors.headline_body
                                        : null
                                    }
                                  />
                                </Stack>
                              )}

                              {/* Intro Type */}
                              {((leadDetailsDialog?.lead_type === 'lead' &&
                                values?.associate &&
                                ((values?.first_name && values?.last_name) ||
                                  values?.company) &&
                                values?.industries?.length > 0 &&
                                values.language) ||
                                (leadDetailsDialog?.lead_type === 'investor' &&
                                  values?.associate &&
                                  values?.company &&
                                  values.language)) && (
                                <>
                                  <Typography variant="h6">
                                    {t('form.leads.intro.title')}
                                  </Typography>

                                  <Stack
                                    spacing={{ xs: 3, sm: 3, md: 1 }}
                                    direction="row"
                                    sx={{ paddingTop: '10px' }}
                                    style={classes.root}
                                    alignItems="stretch"
                                  >
                                    {/* Intro Type */}
                                    <TextField
                                      select
                                      type="text"
                                      disabled={
                                        fetchIntro || fetchHeadline
                                          ? true
                                          : false
                                      }
                                      id="intro_type"
                                      label={t('form.leads.intro.type_label')}
                                      name="intro_type"
                                      variant="standard"
                                      value={values?.intro_type}
                                      onChange={(e) => {
                                        setFieldValue(
                                          e.target.name,
                                          e.target.value,
                                        );

                                        if (e.target.value === '') {
                                          setValues({
                                            ...values,
                                            email: '',
                                            intro_type: '',
                                            intro_subject: '',
                                            intro_message: '',
                                          });
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        errors.lead_type && touched.lead_type
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errors?.lead_type && touched?.lead_type
                                          ? errors?.lead_type
                                          : null
                                      }
                                    >
                                      <MenuItem key={1} value="">
                                        {t('form.no_selection')}
                                      </MenuItem>
                                      <MenuItem key={2} value="Email">
                                        {t('form.email.label_alt')}
                                      </MenuItem>
                                      <MenuItem key={3} value="LinkedIn">
                                        {t('form.linkedin')}
                                      </MenuItem>
                                    </TextField>

                                    {/* Send Email */}
                                    {values.intro_type === 'Email' && (
                                      <FormControlLabel
                                        label={t('form.leads.send_email.label')}
                                        disabled={leadInfo?._id ? true : false}
                                        control={
                                          <Switch
                                            id="send_email"
                                            name="send_email"
                                            className="form-select-field"
                                            aria-invalid={
                                              errors.send_email
                                                ? 'true'
                                                : 'false'
                                            }
                                            aria-describedby="uidnote"
                                            variant="standard"
                                            onChange={(e) => {
                                              setFieldValue(
                                                e.target.name,
                                                e.target.checked,
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            checked={values.send_email}
                                          />
                                        }
                                      />
                                    )}
                                  </Stack>
                                </>
                              )}

                              {/* Introduction */}
                              {values.intro_type > '' && (
                                <Stack
                                  direction="column"
                                  sx={{ marginBottom: '50px' }}
                                >
                                  {/* Get Introduction Button */}
                                  <LoadingButton
                                    disabled={
                                      ((!values.first_name ||
                                        !values.last_name) &&
                                        !values.company) ||
                                      (values.intro_type === 'Email' &&
                                        !values.email) ||
                                      !values.lead_type ||
                                      !values.associate ||
                                      !values.intro_type ||
                                      formik.isSubmitting ||
                                      fetchIntro ||
                                      !values.language ||
                                      fetchHeadline ||
                                      (leadDetailsDialog?.lead_type ===
                                        'investor' &&
                                        !values.lead_type)
                                    }
                                    size="small"
                                    endIcon={<SendIcon />}
                                    loading={fetchIntro}
                                    loadingPosition="end"
                                    variant="contained"
                                    color="info"
                                    onClick={async () => {
                                      setFetchIntro(true);
                                      const getIntro =
                                        await getIntroText(values);

                                      if (getIntro) {
                                        if (getIntro.status === 200) {
                                          let messages = getIntro.data[0];
                                          let recommedation =
                                            messages.message.content;

                                          var recommendationSubject =
                                            recommedation.split(/\n\s*\n/);
                                          var subjectRaw =
                                            recommendationSubject[0];
                                          var subject = subjectRaw
                                            .replace('Subject:', '')
                                            .trim();

                                          var body = recommedation
                                            .replace(subjectRaw, '')
                                            .trim();

                                          setFieldValue(
                                            'intro_subject',
                                            subject,
                                          );
                                          setFieldValue('intro_message', body);

                                          setTimeout(
                                            () =>
                                              setFieldTouched(
                                                'intro_message',
                                                true,
                                              ),
                                            400,
                                          );
                                        }
                                        setFetchIntro(false);
                                      }
                                    }}
                                  >
                                    {transButtons('get_intro')}{' '}
                                    {values.intro_type}
                                  </LoadingButton>

                                  {/* Intro subject and content */}
                                  <Grid item xs={12}>
                                    <Stack
                                      spacing={{ xs: 3, sm: 3, md: 1 }}
                                      direction="column"
                                      sx={{ paddingTop: '10px' }}
                                      style={classes.root}
                                      alignItems="stretch"
                                    >
                                      {/* Intro Subject */}
                                      <TextField
                                        required
                                        ref={introTitleRef}
                                        id="intro_subject"
                                        name="intro_subject"
                                        disabled={fetchIntro || fetchHeadline}
                                        className="form-select-field"
                                        aria-invalid={
                                          errors.intro_subject
                                            ? 'true'
                                            : 'false'
                                        }
                                        aria-describedby="uidnote"
                                        variant="standard"
                                        label={t(
                                          'form.leads.intro.subject_label',
                                        )}
                                        type="text"
                                        placeholder={t(
                                          'form.leads.intro.placeholder',
                                        )}
                                        onChange={(e) => {
                                          setFieldValue(
                                            e.target.name,
                                            e.target.value,
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value={values?.intro_subject}
                                        error={
                                          errors.intro_subject &&
                                          touched.intro_subject
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          errors.intro_subject &&
                                          touched.intro_subject
                                            ? errors.intro_subject
                                            : null
                                        }
                                      />

                                      {/* Intro Body */}
                                      <TextField
                                        required
                                        multiline
                                        disabled={fetchIntro || fetchHeadline}
                                        rows={10}
                                        id="intro_message"
                                        name="intro_message"
                                        className="form-select-field"
                                        aria-invalid={
                                          errors.intro_message
                                            ? 'true'
                                            : 'false'
                                        }
                                        aria-describedby="uidnote"
                                        variant="standard"
                                        label={t('form.leads.intro.body_label')}
                                        type="text"
                                        placeholder={t(
                                          'form.leads.intro.placeholder',
                                        )}
                                        onChange={(e) => {
                                          setFieldValue(
                                            e.target.name,
                                            e.target.value,
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value={values?.intro_message}
                                        error={
                                          errors.intro_message &&
                                          touched.intro_message
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          errors.intro_message &&
                                          touched.intro_message
                                            ? errors.intro_message
                                            : null
                                        }
                                      />
                                    </Stack>
                                  </Grid>
                                </Stack>
                              )}
                            </>
                          )}
                          {/* Lead Activities */}
                          {values?.activity?.length > 0 && <LeadActivity />}
                          {/* Referal Link & Status */}
                          {values?._id && (
                            <>
                              {/* Referal Link */}
                              <Grid item xs={12}>
                                <Stack
                                  spacing={{ xs: 3, sm: 3, md: 1 }}
                                  direction="row"
                                  sx={{ paddingTop: '10px' }}
                                  style={classes.root}
                                  alignItems="stretch"
                                >
                                  <TextField
                                    id="link"
                                    name="link"
                                    disabled
                                    className="form-select-field"
                                    aria-describedby="uidnote"
                                    variant="standard"
                                    label={t('form.leads.referral_link')}
                                    type="text"
                                    placeholder={t('form.leads.referral_link')}
                                    onChange={(e) => {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );
                                    }}
                                    inputProps={{
                                      autoComplete: 'off',
                                    }}
                                    onBlur={handleBlur}
                                    value={
                                      values.lead_type === 'investor'
                                        ? `https://${host_url}/investor?referralID=${values.code}&tracker=true`
                                        : `https://${host_url}/how-it-works?referralID=${values.code}&tracker=true`
                                    }
                                  />

                                  <IntuIconButton
                                    type="connect"
                                    color="primary"
                                    aria-label="share link"
                                    tooltipTitle={transButtons('share', {
                                      type: transTypes('lead'),
                                    })}
                                    onClick={() => {
                                      let link =
                                        values.lead_type === 'investor'
                                          ? `https://${host_url}/investor?referralID=${values.code}&tracker=true`
                                          : `https://${host_url}/how-it-works?referralID=${values.code}&tracker=true`;
                                      copy(link);
                                    }}
                                  >
                                    <LinkIcon />
                                  </IntuIconButton>
                                </Stack>
                              </Grid>

                              <Grid item xs={6}>
                                {/* Custom field to the left */}
                                <FormControlLabel
                                  label={t('form.leads.access_page', {
                                    type: t('types.lead'),
                                  })}
                                  control={
                                    <Switch
                                      required
                                      id="active"
                                      name="active"
                                      className="form-select-field"
                                      aria-invalid={
                                        errors.active ? 'true' : 'false'
                                      }
                                      aria-describedby="uidnote"
                                      variant="standard"
                                      onChange={(e) => {
                                        setFieldValue(
                                          e.target.name,
                                          e.target.checked,
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      checked={values.active}
                                    />
                                  }
                                />
                              </Grid>
                            </>
                          )}
                          {leadDetailsDialog.dialogAction === 'update' && (
                            <>
                              {/* Lead Status */}
                              <TextField
                                select
                                type="text"
                                id="status"
                                label={t('form.leads.status.label')}
                                name="status"
                                variant="standard"
                                value={values?.status}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                error={
                                  errors.status && touched.status ? true : false
                                }
                                helperText={
                                  errors?.status && touched?.status
                                    ? errors?.status
                                    : null
                                }
                              >
                                <MenuItem key={0} value="">
                                  {t('form.leads.status.select')}
                                </MenuItem>
                                <MenuItem key={1} value="created">
                                  {t('form.leads.status.pending')}
                                </MenuItem>
                                <MenuItem key={2} value="shared">
                                  {t('form.leads.status.shared')}
                                </MenuItem>
                                <MenuItem key={3} value="active">
                                  {t('form.leads.status.follow_up')}
                                </MenuItem>
                                <MenuItem key={4} value="closed-won">
                                  {t('form.leads.status.won')}
                                </MenuItem>
                                <MenuItem key={5} value="closed-lost">
                                  {t('form.leads.status.lost')}
                                </MenuItem>
                              </TextField>
                            </>
                          )}
                          {/* Associate Activity Log */}
                          {values?.associate_activity > '' && (
                            <AssociateActivity />
                          )}
                          {/* Lead Activity entry Form */}
                          <AssociateActivityDialog />
                          {/* Add new associate activity */}
                          {values?._id && (
                            <Button
                              variant="contained"
                              color="info"
                              onClick={(e) => {
                                setActivityDialog({
                                  openDialog: true,
                                  leadID: values?._id,
                                  activityDialogTitle: transDialogs(
                                    'AssociateActivityDialog.add.title',
                                  ),
                                  activityDialogText: t(
                                    'AssociateActivityDialog.add.description',
                                  ),
                                  activityPrimaryButtonText: transButtons(
                                    'add',
                                    {
                                      type: transTypes('activity'),
                                    },
                                  ),
                                  activitySecondaryButtonText:
                                    transButtons('close'),
                                  activityDialogAction: 'add',
                                  activityLog: values?.associate_activity,
                                  activityContent: {
                                    _id: '',
                                    user: auth.user_info.full_name,
                                    date: '',
                                    activity: '',
                                    comment: '',
                                  },
                                });
                              }}
                            >
                              {transButtons('add', {
                                type: transTypes('activity'),
                              })}
                            </Button>
                          )}
                        </Stack>
                      )}
                    </DialogContent>
                    <DialogActions>
                      {/* Preview Leanding Page */}
                      {values.code && (
                        <Button
                          disabled={
                            fetchIntro || fetchHeadline || formik.isSubmitting
                          }
                          component={Link}
                          variant="contained"
                          color="info"
                          to={
                            values.lead_type === 'investor'
                              ? `https://${host_url}/investor?referralID=${values.code}&tracker=false`
                              : `https://${host_url}/how-it-works?referralID=${values.code}&tracker=false`
                          }
                          target="_blank"
                          endIcon={<RemoveRedEyeIcon />}
                        >
                          {transButtons('preview')}
                        </Button>
                      )}

                      {/* Close */}
                      <Button
                        variant="contained"
                        color="error"
                        disabled={
                          fetchHeadline || fetchIntro || formik.isSubmitting
                        }
                        onClick={() => {
                          const leadForm = blankLeadForm({ lng });
                          resetForm();
                          setLeadInfo(leadForm);
                          setLeadDetailsDialog({
                            open: false,
                            lead_type: '',
                            title: '',
                            text: '',
                            primaryButtonText: '',
                            secondaryButtonText: '',
                            dialogAction: '',
                          });
                        }}
                      >
                        {leadDetailsDialog.secondaryButtonText}
                      </Button>

                      {/* Add/Update Button */}
                      <LoadingButton
                        variant="contained"
                        size="medium"
                        endIcon={<SendIcon />}
                        loading={formik.isSubmitting}
                        loadingPosition="end"
                        color="primary"
                        disabled={
                          !formik.isValid ||
                          !formik.dirty ||
                          formik.isSubmitting ||
                          fetchHeadline ||
                          fetchIntro
                        }
                        type="submit"
                        onClick={async (e) => {
                          let action = leadDetailsDialog.dialogAction;
                          await saveLead(action, values, formik);
                        }}
                      >
                        {leadDetailsDialog.primaryButtonText}
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                </FormControl>
              </Form>
            </>
          );
        }}
      </Formik>
    </MapContext.Provider>
  );
};

export { LeadDetailsForm };
