import React, { useContext, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Video } from '../../../plugins/BackgroundVideoPlayer.jsx';
import { motion } from 'framer-motion';
import { BackgroundVideo } from '../../../plugins/BackgroundVideoPlayer.jsx';
import { ObjectId } from 'bson';

// Banners
import salesreps from '../../../assets/how-it-works/salesreps.png';
import manufacturer from '../../../assets/how-it-works/manufacturers.png';
import investorBanner from '../../../assets/how-it-works/manufacturers.png';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import {
  Box,
  CardContent,
  Divider,
  Grid,
  Typography,
  Button,
  Dialog,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// Context
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';

// Translator
import { useTranslation } from 'react-i18next';
import { useIntuTheme } from '../../../context/providers/IntuThemeProvider.jsx';

const LeadHeaderContent = () => {
  // const {leadContext} = useContext(provider);
  const { leadInfo, leadContext } = useContext(LeadContext);
  const [isOpen, setIsOpen] = useState(false);

  // Translator
  const { t } = useTranslation('translation', { keyPrefix: 'context.headers' });
  const { t: transButton } = useTranslation('buttons');

  // Theme
  const theme = useTheme();
  const { mode } = useIntuTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  let image;
  if (leadInfo?.company_banner_url) {
    image = leadInfo.company_banner_url;
  } else {
    if (
      leadInfo.lead_type === 'salesrep' ||
      leadInfo.lead_type === 'influencer'
    ) {
      image = salesreps;
    } else if (leadInfo.lead_type === 'manufacturer') {
      image = manufacturer;
    } else if (leadInfo.lead_type === 'investor') {
      image = investorBanner;
    }
  }

  const benefits = leadContext?.benefits;

  function handleClick() {
    setIsOpen(!isOpen);
  }

  const VideoDialog = (open) => {
    return (
      <Dialog open={isOpen} fullScreen>
        <Grid
          container
          disableGutters
          sx={{ position: 'absolute', zIndex: 5 }}
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            onClick={handleClick}
            sx={{ fontSize: '18px' }}
            variant="contained"
          >
            {transButton('exit')}
          </Button>
        </Grid>
        <Video
          width="100vw"
          videoSource={
            'https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/sales_explainer_en.mp4'
          }
          playing
        />
      </Dialog>
    );
  };

  const SalesContainerDesktop = ({ children }) => {
    return (
      <Grid
        container
        className="article"
        sx={{
          minHeight: '55vw',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          paddingBottom: '30px',
        }}
      >
        {children}
      </Grid>
    );
  };

  const SalesContainerMobile = ({ children }) => {
    return (
      <Grid
        container
        className="article"
        sx={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          minHeight: '350px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          paddingBottom: '30px',
        }}
      >
        {children}
      </Grid>
    );
  };

  const Container = small ? SalesContainerMobile : SalesContainerDesktop;

  return (
    <Grid container disableGutters>
      {leadInfo.lead_type === 'manufacturer' && (
        <Grid
          container
          className="article"
          sx={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            minHeight: { xs: '350px', md: '750px' },
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            paddingBottom: '30px',
          }}
        >
          {leadInfo.lead_type === 'manufacturer' && (
            <>
              <Grid
                item
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'var(--color-hero-overlay)', // grey with 70% opacity
                  zIndex: 1,
                }}
              />

              <Grid
                item
                xs={12}
                sx={{
                  position: 'relative',
                  padding: '50px 10px 0 10px',
                  zIndex: 2,
                }}
              >
                <>
                  <Typography
                    variant="h1"
                    className="header"
                    sx={{ color: 'white' }}
                  >
                    {ReactHtmlParser(leadInfo.headline_title)}
                  </Typography>
                </>
              </Grid>

              {/* Benefits */}
              <Grid
                container
                direction={{ xs: 'column', md: 'row' }}
                alignItems="center"
                justifyContent="center"
                sx={{
                  minWidth: 300,
                  width: 'auto',
                  height: 'auto',
                  position: 'relative',
                  zIndex: 2,
                }}
                spacing={4}
              >
                {leadContext?.benefits?.map((benefit, index) => (
                  <Grid item key={index}>
                    <Box
                      sx={{
                        width: '100%',
                        height: { xs: 250, md: 250 },
                        minWidth: 300,
                        maxWidth: 450,
                        color: 'var(--color-card-text)',
                        backgroundColor: 'var(--color-card-background)',
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="h5"
                          component="div"
                          color="var(--intu-darkGrey)"
                        >
                          {benefit?.title}
                        </Typography>
                        <Divider
                          variant="fullWidth"
                          color="#333333"
                          sx={{ marginBottom: '10px' }}
                        />
                        <Typography variant="body">
                          {benefit?.content}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      )}
      {leadInfo.lead_type === 'salesrep' && (
        <Container disableGutters className="hero-video">
          {isOpen && <VideoDialog />}
          {small ? (
            ''
          ) : (
            <Grid item sx={{ position: 'absolute' }}>
              <BackgroundVideo
                videoSource={
                  'https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/sales_explainer_en.mp4'
                }
              />
            </Grid>
          )}
          <Grid
            container
            item
            direction={{ xs: 'column', md: 'row' }}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'var(--color-hero-overlay)', // grey with 70% opacity
              zIndex: 1,
            }}
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction={{ xs: 'row', md: 'column' }}
          >
            <Grid
              item
              container
              xs={12}
              md={5}
              sx={{
                position: 'relative',
                paddingTop: '50px',
                zIndex: 2,
                width: '100%',
              }}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item container xs={12} md={10} justifyContent="center">
                <Typography variant="heroTitleWhite" className="header">
                  {ReactHtmlParser(
                    t('lead.personal', {
                      leadFullName: `${leadInfo.first_name} ${leadInfo.last_name}`,
                    }),
                  )}
                </Typography>
                <Typography variant="heroSubtitleWhite">
                  {t('lead.personal_subtitle')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} mb={5}>
                <Button
                  variant="contained"
                  startIcon={<PlayCircleOutlineIcon />}
                  sx={{ borderRadius: '50px' }}
                  onClick={handleClick}
                >
                  See The Teaser Video
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                minWidth: 300,
                width: '100%',
                height: '100%',
                position: 'relative',
                zIndex: 2,
                overflow: 'hidden',
              }}
              xs={12}
              md={5}
            >
              <motion.div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: `${benefits.length * 100}%`,
                }}
                animate={{
                  x: ['-100%', '100%'],
                }}
                initial={{
                  x: '-100%',
                }}
                transition={{
                  ease: 'linear',
                  duration: 25,
                  repeat: Infinity,
                  delay: 0,
                }}
              >
                {benefits.map((benefit, index) => (
                  <Grid container item key={new ObjectId()} mr={15}>
                    <motion.div transition={{ duration: 10 }}>
                      <Box
                        sx={{
                          height: 200,
                          minWidth: 300,
                          maxWidth: 450,
                          color: 'var(--color-card-text)',
                          backgroundColor: 'var(--color-card-background)',
                          borderRadius: '25px',
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="h5"
                            component="div"
                            color="var(--intu-darkGrey)"
                          >
                            {benefit?.title}
                          </Typography>
                          <Divider
                            variant="fullWidth"
                            color="#333333"
                            sx={{ marginBottom: '30px' }}
                          />
                          <Typography variant="body">
                            {benefit?.content}
                          </Typography>
                        </CardContent>
                      </Box>
                    </motion.div>
                  </Grid>
                ))}
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      )}
    </Grid>
  );
};

export { LeadHeaderContent };
