import ReactGA from 'react-ga4';

const updateGAConsent = (analyticsConsent) => {
  window.gtag('consent', 'update', {
    analytics_storage: analyticsConsent ? 'granted' : 'denied',
  });
};

export const initGA = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
    updateGAConsent(true);
    console.log('Tracking initialized');
  }
};
