import React from 'react';
import { Box, Grid } from '@mui/material';
import './headers.css';
import meetUsHeader from '../../assets/meetUs/meetUsHeader.jpg'

const MeetUsOnlineHeaderContainer = () => {

  return (

    <Grid container direction="column" textAlign="center" sx={{ padding: '0rem' }}>
      <Grid item xs={12}>
        <Box
          component="img"
          alt="meetUsOnline"
          src={meetUsHeader}
          sx={{
            width: '100%',
            height: '100%',
            
          }}
        />
      </Grid>
    </Grid>

  )
}

export default MeetUsOnlineHeaderContainer