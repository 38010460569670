import { useState, useEffect, useRef, useContext } from 'react';

import {
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
  FormControl,
  MenuItem,
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Container,
  InputAdornment,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import ClearIcon from '@mui/icons-material/Clear';

// Action Icons
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// Formik
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';

// Import User Context
import useUser from '../../hooks/useUser.js';

// Process & Reposne Handler
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import useResponseHandling from '../../hooks/useResponseHandler.js';

// @React-page
import '@in2tec/page-editor/lib/index.css';
import { IntuPageBuilder } from '../../pageEditor/PageEditor.jsx';

//  Blog Header & Footer
import PostHeaderContainer from '../../containers/headers/PostHeaderContainer.jsx';
import BlogFooterContainer from '../../containers/footers/BlogFooterContainer.jsx';

// Meta Tags
import { Helmet } from 'react-helmet';

// Convert to Title Case
import { toTitleCase } from '../../helpers/toTitleCase.js';

import { ObjectId } from 'bson';

// Get Context
import { PostContext } from '../providers/PostContextProvider';
import PreLoadContext from '../../hooks/usePreLoadHandler.js';

// Translator
import { useTranslation } from 'react-i18next';

// Routes
import { searchPosts, searchPostTags } from '../../routes/blogRoutes.js';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsTracker.jsx';

// Get Host
const host_url = window.location.host;

const supported_file_formats = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];

function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result.split(',')[1]);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

const PostViewForm = () => {
  const { postInfo, postImage, handleEditPost, auth, isAdmin } =
    useContext(PostContext);
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          IN2TEC - {postInfo?.title ? toTitleCase(postInfo.title) : ''}
        </title>
        <meta name="description" content={postInfo?.description} />
        <meta
          property="og:title"
          content={postInfo?.title ? toTitleCase(postInfo.title) : ''}
        />
        <meta property="og:image" content={postInfo?.image_url} />
        <meta property="og:description" content={postInfo?.description} />
        <meta property="og:url" content={postInfo?.full_url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@IN2TEC" />
        <meta
          name="twitter:title"
          content={postInfo?.title ? toTitleCase(postInfo.title) : ''}
        />
        <meta name="twitter:description" content={postInfo?.description} />
        <meta name="twitter:image" content={postInfo?.image_url} />
      </Helmet>
      <Container maxWidth="auto" disableGutters>
        {/* Header Container */}
        <PostHeaderContainer content={postInfo} image={postImage} />
        {/* <Container maxWidth="auto" className="intu__page"> */}
        {/* Blogs Overview Container */}
        <Grid
          container
          direction="column"
          maxWidth="auto"
          className="intu__page"
        >
          <Grid item container direction="column" className="content" xs={12}>
            <Grid item xs={12}>
              {(isAdmin === true ||
                auth?.user_info?.full_name === postInfo?.author?.name) && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleEditPost(true)}
                >
                  {t('buttons.edit_post')}
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <IntuPageBuilder readOnly={true} values={postInfo} />
            </Grid>
          </Grid>
        </Grid>
        <BlogFooterContainer content={postInfo} />
      </Container>
    </>
  );
};

const PostEditForm = () => {
  const {
    postInfo,
    setPostInfo,
    postImage,
    setPostImage,
    navigate,
    axios,
    handleEditPost,
    axiosData,
    auth,
  } = useContext(PostContext);
  // Loading Spinner Status
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { t } = useTranslation();
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [isSearchingLink, setIsSearchingLink] = useState(false);
  const formRef = useRef();
  const { gaEventTracker } = useAnalyticsEventTracker();

  // Initial Values
  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    ...postInfo,
    link_options: [],
    tag_options: [],
    tag_search: '',
    duplicate_link: '',
    description: postInfo.description ? postInfo.description : '',
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .min(20, ({ value, min }) => {
        let characterCount = value.trim().length;
        // return `Your description is with ${characterCount} characters too short!. Make sure to have at least ${min} characters.`
        return t('validation.post.min', {
          field: 'description',
          characterCount,
          min,
        });
      })
      .max(1000, ({ value, min }) => {
        let characterCount = value.trim().length;
        // return `Your description is with ${characterCount} characters too long!. Make sure to stay below ${min} characters.`
        return t('validation.post.max', {
          field: 'description',
          characterCount,
          min,
        });
      })
      .required(t('validation.post.desc_required')),
    title: Yup.string()
      .min(20, ({ value, min }) => {
        let characterCount = value.trim().length;
        // return `Your title is with ${characterCount} characters too short!. Make sure to have at least ${min} characters.`
        return t('validation.post.min', {
          field: 'title',
          characterCount,
          min,
        });
      })
      .max(90, ({ value, min }) => {
        let characterCount = value.trim().length;
        // return `Your title is with ${characterCount} characters too long!. Make sure to stay below ${min} characters.`
        return t('validation.post.max', {
          field: 'title',
          characterCount,
          min,
        });
      })
      .matches(
        /^[ A-Za-z0-9_@./!%^*$()#&+-]*$/,
        t('validation.post.title_matches'),
      )
      .required(t('validation.post.title_required')),
    tags: Yup.array()
      .min(1, t('validation.post.tags_min'))
      .required(t('validation.post.tags_required')),
    status: Yup.string().required(t('validation.post.status_required')),
    link: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .required(t('validation.post.link_required'))
      .notOneOf(
        [Yup.ref('duplicate_link'), null],
        t('validation.post.link_duplicate'),
      )
      .matches(/^[ A-Za-z0-9_@./#&+-]*$/, t('validation.post.link_matches')),
    content: Yup.object({
      rows: Yup.array()
        .required(t('validation.post.content_required'))
        .min(1, t('validation.post.content_min')),
    }),
    image: Yup.mixed().when('image_url', {
      is: (image_url) => Boolean(!image_url),
      then: (schema) =>
        schema
          .required(t('validation.file.image_required'))
          .test(
            'fileSize',
            t('validation.file.file_size'),
            (value) => value && value.size <= 5000000,
          )
          .test(
            'fileFormat',
            t('validation.file.format'),
            (value) => value && supported_file_formats.includes(value.type),
          ),
    }),
  });

  // Error Dialog States
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMsg, setDialogMsg] = useState('');

  // handle Error Dialog
  function handleConfirmError() {
    setDialogTitle('');
    setDialogMsg('');
    setDialogOpen(false);
  }

  //handle Post Image Upload
  async function handlePostImageChange(e) {
    // get selected file from input
    const file = e.target.files[0];

    if (file) {
      //check if filesize is less than 1mb
      if (file.size > 5120000) {
        let fileSize = Math.round(file.size / 1000);
        setDialogTitle(t('validation.file.file_size_title'));
        setDialogMsg(
          t('validation.file.file_size_msg', {
            fileSize: { fileSize },
            maxFileSize: '5MB',
          }),
        );
        setDialogOpen(true);
        return;
      } else {
        // setIsLoading({ status: true, type: 'spinner', text: 'Updating Post Image...' })
        const reader = new FileReader();
        reader.onloadend = function () {
          // console.log('RESULT', reader.result)
          setPostImage({
            imageBlob: reader.result,
            image: file,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  async function updatePost(values, formik) {
    // Guard Cause for Publishing
    if (values.status === 'published' && formik.isValid !== true) {
      setDialogTitle(t('context.blog.post.dialog.publish.title'));
      setDialogMsg(t('context.blog.post.dialog.publish.msg'));
      setDialogOpen(true);
      return;
    }

    const newUrl = values.new_url;
    const full_url = `https://${host_url}/blog/${values.link}`;
    let fileURL = null;

    // If an image is provided, process it
    if (postImage.image) {
      try {
        const base64File = await convertFileToBase64(postImage.image);
        // console.log('Base64:', base64File);

        // Upload Post Image to aws bucket to make it public
        let fileUrl = '/api/aws/content/add';
        const requestData = {
          file: base64File,
          file_type: postImage.image.type,
          file_name: postImage.image.name,
          folder: `posts/main/${values?.link}`,
        };

        await axiosData
          .post(fileUrl, JSON.stringify(requestData), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((awsRequest) => {
            const response = awsRequest.data;
            if (awsRequest.status === 200) {
              fileURL = awsRequest.data.url;
            } else {
              // error while uploading
              handleRegularResponse({
                open: true,
                status: response.status,
                message: response.message,
              });
            }
            return fileURL;
          });
      } catch (error) {
        console.error('Error during file conversion:', error);
        // Handle error...
      }
    }

    // Construct payload
    let payload = {
      title: values.title,
      description: values.description,
      tags: values.tags,
      author: values.author,
      status: values.status,
      content: values.content,
      link: values.link,
      image_url: fileURL,
      full_url,
    };

    // If fileURL is available, add it to payload
    if (fileURL) {
      payload.image_url = fileURL;
    }

    // Check for post id and assign one if missing
    if (!values._id) {
      payload = { ...payload, _id: new ObjectId() };
    } else {
      payload = { ...payload, _id: values._id };
    }
    try {
      const url = '/api/blog/posts/update';
      const response = await axios.put(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      });

      const { data, status } = response;

      if (status === 200) {
        gaEventTracker({
          category: 'Blog Management',
          action: 'Event',
          label: 'Post Updated',
        });
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });

        // If an image is available, fetch and convert it
        if (data.image_url) {
          const controller = new AbortController();
          const { signal } = controller;
          const imageResponse = await fetch(data.image_url, { signal });
          if (!imageResponse.ok) {
            throw new Error(
              'Network response was not ok' + imageResponse.statusText,
            );
          }
          const imageFile = await imageResponse.blob();
          const reader = new FileReader();

          reader.onloadend = () => {
            let imageBlob = reader.result;

            setPostImage(imageBlob);
          };

          reader.readAsDataURL(imageFile);
        }

        // Update Post to avoid data loss
        setPostInfo({
          ...data.data,
          inEditMode: true,
        });

        if (newUrl) {
          window.location.href = data.full_url;
        }

        navigate(-1);
        return;
        // Update Link id URL has changed
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  }

  const [openCloseDialog, setCloseDialogOpen] = useState(false);
  const CloseDialog = () => {
    function handleConfirm() {
      setCloseDialogOpen(false);
      navigate(-1);
    }

    return (
      <Dialog
        open={openCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Discard your edits?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('context.blog.post.publish.discard')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="warning" onClick={handleConfirm}>
            {t('buttons.discard_edit')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCloseDialogOpen(false)}
          >
            {t('buttons.continue_edit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Formik
      values={formik.values}
      initialValues={formik.initialValues}
      errors={formik.errors}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {(formik) => {
        const {
          values,
          setFieldValue,
          errors,
          touched,
          handleBlur,
          setValues,
          setTouched,
          resetForm,
        } = formik;
        return (
          <Container maxWidth="auto" disableGutters>
            {/* Header Container */}
            <PostHeaderContainer
              content={formik.values}
              formik={formik}
              image={postImage}
              functions={handlePostImageChange}
              states={{
                inEditMode: postInfo.inEditMode,
                setDialogMsg,
                setDialogOpen,
                setDialogTitle,
                setPostInfo,
              }}
            />
            <Container component="main" maxWidth="xxl" className="intu__page">
              {/* Blogs Overview Container */}
              <Grid container className="content">
                <Grid item className="title content-centered" xs={12}>
                  <Typography variant="h1"></Typography>
                </Grid>
                <Grid item className="content" xs={12}>
                  <Form className="form-horizontal intu__form" role="form">
                    {/* Error Dialog */}
                    <Dialog
                      open={dialogOpen}
                      onClose={handleConfirmError}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {dialogTitle}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {dialogMsg}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleConfirmError} autoFocus>
                          {t('buttons.ok')}
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <FormControl fullWidth>
                      <Typography variant="h4">
                        {t('context.blog.post.edit_title')}
                      </Typography>
                      <Grid container direction="column">
                        <Grid
                          item
                          container
                          xs={12}
                          direction="row"
                          spacing={2}
                        >
                          {/* row 1 - Column 1 */}
                          <Grid item xs={12} md={8}>
                            {/* Title */}
                            <Typography variant="h5">
                              {t('context.blog.post.detail_title')}
                            </Typography>
                            <Stack direction="column" spacing={2}>
                              <TextField
                                required
                                id="title"
                                name="title"
                                aria-invalid={errors.title ? 'true' : 'false'}
                                aria-describedby="uidnote"
                                className="form-select-field"
                                variant="outlined"
                                label={t('form.post.details.title_label')}
                                type="text"
                                placeholder={t(
                                  'form.post.details.title_placeholder',
                                )}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values?.title}
                                error={
                                  errors?.title && touched?.title ? true : false
                                }
                                helperText={
                                  touched?.title && errors?.title
                                    ? errors?.title
                                    : null
                                }
                              />

                              {/* Link */}
                              <TextField
                                id="link"
                                name="link"
                                className="form-select-field"
                                variant="outlined"
                                type="text"
                                label={t('form.post.details.link_label')}
                                InputLabelProps={{ shrink: true }}
                                placeholder={t(
                                  'form.post.details.link_placeholder',
                                )}
                                onBlur={(e) => {
                                  if (formik.values?.link_changed > '') {
                                    let input = e.target.value;
                                    setIsSearchingLink(true);
                                    let urlSearch = setTimeout(async () => {
                                      try {
                                        const response =
                                          await searchPosts(input);
                                        const { data, status } = response;
                                        if (status === 'success') {
                                          // Check if current post is the one being found
                                          if (data[0]._id === values._id) {
                                            setValues({
                                              ...formik.values,
                                              duplicate_link: '',
                                              new_url: true,
                                            });
                                          } else {
                                            setValues({
                                              ...formik.values,
                                              duplicate_link: data[0].link,
                                              new_url: false,
                                            });
                                          }
                                        } else {
                                          setValues({
                                            ...formik.values,
                                            duplicate_link: '',
                                            new_url: true,
                                          });
                                        }
                                      } catch (err) {
                                        console.log(err);
                                      } finally {
                                        setIsSearchingLink(false);
                                      }
                                      clearTimeout(urlSearch);
                                    }, 100);
                                  }
                                  handleBlur(e);
                                }}
                                onChange={(e) => {
                                  setValues({
                                    ...formik.values,
                                    link: e.target.value,
                                    link_changed: true,
                                  });
                                }}
                                InputProps={{
                                  type: 'text',
                                  autoComplete: 'off',
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {isSearchingLink ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                    </InputAdornment>
                                  ),
                                }}
                                value={values.link}
                                error={
                                  touched?.link && errors?.link ? true : false
                                }
                                helperText={
                                  touched?.link && errors?.link
                                    ? errors?.link
                                    : null
                                }
                              />

                              {/* Description */}
                              <TextField
                                required
                                multiline
                                aria-invalid={
                                  errors.description ? 'true' : 'false'
                                }
                                aria-describedby="uidnote"
                                id="description"
                                name="description"
                                className="form-select-field"
                                variant="outlined"
                                placeholder={t(
                                  'form.post.details.desc_placeholder',
                                )}
                                type="input"
                                label={t('form.post.details.desc_label')}
                                minRows={8}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                inputProps={{ style: { resize: 'vertical' } }}
                                onBlur={handleBlur}
                                value={values?.description}
                                error={
                                  errors?.description && touched?.description
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors?.description && touched?.description
                                    ? errors.description
                                    : null
                                }
                              />

                              {/* Tags */}
                              <Autocomplete
                                freeSolo
                                autoComplete
                                multiple
                                required
                                loading={isLoadingTags || false}
                                loadingText={t(
                                  'form.post.details.tags_loading',
                                )}
                                filterSelectedOptions
                                handleHomeEndKeys
                                id="tags"
                                name="tags"
                                value={values?.tags}
                                clearIcon={
                                  <ClearIcon
                                    sx={{ color: 'var(--intu-lightGrey)' }}
                                  />
                                }
                                options={values?.tag_options}
                                className="form-select-field"
                                onChange={(e, options) => {
                                  setFieldValue('tags', options);
                                }}
                                onBlur={handleBlur}
                                pb="50px"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'text',
                                      autoComplete: 'off',
                                      endAdornment: (
                                        <>
                                          {isLoadingTags ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={20}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                    value={values?.tag_options}
                                    onChange={async (e) => {
                                      let input = e.target.value;
                                      setFieldValue('tag_search', input);

                                      if (input.length > 2) {
                                        setIsLoadingTags(true);
                                        let tagSearch = setTimeout(async () => {
                                          try {
                                            const response =
                                              await searchPostTags(input, auth);
                                            const { data, status } = response;
                                            if (status === 'success') {
                                              setFieldValue(
                                                'tag_options',
                                                data,
                                              );
                                            }
                                            setIsLoadingTags(false);
                                          } catch (err) {
                                            setIsLoadingTags(false);
                                          }
                                          clearTimeout(tagSearch);
                                        }, 100);
                                      }
                                    }}
                                    placeholder={t(
                                      'form.post.details.tags_placeholder',
                                    )}
                                    label={t('form.post.details.tags_label')}
                                    error={
                                      touched?.tags && errors?.tags
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched?.tags && errors?.tags
                                        ? errors?.tags
                                        : null
                                    }
                                  />
                                )}
                              />
                            </Stack>
                          </Grid>
                          {/* row 1 - Column 2 */}
                          <Grid item xs={12} md={4}>
                            <Typography variant="h5">
                              {t('context.blog.post.settings_title')}
                            </Typography>
                            <Stack direction="column" spacing={2}>
                              {/* Action Buttons and Status */}
                              <Stack direction="column" spacing={2}>
                                {/* Status */}
                                <TextField
                                  required
                                  select
                                  type="text"
                                  id="status"
                                  label={t('form.post.settings.status_label')}
                                  name="status"
                                  variant="outlined"
                                  value={values?.status ? values.status : ''}
                                  onChange={(e) => {
                                    if (formik.isValid === true) {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );
                                    } else if (e.target.value === 'published') {
                                      setDialogTitle(
                                        t('form.post.settings.dialog.title'),
                                      );
                                      setDialogMsg(
                                        t('form.post.settings.dialog.msg'),
                                      );
                                      setDialogOpen(true);
                                      setFieldValue('status', 'draft');
                                    } else {
                                      setFieldValue('status', e.target.value);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    errors.status && touched.status
                                      ? true
                                      : false
                                  }
                                >
                                  <MenuItem key={0} value="">
                                    {t('form.post.settings.status_select')}
                                  </MenuItem>
                                  <MenuItem key={1} value="draft">
                                    {t('form.post.settings.status_draft')}
                                  </MenuItem>
                                  <MenuItem key={2} value="published">
                                    {t('form.post.settings.status_published')}
                                  </MenuItem>
                                  <MenuItem key={3} value="archived">
                                    {t('form.post.settings.status_archive')}
                                  </MenuItem>
                                </TextField>
                                {/* Action Buttons */}
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    const update = updatePost(values, formik);
                                    if (update.status === 'error ') {
                                      resetForm();
                                    } else {
                                      setTouched({}, false);
                                    }
                                  }}
                                  endIcon={<UploadIcon />}
                                >
                                  {t('buttons.update')}
                                </Button>
                                {/* Preview Post */}
                                <Button
                                  variant="contained"
                                  disabled={
                                    !values.title || !values.description
                                  }
                                  color="info"
                                  onClick={() =>
                                    handleEditPost(false, formik.values)
                                  }
                                  endIcon={<RemoveRedEyeIcon />}
                                >
                                  {t('buttons.preview')}
                                </Button>
                                {/* Close Edit Mode */}
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() => {
                                    const hasKeys =
                                      !!Object.keys(touched).length;
                                    if (hasKeys) {
                                      setCloseDialogOpen(true);
                                    } else {
                                      navigate(-1);
                                    }
                                  }}
                                  endIcon={<CancelIcon />}
                                >
                                  {t('buttons.close')}
                                </Button>
                                <CloseDialog />
                              </Stack>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* Editor Component */}
                      <Divider
                        sx={{ margin: '20px 0 20px 0' }}
                        color="#a8c957"
                      />
                      <Typography variant="h5">
                        {t('context.blog.post.content')}
                      </Typography>
                      <IntuPageBuilder formik={formik} values={values} />
                      Errors:
                      {JSON.stringify(formik.errors)}
                    </FormControl>
                  </Form>
                </Grid>
              </Grid>
            </Container>
          </Container>
        );
      }}
    </Formik>
  );
};

const PostContent = (props) => {
  const {
    postInfo,
    setPostInfo,
    setPostImage,
    location,
    navigate,
    axios,
    auth,
    isAdmin,
  } = useContext(PostContext);
  const { user } = useUser();
  const { t } = useTranslation();

  // Loading Spinner Status
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { isLoading, setIsLoading } = useProcessingHandler();

  // Pre Load Form Data
  useEffect(() => {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('form.message.loading'),
    });

    const mode = props?.mode;

    // Get post path
    const path = location?.pathname;
    const params = new URLSearchParams(window.location.search);
    const tracker = params.get('tracker') === 'false' ? false : true;

    if (!path) {
      navigate(-1);
    }

    async function getPost(props) {
      const link = path.substring(path.lastIndexOf('/') + 1);

      const controller = new AbortController();
      const signal = controller.signal;

      try {
        const request = await searchPosts(link, auth);

        if (request.status === 'success') {
          let data = request.data[0];
          // Check if Pos has Image and convert it
          const imageUrl = data?.image_url;

          if (imageUrl) {
            const imageResponse = await fetch(imageUrl, { signal });
            if (!imageResponse.ok) {
              throw new Error(
                'Network response was not ok' + imageResponse.statusText,
              );
            }
            const imageBlob = await imageResponse.blob();
            const reader = new FileReader();

            reader.onloadend = () => {
              const imageBase64 = reader.result; // Store the base64 data in a variable

              // Finally, update postInfo with all changes
              // setPostInfo(updatePostInfo);
              setPostImage({
                imageBlob: imageBase64,
              });
            };

            reader.readAsDataURL(imageBlob);
          }

          // Set View or Edit Mode
          if (props?.inEditMode === true) {
            // Check if user is admin or author
            if (
              auth?.user_info?.full_name === postInfo?.author.name ||
              isAdmin
            ) {
              data = {
                ...data,
                inEditMode: true,
              };
            }
          }

          // Update postInfo with all changes
          setPostInfo(data);
        } else {
          handleRegularResponse({
            open: true,
            status: request.status,
            message: request.message,
          });
        }
      } catch (err) {
        handleErrorResponse(err);
        // navigate(-1)
      }
    }

    async function trackActivity() {
      const controller = new AbortController();
      const signal = controller.signal;

      if (!postInfo?._id) {
        return;
      }

      try {
        const payload = {
          _id: postInfo._id,
          user: user,
          module: 'posts',
          action: 'Post View',
          tracker,
          additionalParams: {
            title: postInfo.title,
          },
        };
        const url = '/api/tracker/track';
        const request = await axios.post(url, JSON.stringify(payload), {
          signal,
          headers: { 'Content-Type': 'application/json' },
        });
        // let response = request?.data
        // console.log(response)
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    // Get Lead Content
    async function getPostContent() {
      if (user.location && user.sessionID) {
        if (mode === 'viewPost') {
          // Content missing, fetching data from server using the path
          // console.log(`Fetching Post`)
          await getPost({ inEditMode: false });
          await trackActivity();
        } else if (mode === 'editPost') {
          await getPost({ inEditMode: true });
        } else if (mode === 'createPost') {
          // let link = props?.link
          await getPost({ inEditMode: true });
        }
        setIsLoading({ status: false, type: '', text: '' });
      }
    }

    getPostContent();
  }, [user?.sessionID, user, postInfo._id]);

  return (
    <>
      {isLoading.status === true ? (
        <PreLoadContext />
      ) : postInfo.inEditMode === true ? (
        <PostEditForm />
      ) : (
        <PostViewForm />
      )}
    </>
  );
};

export { PostContent };
