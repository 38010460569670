import React, { useContext } from 'react';

import {
  Autocomplete,
  Box,
  TextField,
  Skeleton,
  Chip,
  Avatar,
} from '@mui/material';

// Languages List
import languagesList from '../../../data/LanguagesData.json';

// Context
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper.jsx';
import ProcessingContext from '../../../context/providers/ProcessingProvider.jsx';
import { getNestedValue } from '../../../helpers/getNestedValuesIn.js';

const IndividualLanguageField = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  fieldID = 'individual.languages', // Defines the ID and Name of the Field
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
  showInitialErrors = false, // Whether errors are shown without field being touched
}) => {
  const { values, setFieldTouched, setFieldValue, errors, touched } =
    useFormikContext();

  //SetUp Trnaslation
  const { t } = useTranslation('fields', {
    keyPrefix: 'account.individual.IndividualLanguagesField',
  });

  // Country Data
  const countryOptions = languagesList;

  const { isLoading } = useContext(ProcessingContext);

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  return (
    <>
      {isLoading.status ? (
        <Skeleton variant="rectangular" width={'100%'} height={60} />
      ) : (
        <FormikFieldWrapper
          fieldTitle={fieldTitle}
          fieldDescription={fieldDescription}
          transition={transition}
        >
          <Autocomplete
            required
            multiple
            disabled={disabled}
            autoComplete={false}
            id={fieldID}
            name={fieldID}
            autoSelect={true}
            disableClearable
            options={countryOptions?.sort((a, b) => {
              return -b.name.localeCompare(a.name);
            })}
            filterSelectedOptions={true}
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option, value) =>
              option?.code === value?.code
            }
            onChange={async (e, options) => {
              setFieldValue(fieldID, options);
            }}
            onBlur={async (e) => {
              setFieldTouched(fieldID, true, true);
            }}
            value={fieldValue}
            renderOption={(props, option) => {
              return (
                <Box
                  component="li"
                  sx={{
                    '& > img': { mr: 2, flexShrink: 0 },
                  }}
                  {...props}
                  key={option.flagCode}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.flagCode.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.flagCode.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option?.name}
                </Box>
              );
            }}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  avatar={
                    <Avatar
                      sx={{ width: 24, height: 24 }}
                      src={`https://flagcdn.com/w20/${option.flagCode.toLowerCase()}.png`}
                      alt={option.name}
                    />
                  }
                  label={option.name}
                  {...getTagProps({ index })}
                  key={option.flagCode}
                />
              ))
            }
            className="form-select-field"
            renderInput={(params) => (
              <TextField
                {...params}
                required={required}
                variant="outlined"
                label={!disabled ? t('label') : null}
                placeholder={!disabled ? t('placeholder') : null}
                inputProps={{
                  ...params.inputProps,
                }}
                error={
                  showInitialErrors ? fieldError ? true : false : fieldTouched && fieldError ? true : false
                  // fieldTouched && fieldError ? true : false
                }
                helperText={
                  showInitialErrors ? fieldError ? fieldError : null : fieldTouched && fieldError ? fieldError : null
                  // fieldTouched && fieldError ? fieldError : null
                }
              />
            )}
          />
        </FormikFieldWrapper>
      )}
    </>
  );
};

export default IndividualLanguageField;
