import { motion } from 'framer-motion';
import { Grid, Typography } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';

const InfiniteCarousel = ({
  slides,
  bannerHeight,
  slideWidth,
  slideHeight,
}) => {
  const itemDuration = 4; // Duration for each item in seconds
  const totalDuration = slides.length * itemDuration;
  const initialDelay = 0; // Initial delay in seconds before the animation starts

  return (
    <Grid
      container
      spacing={2}
      sx={{
        overflow: 'hidden',
        width: '100%',
        minHeight: bannerHeight || '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <motion.div
        animate={{
          x: ['-100%', '100%'],
        }}
        initial={{
          x: '-100%',
        }}
        transition={{
          ease: 'linear',
          duration: totalDuration,
          repeat: Infinity,
          delay: initialDelay,
        }}
        style={{
          display: 'flex',
          width: `${slides.length * 100}%`,
          height: '120px',
        }}
      >
        {slides.map((slide, index) => {
          return (
            <Grid
              item
              container
              spacing={1}
              key={index}
              direction="row"
              alignContent="center"
              justifyContent="center"
              sx={{
                display: 'flex',
                flex: '0 0 auto',
                padding: slide?.label ? '30px' : '0px',
                maxWidth: `calc((${slideWidth || '450px'}) / 2)`,
                minHeight: `calc((${slideHeight || '450px'}) / 2)`,
                marginLeft: `calc((${slideWidth || '50px'}) / 2)`,
                marginRight: `calc((${slideWidth || '50px'}) / 2)`,
              }}
            >
              {/* Image */}
              {slide.src && (
                <Grid
                  item
                  xs={12}
                  style={{
                    overflow: 'hidden',
                    minWidth: slideWidth || '100%',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <img
                    src={slide.src}
                    alt={slide.alt}
                    width="100%"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}
                  />
                </Grid>
              )}

              {/* Icon */}
              {slide.icon && (
                <Grid item xs={12} style={{ overflow: 'hidden' }}>
                  {slide.icon}
                </Grid>
              )}

              {/* Label */}
              <Grid item textAlign="center">
                <Typography
                  variant="body1"
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: `calc((${slideWidth || '200px'}) / 5)`,
                  }}
                >
                  {ReactHtmlParser(slide.label)}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </motion.div>
    </Grid>
  );
};

export default InfiniteCarousel;
