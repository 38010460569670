import { Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LanguageIcon from '@mui/icons-material/Language';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import useIntuTheme from '../../../hooks/useIntuTheme';
import LanguagesData from '../../../data/LanguagesData.json';

import { motion } from 'framer-motion';
import { LanguageItem } from '../../../framer-motion/animations';
import { useTranslation } from 'react-i18next';

const DrawerFooterContent = ({ onLanguageChange }) => {
  const theme = useTheme();
  const { mode, setMode } = useIntuTheme();

  const { t } = useTranslation('buttons', {
    keyPrefix: 'navigation',
  });

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      sx={{
        flexShrink: 0,
        padding: '5px 10px',
        position: 'absolute',
        marginTop: 'auto',
        bottom: 0,
        width: '100%',
      }}
    >
      <Grid item xs={10} container alignItems="center">
        <Grid item xs="auto">
          <Typography
            variant="body1"
            sx={{ color: theme.components.IntuDrawer.textColor }}
          >
            {mode === 'dark' ? t('dark_mode') : t('light_mode')}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            color="inherit"
            onClick={() =>
              setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
            }
            sx={{ ...theme.components.DrawerListItem, ml: 1 }}
          >
            {theme.palette.mode === 'dark' ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
        </Grid>
      </Grid>

      {/* Temporarely only active on dev until Translation is complete and of good quality */}
      {process.env.REACT_APP_ENV === 'development' && (
        <Grid item xs={2}>
          <Select
            variant="standard"
            disableUnderline
            id="language-menu"
            IconComponent={LanguageIcon}
            value=""
            sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}
          >
            {LanguagesData.map(({ name, code, supported }) => {
              if (supported) {
                return (
                  <motion.ul
                    variants={LanguageItem}
                    whilehover={{ scale: 1.1 }}
                    key={code}
                  >
                    <MenuItem
                      key={code}
                      value={code}
                      onClick={() => onLanguageChange(code)}
                    >
                      {name}
                    </MenuItem>
                  </motion.ul>
                );
              }
            })}
          </Select>
        </Grid>
      )}
    </Grid>
  );
};

export default DrawerFooterContent;
