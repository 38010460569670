import axios from '../hooks/axios/useAxios';

// Get Idnustries
async function getIndustries(showAll = false) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/industries/list';

  try {
    let params = {
      showall: showAll,
    };

    let request = await axios.get(url, {
      signal,
      params,
    });

    let response = request?.data;

    if (request?.status) {
      return response;
    }
  } catch (err) {
    return err;
  }
}

// Search Industries
async function searchIndustries(industries) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/industries/search';

  try {
    let payload = {
      key: '_id',
      values: industries,
    };
    let request = await axios.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    let response = request?.data;
    // console.log(response)

    if (request?.status) {
      // console.log(JSON.stringify(response))
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

export { getIndustries, searchIndustries };
