import React, { useRef } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { getNestedValue } from '../../helpers/getNestedValuesIn';

const MobileNumberField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'mobile', // Determines the Name and IDD of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'MobileNumberField', // KeyPrefix which holds label and placeholde
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
  variant = 'outlined', // Takes Standard or outlined as a property
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const mobileRef = useRef();

  // Context
  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  return (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <MuiTelInput
        id={fieldID}
        name={fieldID}
        required={required}
        disabled={disabled}
        variant={variant}
        ref={mobileRef}
        autoComplete="off"
        className="form-select-field"
        label={t('label')}
        placeholder={t('placeholder')}
        onChange={(value, info) => {
          setFieldValue(fieldID, {
            value: value,
            info,
          });
        }}
        onBlur={handleBlur}
        value={fieldValue?.value}
        error={
          fieldError && fieldTouched && fieldError?.info?.nationalNumber
            ? true
            : false
        }
        helperText={
          fieldError?.info?.nationalNumber && fieldTouched
            ? fieldError?.info?.nationalNumber
            : null
        }
      />
    </FormikFieldWrapper>
  );
};

export default MobileNumberField;
