import { Container } from '@mui/material';
import CompanyProductList from '../../context/product/CompanyProductList';
import { ManageProductContext } from '../../context/product/ManageProductContext';
import { ProductProvider } from '../../context/providers/ProductContextProvider';
import { useLocation } from 'react-router-dom';
import FormContextProvider from '../../context/providers/FormContextProvider';
import DashboardLayoutComponent from '../../components/layout/DashboardLayout';
import { useTranslation } from 'react-i18next';

function AdminProducts() {
  const location = useLocation();
  const mode = location?.state?.mode || 'list';
  const { t } = useTranslation();

  return (
    <ProductProvider>
      <FormContextProvider>
        <Container maxWidth="xl" style={{ marginTop: '8rem' }}>
          {mode === 'edit' ? (
            <ManageProductContext />
          ) : (
            <DashboardLayoutComponent
              dashboardTitle={t('pages.CompanyProductsPage.title')}
              dashboardDescription={t('pages.CompanyProductsPage.description')}
            >
              <CompanyProductList />
            </DashboardLayoutComponent>
          )}
        </Container>
      </FormContextProvider>
    </ProductProvider>
  );
}

export default AdminProducts;
