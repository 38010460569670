// Create Random String for Link
function randomStr(len, str) {
  let ans = '';
  for (let i = len; i > 0; i--) {
    ans += str[Math.floor(Math.random() * str.length)];
  }
  return ans;
}

export { randomStr };
