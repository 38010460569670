import React, { useContext } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { TextField, useTheme } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { useFormikContext } from 'formik';
import { classes } from '../../mui/theme';
import { TwoFactorVerificationContext } from '../../context/providers/TwoFactorVerificationContextProvider';

const MobileOTPField = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  fieldID = 'otp', //Determines the Name and ID of the field
  transNS = 'fields', // Name Spoace of Transaltion
  transPrefix = 'MobileOTPField', // Namespace for translation Value
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
  variant = 'outlined', // Takes Standard or outlined as a property
}) => {
  // Context
  const { values, setFieldValue, errors, handleBlur, touched } =
    useFormikContext();

  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  // Theme context
  const theme = useTheme();
  return (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        required={required}
        disabled={disabled}
        style={classes.root}
        id={fieldID}
        name={fieldID}
        className="form-select-field"
        autoComplete="off"
        aria-invalid={errors.otp ? 'true' : 'false'}
        aria-describedby="uidnote"
        variant={variant}
        label={t('label')}
        type="password"
        placeholder={t('placeholder')}
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value);
        }}
        inputProps={{
          autoComplete: 'one-time-code',
        }}
        onBlur={handleBlur}
        value={values?.otp}
        error={errors?.otp && touched?.otp ? true : false}
        helperText={errors?.otp && touched?.otp ? errors?.otp : null}
      />
    </FormikFieldWrapper>
  );
};

export default MobileOTPField;
