import { Stack, Skeleton } from '@mui/material';

const ProductListLoader = () => {
  return (
    <Stack direction="column" spacing={-1}>
      <Stack spacing={1} direction="row" height={50}>
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
      </Stack>
      <Stack spacing={1} direction="row" height={50}>
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
      </Stack>
      <Stack spacing={1} direction="row" height={50}>
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
      </Stack>
      <Stack spacing={1} direction="row" height={50}>
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
      </Stack>
      <Stack spacing={1} direction="row" height={50}>
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
      </Stack>
      <Stack spacing={1} direction="row" height={50}>
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
      </Stack>
      <Stack spacing={1} direction="row" height={50}>
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
      </Stack>
      <Stack spacing={1} direction="row" height={50}>
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
        <Skeleton variant="rectangular" animation="wave" width="25%" />
      </Stack>
    </Stack>
  );
};

export default ProductListLoader;
