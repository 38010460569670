import { Box, MenuItem, Button } from '@mui/material';

const DrawerContent = ({ generalNavItems, mode }) => {
  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'flex' },
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {generalNavItems.map(
        (menuItem) =>
          menuItem.visible === 'nav' ||
          (menuItem.visible === 'all' && (
            <MenuItem
              key={`${mode}.item.${menuItem.index}`}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Button
                key={`${mode}.button.${menuItem.index}`}
                variant={menuItem.variant}
                color={menuItem.color}
                type="submit"
                onClick={menuItem.target}
              >
                {menuItem.label}
              </Button>
            </MenuItem>
          )),
      )}
    </Box>
  );
};

export default DrawerContent;
