import { useTranslation } from 'react-i18next';
import IntuPage from '../../components/layout/IntuPage';
import { TermsOfUseContent } from '../../components/terms/TermsOfUseContent';

const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <IntuPage pageTitle={t('pages.TermsOfUse.title')}>
      <TermsOfUseContent />
    </IntuPage>
  );
};

export default TermsOfUse;
