import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { useContext } from 'react';
import { Skeleton, TextField } from '@mui/material';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import dayjs from 'dayjs';
import { toTitleCase } from '../../helpers/toTitleCase';

const DatePickerField = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  fieldID = 'date', // Defines the ID and Name of the Field
  transType = 'activity', // Name of the Translation Type
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
  showInitialErrors = false, // Show Errors even when the field has not been touched
}) => {
  const { t } = useTranslation('fields', {
    keyPrefix: 'date.DatePickerField',
  });
  const { t: transTypes } = useTranslation();

  const {
    values,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
    handleBlur,
  } = useFormikContext();

  const { isLoading } = useContext(ProcessingContext);

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  return (
    <>
      {isLoading.status ? (
        <Skeleton variant="rectangular" width={'100%'} height={60} />
      ) : (
        <FormikFieldWrapper
          fieldTitle={fieldTitle}
          fieldDescription={fieldDescription}
          transition={transition}
        >
          <DatePicker
            label={toTitleCase(t('label', { type: transTypes(transType) }))}
            placeholder={t('placeholder')}
            disabled={disabled}
            id={fieldID}
            name={fieldID}
            variant="standard"
            value={dayjs(fieldValue)}
            onblur={handleBlur}
            onChange={(date) => {
              setFieldValue(fieldID, date);
              setTimeout(() => {
                setFieldTouched(fieldID, true, true);
              });
            }}
            disableFuture={true}
            slotProps={{
              textField: {
                required: required,
                disabled: disabled,
                onChange: (date) => {
                  setFieldValue(fieldID, date);
                  setTimeout(() => {
                    setFieldTouched(fieldID, true, true);
                  });
                },
                onBlur: () => handleBlur,
                error: showInitialErrors
                  ? fieldError
                    ? true
                    : false
                  : fieldTouched && fieldError
                    ? true
                    : false,
                helperText: showInitialErrors
                  ? fieldError
                    ? fieldError
                    : null
                  : fieldError && showInitialErrors
                    ? fieldError
                    : null,
              },
            }}
            textField={(params) => (
              <TextField
                {...params}
                required={required}
                disabled={disabled}
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.value);
                  setFieldTouched(e.target.name, true, true);
                }}
              />
            )}
          />
        </FormikFieldWrapper>
      )}
    </>
  );
};

export default DatePickerField;
