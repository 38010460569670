import { Container, Grid } from '@mui/material';
import { IntuForm } from '../../components/layout/IntuForm';
import { SubscriberContent } from '../../components/newsletter/SubscriberContent';
import IntuPage from '../../components/layout/IntuPage';
import './subscribers.css';

const Subscribers = ({ mode, source }) => {
  switch (mode) {
    case 'signup-component':
      return <SubscriberContent source={source} mode={mode} />;
    default:
      return (
        <Container maxWidth="auto" disableGutters>
          <IntuPage>
            <IntuForm form="Subscriber Page">
              <SubscriberContent source={source} mode={mode} />
            </IntuForm>
          </IntuPage>
        </Container>
      );
  }
};

export default Subscribers;
