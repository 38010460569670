import { useEffect, useState, createContext, useContext } from 'react';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import AuthContext from './AuthProvider';
import { UserProfileContext } from './UserProfileProvider';
import { CompanyProfileContext } from './CompanyProfileProvider';

export const StripeContext = createContext({});

export const StripeProvider = ({ children }) => {
  const { auth, isAdmin } = useContext(AuthContext);
  const { userProfile } = useContext(UserProfileContext);
  const { companyProfile } = useContext(CompanyProfileContext);

  const axios = useAxiosPrivate();
  const [stripeUserData, setStripeUserData] = useState({
    account_id: '',
    currently_due: '',
    past_due: '',
    account_Link: '',
    account_balance: 0,
    account_complete: false,
  });
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState('');

  const getStripeAccountDetails = async () => {
    // Guard Clause
    if (!auth.auth_info || auth?.user_info?.account_type === 'intutec') {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    try {
      setIsStripeLoading(true);
      const getStripeAccountDetails = await axios.get(
        '/api/stripe/connect/account/retrieve',
        { signal },
      );

      const { statusCode, data } = getStripeAccountDetails.data;
      if (statusCode === 200) {
        let accountLink;

        if (data.currently_due.length || data.past_due.length) {
          const payload = {
            host_url: window.location.host,
          };
          const getAccountCompleteLink = await axios.post(
            '/api/stripe/connect/account/create-account-link',
            JSON.stringify(payload),
            {
              signal,
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            },
          );
          const { statusCode, data } = getAccountCompleteLink.data;

          if (statusCode === 200) {
            accountLink = data.url;
          }
        }

        setStripeUserData({
          account_id: data.id,
          currently_due: data.currently_due,
          past_due: data.past_due,
          account_link: accountLink,
        });
      }
    } catch (error) {
      console.error('error', error);
      return error;
    } finally {
      controller.abort(signal);
      setIsStripeLoading(false);
    }
  };

  useEffect(() => {
    getStripeAccountDetails();
  }, [auth?.auth_info, userProfile._id, companyProfile._id, isAdmin]);

  return (
    <StripeContext.Provider
      value={{
        stripeUserData,
        setStripeUserData,
        isStripeLoading,
        stripeErrorMessage,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};
