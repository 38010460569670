import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n.js";
import { useNavigate } from "react-router-dom";
import { RiveAnimationComponent } from '../../../components/animations/RiveAnimationComponent.jsx';
import { ButtonHoverGradiant } from '../../../components/buttons/ButtonHoverGradiant.jsx';

export const FindManufacturersContent = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.home.SalesHome.BrandsContent',
    });
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            sx={{ marginTop: { xs: '15vh' } }}
        >
            <Grid
                item
                container
                direction={{ xs: 'column', xl: 'row' }}
                alignItems={{ xs: 'flex-start' }}
                justifyContent={{ xs: 'space-around' }}
                sx={{ marginTop: { xs: '10vh' } }}
            >
                {/* Columns 1 */}
                <Grid xs={3} container item alignItems="center" direction="column">
                    <Grid item xs={12}>
                        <RiveAnimationComponent
                            src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/search_icon.riv"
                            stateMachines="search"
                            width="200px"
                            height="250px"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h4">
                            {t('title1')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography align="center" variant="body">
                            {t('description1')}
                        </Typography>
                    </Grid>
                </Grid>
                {/* Column 2 */}
                <Grid xs={3} container item alignItems="center" direction="column">
                    <RiveAnimationComponent
                        src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/engage.riv"
                        stateMachines="Timeline 1"
                    />
                    <Grid item xs={12}>
                        <Typography align="center" variant="h4">
                            {t('title3')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography align="center" variant="body">
                            {t('description3')}
                        </Typography>
                    </Grid>
                </Grid>
                {/* Column 3 */}
                <Grid xs={3} container item alignItems="center" direction="column">
                    <Grid item xs={12}>
                        <RiveAnimationComponent
                            src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/robot.riv"
                            stateMachines="State Machine 1"
                            width="250px"
                            height="250px"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h4">
                            {t('title2')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography align="center" variant="body">
                            {t('description2')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={14} mb={10}>
                <ButtonHoverGradiant
                    label={i18n.t('join_now', { ns: 'buttons' })}
                    onClick={() => navigate('/account/signup')}
                />
            </Grid>
        </Grid>
    );
};