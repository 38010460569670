import React from 'react';
import { useAnalyticsPageViewTracker } from '../../hooks/useAnalyticsTracker';
import HubSpotMeeting from '../../components/meetus/hubSpotMeeting';
import { Container } from '@mui/material';

import MeetUsOnlineHeaderContainer from '../../containers/headers/MeetUsOnlineHeaderContainer';

const MeetUsOnlinePage = () => {
  return (
    <Container maxWidth="auto" disableGutters>
      {/* Header Component */}

      <MeetUsOnlineHeaderContainer />

      <Container component="main" maxWidth="xl" className="intu__page">
        {/* Content Component */}
        <HubSpotMeeting />
      </Container>
    </Container>
  );
};

export default MeetUsOnlinePage;
