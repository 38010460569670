import React, { useEffect } from 'react';

// Materail UI
import { Container, Grid, Typography } from '@mui/material';

// FORM CONTENT
const CompanySalesTeamList = (props) => {
  // Get Existing User Info before Component mounts
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });

  return (
    <Grid className="content" sx={{ marginBottom: '8rem' }}>
      <Container
        component="main"
        maxWidth="xl"
        className="intu__section content-centered"
      >
        <Container
          className="intu__section intu__page_content"
          style={{ marginTop: '10rem' }}
        >
          <Grid
            container
            className="content"
            sx={{ marginBottom: '8rem' }}
            direction={{ xs: 'column', md: 'row' }}
            alignContent="flex-start"
            alignItems="center"
            textAlign={{ xs: 'center', md: 'left' }}
          >
            <Grid item xs={12} sx={{ paddingBottom: '50px' }}>
              <Typography variant="h1">Sales & Marketing Team</Typography>
            </Grid>
            <Grid item xs={12} md={12} sx={{ paddingBottom: '100' }}>
              <Typography variant="h6">Coming Soon...</Typography>
              <Typography variant="h6">
                You will be able to manage your sales & marketing team from here
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
};

export { CompanySalesTeamList };
