
import React, { useState, useEffect} from 'react';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

// charts
import * as am5 from "@amcharts/amcharts5";
import { PieChartContext } from '../../charts/pieChart';

// Mui
import { Grid } from '@mui/material';

// Uinique Chart ID
import { v4 as uuid } from 'uuid';

// Intu Page Editor Components
import { ColorPickerField } from '@in2tec/page-editor';


const pieChartPlugin = {
  Renderer: ({ data }) => {
    const id = uuid();
    const [chartData, setChartData] = useState([]);

    // Viewport
    const [mobileView, setMobileView] = useState(false)
    useEffect(() => {
      function handleResize() {
        if (window.innerWidth > 767) {
          setMobileView(false);
        } else if (window.innerWidth < 767) {
          setMobileView(true);
        }
      };

      handleResize();
      window.addEventListener('resize', handleResize)
    })

    useEffect(() => {
      // This effect will run whenever the data prop changes
      // You can update the chart here with the new data
      if (data.seriesData?.length > 0) {
        // Check if custom Color is specified
        let seriesData = []
        for (let i = 0; i < data.seriesData.length; i++) {
          let element = data.seriesData[i];

          if ( element.color > '') {
            element = {
              ...element, 
              sliceSettings: {
                fill: am5.color(element.color),
                // stroke: am5.color(element.color)
            }}
          }

          seriesData.push(element)
        }

        let payload = {
          ...data,
          seriesData
        }
        
        // Update the chart with the new data
        setChartData(payload);
      }
    }, [data]);

    if (data.seriesData?.length > 0) {
      return (
        <Grid container direction="column">
          <Grid item xs={12}>
              <PieChartContext
                chartId={id}
                data={chartData?.seriesData}
                description={chartData?.description}
                title={chartData?.title}
                legend={chartData.legend}
                mobileView={mobileView} 
              />
          </Grid>
        </Grid>
      );
    }
  },
  id: 'AmChartsPieChart',
  title: 'Pie Chart',
  icon: <DonutLargeIcon />,
  description: 'Create a Pie Chart',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: "string",
          uniforms: {
            label: "Chart Title",
            placeholder: "Give your Chart a Title",
          },
        },
        description: {
          type: 'string',
          uniforms: {
            label: "Chart Description", // custom label
            placeholder: "Describe your chart!", // custom placeholder
            multiline: true, // wether to show multiline (for text fields)
            rows: 4, // show multiple lines
          },
        },
        legend: {
          type: 'boolean',
          uniforms: {
            label: "Chart Legend",
          },
        },
        seriesData: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              category: {
                type: 'string',
              },
              value: {
                type: 'number',
                default: 0,
              },
              color: {
                type: 'string',
                default: '',
                uniforms: {
                  component: ColorPickerField,
                },
              },
            },
          },
        },
      },
      required: ['seriesData'],
    },
  },
};

export default pieChartPlugin;
