import { Container, Grid } from '@mui/material';

// IntuForm
const PageHeader = (props) => {
  return (
    <Container maxWidth="auto" disableGutters className="intu__divider">
      <Grid>{props?.body}</Grid>
    </Container>
  );
};

export default PageHeader;
