import countryList from '../data/CountryData.json';

export function getUserPhoneDetails(countryCode) {
  const countryOptions = countryList;
  let phoneDetails = {
    value: '',
    info: {
      countryCallingCode: '+1',
      countryCode: 'US',
      nationalNumber: '1',
      numberType: null,
      numberValue: null,
      reason: null,
    },
  };

  if (countryCode) {
    const countryDetails = countryOptions.filter(
      (country) => country.code === countryCode,
    );
    phoneDetails = {
      value: '+' + countryDetails[0].dialCode,
      info: {
        countryCallingCode: countryDetails[0].dialCode,
        countryCode: countryDetails[0].code,
        nationalNumber: null,
        numberType: null,
        numberValue: '+' + countryDetails[0].dialCode,
        reason: 'country',
      },
    };
  }

  return phoneDetails;
}
