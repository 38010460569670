import React, { useContext, useEffect, useState } from 'react';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Fade, Skeleton, Tooltip, Typography } from '@mui/material';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
// PlaceHolder Image
import PlaceHolderBanner from '../../../assets/core/intuPlacerBanner.png';
import ResponseContext from '../../../context/providers/ResponseProvider';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider';

const CompanyBanner = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'company_banner_blob', // Determines the Name and IDD of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'account.company.CompanyBanner', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
}) => {
  // Translation

  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { t: transValidations } = useTranslation('validation');

  const { setErrorDialog } = useContext(ResponseContext);

  // Dragger for Drag & Drop function of company
  const [isDragging, setIsDragging] = useState(false);

  //   Formik Context
  const { values, setValues } = useFormikContext();

  const { isLoading } = useContext(ProcessingContext);

  const fieldValue = getNestedValue(values, fieldID);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e, values) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      // Handle the dropped files here, e.g., trigger your file upload function
      handleCompanyBannerChange(files[0], values);
    }
  };

  const { transitionInterval } = useContext(IntuThemeContext);
  const [isVisible, setIsVisible] = useState(false);

  // Handle Company Banner upload
  async function handleCompanyBannerChange(file, values) {
    // Set maximum file size
    const MAX_FILE_SIZE = 5242880;

    // Set allowed file types
    const validFileExtensions = [
      'image/jpg',
      'image/jpeg',
      'image/jpg',
      'image/png',
    ];

    // Check if ile type is valid
    function isValidFileType(fileType) {
      let extension = validFileExtensions.indexOf(fileType) > -1;
      return extension;
    }

    if (file) {
      //check if filesize is less than 5MB
      if (file.size > MAX_FILE_SIZE) {
        let fileSize = Math.round(file.size / 1000000);
        fileSize = fileSize.toFixed(2);
        setErrorDialog({
          title: transValidations('file.file_size_title'),
          message: transValidations('file.file_size_msg', {
            fileSize: { fileSize },
            maxFileSize: '5MB',
          }),
          open: true,
        });
        return;
      } else if (isValidFileType(file.type) === false) {
        setErrorDialog({
          title: transValidations('file.invalid_type_title'),
          message: (
            <>
              {transValidations('file.invalid_type_msg')}
              <ul className="intu__form-bullets">
                {validFileExtensions.map((e) => (
                  <li className="intu__form-bullets">{e}</li>
                ))}
              </ul>
            </>
          ),
          open: true,
        });
      } else {
        var reader = new FileReader();
        reader.onloadend = function () {
          setValues({
            ...values,
            company_banner: file,
            company_banner_blob: reader.result,
            company_banner_new: true,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  useEffect(() => {
    if (transition === true) {
      // FadeIn
      setIsVisible(transition);
    } else {
      // FadeOut
      setTimeout(() => setIsVisible(transition), transitionInterval);
    }
  }, [transition]);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={80} />
  ) : (
    <>
      {isVisible && (
        <>
          {fieldTitle && (
            <Fade in={transition} timeout={transitionInterval}>
              <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
                {fieldTitle}
              </Typography>
            </Fade>
          )}
          {fieldDescription && (
            <Fade in={transition} timeout={transitionInterval}>
              <Typography
                variant="body1"
                className="form-note"
                textAlign={{ xs: 'center', md: 'left' }}
                sx={{ marginBottom: '1rem' }}
              >
                {fieldDescription}
              </Typography>
            </Fade>
          )}

          <Grid
            container
            item
            disabled={disabled}
            xs={12}
            alignItems="center"
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => handleDrop(e, values)}
          >
            <label
              htmlFor="companyBanner"
              style={{
                display: 'inline-block',
                width: '100%',
              }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                id="companyBanner"
                onChange={(e) =>
                  handleCompanyBannerChange(e.target.files[0], values)
                }
              />
              <Tooltip title={transFields('label')}>
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    maxHeight: { xs: 233, md: '100%' },
                    minHeight: {
                      xs: '150px',
                      md: '300px',
                    },
                  }}
                  alt={transFields('alt')}
                  src={fieldValue || PlaceHolderBanner}
                />
              </Tooltip>
            </label>
          </Grid>
        </>
      )}
    </>
  );
};

export { CompanyBanner };
