import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import ResetChangePasswordForm from '../../components/account/forms/reset/ResetPasswordForm';
import IntuPage from '../../components/layout/IntuPage';
import './account.css';

function AccountChangePassword() {
  const { t } = useTranslation();
  return (
    <IntuPage>
      <IntuForm
        formTitle={t('context.account.changePasswordContext.title')}
        formDescription={t('context.account.changePasswordContext.body')}
        form="Change Password"
      >
        <ResetChangePasswordForm
          formType="accPassChange"
          buttonLabel={t('buttons.change_password')}
          buttonAction="accPassChange"
          showEmail={false}
          showDefinePassword={true}
          showOTP={false}
          showSigninButton={true}
        />
      </IntuForm>
    </IntuPage>
  );
}
export default AccountChangePassword;
