// TODO: only used in one place, move it there styledInput.js
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IntuIconButton = styled(IconButton)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  background: 'inherit',
  cursor: 'pointer',
}));
