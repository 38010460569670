import { axiosPrivate } from '../hooks/axios/axios';

const searchCompaniesByName = async (input) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = `/api/companies/company/name/${input}`;

  try {
    const request = await axiosPrivate.get(url, {
      signal,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    const { data, status, message, statusCode } = request?.data;

    return {
      status,
      statusCode,
      message,
      data,
    };
  } catch (error) {
    return error;
  }
};

const getCompanyDetails = async (id) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = `/api/companies/company/details/${id}`;

  try {
    const request = await axiosPrivate.get(url, {
      signal,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    const { data, status, message, statusCode } = request?.data;

    if (statusCode === 200) {
      return {
        status,
        statusCode,
        message,
        data,
      };
    } else {
    }
  } catch (error) {
    return error;
  }
};

export { searchCompaniesByName, getCompanyDetails };
