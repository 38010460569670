import React from 'react';
import { Container, Typography, Grid, Box, CardContent } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

const EventInfoContent = (props) => {
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="xl">
      <Container>
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent="space-between"
          // alignItems="center"
          alignItems="stretch"
        >
          {/* <Grid component="main" sx={{ width: '100%' }}> */}
          <Grid
            container
            direction="row"
            textAlign={{ xs: 'center', md: 'left' }}
            spacing={1}
          >
            {/* Event Title */}
            <Grid item xs={12}>
              <Typography variant="h1" color="primary">
                {ReactHtmlParser(props?.eventTitle)}
              </Typography>
            </Grid>

            {/* Event Subtitle */}
            <Grid item xs={12}>
              <Typography variant="h3" color="white">
                {ReactHtmlParser(props?.eventSubTitle)}
              </Typography>
            </Grid>

            {/* Event Info */}
            <Grid item xs={12}>
              <Typography variant="body" color="white">
                {ReactHtmlParser(props?.eventInfo)}
              </Typography>
            </Grid>

            {/* Event Agenda */}
            {props?.eventAgenda && (
              <Grid
                item
                xs={12}
                container
                textAlign={{ xs: 'center', md: 'left' }}
                sx={{ marginTop: 1 }}
              >
                {t('context.events.agenda', { returnObjects: true }).map(
                  (event, index) => {
                    return (
                      <Grid key={index} item xs={12}>
                        <Box
                          sx={{
                            width: '100%',
                            height: { xs: 130, lg: 100 },
                            marginTop: 2,
                            color: 'var(--color-card-text)',
                            backgroundColor: 'var(--color-card-background)',
                          }}
                        >
                          <CardContent>
                            <Typography
                              variant="h5"
                              component="div"
                              color="var(--intu-darkGrey)"
                            >
                              {event.time}
                            </Typography>
                            <Typography variant="h6" color="white">
                              {event.topic}
                            </Typography>
                          </CardContent>
                        </Box>
                      </Grid>
                    );
                  },
                )}
              </Grid>
            )}
          </Grid>
          {/* </Grid> */}
        </Grid>
      </Container>
    </Container>
  );
};

export default EventInfoContent;
