import React, { useContext, useEffect, useRef, useState } from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { TextField, CircularProgress, Skeleton, Button } from '@mui/material';
import axios from 'axios';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate';

const IndividualProfileField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'individual.profile_link', // Determines the Field ID
  linkedInFieldID = 'individual.linkedin', // ID of the Linkedin Profile Data ID (Required if showLinkedinButton is true)
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  showLinkedInButton = false, // Whether or not to show the LinkedIn SignIn Button
}) => {
  //   Field Reference
  const individualProfileRef = useRef();

  const axiosPrivate = useAxiosPrivate();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  // Translation
  const { t } = useTranslation('fields', {
    keyPrefix: 'account.individual.IndividualProfileField',
  });

  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');
  const { t: transValidations } = useTranslation('validation');

  const host_url = window.location.host;

  //   Formik Context
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateField,
    setFieldError,
  } = useFormikContext();

  // Fetching State
  const [isFetching, setIsFetching] = useState(false);
  const [isLinkedIn, setIsLinkedIn] = useState(false);
  const [linkedinButtonShowing, setLinkedInButtonShowing] =
    useState(showLinkedInButton);

  useEffect(() => {
    if (touched?.fieldID) {
      setIsFetching(true);
      validateField(fieldID).finally(() => {
        setIsFetching(false);
      });
    }
  }, [values?.fieldID]);

  const { isLoading } = useContext(ProcessingContext);

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  // handle linkedin signin
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_MEMBER_INFO_CLIENT_ID,
    scope: 'r_basicprofile',
    redirectUri: `${window.location.origin}/linkedin-callback`,
    onSuccess: async (authCode) => {
      const controller = new AbortController();
      const { signal } = controller;

      try {
        setIsFetching(true);

        const url = `/api/linkedin/profile/auth`;
        const params = {
          code: authCode,
          host_url: host_url,
        };

        // Fetch user profile info using the access token
        const getAccessToken = await axiosPrivate.get(url, { params });

        // login successful
        if (getAccessToken.status === 200) {
          const { data } = getAccessToken.data;
          const accessToken = data.accessToken;

          const url = `/api/linkedin/profile/get`;
          const params = {
            accessToken: accessToken,
          };

          const getLinkedInProfile = await axiosPrivate.get(url, { params });

          if (getLinkedInProfile.status === 200) {
            const { data } = getLinkedInProfile.data;

            setIsLinkedIn(true);
            setLinkedInButtonShowing(false);
            setFieldValue(
              fieldID,
              `https://linkedin.com/in/${data.vanityName}`,
            );

            const existingValues = linkedInFieldID
              .split('.')
              .reduce((acc, key) => acc && acc[key], values);
            setFieldValue(linkedInFieldID, {
              ...existingValues,
              ...data,
            });
            setTimeout(() => {
              setFieldTouched(fieldID, true, true);
            }, 100);
          }
        } else {
          console.log('Error while trying to get member profile details');
        }
      } catch (error) {
        console.log(error);
        handleRegularResponse({
          open: true,
          status: 'warning',
          message: transMessages('global.feature_not_available'),
        });
        setIsLinkedIn(false);
        setLinkedInButtonShowing(false);
      } finally {
        setIsFetching(false);
        controller.abort(signal);
      }
    },
    onError: (error) => {
      console.log('Error Authenticating: ', error);
    },
  });

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <>
      <FormikFieldWrapper
        fieldTitle={fieldTitle}
        fieldDescription={fieldDescription}
        transition={transition}
      >
        <TextField
          required={required}
          disabled={disabled || isLinkedIn}
          ref={individualProfileRef}
          autoComplete="off"
          id={fieldID}
          name={fieldID}
          className="form-select-field"
          variant="outlined"
          label={t('label')}
          placeholder={t('placeholder')}
          sx={{ paddingBottom: '50px' }}
          type="text"
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.value);
          }}
          onBlur={async (e) => {
            let input = e.target.value;
            const linkedinRegex =
              /^https:\/\/(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?$/;

            let urlSearch = setTimeout(async () => {
              setIsFetching(true);

              if (linkedinRegex.test(input)) {
                // LinkedIn URL structure validation
                if (!linkedinRegex.test(input)) {
                  setFieldError(e.target.name, transValidations('profile'));
                }
                setIsFetching(false);
                handleBlur(e);
              } else {
                // Regular URL API check
                try {
                  await axios.post(
                    '/api/verify/website',
                    { url: input },
                    {
                      headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                      },
                    },
                  );
                } catch (error) {
                  setTimeout(() => {
                    setFieldError(e.target.name, transValidations('website'));
                  });
                } finally {
                  clearTimeout(urlSearch);
                  setIsFetching(false);
                  handleBlur(e);
                }
              }
            }, 100);
          }}
          value={fieldValue}
          error={fieldError && fieldTouched ? true : false}
          helperText={fieldError && fieldTouched ? fieldError : null}
          InputProps={{
            endAdornment: isFetching ? <CircularProgress size={24} /> : null,
          }}
        />
      </FormikFieldWrapper>
      {linkedinButtonShowing && transition ? (
        <Button
          variant="contained"
          type="button"
          sx={{
            backgroundColor: (theme) => theme.palette.linkedIn.main,
            color: (theme) => theme.palette.linkedIn.contrastText,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.linkedIn.dark,
            },
          }}
          onClick={linkedInLogin}
          startIcon={
            isFetching ? (
              <CircularProgress size="1rem" />
            ) : (
              <LinkedInIcon sx={{ color: '#fff' }} />
            )
          }
        >
          {transButtons('get_linkedin_profile')}
        </Button>
      ) : null}
    </>
  );
};

export default IndividualProfileField;
