import { ColorPickerField } from '@in2tec/page-editor';
import slate, { pluginFactories } from '@in2tec/plugins-slate';
import React from 'react';

// Color Picker Icon
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';

const colorPickerPlugin = pluginFactories.createComponentPlugin({
  addHoverButton: true, // whether to show it above the text when selected
  addToolbarButton: true, // whether to show it in the bottom toolbar
  type: 'SetColor', // a well defined string, this is kind of the id of the plugin
  object: 'mark', // mark is like a span, other options are inline and block
  icon: <FormatColorTextIcon/>, // an icon to show
  label: 'Set Color',
  Component: props => <span {...props} />, // the component to render
  getStyle: ({ color }) => ({ color }),
  controls: {
    // identical to custom cell plugins
    type: 'autoform',
    schema: {
      type: 'object',
      required: ['color'],
      properties: {
        color: {
          uniforms: {
            component: ColorPickerField,
          },
          default: '#333333',
          type: 'string',
        },
      },
    },
  },
});

export const customSlate = slate((config) => ({
  ...config,
  plugins: {
    ...config.plugins,
    color: {
      colorPicker: colorPickerPlugin,
    }
  },
}));