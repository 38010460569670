import { useEffect } from 'react';
import { createContext, useContext, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Grid,
  Skeleton,
  createFilterOptions,
  Fade,
  InputAdornment,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import ClearIcon from '@mui/icons-material/Clear';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CompanyProfileContext } from '../../../context/providers/CompanyProfileProvider.jsx';
import ProcessingContext from '../../../context/providers/ProcessingProvider.jsx';
import { UserProfileContext } from '../../../context/providers/UserProfileProvider.jsx';
import { getNestedValue } from '../../../helpers/getNestedValuesIn.js';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider.jsx';
import { ChangeCompanyAssociationDialog } from './changeCompany/ChangeCompanyAssociationDialog.jsx';

export const CompanyNameFieldContext = createContext({});

const IndividualCompanyNameField = ({
  fieldID = 'company.name', // Fetermine the Name and ID of the Field
  autoCompleteID = 'company',
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  transition = true, // Transition of the Field (true = fade in, false = fadeout)
  disabled = false, // Whether the field is disabled
  required = false, // Whether the field is required
  variant = 'outlined'
}) => {
  // Translation
  const { t } = useTranslation('fields', {
    keyPrefix: 'account.individual.CompanyNameField',
  });

  const { values, errors, touched } = useFormikContext();

  const { companyOptions } = useContext(CompanyProfileContext);

  const { setChangeCompanyDialog, userProfile, updateCompanyAssociation } =
    useContext(UserProfileContext);

  const { isLoading } = useContext(ProcessingContext);

  const companySearchRef = useRef();

  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  // console.log('Autocomplete Value: ', autoCompleteValue);

  const { transitionInterval } = useContext(IntuThemeContext);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (transition) {
      // FadeIn
      setIsVisible(transition);
    } else {
      // FadeOut
      setTimeout(() => setIsVisible(transition), transitionInterval);
    }
  }, [transition]);

  const filter = createFilterOptions();

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <>
      {isVisible && (
        <>
          {fieldTitle && (
            <Fade in={transition} timeout={transitionInterval}>
              <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
                {fieldTitle}
              </Typography>
            </Fade>
          )}
          {fieldDescription && (
            <Fade in={transition} timeout={transitionInterval}>
              <Typography
                variant="body1"
                className="form-note"
                textAlign={{ xs: 'center', md: 'left' }}
                sx={{ marginBottom: '1rem' }}
              >
                {fieldDescription}
              </Typography>
            </Fade>
          )}
          <Fade in={transition} timeout={transitionInterval}>
            <Autocomplete
              freeSolo
              disabled={disabled}
              required={required}
              autoComplete
              clearIcon={<ClearIcon sx={{ color: 'var(--intu-lightGrey)' }} />}
              id={fieldID}
              name={fieldID}
              ref={companySearchRef}
              className="form-select-field"
              sx={{ marginRight: '10px' }}
              options={companyOptions}
              getOptionLabel={(option) => option?.name || ''}
              value={values?.company}
              handleHomeEndKeys
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          width: 44,
                        }}
                      >
                        <BusinessIcon sx={{ color: 'text.secondary' }} />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: 'calc(100% - 44px)',
                          wordWrap: 'break-word',
                        }}
                      >
                        <Box key={option?.name} component="span">
                          {option?.name}
                        </Box>
                        {option.address && (
                          <Typography variant="body2" color="text.secondary">
                            {option?.address?.city}, {option?.address?.state},{' '}
                            {option?.address?.country?.label}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                  filtered.push({
                    // inputValue: params.inputValue,
                    name: `${t('add')} "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={disabled}
                  variant={variant}
                  label={t('label')}
                  placeholder={t('placeholder')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IntuIconButton
                          variant="outlined"
                          tooltipTitle="link"
                          tooltipType="company"
                          type="connect"
                          onClick={() => {
                            setChangeCompanyDialog(true);
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  error={fieldTouched?.name && fieldError?.name ? true : false}
                  helperText={
                    fieldTouched?.name && fieldError?.name
                      ? fieldError?.name
                      : null
                  }
                />
              )}
            />
          </Fade>
          <ChangeCompanyAssociationDialog />
        </>
      )}
    </>
  );
};

export default IndividualCompanyNameField;
