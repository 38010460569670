import { LeadProvider } from '../../../context/providers/leads/LeadContextProvider.jsx';
import { ManufacturerHomeContent } from './ManufacturerHomeContent.jsx';

function ManufacturerHome() {
  return (
    <LeadProvider>
      <ManufacturerHomeContent />
    </LeadProvider>
  );
}

export default ManufacturerHome;
