import * as Yup from 'yup';
import i18next from 'i18next';

export const createChangeUserPasswordValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    userPassExisting: Yup.string().required(t('password.required')),
    userPassNew: Yup.string()
      .required(t('password.required'))
      .min(8, t('password.short'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
        t('password.matches'),
      ),
    userPassConf: Yup.string()
      .required(t('password.comf_required'))
      .oneOf([Yup.ref('userPassNew'), null], t('password.comf_match')),
  });
};
