import * as am5 from '@amcharts/amcharts5';

const intuMiddleGrey = '#6F7978';
const intuLightGrey = '#ededed';
const intuDarkGreen = '#A8C957';
const intuLightPurple = '#e8d46f';
const intuOrange = '#d76446';
const intuLightYellow = '#e8d46f';
const intuLightBlue = '#6ea4d4';

class am5Themes_IntuTheme extends am5.Theme {
  setupDefaultRules() {
    // Example rule: setting the default color and size for all Label elements.
    this.rule('Label').setAll({
      fontSize: 14,
    });

    // Pie Chart Colors
    let pieChartColors = am5.ColorSet.new(this._root, {
      baseColor: intuDarkGreen,
      colors: [
        am5.color(intuDarkGreen),
        // am5.color(intuMiddleGrey)
        // ... more colors
      ],
      passOptions: { saturation: 0.09, lightness: 0.1 }, // Adjust color generation step options
    });

    // Apply custom colors to pie chart's series
    this.rule('PieSeries').setAll({
      colors: pieChartColors,
    });

    // Pie Chart Colors
    let forceDirectedColors = am5.ColorSet.new(this._root, {
      baseColor: intuDarkGreen,
      colors: [
        am5.color(intuDarkGreen),
        am5.color(intuMiddleGrey),
        am5.color(intuLightPurple),
        am5.color(intuOrange),
        am5.color(intuLightBlue),
        am5.color(intuLightYellow),
      ],
      passOptions: { saturation: 0.09, lightness: 0.1 }, // Adjust color generation step options
    });

    // Apply custom colors to force diagram's series
    this.rule('ForceDirected').setAll({
      colors: forceDirectedColors,
    });

    // Apply custom colors to force diagram's series
    this.rule('Tree').setAll({
      colors: forceDirectedColors,
    });

    // INTU Bar Chart Colors
    let barChartColors = am5.ColorSet.new(this._root, {
      baseColor: intuDarkGreen,
      colors: [am5.color(intuMiddleGrey), am5.color(intuDarkGreen)],
    });

    this.rule('XYChart').setAll({
      colors: barChartColors,
    });

    // INTU Bar Chart Colors
    let mapChartColors = am5.ColorSet.new(this._root, {
      baseColor: intuDarkGreen,
      colors: [am5.color(intuMiddleGrey), am5.color(intuDarkGreen)],
    });

    this.rule('MapChart').setAll({
      colors: mapChartColors,
    });
  }
}

export default am5Themes_IntuTheme;
