import axios from 'axios';

const geoCodeAddress = async (param) => {
  // Guard Clause
  let req_fields = [];

  !param && req_fields.push(`Payload missing`);
  let street = param?.street
    ? param.street
    : req_fields.push('Address Line 1 missing');
  let postcode = param?.postcode
    ? param.postcode
    : req_fields.push('Postcode missing');
  let state = param?.state ? param.state : req_fields.push('State missing');
  let country = param?.country
    ? param.country
    : req_fields.push('Country missing');

  if (req_fields.length > 0) {
    return {
      status: 'error',
      statusCode: 251,
      message: `Required Fields Missing: ${JSON.stringify(req_fields)}`,
    };
  }

  let payload = {
    street,
    postcode,
    state,
    country,
  };

  let url = '/api/mapbox/geocode-address';

  const controller = new AbortController();
  const signal = controller.signal;

  try {
    const request = await axios.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    const response = request?.data;

    if (request.status === 200) {
      return {
        status: response.status,
        statusCode: response.statusCode,
        message: response.message,
        location: response.data,
      };
    } else {
      return response;
    }
  } catch (err) {
    return err;
  } finally {
    controller.abort(signal);
  }
};

export { geoCodeAddress };
