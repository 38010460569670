import React from 'react';

import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n.js';
import { useNavigate } from 'react-router-dom';

import { ParallaxZoomOut } from '../../../components/motion/ParallaxZoomOut.jsx';
import { ScrollingCards } from '../../../components/motion/ScrollingCards.jsx';
import { ScrollingTextStickyImage } from '../../../components/motion/ScrollingTextStickyImage.jsx';
import { RiveAnimationComponent } from '../../../components/animations/RiveAnimationComponent.jsx';
import { SimpleZoomInOutcomponent } from '../../../components/motion/SimpleZoomInOut.jsx';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { useContext, useEffect } from 'react';
import { LayoutBodyContext } from '../../../context/providers/LayoutContextProvider.jsx';
import { VideoPlayer } from '../../../plugins/VideoPlayer.js';
import HowItWorksSignUpForm from '../../../components/howitworks/HowItWorksSignUpForm.jsx';

// Content
import { HeaderContent } from '../HeaderContent.jsx';
import { FindManufacturersContent } from './FindManufacturersContent.jsx';
import { OpportunitiesContent } from './OpportunitiesContent.jsx';
import { DashboardContent } from './DashboardContent.jsx';
import { CardFooter, CardHeader } from './CardContent.jsx';
import { ScrollingText } from './ScrollingText.jsx';

const SalesHomeContent = () => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const medium = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home.SalesHome',
  });
  const { t: transButtons } = useTranslation('buttons');
  const navigate = useNavigate();

  const { setLayoutTitle } = useContext(LayoutBodyContext);

  useEffect(() => {
    setLayoutTitle(t('title'));
  }, []);

  const params = new URLSearchParams(window.location.search);
  const fName = params.get('fName');
  const lName = params.get('lName');
  const cName = params.get('cName');

  const srcArray = [
    {
      type: 'image',
      src: small
        ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/SalesFindBrandsMobile.png'
        : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/SalesFindBrandsDesktop.png',
      title: t('ParallaxZoomInOut2.title'),
      description: t('ParallaxZoomInOut2.description'),
      footerContent: <FindManufacturersContent />,
      stickyVal: 0.75,
      titleMT: '20%',
      titleMB: small ? '0' : '10%',
    },
    {
      type: 'image',
      src: small
        ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/SalesDashboardMobile.png'
        : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/SalesDashboardDesktop.png',
      title: t('ParallaxZoomInOut1.title'),
      description: t('ParallaxZoomInOut1.description'),
      progress: [0.5, 0.8, 1],
      zoom: [0, 0.5, 1],
      footerContent: <DashboardContent />,
      stickyVal: 0.75,
      titleMT: '20%',
      titleMB: small ? '0' : '10%',
    },
    {
      type: 'animation',
      src: small
        ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/SalesDashboardMobile.riv'
        : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/SalesDashboard.riv',
      stateMachines: 'State Machine 1',
      width: small ? '350px' : '800px',
      height: small ? '650px' : '800px',
      title: t('Dashboard.title'),
      description: t('Dashboard.description'),
      progress: [0.5, 0.8, 1],
      zoom: [0, 0.5, 1],
      stickyVal: 0.85,
    },
  ];

  const StickyImages = [
    {
      type: 'animation',
      src: (
        <RiveAnimationComponent
          src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/promote.riv"
          stateMachines="State Machine 1"
          width={small ? '300px' : '400px'}
          height={small ? '300px' : '400px'}
        />
      ),
    },
    {
      type: 'animation',
      src: (
        <RiveAnimationComponent
          src={
            small
              ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/SalesDashboardMobile.riv'
              : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/SalesDashboard.riv'
          }
          stateMachines="State Machine 1"
          width={small ? '350px' : '400px'}
          height={small ? '650px' : '400px'}
          marginBottom="25vh"
        />
      ),
    },
    {
      type: 'animation',
      src: (
        <RiveAnimationComponent
          src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/Enjoy_Adjustable_Hours.riv"
          stateMachines="State Machine 1"
          width={small ? '300px' : '400px'}
          height={small ? '300px' : '400px'}
        />
      ),
    },
  ];

  const SalesDashboard = [
    {
      type: 'animation',
      src: 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/SalesDashboardMobile.riv',
      stickyVal: 0.99,
      width: '350px',
      height: '408px',
      stateMachines: 'State Machine 1',
      title: t('Dashboard.title'),
      description: t('Dashboard.description'),
    },
  ];

  const CardsContent = [
    <TouchAppIcon sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }} />,
    <MenuBookIcon sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }} />,
    <EditNoteIcon sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }} />,
    <AutoGraphIcon sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }} />,
  ].map((icon, index) => {
    return (
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent={{ xs: 'space-around', md: 'center' }}
        sx={{ height: '100%' }}
      >
        <Grid
          item
          xs={6}
          container
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          sx={{ height: '65%', width: '90%' }}
        >
          {/* Header */}
          <Grid item xs={1}>
            <Typography
              sx={{ fontSize: { xs: 35, md: 75 }, color: '#A8C957' }}
              align="left"
              variant="h6"
            >
              {index + 1}
            </Typography>
          </Grid>

          {/* Icon & Label */}
          <Grid item xs={4} container direction="column" alignItems="center">
            <Grid item>{icon}</Grid>
            <Grid item>
              <Typography variant="h5" align="center" sx={{ color: '#4d4d4d' }}>
                {i18n.t(`pages.home.SalesHome.CardsContent.title${index + 1}`)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant={small ? 'body1' : 'h5'}
            sx={{ color: '#4d4d4d' }}
            align="center"
          >
            {i18n.t(
              `pages.home.SalesHome.CardsContent.description${index + 1}`,
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      {/* Hero */}
      <Container maxWidth="auto" disableGutters sx={{ position: 'relative' }}>
        <ParallaxZoomOut
          image={
            small
              ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/SalesDashboardMobile.png'
              : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/SalesDashboardDesktop.png'
          }
          overlay={{
            title: t('ParallaxZoomOut.title', {
              name: cName
                ? `${cName} `
                : fName && lName
                  ? `${fName} ${lName} `
                  : fName
                    ? `${fName} `
                    : '',
            }),
          }}
          content={<HeaderContent />}
        />
      </Container>
      {/* Content */}
      <Grid>
        {/* Zoom In Zoom Out Compoennt */}
        <Grid item xs={12}>
          <SimpleZoomInOutcomponent srcArray={srcArray} />
        </Grid>
        {/* Dashboard */}

        {/* <Grid item xs={12} container justifyContent="center">
          <Grid item xs={8}>
            <SimpleZoomInOutcomponent srcArray={SalesDashboard} />
          </Grid>
        </Grid> */}

        {/* Opportunity */}
        <Grid item xs={12} sx={{ marginBottom: '2rem' }}>
          <OpportunitiesContent />
        </Grid>

        {/* Explainer Video */}
        <Grid
          item
          xs={12}
          container
          direction="column"
          sx={{ marginBottom: '5rem' }}
        >
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h1">{t('Video.title')}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            sx={{ marginBottom: '3rem' }}
          >
            <Typography variant="body1">{t('Video.description')}</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <VideoPlayer
                videoSource="https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/sales_explainer_en.mp4"
                controls
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Scrolling Cards */}
        <Grid item xs={12}>
          <ScrollingCards
            contentArr={CardsContent}
            header={<CardHeader />}
            footer={<CardFooter />}
          />
        </Grid>

        {/* Scrolling Image */}
        <Grid item xs={12} sx={{ marginTop: '25rem' }}>
          <ScrollingTextStickyImage
            imgArr={StickyImages}
            textArr={ScrollingText}
          />
        </Grid>
        {/* SignUp */}
        <Box
          sx={{
            width: { xs: '80%', sm: '80%', md: '60%' },
            margin: '0 auto',
            paddingBottom: { xs: '150px', sm: '150px', md: '100px' },
          }}
        >
          <HowItWorksSignUpForm
            initialStep={1}
            initialType="salesrep"
            title={t('HowItWorks.title')}
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography my={5}>{t('HowItWorks.not_convinced')}</Typography>
            <Button variant="outlined" onClick={() => navigate('/contact')}>
              {transButtons('navigation.contact_us')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export { SalesHomeContent };
