import React, { useRef, useLayoutEffect, useEffect } from "react";

// HTML Parser
import ReactHtmlParser from "react-html-parser";

// AM5
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5Themes_IntuTheme from "./theme";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { Grid, Typography } from "@mui/material";

const ForceDirectedTreeContext = ({
    mobileView,
    chartId,
    description,
    descriptionColor,
    data
}) => {

    useEffect(() => {

        // Guard Clause
        if (!chartId) {
            return
        }
        
        let root = am5.Root.new(chartId);


        // Set themes

        // Default Color
        const intuDarkGreen = "#A8C957";

        root.setThemes([
            am5themes_Animated.new(root),
            am5Themes_IntuTheme.new(root),
        ]);


        // Create wrapper container
        let container = root.container.children.push(am5.Container.new(root, {
            width: am5.percent(100),
            height: am5.percent(100),
            layout: root.verticalLayout
        }));


        // Create series
        // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
        let series = container.children.push(am5hierarchy.ForceDirected.new(root, {
            singleBranchOnly: false,
            initialDepth: 2,
            valueField: "value",
            fillField: "color",
            categoryField: "name",
            childDataField: "children",
            xField: "x",
            yField: "y",
            centerStrength: 0.3,
            minRadius: mobileView ? 20 : 30,
            maxRadius: mobileView ? 60 : 100,
            nodePadding: 10

        }));


        series.nodes.template.setup = function (target) {
            target.events.on("dataitemchanged", function (ev) {
                const dataContext = ev.target.dataItem.dataContext;

                target.children.push(am5.Picture.new(root, {
                    width: mobileView ? 40 : 70,
                    height: mobileView ? 40 : 70,
                    centerX: am5.percent(50),
                    centerY: am5.percent(50),
                    src: ev.target.dataItem.dataContext.image
                }));

                // SetUp Tooltip Text
                target.set("tooltipText", "{dataContext.context || dataContext.name}");
                target.set("interactive", true);  // Ensure the element can trigger interactions like tooltips;
                target.set("valign", "bottom");

                // Make Nodes non-draggable if option is provided
                // Default to true if 'draggable' is not provided
                const isDraggable = ev.target.dataItem.dataContext?.draggable ? ev.target.dataItem.dataContext.draggable : true;
                target.set("draggable", isDraggable);
            });


            target.adapters.add("tooltipText", function (text, target) {
                let context = target.dataItem.dataContext.context;
                return context ? context : target.dataItem.dataContext.name;
            });

            let tooltip = am5.Tooltip.new(root, {
                getFillFromSprite: false,
                labelText: "[bold]{name}[/]\n{context}"
            });

            tooltip.get("background").setAll({
                fill: am5.color(intuDarkGreen),
                fillOpacity: 0.8
            });

            // Conditionally displaying labels based on presence of image
            series.labels.template.adapters.add("forceHidden", function (forceHidden, target) {
                // Determine whether the data item has an image
                const hasImage = target.dataItem.dataContext.image;
                // Hide label if there's an image
                return hasImage ? true : forceHidden;
            });

            // Adapter for changing fill color conditionally
            series.circles.template.adapters.add("fill", function (fill, target) {
                // Check if the data context has an image
                if (target.dataItem.dataContext.image) {
                    return "transparent";
                }
                return fill;  // Use the default/original fill if no image is present
            });

            // Adapter for changing fill color conditionally
            series.circles.template.adapters.add("stroke", function (fill, target) {
                // Check if the data context has an image
                if (target.dataItem.dataContext.image) {
                    return "transparent";
                }
                return fill;  // Use the default/original fill if no image is present
            });


            // Hide Background if image is present
            // series.circles.template.set("fill", "transparent");

            series.set("tooltip", tooltip);
        }
        series.data.setAll(data);
        series.set("selectedDataItem", series.dataItems[0]);

        // Make stuff animate on load
        series.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [])

    return (
        // Chart Component
        <>
            <Grid item xs={12}>
                <Typography align="center" variant="body2" color={descriptionColor ? descriptionColor : null}>{ReactHtmlParser(description)}</Typography>
            </Grid>
            <Grid
                item
                xs={12}
                id={chartId}
                style={{
                    width: '100%',
                    minHeight: mobileView ? '350px' : '600px',
                    margin: '0 30px 0 0px',
                }}
            />
        </>
    )
}

export { ForceDirectedTreeContext }