import React, { useContext } from 'react';
import { Grid, Avatar, Divider, Typography } from '@mui/material';
import BrandsInfiniteSliderContainer from '../../../containers/brandsInfiniteSliderContainer/BrandsInfiniteSliderContainer';
import { useIntuTheme } from '../../../context/providers/IntuThemeProvider';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';
import i18n from 'i18next';

// Icons
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { ButtonHoverGradiant } from '../../../components/buttons/ButtonHoverGradiant';
import { useNavigate } from 'react-router-dom';

const IntroSalesReps = () => {
  const { mode } = useIntuTheme();
  const { leadInfo } = useContext(LeadContext);

  // Navigate
  const navigate = useNavigate();

  // Sales Icon
  const salesIcons = [
    <LocalAtmIcon sx={{ fontSize: 148, color: '#fff' }} />,
    <GroupAddIcon sx={{ fontSize: 124, color: '#fff', marginLeft: '25px' }} />,
    <TipsAndUpdatesIcon
      sx={{ fontSize: 124, color: '#fff', marginLeft: '30px' }}
    />,
  ];

  // JoinNow Button
  const JoinNow = () => {
    return (
      <ButtonHoverGradiant
        label={i18n.t('join_now', { ns: 'buttons' })}
        onClick={() => navigate('/account/signup')}
      />
    );
  };

  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.lead_context',
  });

  return (
    <Grid
      container
      sx={{ padding: '50px 20px 0 20px' }}
      direction="column"
      item
    >
      <Grid container item xs={12} justifyContent="center">
        <Grid item>
          <Typography
            variant="h2"
            align="center"
            sx={{
              color: mode === 'dark' ? 'white' : 'black',
              fontWeight: '600',
            }}
          >
            {t('sales.intro.title')}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ padding: '20px 0' }}>
          <Divider variant="middle" />
        </Grid>
        <Grid container item xs={10}>
          <Typography variant="h5" align="center">
            {parse(leadInfo.headline_body)}
          </Typography>
        </Grid>
      </Grid>
      {/* Columns */}
      <Grid item container xs={12} sx={{ paddingTop: '50px' }}>
        <Grid
          item
          container
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="space-around"
        >
          {salesIcons.map((icon, index) => {
            return (
              <Grid
                item
                container
                direction="column"
                xs={3}
                alignItems="center"
                key={index}
                mb={10}
              >
                <Grid item>
                  <Avatar
                    sx={{
                      height: '200px',
                      width: '200px',
                      backgroundColor: '#A8C957',
                    }}
                    sizes="large"
                  >
                    {icon}
                  </Avatar>
                </Grid>
                <Grid item mt={5}>
                  <Typography variant="h5">
                    {t(`sales.intro.point${index + 1}_title`)}
                  </Typography>
                </Grid>
                <Grid item mt={5}>
                  <Typography variant="body1" align="center">
                    {t(`sales.intro.point${index + 1}_desc`)}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item>
        {/* Call to Action */}
        <JoinNow />
      </Grid>
      <Grid item>
        <BrandsInfiniteSliderContainer />
      </Grid>
    </Grid>
  );
};

export { IntroSalesReps };
