import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import IntuPage from '../../components/layout/IntuPage';
import { RiveAnimationComponent } from '../../components/animations/RiveAnimationComponent';
import { useTranslation } from 'react-i18next';
import { LayoutBodyContext } from '../../context/providers/LayoutContextProvider';

const MissingPage = () => {
  // navigate
  const navigate = useNavigate();

  const { setLayoutTitle } = useContext(LayoutBodyContext);

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.Missing',
  });

  useEffect(() => {
    // Set the Page Title
    setLayoutTitle(t('title'));
  }, []);

  return (
    <IntuPage vCentered={true} hCentered={true}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        className="intu__section intu__page_content section__padding-content"
      >
        <Grid
          item
          container
          xs={12}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          textAlign="center"
        >
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '5rem' }}>404</Typography>
            </Grid>

            <RiveAnimationComponent
              src={
                'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/404_page.riv'
              }
              stateMachines="SM_ComingSoon"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2">{t('title')}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: '20px' }}>
            <Typography variant="body1">{t('body')}</Typography>
          </Grid>

          <Button
            variant="contained"
            type="submit"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('button')}
          </Button>
        </Grid>
      </Grid>
    </IntuPage>
  );
};

export default MissingPage;
