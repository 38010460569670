// THE USER INFO PROVIDER HOLDS PUBLIC USER INFORMATION e.g.
// - Ip Addres
// - Cookie Settings
// - Location Details
// - Screen Parameters (mobileView)
// - Language Details
//
// ONLY ADD Features and functions which do not involve personal user information

import React, { createContext, useState, useEffect } from 'react';
import axios from '../../hooks/axios/axios';
import { v4 as uuid } from 'uuid';
import { getCookieConsentValue } from 'react-cookie-consent';
import countryList from '../../data/CountryData.json';
import i18n from '../../i18n';
import { initGA } from '../../ga-utils';
import LanguagesData from '../../data/LanguagesData.json';

const UserContext = createContext({});

//Get Current Users Position for Address Search
async function getCoordinates() {
  const countryOptions = countryList;

  return await axios
    .get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IP_INFO_TOKEN}`)
    .then((response) => {
      const countryDetails = countryOptions.filter(
        (country) => country.code === response.data.country,
      );
      const locationDetails = {
        dialCode: '+' + countryDetails[0].dialCode,
        countryLabel: countryDetails[0].label,
      };

      let locationData = response.data.loc;
      let positionArray = locationData.split(',');
      let location = {
        longitude: positionArray[1],
        latitude: positionArray[0],
        ip: response.data.ip,
        city: response.data.city,
        state: response.data.region,
        country: response.data.country,
        countryLabel: locationDetails?.countryLabel,
        postcode: response.data.postal,
        dialCode: `+${locationDetails?.dialCode}`,
        countryCallingCode: locationDetails?.dialCode,
      };

      return location;
    })
    .catch((e) => {
      // console.log(e);
      return {
        longitude: '74.0060',
        latitude: '40.7128',
        ip: '8.8.8.8',
        city: 'New York',
        state: 'NY',
        country: 'US',
        countryLabel: 'United States',
        dialCode: '+1',
        postcode: '10014',
        countryCallingCode: '1',
      };
    });
}

const getUserCookieSettings = () => {
  // check if cookie settings exist
  let cookieSettings = getCookieConsentValue('intu_cookie_settings');
  let cookieObject = { auth: true, exp: true, trck: true };

  if (!cookieSettings) {
    return cookieObject;
  } else {
    // convet it to JSON Object
    return (cookieObject = cookieSettings.split(';').reduce((res, c) => {
      const [key, val] = c.trim().split('=').map(decodeURIComponent);
      const allNumbers = (str) => /^\d+$/.test(str);
      try {
        return Object.assign(res, {
          [key]: allNumbers(val) ? val : JSON.parse(val),
        });
      } catch (e) {
        return Object.assign(res, { [key]: val });
      }
    }, {}));
  }
};

export const UserProvider = ({ children }) => {
  const userCookieSettings = getUserCookieSettings();

  const [languages, setLanguages] = useState(LanguagesData);
  const [cookieSettings, setCookieSettings] = useState(userCookieSettings);
  const [user, setUser] = useState({
    location: {
      longitude: '',
      latitude: '',
      ip: '',
      city: '',
      state: '',
      country: '',
      countryLabel: '',
      dialCode: '',
      postcode: '',
      countryCallingCode: '',
    },
    sessionID: '',
    cookieSettings: {
      auth: true,
      base: false,
      ext: true,
    },
    firstName: null,
    lastName: null,
  });

  const sessionID = uuid();

  const [lng, setLng] = useState(null);
  const host_url = window.location.host;

  const handleLanguageChange = (code) => {
    i18n.changeLanguage(code);
    setLng(code);
  };

  if (userCookieSettings.trck) {
    initGA();
  }

  // Preload User Info
  useEffect(() => {
    // Get User Coordinates
    getCoordinates()
      .then((location) => {
        setUser({ location, sessionID, cookieSettings });

        // DEATCIVATED TEMPORARELY UNTIL TRANSLATIONS ARE COMPLETE

        if (process.env.REACT_APP_ENV === 'development') {
          setLng(location.country);
        }
      })
      .catch((err) => {
        console.log(err.message);
        let location = { longitude: '', latitude: '' };
        setUser({ location, sessionID, cookieSettings });
      });
  }, []);

  return (
    <UserContext.Provider
      value={{
        host_url,
        user,
        setUser,
        cookieSettings,
        setCookieSettings,
        languages,
        setLanguages,
        lng,
        setLng,
        handleLanguageChange,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
