import { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import Globe from 'react-globe.gl';
import countryJSON from './countries.geojson';
import cityJSON from './cities.geojson';

const originCities = [
  // MILAN
  [9.1824, 45.4685],
  // BARCELONA
  [2.1686, 41.3874],
  // ROTTERDAM
  [4.4777, 51.9244],
  // MUNICH
  [11.582, 48.1351],
];

const destinationCities = [
  // NYC
  [-74.006, 40.7128, 'na'],
  // LA
  [-118.2426, 34.0549, 'na'],
  // CINCINNATI
  [-84.512, 39.1031, 'na'],
  // HOUSTON
  [-95.3701, 29.7601, 'na'],
  // DENVER
  [-104.9903, 39.7392, 'na'],
  // SEATTLE
  [-122.3328, 47.6061, 'na'],
  // CHARLOTTE
  // [-80.8431, 35.2271],
  // OKC
  [-97.5164, 35.4676, 'na'],
  // ALBUQUERQUE
  [-104.6404, 35.0844, 'na'],
  // TORONTO
  [-79.3832, 43.6523, 'na'],
  // EDMONTON
  [-113.4937, 53.5461, 'na'],
  // MEXICO CITY
  [-99.1332, 19.4326, 'na'],
  // TORONTO
  [-79.3832, 43.6523, 'na'],
  // EDMONTON
  [-113.4937, 53.5461, 'na'],
  // MEXICO CITY
  [-99.1332, 19.4326, 'na'],
  // TOKYO
  [139.65, 35.6764, 'japan'],
  // SEOUL
  [126.9918, 37.5519, 'korea'],
  // TAIPEI
  [121.5654, 25.033, 'taiwan'],
  // SHENZHEN
  [114.0596, 22.5429, 'china'],
  // DUBAI
  [55.2708, 25.2048, 'me'],
  // SAO PAULO
  [-46.6396, -23.5558, 'sa'],
  // BUENOS AIRES
  [-58.3816, -34.6037, 'sa'],
  // SANTIAGO
  [-70.6693, -33.4489, 'sa'],
  // MUMBAI
  [72.8777, 19.076, 'india'],
  // SINGAPORE
  [103.8198, 1.3521, 'sea'],
];

function shuffleArray(array) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

export const GlobeHero = ({ children }) => {
  const globeRef = useRef(null);
  const globeContainerRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [countries, setCountries] = useState({ features: [] });
  const [places, setPlaces] = useState([]);
  const arcs = [];
  const colors = ['#E06A58', '#588AE0', '#57C9B1', '#C9B157'];

  const shuffledCities = shuffleArray(destinationCities);

  // Connect origin cities with destination cities
  useEffect(() => {
    let cityIndex = 0;
    originCities.forEach((city, index) => {
      for (let i = 0; i < 6; i++) {
        const targetCityIndex = cityIndex % shuffledCities.length;
        arcs.push({
          startLat: city[1],
          startLng: city[0],
          endLat: shuffledCities[targetCityIndex][1],
          endLng: shuffledCities[targetCityIndex][0],
          color: colors[index],
          group: 1,
          dashAnimateTime: 2000,
          initialDashGap: 0,
        });
        shuffledCities[targetCityIndex].push(colors[index]);
        cityIndex++;
      }
    });

    // Connect destination cities with random points
    shuffledCities.forEach((city) => {
      for (let i = 0; i < 9; i++) {
        // North America
        if (city[2] === 'na') {
          if (city[0] < -81 && city[1] > 20) {
            // NE
            arcs.push({
              startLat: city[1],
              startLng: city[0],
              endLat: city[1] + Math.random() * i,
              endLng: city[0] + Math.random() * i,
              color: city[3],
              group: 2,
              dashAnimateTime: 2000,
              initialDashGap: 3,
              transitionDuration: 1000,
            });

            if (city[1] > 35) {
              // SE
              arcs.push({
                startLat: city[1],
                startLng: city[0],
                endLat: city[1] - Math.random() * i,
                endLng: city[0] + Math.random() * i,
                color: city[3],
                group: 2,
                dashAnimateTime: 2000,
                initialDashGap: 3,
              });
            }
          }

          if (city[0] > -118) {
            // NW
            arcs.push({
              startLat: city[1],
              startLng: city[0],
              endLat: city[1] + Math.random() * i,
              endLng: city[0] - Math.random() * i,
              color: city[3],
              group: 2,
              dashAnimateTime: 2000,
              initialDashGap: 3,
            });

            if (city[1] > 35) {
              // SW
              arcs.push({
                startLat: city[1],
                startLng: city[0],
                endLat: city[1] - Math.random() * i,
                endLng: city[0] - Math.random() * i,
                color: city[3],
                group: 2,
                dashAnimateTime: 2000,
                initialDashGap: 3,
              });
            }
          }
        }
        // Japan
        if (city[2] === 'japan') {
          // WEST
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1],
            endLng: city[0] - (Math.random() * i) / 2,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
          });
          // NORTH
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] + (Math.random() * i) / 2,
            endLng: city[0],
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
          });
        }
        // Korea
        if (city[2] === 'korea') {
          // SW
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] - (Math.random() * i) / 3,
            endLng: city[0] + (Math.random() * i) / 3,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
          });
        }
        // Taiwan
        if (city[2] === 'taiwan') {
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] - (Math.random() * i) / 3,
            endLng: city[0] - (Math.random() * i) / 10,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
          });
        }
        // China
        if (city[2] === 'china') {
          // NW
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] + Math.random() * i,
            endLng: city[0] - Math.random() * i,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
          });
        }
        // India
        if (city[2] === 'india') {
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] + Math.random() * i,
            endLng: city[0] + Math.random() * i,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
            transitionDuration: 1000,
          });

          // SE
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] - Math.random() * i,
            endLng: city[0] + Math.random() * i,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
          });
        }
        // Southeast Asia
        if (city[2] === 'sea') {
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] + (Math.random() * i) / 2,
            endLng: city[0] - Math.random() * i,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
            transitionDuration: 1000,
          });

          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] - (Math.random() * i) / 2,
            endLng: city[0] - Math.random() * i,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
          });
        }
        // Middle East
        if (city[2] === 'me') {
          // NE
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] + Math.random() * i,
            endLng: city[0] + (Math.random() * i) / 5,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
            transitionDuration: 1000,
          });

          // SE
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] - Math.random() * i,
            endLng: city[0] + (Math.random() * i) / 5,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
          });

          // SW
          arcs.push({
            startLat: city[1],
            startLng: city[0],
            endLat: city[1] - (Math.random() * i) / 2,
            endLng: city[0] - Math.random() * i,
            color: city[3],
            group: 2,
            dashAnimateTime: 2000,
            initialDashGap: 3,
          });
        }
        // South America
        if (city[2] === 'sa') {
          if (city[0] > -71 && city[0] < -70) {
            // NE
            arcs.push({
              startLat: city[1],
              startLng: city[0],
              endLat: city[1] + Math.random() * i,
              endLng: city[0] + Math.random() * i,
              color: city[3],
              group: 2,
              dashAnimateTime: 2000,
              initialDashGap: 3,
              transitionDuration: 1000,
            });
            // SE
            arcs.push({
              startLat: city[1],
              startLng: city[0],
              endLat: city[1] - Math.random() * i,
              endLng: city[0] + Math.random() * i,
              color: city[3],
              group: 2,
              dashAnimateTime: 2000,
              initialDashGap: 3,
            });
          }
          if (city[0] > -70) {
            // NW
            arcs.push({
              startLat: city[1],
              startLng: city[0],
              endLat: city[1] + Math.random() * i,
              endLng: city[0] - (Math.random() * i) / 5,
              color: city[3],
              group: 2,
              dashAnimateTime: 2000,
              initialDashGap: 3,
            });

            // SW
            arcs.push({
              startLat: city[1],
              startLng: city[0],
              endLat: city[1] - (Math.random() * i) / 5,
              endLng: city[0] - Math.random() * i,
              color: city[3],
              group: 2,
              dashAnimateTime: 2000,
              initialDashGap: 3,
            });
          }
        }
      }
    });
  });

  // Fetch country + city data
  useEffect(() => {
    try {
      fetch(countryJSON)
        .then((res) => res.json())
        .then(setCountries);

      fetch(cityJSON)
        .then((res) => res.json())
        .then(({ features }) => setPlaces(features));
    } catch (error) {
      console.error('error', error);
    }
  }, []);

  // Camera Controls
  useEffect(() => {
    // Set initial POV
    globeRef.current.pointOfView(
      {
        lat: 30,
        lng: -40,
        altitude: 2.5,
      },
      0,
    );
    const controls = globeRef.current.controls();
    controls.autoRotate = true;
    controls.enableZoom = false;
  });

  const memoizedLabelsData = useMemo(() => places, [places]);
  const memoizedArcsData = useMemo(() => arcs, [arcs]);

  const onWindowResize = () => {
    const camera = globeRef.current.camera();
    const renderer = globeRef.current.renderer();
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  // Set Globe dimensions to match viewport dimensions
  useEffect(() => {
    const { offsetWidth, offsetHeight } = globeContainerRef.current;
    setWidth(offsetWidth);
    setHeight(offsetHeight);

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return (
    <div
      ref={globeContainerRef}
      style={{ width: '100%', height: '100vh', alignContent: 'center' }}
    >
      <Globe
        ref={globeRef}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
        arcsData={memoizedArcsData}
        arcColor="color"
        arcDashAnimateTime={(d) => d.dashAnimateTime}
        arcTransitionDuration={2000}
        arcDashLength={2}
        arcDashGap={3.5}
        arcAltitudeAutoScale={0.5}
        arcStroke={0.3}
        arcDashInitialGap={(d) => d.initialDashGap}
        hexPolygonsData={countries.features}
        hexPolygonGeoJsonGeometry={(d) => d.geometry}
        hexPolygonColor={useCallback(() => '#A8C957', [])}
        hexPolygonResolution={useCallback(() => 3, [])}
        hexPolygonMargin={useCallback(() => 0.7, [])}
        hexPolygonUseDots={true}
        labelsData={memoizedLabelsData}
        labelLat={(d) => d.geometry.coordinates[1]}
        labelLng={(d) => d.geometry.coordinates[0]}
        labelText={(d) => ''}
        labelDotRadius={0.5}
        labelColor={() => '#A857C9'}
        labelAltitude={0.0075}
        labelIncludeDot={true}
        width={width}
        height={height}
        rendererConfig={{ antialias: false }}
      />
      {children}
    </div>
  );
};
