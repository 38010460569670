import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import useResponseHandling from './useResponseHandler';
import { useProcessingHandler } from './useProcessingHandler';
import { useAxiosPrivate } from '../hooks/axios/useAxiosPrivate';

const useLogout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  // Set up Axios
  const axios = useAxiosPrivate();

  async function logoutUser() {
    setIsLoading({ status: true, type: 'spinner', text: 'Logging you out!' });
    try {
      const response = await axios.get('/api/users/account/signout', {
        withCredentials: true,
        credentials: 'same-origin',
      });
      const { data, status } = response;
      if (status === 200) {
        setAuth({ auth_info: null, user_info: null });
      }
      navigate('./account/signin');
      handleRegularResponse({
        open: true,
        status: data.status,
        message: data.message,
      });
      setIsLoading({ status: false, type: '', text: '' });
    } catch (err) {
      handleErrorResponse(err);
    }
  }
  return logoutUser; // Return the logoutUser function to call manually
};

export default useLogout;
