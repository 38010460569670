import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

const IntuIconButton = ({
  type = 'edit', // Define Button type e.g. add, edit, delete
  tooltipTitle = '', // Defines the Tooltip Label and/or Description
  tooltipType = '', // Pass in the type For further customization - used for translations
  size = 'small', // Defines the size of the Icon Button
  variant = 'contained', // Whether the button is of type outlined or contained
  disabled = false, // Whether the Icon Button is disabled or not
  onClick = () => {}, // Function
  IconComponent = null, // Custom Icon Component
  isLoading = false, // If the button is loading
}) => {
  const theme = useTheme();
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const tooltip = transButtons(`${tooltipTitle}`, {
    type: transTypes(`${tooltipType}`),
  });

  const iconProps = {
    fontSize: size,
    sx: {
      color:
        variant === 'outlined'
          ? theme.palette[type]?.main
          : theme.palette[type]?.contrastText,
    },
  };

  let iconElement;
  if (IconComponent) {
    iconElement = <IconComponent {...iconProps} />;
  } else {
    switch (type) {
      case 'add':
        iconElement = <AddIcon {...iconProps} />;
        break;
      case 'edit':
        iconElement = <EditIcon {...iconProps} />;
        break;
      case 'connect':
        iconElement = <SettingsEthernetIcon {...iconProps} />;
        break;
      case 'cancel':
        iconElement = <CloseIcon {...iconProps} />;
        break;
      case 'error':
        iconElement = <DeleteOutlineIcon {...iconProps} />;
        break;
      case 'submit':
      case 'success':
        iconElement = <CheckIcon {...iconProps} />;
        break;
      default:
        return null;
    }
  }

  return (
    <Tooltip title={tooltip} placement="top-start">
      <span>
        <IconButton
          disabled={disabled || isLoading}
          sx={{
            borderRadius: '50%',
            border: variant === 'outlined' ? '1px solid' : 'none',
            borderColor:
              variant === 'outlined' ? theme.palette[type]?.main : 'none',
            backgroundColor:
              variant === 'contained' ? theme.palette[type]?.main : 'none',
            marginRight: '0.5rem',
            '&:disabled': {
              backgroundColor: theme.palette.disabled?.main,
            },
            '&:hover': {
              backgroundColor: theme.palette[type]?.light,
              borderColor: theme.palette[type]?.dark,
              '& .MuiSvgIcon-root': {
                color:
                  variant === 'contained'
                    ? theme.palette[type]?.contrastText
                    : theme.palette[type]?.dark,
              },
            },
          }}
          onClick={onClick}
        >
          {isLoading ? <CircularProgress size={20} /> : iconElement}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default IntuIconButton;
