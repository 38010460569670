import React, { useContext } from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { Skeleton } from '@mui/material';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';

const IndividualMobileNumberField = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  fieldID = 'mobile', // Determines the Name and IDD of the Field
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
}) => {
  // Translator
  const { t } = useTranslation('fields', {
    keyPrefix: 'account.individual.IndividualMobileNumberField',
  });

  // Context
  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext();

  const { isLoading } = useContext(ProcessingContext);

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <MuiTelInput
        required={required}
        disabled={disabled}
        id={fieldID}
        name={fieldID}
        autoComplete="off"
        className="form-select-field"
        label={t('label')}
        placeholder={t('placeholder')}
        value={fieldValue?.value}
        onChange={(value, info) => {
          setFieldValue(fieldID, {
            value: value,
            info,
          });
        }}
        onBlur={handleBlur}
        error={
          fieldError && fieldTouched && fieldError['info']['nationalNumber']
            ? true
            : false
        }
        helperText={
          fieldError?.info?.nationalNumber && fieldTouched
            ? fieldError?.info?.nationalNumber
            : null
        }
      />
    </FormikFieldWrapper>
  );
};

export default IndividualMobileNumberField;
