import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const HeaderContent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home.SalesHome.HeaderContent',
  });
  const params = new URLSearchParams(window.location.search);
  const fName = params.get('fName');

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }}>
          {t('header', { name: fName ? fName + ',' : '' })}
        </Typography>
      </Grid>
    </Grid>
  );
};
