export const createSignUpInitialValues = (props) => {
  // Get additional props from lead/how it woks
  let leadType = props?.type;
  let leadEmail = props?.email;
  let leadFName = props?.firstName;
  let leadLName = props?.lastName;

  return {
    account_type: leadType ? leadType : '',
    first_name: leadFName ? leadFName : '',
    last_name: leadLName ? leadLName : '',
    email: leadEmail ? leadEmail : '',
    password: '',
    passwordConf: '',
  };
};
