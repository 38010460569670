import React from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

const MobileNotificationCheckBox = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
}) => {
  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix:
      'components.account.two-factor-authentication.fields.MobileNotificationCheckBox',
  });
  // Context
  const { values, setFieldValue, setFieldTouched, handleBlur } =
    useFormikContext();
  return (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <FormControlLabel
        checked={values.account_notifications}
        defaultChecked={true}
        control={
          <Checkbox
            id="account_notifications"
            name="account_notifications"
            disabled={disabled}
            required={required}
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue('account_notifications', e.target.checked);
              setFieldTouched('account_notifications', true);
            }}
            inputProps={{
              'aria-label': 'controlled',
            }}
          />
        }
        label={t('label')}
      />
    </FormikFieldWrapper>
  );
};

export default MobileNotificationCheckBox;
