import React from 'react';

// Materail UI
import { Grid } from '@mui/material';

// Translator
import { useTranslation } from 'react-i18next';

// Admin Dashboard
const AdminDashboard = () => {
  // Translator
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      textAlign="center"
    ></Grid>
  );
};

export { AdminDashboard };
