// Generate HSL Color
function generateHSLColor(index, total) {
  const hue = (index * (360 / total)) % 360; // This will cycle through the color wheel.
  const saturation = 70; // Saturation percentage
  const lightness = 50; // Lightness percentage
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

// Convert Locations
const convertDataToGeoJSON = (data) => {

    return {
      type: "FeatureCollection",
      features: data.map((item) => ({
        type: "Feature",
        properties: {
          name: item?.name,
          content: item?.content,
          category: item?.category
        },
        geometry: {
          type: "Point",
          coordinates: [item.coordinates.lng, item.coordinates.lat ] // GeoJSON expects coordinates in [longitude, latitude] order
        },
      })),
    };
};

// Create a Color Expression
function createColorExpression(categories, colors, defaultColor) {
  // Start with the 'match' expression and the input expression
  let expression = ['match', ['get', 'category']];

  // Add each category and its corresponding color to the expression
  categories.forEach((category, index) => {
    expression.push(category, colors[index]);
  });

  // Add the default color at the end of the expression
  expression.push(defaultColor);

  return expression;
}

  // Sort Unique Categories for Clustered Map
  function getUniqueCategories(locations) {
    const categories = new Set();
  
    // Assuming each location has a 'category' property
    locations.forEach(location => {
      categories.add(location.category);
    });
  
    // Convert the Set to an Array and return it
    return Array.from(categories);
  }

function getUniqueCategoriesWithColors(locations) {
  // Colors for Data
  const intuLightPurple = '#e8d46f';
  const intuOrange = '#d76446';
  const intuLightBlue = '#6ea4d4';
  const intuDarkGreen = '#A8C957';
  const intuMiddleGrey = '#6F7978';

  const categories = new Set();

  // Filter locations to ensure there is a category and it's not empty
  locations.forEach(location => {
    if (location.category && location.category.trim() !== '') {
      categories.add(location.category.trim());
    }
  });

  const uniqueCategories = Array.from(categories);

  const categoryColorMap = {};
  const predefinedColors = [
    intuDarkGreen,
    intuMiddleGrey,
    intuLightPurple,
    intuOrange,
    intuLightBlue
  ];

  uniqueCategories.forEach((category, index) => {
    categoryColorMap[category] = predefinedColors[index] || generateHSLColor(index, uniqueCategories.length);
  });

  let categoryColors = uniqueCategories.map((category, index) => {
    return predefinedColors[index] || generateHSLColor(index, uniqueCategories.length);
  });


  return categoryColors;
}
  

  export { convertDataToGeoJSON, createColorExpression, getUniqueCategories, getUniqueCategoriesWithColors }