import { createContext, useState } from 'react';

export const ProfileIntroductionContext = createContext({});

export const ProfileIntroProvider = ({ children }) => {
  const [introDialog, setIntroDialog] = useState(false);
  const [options, setOptions] = useState([]);
  const [fetchIntro, setFetchIntro] = useState(false);

  return (
    <ProfileIntroductionContext.Provider
      value={{
        introDialog,
        setIntroDialog,
        options,
        setOptions,
        fetchIntro,
        setFetchIntro,
      }}
    >
      {children}
    </ProfileIntroductionContext.Provider>
  );
};
