import { useContext, useEffect } from 'react';
import { Fade, Typography, Grid, Stack, Skeleton } from '@mui/material';
import { useFormikContext } from 'formik';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider.jsx';
import { useTranslation } from 'react-i18next';
import { IntuColorPickerField } from '../../IntuColorPicker/IntuColorPicker.js';
import ProcessingContext from '../../../context/providers/ProcessingProvider.jsx';
import { CompanyProfileContext } from '../../../context/providers/CompanyProfileProvider.jsx';

const CompanyBrandColors = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  transition = true, // Transition of the Field (true = fade in, false = fadeout)
  disabled = false, // Whether the field is disabled
  required = false, // Whether the field is required
}) => {
  const { transitionInterval, userThemeColors, setUserThemeColors } = useContext(IntuThemeContext);
  const { companyProfile } = useContext(CompanyProfileContext)
  // Translation
  const { t } = useTranslation('fields', {
    keyPrefix: 'account.company.CompanyNameField',
  });

  const formik = useFormikContext();

  const { isLoading } = useContext(ProcessingContext);

  useEffect(() => {
    setUserThemeColors(companyProfile.colors)
  }, [companyProfile.colors])

  return (
    <Grid container alignItems="center" direction={{ xs: 'column', lg: 'row' }}>
      <Grid item xs={12} lg={12} sx={{ width: '100%' }}>
        {fieldTitle && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
              {fieldTitle}
            </Typography>
          </Fade>
        )}
        {fieldDescription && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography
              className="form-note"
              textAlign={{ xs: 'center', md: 'left' }}
            >
              {fieldDescription}
            </Typography>
          </Fade>
        )}
        <Stack direction="row" spacing={4}>
          {isLoading.status ? (
            <>
              <Skeleton variant="rectangular" width={'100%'} height={60} />
              <Skeleton variant="rectangular" width={'100%'} height={60} />
            </>
          ) : (
            <>
              <IntuColorPickerField
                defaultColor={userThemeColors?.primary ? userThemeColors.primary : '#333333'}
                fieldID="colors.primary"
                fieldName="colors.primary"
                formik={formik}
                disabled={disabled}
                required={required}
                transition={transition}
              />
              <IntuColorPickerField
                defaultColor={userThemeColors?.secondary ? userThemeColors.secondary : '#A8C957'}
                fieldID="colors.secondary"
                fieldName="colors.secondary"
                formik={formik}
                disabled={disabled}
                required={required}
                transition={transition}
              />
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CompanyBrandColors;
