import React from 'react';
import { Container, Grid } from '@mui/material';
// import './headers.css';
import { LeadHeaderContent } from './LeadHeaderContent';

const LeadHeaderContainer = (props) => {
  let leadInfo = props.leadInfo;

  return (
    <Container maxWidth="auto" disableGutters>
      <Grid className="intu__divider">
        <LeadHeaderContent leadInfo={leadInfo} />
      </Grid>
    </Container>
  );
};

export default LeadHeaderContainer;
