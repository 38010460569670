import { Typography, Grid, Link, List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PrivacyContent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.general.PrivacyPolicyContent',
  });

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      spacing={2}
      className="intu__section"
      sx={{ marginBottom: '4rem', textWrap: 'pretty' }}
    >
      <Grid item xs={12}>
        <Typography variant="body1">{t('effective_date')}</Typography>
        <Typography variant="body1">{t('last_updated')}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">
          {t('intro')}{' '}
          <Link className="link" href="https://intutec.io">
            https://intutec.io
          </Link>
          , {t('other_sites')}
        </Typography>
        <br />
        <Typography variant="body1">{t('personal_information_def')}</Typography>
        <br />

        <Typography variant="body1">{t('third_party_links')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('information_we_collect')}</Typography>
        <Typography variant="body1">{t('voluntarily_provided')}</Typography>
        <Typography variant="body1">{t('automatically_collected')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('log_data')}</Typography>
        <Typography variant="body1">{t('log_data_info')}</Typography>
        <Typography variant="body1">{t('error_data_info')}</Typography>
        <Typography variant="body1">{t('combination_warning')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('device_data')}</Typography>
        <Typography variant="body1">{t('device_data_intro')}</Typography>
        <List
          sx={{
            listStyleType: 'disc',
            paddingLeft: 1,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <Typography variant="body1">{t('device_type')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('operating_system')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('unique_device_identifiers')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('device_settings')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('geo_location_data')}</Typography>
          </ListItem>
        </List>
        <Typography variant="body1">
          {t('device_data_recommendation')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('personal_information')}</Typography>
        <Typography variant="body1">
          {t('personal_information_intro')}
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            paddingLeft: 1,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <Typography variant="body1">{t('name')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('email')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('social_media_profiles')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('date_of_birth')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('phone_number')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('home_address')}</Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('user_generated_content')}</Typography>
        <Typography variant="body1">
          {t('user_generated_content_intro')}
        </Typography>
        <Typography variant="body1">
          {t('user_generated_content_warning')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('legitimate_reasons')}</Typography>
        <Typography variant="body1">{t('legitimate_reasons_intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('collection_and_use')}</Typography>
        <Typography variant="body1">{t('collection_and_use_intro')}</Typography>
        <List
          sx={{
            listStyleType: 'disc',
            paddingLeft: 1,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <Typography variant="body1">{t('register_account')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('purchase_products_services')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('purchase_subscription')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('enter_competitions')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('sign_up_updates')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('access_content')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('contact_us')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('mention_us')}</Typography>
          </ListItem>
        </List>
        <Typography variant="body1">
          {t('collection_and_use_purposes_intro')}
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            paddingLeft: 1,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <Typography variant="body1">{t('core_features')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('customize_experience')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('process_payments')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('deliver_products_services')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('contact_communication')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('analytics_research')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('advertising_marketing')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('employment_application')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('access_website')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('record_keeping')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('run_competitions')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('legal_obligations')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('attribute_content')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('security_fraud_prevention')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('technical_assessment')}</Typography>
          </ListItem>
        </List>
        <Typography variant="body1">
          {t('combination_with_other_data')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">{t('security')}</Typography>
        <Typography variant="body1">{t('security_intro')}</Typography>
        <Typography variant="body1">{t('security_limitations')}</Typography>
        <Typography variant="body1">{t('password_responsibility')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">{t('data_retention')}</Typography>
        <Typography variant="body1">{t('data_retention_intro')}</Typography>
        <Typography variant="body1">{t('data_retention_criteria')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('childrens_privacy')}</Typography>
        <Typography variant="body1">{t('childrens_privacy_intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('third_party_disclosure')}</Typography>
        <Typography variant="body1">
          {t('third_party_disclosure_intro')}
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            paddingLeft: 1,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <Typography variant="body1">
              {t('parent_subsidiary_affiliate')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('third_party_service_providers')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('employees_contractors')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('business_partners')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('sponsors_promoters')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('credit_reporting_agencies')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('courts_regulatory_authorities')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('third_parties_assistants')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('third_parties_collect_process_data')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('business_transfers')}</Typography>
          </ListItem>
        </List>
        <Typography variant="body1">{t('third_parties_we_use')}</Typography>
        <List
          sx={{
            listStyleType: 'disc',
            paddingLeft: 1,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <Typography variant="body1">{t('google_analytics')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('ipinfo')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('snovio')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('brevo')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('stripe')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{t('apple_pay')}</Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('international_transfers')}</Typography>
        <Typography variant="body1">
          {t('international_transfers_intro')}
        </Typography>
        <Typography variant="body1">
          {t('international_transfers_protection')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('your_rights')}</Typography>
        <Typography variant="body1">{t('your_rights_intro')}</Typography>
        <Typography variant="body1">
          {t('third_party_info_protection')}
        </Typography>
        <Typography variant="body1">{t('marketing_permission')}</Typography>
        <Typography variant="body1">{t('access_right')}</Typography>
        <Typography variant="body1">{t('correction_right')}</Typography>
        <Typography variant="body1">{t('non_discrimination')}</Typography>
        <Typography variant="body1">{t('data_breach_notification')}</Typography>
        <Typography variant="body1">{t('complaints')}</Typography>
        <Typography variant="body1">{t('unsubscribe')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('cookies')}</Typography>
        <Typography variant="body1">{t('cookies_intro')}</Typography>
        <Typography variant="body1">{t('cookies_policy')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('business_transfers_policy')}</Typography>
        <Typography variant="body1">{t('business_transfers_intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('policy_limits')}</Typography>
        <Typography variant="body1">{t('policy_limits_intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('policy_changes')}</Typography>
        <Typography variant="body1">{t('policy_changes_intro')}</Typography>
        <Typography variant="body1">{t('significant_changes')}</Typography>
        <Typography variant="body1">
          {t('permission_opt_in_opt_out')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('additional_disclosures_au')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">
          {t('additional_disclosures_au_intro')}
        </Typography>
        <Typography variant="body1">
          {t('additional_disclosures_au_details')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('additional_disclosures_gdpr')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">{t('data_controller_processor')}</Typography>
        <Typography variant="body1">
          {t('data_controller_processor_intro')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">{t('legal_bases')}</Typography>
        <Typography variant="body1">{t('legal_bases_intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('consent')}</Typography>
        <Typography variant="body1">{t('consent_intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('performance_of_contract')}</Typography>
        <Typography variant="body1">
          {t('performance_of_contract_intro')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('legitimate_interests')}</Typography>
        <Typography variant="body1">
          {t('legitimate_interests_intro')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('compliance_with_law')}</Typography>
        <Typography variant="body1">
          {t('compliance_with_law_intro')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">
          {t('international_transfers_outside_eea')}
        </Typography>
        <Typography variant="body1">
          {t('international_transfers_outside_eea_intro')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">{t('your_rights_and_control')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <strong>{t('restrict')}</strong>
        </Typography>
        <Typography variant="body1">{t('restrict_intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <strong>{t('objecting_to_processing')}</strong>
        </Typography>
        <Typography variant="body1">
          {t('objecting_to_processing_intro')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <strong>{t('data_portability')}</strong>
        </Typography>
        <Typography variant="body1">{t('data_portability_intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <strong>{t('deletion')}</strong>
        </Typography>
        <Typography variant="body1">{t('deletion_intro')}</Typography>
        <Typography variant="body1">{t('deletion_right_intro')}</Typography>
        <List
          sx={{
            listStyleType: 'disc',
            paddingLeft: 1,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <Typography variant="body1">
              {t('deletion_right_no_longer_necessary')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('deletion_right_withdrawn_consent')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('deletion_right_no_overriding_interests')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('deletion_right_direct_marketing')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('deletion_right_legal_obligation')}
            </Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <strong>{t('right_to_portability')}</strong>
        </Typography>
        <Typography variant="body1">
          {t('right_to_portability_intro')}
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            paddingLeft: 1,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <Typography variant="body1">
              {t('portability_applies_to_direct_data')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('portability_onward_transfer')}
            </Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <strong>{t('right_to_rectification')}</strong>
        </Typography>
        <Typography variant="body1">
          {t('right_to_rectification_intro')}
        </Typography>
        <Typography variant="body1">
          {t('rectification_only_own_data')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <strong>{t('data_breach_notification_right')}</strong>
        </Typography>
        <Typography variant="body1">
          {t('data_breach_notification_right_intro')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <strong>{t('complaints_right')}</strong>
        </Typography>
        <Typography variant="body1">{t('complaints_right_intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">{t('enquiries_reports')}</Typography>
        <Typography variant="body1">{t('enquiries_reports_intro')}</Typography>
        <Typography variant="body1">{t('ico_contact')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('contact_us_title')}</Typography>
        <Typography variant="body1">{t('contact_us_intro')}</Typography>
        <Typography variant="body1">{t('contact_details')}</Typography>
      </Grid>
    </Grid>
  );
};

export { PrivacyContent };
