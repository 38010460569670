import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubNavDrawerContext } from '../navitems/NonAuthNavItem';
import { ListItemButton, ListItemText } from '@mui/material';
import { NavItem } from '../../../framer-motion/animations';
import { motion } from 'framer-motion';

const SubMenu = ({ menuItem }) => {
  const { handleDrawerClose } = useContext(SubNavDrawerContext);

  const { t } = useTranslation('buttons', {
    keyPrefix: 'navigation',
  });

  return (
    <motion.li variants={NavItem} whileHover={{ scale: 1.05 }}>
      <ListItemButton
        sx={{
          height: '2.3rem',
          justifyContent: 'initial',
          px: 3.5,
        }}
        onClick={() => {
          menuItem.target();
          handleDrawerClose();
        }}
        key={menuItem.key}
      >
        <ListItemText primary={menuItem.label} disableTypography />
      </ListItemButton>
    </motion.li>
  );
};

export default SubMenu;
