import React, { useEffect, useRef, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";

// Map components
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_continentsLow from "@amcharts/amcharts5-geodata/continentsLow";

// Mui Components
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';

// Themes
import am5Themes_IntuTheme from "./theme";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// HTML Parser
import ReactHtmlParser from "react-html-parser";

// Chart Colors
const intuDarkGreen   = '#A8C957';

const MapChartContext = ({
    chartId, 
    description: chartDescription = "", 
    descriptionColor: chartDescriptionColor = "", 
    data: mapData = [],
    mobileView = false 
}) => {

    let currentYear = 2022;
    const yearLabelRef = useRef(null);

    const [isInView, setIsInView] = useState(false);
const chartRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            // entries[0] because we are observing a single element
            setIsInView(entries[0].isIntersecting);
        }, {
            // Adjust the options for your needs
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Trigger when 50% of the element is in view
        });

           // Observe the chartRef
    if (chartRef.current) {
        observer.observe(chartRef.current);
    }

        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root = am5.Root.new(chartId);

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root),
            am5Themes_IntuTheme.new(root)
        ]);

        // Create the map chart
        // https://www.amcharts.com/docs/v5/charts/map-chart/
        let chart = root.container.children.push(am5map.MapChart.new(root, {
            projection: am5map.geoNaturalEarth1(),
            maxZoomLevel: 1, // Disabling zooming by setting the max zoom level to 1
            panX: "move", // Enabling horizontal panning by setting panX to "move"
            panY: "move", // Enabling vertical panning by setting panY to "move",
            panEventsEnabled: false,
            wheelable: true,
            wheelSensitivity: 9,
            wheelY: "zoom",
            draggable: false,
            resizeable: false,
            // showTooltipOn: "always",
        }));

        // Add the year label box
        let yearLabelContainer = chart.children.push(am5.Container.new(root, {
            x: am5.percent(10),
            y: am5.percent(10),
            layout: root.verticalLayout,
        }));

        yearLabelRef.current = yearLabelContainer.children.push(am5.Label.new(root, {
            text: `Year: ${currentYear}`,
            fontSize: 15,
            background: am5.RoundedRectangle.new(root, {
                fill: am5.color(intuDarkGreen),
                cornerRadius: 10,
            }),
            padding: 10,
            fill: am5.color(0xffffff),
        }));


        // Set Series      
        let continentSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            geoJSON: am5geodata_continentsLow,
            exclude: ["antarctica"],
            useGeodata: true,
        }));

        continentSeries.mapPolygons.template.setAll({
            // tooltipText: "{name}",
            interactive: true,
            fill: am5.color(0xaaaaaa),
            templateField: "polygonSettings"
        });

        let bubbleSeries = chart.series.push(
            am5map.MapPointSeries.new(root, {
                valueField: "value",
                calculateAggregates: true,
                polygonIdField: "id"
            })
        );

        let circleTemplate = am5.Template.new({});

        let colorSet = am5.ColorSet.new(root, { step: 2 });

        bubbleSeries.bullets.push(function (root, series, dataItem) {

            // Set Label Text
            let value = dataItem.dataContext.marketSize;
            let valueLabel = dataItem.dataContext.marketSizeUnit;
            let toolTipText = dataItem.dataContext.toolTipText;

            let container = am5.Container.new(root, {});
            let color = colorSet.next();
            // let radius = 15 + value / 20 * 150;
            let radius = value;

            let circle = container.children.push(
                am5.Circle.new(root, {
                    radius: radius,
                    stroke: color,
                    height: -40,
                    stops: [
                        { opacity: 1 },
                        { opacity: 1 },
                        { opacity: 0 }
                    ],
                    fillOpacity: 0.7,
                    fill: color,
                    cursorOverStyle: "pointer",
                    //   dy: -radius * 2,
                    tooltipHTML: toolTipText,
                }, circleTemplate)
            );

            return am5.Bullet.new(root, {
                sprite: container,
                dynamic: true
            });
        });

        for (var i = 0; i < mapData.length; i++) {
            let d = mapData[i];

            // Set Label Text
            let toolTipText = `<b>${d?.name}</b><br>
            <img src='https://in2tec.nyc3.digitaloceanspaces.com/investors/Share_Pie.svg' width="15" alt='image' />  Market Size: ${d?.marketSize} ${d?.marketSizeUnit}<br>
            <img src='https://in2tec.nyc3.digitaloceanspaces.com/investors/Growth_Chart.svg' width="20" alt='image' /> CAGR: ${d?.CAGR}%<br>`


            bubbleSeries.bullets.push(function (root, series, dataItem) {

                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        tooltipHTML: toolTipText,
                        text: "{marketSize.formatNumber('#.###')} {marketSizeUnit}\n({CAGR}% CAGR)",
                        populateText: true,
                        centerX: am5.p50,
                        centerY: am5.p50,
                        textAlign: "center",
                        fill: am5.color(0xffffff),
                        fontWeight: "400",
                        fontSize: mobileView ? 10 : 13,
                    }),
                    dynamic: true
                });


            });

            bubbleSeries.data.push({
                geometry: { type: "Point", coordinates: [d.longitude, d.latitude] },
                toolTipText,
                title: d.name,
                marketSize: d.marketSize,
                marketSizeUnit: d?.marketSizeUnit,
                CAGR: d?.CAGR
            });

            // minValue and maxValue must be set for the animations to work
            bubbleSeries.set("heatRules", [
                {
                    target: circleTemplate,
                    dataField: "value",
                    min: mobileView ? 5 : 30,
                    max: mobileView ? 20 : 50,
                    minValue: 0,
                    maxValue: mobileView ? 50 : 50,
                    key: "radius",
                }
            ]);
            bubbleSeries.set("transitionDuration", 1000);
        }

        // Update function
        const updateData = () => {
            if (currentYear <= 2029) { // Only update until 2028 (2022 + 6 more years)
                for (let i = 0; i < mapData.length; i++) {
                    const d = mapData[i];
                    // Update the marketSize based on the CAGR and the current marketSize
                    d.marketSize = d.marketSize * ((d.CAGR / 100) + 1);
                    // Update the data in the bubbleSeries.data to reflect the changed market size.
                    let nextYear = d.year + 1
                    bubbleSeries.data.setIndex(i, {
                        ...d,
                        value: d.marketSize * 20, // Or however you wish to calculate 'value'
                        id: d.id,
                        year: nextYear,
                        name: d.name,
                    });
                }

                // We use `newYear` instead of `currentYear` to ensure accuracy.
                yearLabelRef.current.set("text", `Year: ${currentYear}`);

                // Update the current year
                // currentYear++;
                currentYear += 1
                

            } else {
                clearInterval(updateTimerID);
            }
        };

        // Set up a timer to call the update function every 1 seconds
        const updateTimerID = setInterval(updateData, 1000);

        return () => {
            root.dispose();
            clearInterval(updateTimerID);
            if (chartRef.current) {
                observer.unobserve(chartRef.current);
            }
        };
    }, [isInView]);

    return (
        // Chart Component
        <>
            <Grid item xs={12}>
                <Typography align="center" variant="h5" color={chartDescriptionColor ? chartDescriptionColor : null}>{ReactHtmlParser(chartDescription)}</Typography>
            </Grid>
            <Grid
            ref={chartRef}  // Assign ref here
            item
            xs={12}
            id={chartId}
            sx={{
                width: '100%',
                minHeight: mobileView ? '250px' : '600px',
            }}
        />
        </>
    );
}

export { MapChartContext };