import { Box, Container, Grid } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';

import {
  industryRobotParticleDesktop,
  industryRobotParticleIpad,
  industryRobotParticleMobile,
} from '../animations/industryRobotParticles.js';

// Particle Animations
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import { tsParticles } from 'tsparticles-engine';
import { loadPolygonMaskPlugin } from '@in2tec/tsparticles-mask-plugin';
import useWindowDimensions from './useWindowDimension.jsx';

const PreLoadContext = () => {
  const _isMounted = useRef(true);

  const { width } = useWindowDimensions();

  // SetUp Particle Options based on screen size
  let particleConfig;
  if (width > 820) {
    particleConfig = industryRobotParticleDesktop;
  } else if (width > 390) {
    particleConfig = industryRobotParticleIpad;
  } else {
    particleConfig = industryRobotParticleMobile;
  }

  // Particle Animations
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await container;
  }, []);

  useEffect(() => {
    _isMounted.current = true;

    const loadMask = async () => {
      try {
        await loadPolygonMaskPlugin(tsParticles);
        // Additional code to run after the plugin has loaded...
      } catch (error) {
        if (_isMounted.current) {
          // Handle error only if component is still mounted...
          console.error('Failed to load the Polygon Mask Plugin:', error);
        }
      }
    };

    loadMask();

    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <Container
      maxWidth="auto"
      className="intu__section"
      sx={{ padding: '0', margin: '0' }}
      disableGutters
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        textAlign="center"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Particles
          width="100%"
          height="100vh"
          id="tsparticles"
          className="particles"
          options={particleConfig}
          init={particlesInit}
          loaded={particlesLoaded}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        />
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#333333',
            opacity: [0.3, 0.2, 0.1],
          }}
        ></Box>
      </Grid>
    </Container>
  );
};

export default PreLoadContext;
