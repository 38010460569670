import ReactGA from 'react-ga4';
import { getCookieConsentValue } from 'react-cookie-consent';

const getUserCookieSettings = () => {
  const cookieSettings = getCookieConsentValue('intu_cookie_settings');
  const defaultCookieObject = { auth: true, exp: true, trck: true };

  if (!cookieSettings) {
    return defaultCookieObject;
  }

  return cookieSettings.split(';').reduce((res, c) => {
    const [key, val] = c.trim().split('=').map(decodeURIComponent);
    const allNumbers = (str) => /^\d+$/.test(str);
    try {
      return { ...res, [key]: allNumbers(val) ? val : JSON.parse(val) };
    } catch (e) {
      return { ...res, [key]: val };
    }
  }, defaultCookieObject);
};

const trackEvent = (data) => {
  const { category, action, label, value } = data;

  if (!category || !action || !label) {
    console.log(`Can't track event due to missing parameter!`);
    return;
  }

  ReactGA.event({ category, action, label, value });
};

const useAnalyticsEventTracker = () => {
  const trackingSettings = getUserCookieSettings();

  const gaEventTracker = (data) => {
    if (!data || trackingSettings.trck === false) {
      return;
    }
    trackEvent(data);
  };

  return { gaEventTracker };
};

const useAnalyticsPageViewTracker = () => {
  const gaPageViewTracker = (data) => {
    const {
      hitType = 'pageview',
      path = document.location.pathname,
      label = document.location.pathname,
    } = data || {};

    ReactGA.send({ hitType, page: path, title: label });
  };

  return { gaPageViewTracker };
};

export { useAnalyticsEventTracker, useAnalyticsPageViewTracker };
