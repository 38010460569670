// Example Payload: {
//     "key": "_id",
//     "values": [ "64fb8de3708aa1293917d193" ]
// }

import { axiosPrivate } from '../hooks/axios/axios.js';
import { t } from 'i18next';

// Search Users by payload
const searchUsers = async (payload) => {
  const controller = new AbortController();
  const signal = controller.signal;

  if (!payload) {
    throw new Error(t('routes.req_fields.payload'));
  }

  try {
    const url = '/api/users/search';
    const request = await axiosPrivate.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    let { data, status, statusCode, message } = request.data;

    if (request.status === 200) {
      // Update User Info
      return {
        status,
        statusCode,
        message,
        data,
      };
    } else {
      return {
        statusCode,
        status,
        data,
      };
    }
  } catch (err) {
    return err;
  }
};

const updateUser = async (payload) => {
  const controller = new AbortController();
  const signal = controller.signal;

  if (!payload) {
    throw new Error(t('routes.req_fields.payload'));
  }

  try {
    const url = '/api/users/account/update?userDashboard=false';
    const response = await axiosPrivate.put(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    const { data, status, statusCode, message } = response.data;

    if (response.status === 200) {
      // Update User Info
      return {
        status,
        statusCode,
        message,
        data,
      };
    } else {
      return {
        statusCode,
        status,
        data,
      };
    }
  } catch (err) {
    console.error('error', err)
  }
}

export { searchUsers, updateUser };
