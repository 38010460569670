import { Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonHoverGradiant } from '../../../components/buttons/ButtonHoverGradiant.jsx';

export const CardHeader = () => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.home.MfgHome.CardHeader',
    });
    return (
        <Grid container justifyContent="center">
            <Typography variant="h1">{t('header')}</Typography>
        </Grid>
    );
};

export const CardFooter = () => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.home.SalesHome.CardFooter',
    });
    const navigate = useNavigate();
    return (
        <Grid
            container
            item
            direction="column"
            alignItems="center"
            justifyContent="space-around"
            xs={10}
            md={12}
            mt={25}
        >
            <Grid item>
                <Typography variant="h3">{t('title')}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body" align="center">
                    {t('description')}
                </Typography>
            </Grid>
            <Grid item>
                <ButtonHoverGradiant
                    label="Join Now"
                    onClick={() => navigate('/account/signup')}
                />
            </Grid>
        </Grid>
    );
};