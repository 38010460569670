import { useContext } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { classes } from '../../mui/theme';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Skeleton, TextField } from '@mui/material';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { useTheme } from '@mui/material/styles';
import { getNestedValue } from '../../helpers/getNestedValuesIn';

const EmailOTPField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  type = 'Enter', // Define the Password Type Enter, Define, New etc....
  fieldID = 'userOTP', // ID of the Field
}) => {
  // Translations
  const { t: transTypes } = useTranslation('types');
  const { t: transFields } = useTranslation('fields', {
    keyPrefix: 'otp',
  });

  //   Formik Context
  const { values, touched, errors, handleBlur, setFieldValue } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  const theme = useTheme();

  return (
    <>
      {isLoading.status ? (
        <Skeleton variant="rectangular" width={'100%'} height={60} />
      ) : (
        <FormikFieldWrapper
          fieldTitle={fieldTitle}
          fieldDescription={fieldDescription}
          transition={transition}
        >
          <TextField
            required={required}
            disabled={disabled}
            style={classes.root}
            id={fieldID}
            name={fieldID}
            className="form-select-field"
            autoComplete="off"
            aria-invalid={errors[fieldID] ? 'true' : 'false'}
            aria-describedby="uidnote"
            variant="outlined"
            type="password"
            label={transFields('label', { type: transTypes(type) })}
            placeholder={transFields('placeholder', {
              type: transTypes(type),
            })}
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
            inputProps={{
              autoComplete: 'one-time-code',
            }}
            onBlur={handleBlur}
            value={fieldValue}
            error={fieldError && fieldTouched ? true : false}
            helperText={fieldError && fieldTouched ? fieldError : null}
          />
        </FormikFieldWrapper>
      )}
    </>
  );
};

export default EmailOTPField;
