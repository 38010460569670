import React from 'react';
import { EventsList } from '../../components/events/ManageEvents';

function IntuManageEvents() {
  return (
    <EventsList
      formTitle="Events"
      formText="You can manage and/or add events from here."
    />
  );
}

export default IntuManageEvents;
