import { Grid, Typography, List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";

const ScrollingText1 = () => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.home.SalesHome.ScrollingText1',
    });
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h2">{t('title')}</Typography>
            <Grid item container sx={{ width: '75%' }} justifyContent="center">
                <List sx={{ listStyleType: 'disc' }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem1')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem2')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem3')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem4')}</Typography>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};

const ScrollingText2 = () => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.home.SalesHome.ScrollingText2',
    });
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h2" align="center">
                {t('title')}
            </Typography>
            <Grid item container sx={{ width: '75%' }} justifyContent="center">
                <List sx={{ listStyleType: 'disc' }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem1')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem2')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem3')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem4')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem5')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem6')}</Typography>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};

const ScrollingText3 = () => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.home.SalesHome.ScrollingText3',
    });
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: '10vh' }}>
            <Typography variant="h2">{t('title')}</Typography>
            <Grid item container sx={{ width: '75%' }} justifyContent="center">
                <List sx={{ listStyleType: 'disc' }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem1')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem2')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem3')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem4')}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body">{t('listitem5')}</Typography>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};

export const ScrollingText = [
    <ScrollingText1 />,
    <ScrollingText2 />,
    <ScrollingText3 />,
];