import * as React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import RequireAuth from './components/routes/RequireAuth';
import useUser from './hooks/useUser';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

// Meta Tag Handling
import { Helmet } from 'react-helmet';

// Theming and Styling
import './app.css';

// Pages
import {
  SignUpSuccess,
  Missing,
  Unauthorized,
  Home,
  PrivacyPolicy,
  ContactUs,
  AboutCompany,
  SignUp,
  AdminDashboard,
  UserDashboard,
  UserProfile,
  CompanyProfile,
  AccountResetPassword,
  AccountChangePassword,
  IntuUsers,
  IntuLeads,
  HowItWorksLeads,
  HowItWorksInvestors,
  CompanyCampaigns,
  Blog,
  IntuContacts,
  Post,
  PostCreate,
  UserPosts,
  Subscribers,
  SignIn,
  Products,
  AccountConfirm,
  SignUpComplete,
  CompanySalesTeam,
  CompanyLeads,
  AdminProducts,
  IntuSalesEvents,
  IntuVendorEvents,
  IntuManageEvents,
  NewProductPage,
  MeetUsOnlinePage,
  TermsOfUse,
  CreditsExplainer,
  SalesHome,
  ManufacturerHome,
} from './pages';

// Translator
import i18n from './i18n';

// Import Available Languages
import CountryData from './data/CountryData.json';
import { useEffect, useContext } from 'react';
import ProfileFormLayoutComponent from './components/layout/ProfileFormLayout';
import LandingLayout from './components/layout/LandingLayout';
import GlobalLayout from './components/layout/GlobalLayout';
import CheckAuth from './components/routes/CheckAuth';
import NonAuthLayout from './components/layout/NonAuthLayout';
import AuthLayout from './components/layout/AuthLayout';
import FormProviderComponent from './components/providers/FormProviderComponent';
import { useAnalyticsPageViewTracker } from './hooks/useAnalyticsTracker';
import { LayoutBodyContext } from './context/providers/LayoutContextProvider';

const App = () => {
  const { user } = useUser();

  //Detects User's country and sets displayed language
  useEffect(() => {
    if (!user) {
      return;
    }

    const userCountry = CountryData.find(
      (country) => country.code === user.location.country,
    );
    userCountry?.languageCode
      ? i18n.changeLanguage(userCountry.languageCode)
      : i18n.changeLanguage('en');
  }, [user]);

  // Google Page Tracker
  const location = useLocation();
  const { layoutTitle } = useContext(LayoutBodyContext);
  const { gaPageViewTracker } = useAnalyticsPageViewTracker();

  useEffect(() => {
    document.title = layoutTitle || 'IN2TEC - Distribution 2.0';

    gaPageViewTracker({
      type: 'pageview',
      page: document.location.pathname,
      name: `Page Visit - ${layoutTitle || document.location.pathname}`,
    });

    window.scrollTo(0, 0);
  }, [layoutTitle, location, gaPageViewTracker]);

  return (
    <>
      {/* TO DO -> Make public pages server side rendered and remove HELMET */}
      <Helmet>
        <title>IN2TEC - Distribution 2.0</title>
        <meta
          name="description"
          content="We connect technology brands in process automation, warehouse automation to sales professionals and influencers with the goal of bringing great products and solutions directly in front of your customers."
        />
        <meta name="theme-color" content="#A8C957" />
        <meta
          property="og:title"
          content="B2B Marketplace for sophisticated technology products."
        />
        <meta property="og:image" content="//intutec.io/intu512.png" />
        <meta
          property="og:description"
          content="Build your own network of sales rep's and influencers in in process- and warehouse automation, food & beverage, medical and more..."
        />
        <meta property="og:url" content="//intutec.io" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@IN2TEC" />
        <meta
          name="twitter:title"
          content="Build your own network of sales rep's and influencers in in process- and warehouse automation, food & beverage, medical and more..."
        />
        <meta
          name="twitter:description"
          content="Build your own network of sales rep's and influencers in in process- and warehouse automation, food & beverage, medical and more..."
        />
        <meta name="twitter:image" content="https://intutec.io/intu512.png" />
      </Helmet>
      <div className="wrapper" id="root">
        {/* Routes */}
        <Routes>
          {/* LinkedIn */}
          <Route
            exact
            path="/linkedin-callback"
            element={<LinkedInCallback />}
          />

          {/*  */}
          <Route element={<CheckAuth />}>
            {/* LANDING PAGE */}
            <Route path="/" element={<LandingLayout />}>
              <Route path="/" element={<Home />} />
            </Route>

            {/* CONTENT */}
            <Route path="/" element={<GlobalLayout />}>
              {/* < ------ Check for user authentication in order to show the correct nav items------> */}

              <Route path="/" element={<FormProviderComponent />}>
                {/* < ------ NON-AUTH (PUBLIC) LAYOUTS ------> */}
                <Route path="/" element={<NonAuthLayout />}>
                  {/* < ------ PAGES ------> */}
                  {/* Home */}
                  <Route path="/" element={<Home />} />
                  <Route path="/saleshome" element={<SalesHome />} />
                  <Route path="/mfghome" element={<ManufacturerHome />} />

                  {/* Privacy & Tos */}
                  <Route path="privacy" element={<PrivacyPolicy />} />
                  <Route path="terms-of-use" element={<TermsOfUse />} />
                  <Route path="products" element={<Products />} />
                  <Route path="credits" element={<CreditsExplainer />} />

                  {/* Events */}
                  <Route path="events/sales" element={<IntuSalesEvents />} />
                  <Route path="events/vendors" element={<IntuVendorEvents />} />

                  {/* Meet us Online */}
                  <Route path="meetUsOnline" element={<MeetUsOnlinePage />} />

                  {/* About the Company */}
                  <Route path="company" element={<AboutCompany />} />

                  {/* Blog */}
                  <Route path="blog" element={<Blog />} />
                  <Route path="blog/:id" element={<Post />} />

                  {/* Not authorized*/}
                  <Route path="unauthorized" element={<Unauthorized />} />

                  {/* Catch Not Found */}
                  <Route path="*" element={<Missing />} />

                  {/* < ------ FORMS ------> */}
                  {/* How it works */}
                  <Route path="how-it-works" element={<HowItWorksLeads />} />
                  <Route path="investor" element={<HowItWorksInvestors />} />

                  {/* sign in */}
                  <Route path="account/signin" element={<SignIn />} />

                  {/* Password Reset */}
                  <Route
                    path="account/reset-password"
                    element={<AccountResetPassword />}
                  />

                  {/* Password change */}
                  <Route
                    path="account/change-password"
                    element={<AccountChangePassword />}
                  />

                  {/* SignUp */}
                  <Route path="account/signup" element={<SignUp />} />

                  {/* Contact Us */}
                  <Route path="contact" element={<ContactUs />} />

                  {/* SignUp Confirm */}
                  <Route path="account/confirm" element={<AccountConfirm />} />

                  {/* subscribers */}
                  <Route
                    path="subscribers/signup"
                    element={<Subscribers mode="signup-" source="direct" />}
                  />
                  <Route
                    path="subscribers/confirm"
                    element={<Subscribers mode="confirm" />}
                  />
                  <Route
                    path="subscribers/verify"
                    element={<Subscribers mode="verify" />}
                  />
                  <Route
                    path="subscribers/profile"
                    element={<Subscribers mode="profile" />}
                  />
                </Route>

                {/* < ------ AUTH (PROTECTED) LAYOUTS------> */}
                <Route path="/" element={<AuthLayout />}>
                  {/* <----- Protected Routes for Admins ----->*/}

                  <Route element={<RequireAuth allowedRoles={['admin']} />}>
                    <Route
                      path="admin/dashboard"
                      element={<AdminDashboard />}
                    />
                    <Route path="admin/manage-users" element={<IntuUsers />} />
                    <Route
                      path="admin/manage-products"
                      element={<AdminProducts />}
                    />
                    <Route path="admin/manage-leads" element={<IntuLeads />} />
                    <Route
                      path="admin/manage-contacts"
                      element={<IntuContacts />}
                    />
                    <Route
                      path="admin/manage-events"
                      element={<IntuManageEvents />}
                    />
                    <Route path="admin/posts" element={<UserPosts />} />
                  </Route>

                  {/* <----- Protected Routes for Users ----->*/}
                  <Route
                    element={<RequireAuth allowedRoles={['user', 'admin']} />}
                  >
                    <Route path="user/dashboard" element={<UserDashboard />} />
                    <Route path="user/posts" element={<UserPosts />} />
                    <Route
                      path="account-completed"
                      element={<SignUpSuccess />}
                    />

                    <Route
                      path="company/campaigns"
                      element={<CompanyCampaigns />}
                    />
                    <Route
                      path="company/salesteam"
                      element={<CompanySalesTeam />}
                    />
                    <Route
                      path="company/products"
                      element={<AdminProducts />}
                    />
                    <Route path="company/leads" element={<CompanyLeads />} />

                    <Route path="/" element={<ProfileFormLayoutComponent />}>
                      <Route path="user/profile" element={<UserProfile />} />
                      <Route
                        path="company/profile"
                        element={<CompanyProfile />}
                      />
                      <Route
                        path="account/complete"
                        element={<SignUpComplete />}
                      />
                      {/* Blog */}
                      <Route path="blog/create" element={<PostCreate />} />
                    </Route>

                    {/* Blog */}
                    <Route path="blog/edit/:id" element={<Post />} />

                    {/* Products */}
                    <Route
                      path="manage-products/list"
                      mode="list"
                      element={<AdminProducts />}
                    />
                    {/* TODO: deprecate route */}
                    <Route
                      path="manage-products/edit/:id"
                      mode="edit"
                      element={<AdminProducts />}
                    />

                    {/* <Route
                      path="new-product/:id"
                      element={<NewProductPage />}
                    />
                    <Route
                      path="edit-product/:id"
                      element={<NewProductPage isEdit />}
                    /> */}

                    {/* Blog */}
                    <Route path="blog/create" element={<PostCreate />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default App;
