import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { SignUpCompleteForm } from '../../components/account/forms/complete/SignUpCompleteForm';
import IntuPage from '../../components/layout/IntuPage';
import { LayoutBodyContext } from '../../context/providers/LayoutContextProvider';
import useAuth from '../../hooks/useAuth';
import useUser from '../../hooks/useUser';
import './account.css';

function SignUpComplete() {
  const { t } = useTranslation();
  const {
    layoutTitle,
    setLayoutTitle,
    layoutDescription,
    setLayoutDescription,
  } = useContext(LayoutBodyContext);
  const { auth } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    // Set Global Form Details
    let userLabel;

    if (auth.user_info.account_type === 'manufacturer') {
      userLabel = t('context.account.SignUpComplete.role.manufacturer');
    } else if (auth.user_info.account_type === 'salesrep') {
      userLabel = t('context.account.SignUpComplete.role.salesrep');
    } else if (auth.user_info.account_type === 'influencer') {
      userLabel = t('context.account.SignUpComplete.role.influencer');
    }

    setLayoutTitle(
      t('pages.account.SignUpComplete.title', {
        userLabel: userLabel,
      }),
    );

    setLayoutDescription(
      t('pages.account.SignUpComplete.description', {
        userType: userLabel,
      }),
    );
  }, [auth?.user_info?.account_type, user.location, i18n.language]);

  return (
    <IntuPage
      pageTitle={layoutTitle}
      pageDescription={layoutDescription}
      hCentered={true}
      vCentered={false}
    >
      <IntuForm hCentered={true} vCentered={false} form="Account Complete">
        <SignUpCompleteForm />
      </IntuForm>
    </IntuPage>
  );
}

export default SignUpComplete;
