import { BlogList } from '../../components/blog/lists/BlogList';
import { Container, Grid } from '@mui/material';
import './blog.css';
import { BlogsHeaderContainer } from '../../components/blog/containers/BlogsHeaderContainer';

const Blogs = () => {
  return (
    <Grid>
      <Grid item xs={12}>
        <BlogsHeaderContainer />
      </Grid>
      <Grid item xs={12}>
        <Container
          component="main"
          maxWidth="auto"
          sx={{ marginTop: '3rem', minHeight: '680px' }}
        >
          <BlogList />
        </Container>
      </Grid>
    </Grid>
  );
};

export default Blogs;
