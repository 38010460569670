import countryList from '../data/CountryData.json';

export function getUserCountryDetails(user) {
  const countryOptions = countryList;
  let countryDetails = { label: 'United States', dialCode: '1', code: 'US' };

  // Get User Location
  if (user?.location?.country) {
    [countryDetails] = countryOptions.filter(
      (country) => country.code === user.location.country,
    );
  }

  return countryDetails;
}
