import React, { createContext, useState } from 'react';

const ResponseContext = createContext({});

export const ResponseProvider = ({ children }) => {
  const [statusMsg, setStatusMsg] = useState({
    open: false,
    status: 'info',
    message: '',
  });
  const [errorDialog, setErrorDialog] = useState({
    open: false,
    title: '',
    message: '',
  });
  const [statusContext, setStatusContext] = useState({
    open: false,
    title: null,
    content: null,
    action: null,
  });

  const [isLoading, setIsLoading] = useState({ status: false, text: '' });

  return (
    <ResponseContext.Provider
      value={{
        response: {},
        statusMsg,
        setStatusMsg,
        isLoading,
        setIsLoading,
        errorDialog,
        setErrorDialog,
        statusContext,
        setStatusContext,
      }}
    >
      {children}
    </ResponseContext.Provider>
  );
};

export default ResponseContext;
