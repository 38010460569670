import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Share Buttons
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from '@in2tec/social-share';

// Share Icnons
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import RedditIcon from '@mui/icons-material/Reddit';
import EmailIcon from '@mui/icons-material/Email';
import { Subscribers } from '../../pages';

const BlogFooterContent = (props) => {
  const postInfo = props.content;
  const { t } = useTranslation();

  let emailContent = `Hi there, I would like to share with you an interesting article: ${postInfo?.description} under this <a href="${postInfo?.full_url}">here</a>`;
  let hashTags = postInfo.tags?.length > 0 ? postInfo.tags.join(', ') : '';

  return (
    <Grid
      container
      spacing={4}
      textAlign={{ xs: 'center', md: 'left' }}
      alignItems={{ xs: 'center', md: 'flex-start' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{ padding: '2rem', marginTop: '30px', backgroundColor: '#333333' }}
    >
      <Grid item xs={6}>
        {/* Newsletter Signup */}
        {
          <Typography variant="h5" color="white">
            {t('context.footer.news_signup')}
          </Typography>
        }
        <Subscribers mode="signup-component" source={postInfo.title} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" color="white">
          {t('context.footer.sharing')}
        </Typography>
        <Stack direction="row" spacing={2}>
          {/* LinkedIn */}
          <LinkedinShareButton
            url={`${postInfo?.full_url}`}
            title={postInfo?.title}
            summary={postInfo?.description}
          >
            <LinkedInIcon fontSize="large" color="primary" />
          </LinkedinShareButton>
          {/* Twitter */}
          <TwitterShareButton
            url={postInfo?.full_url}
            title={postInfo?.title}
            via="IN2TEC"
            hashtags={postInfo?.tags}
            related={['IN2TEC']}
          >
            <TwitterIcon fontSize="large" color="primary" />
          </TwitterShareButton>
          {/* Facebook */}
          <FacebookShareButton
            url={postInfo?.full_url}
            quote={postInfo?.title}
            hashtag={hashTags}
          >
            <FacebookIcon fontSize="large" color="primary" />
          </FacebookShareButton>
          {/* Reddit Icon */}
          <RedditShareButton url={postInfo?.full_url} title={postInfo?.title}>
            <RedditIcon color="primary" />
          </RedditShareButton>
          {/* Email Share Button */}
          <EmailShareButton
            url={postInfo?.full_url}
            subject={`Reading Recommendation for the article ${postInfo?.title}`}
            body={emailContent}
            seperator=" "
          >
            <EmailIcon color="primary" />
          </EmailShareButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export { BlogFooterContent };
