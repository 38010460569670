import { Fade, Skeleton, Typography } from '@mui/material';
import { IntuRichTextEditor } from '../../IntuRichTextEditor/IntuRichtTextEditor';
import { useFormikContext } from 'formik';
import { convertToRaw } from 'draft-js';
import { useTranslation } from 'react-i18next';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider';
import { useContext } from 'react';
import ProcessingContext from '../../../context/providers/ProcessingProvider';

const CompanyTowField = ({
  fieldID = 'tow',
  transNS = 'fields',
  transPrefix = 'account.company.CompanyTowField',
  fieldTitle, // field Title
  fieldDescription, // Field Description
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
  initialSetupComplete = true
}) => {
  //SetUp Trnaslation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { transitionInterval } = useContext(IntuThemeContext);

  const { touched, setFieldTouched, values, setFieldValue, errors } =
    useFormikContext();

  const { isLoading } = useContext(ProcessingContext);

  return (
    <>
      {fieldTitle && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
            {fieldTitle}
          </Typography>
        </Fade>
      )}
      {fieldDescription && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography
            className="form-note"
            textAlign={{ xs: 'center', md: 'left' }}
          >
            {fieldDescription}
          </Typography>
        </Fade>
      )}
      {transition &&
        (isLoading.status || initialSetupComplete === false ? (
          <Skeleton variant="rectangular" width={'100%'} height={100} />
        ) : (
          <>
            <IntuRichTextEditor
              id="tow"
              name="tow"
              required={required}
              disabled={disabled}
              controls={[
                'title',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'highlight',
                'undo',
                'redo',
                'media',
                'numberList',
                'bulletList',
                'quote',
                'clear',
              ]}
              className="form-select-field"
              aria-describedby="uidnote"
              variant="standard"
              label={t('label', { type: 'Company' })}
              defaultValue={JSON.stringify(values?.tow_rte)}
              onChange={(event) => {
                const rteContent = convertToRaw(event.getCurrentContent());
                rteContent && setFieldValue('tow', rteContent);
                setFieldTouched('tow', true);
              }}
              onBlur={() => setFieldTouched('tow', true)}
              inlineToolbar={true}
              error={errors?.tow && touched?.tow ? true : false}
              helperText={errors?.tow && touched?.tow ? errors?.tow : null}
            />
          </>
        ))}
    </>
  );
};

export default CompanyTowField;
