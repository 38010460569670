import { useContext, useDebugValue } from 'react';
import UserContext from '../context/providers/UserInfoProvider';

const useUser = () => {
  const { user } = useContext(UserContext);
  useDebugValue(user, (user) =>
    user?.user ? 'User info present' : 'User Info not present',
  );
  return useContext(UserContext);
};

export default useUser;
