import * as Yup from 'yup';
import i18next from 'i18next';

export const createSignUpValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    account_type: Yup.string().required(t('role_required')),
    first_name: Yup.string()
      .min(2, t('short'))
      .max(50, t('long'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.first_required'))
      .required(t('name.first_required')),
    last_name: Yup.string()
      .min(2, t('short'))
      .max(50, t('long'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.last_matches'))
      .required(t('name.last_required')),
    email: Yup.string()
      .required(t('email.user_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('email.invalid'),
      ),
    password: Yup.string()
      .required(t('password.required'))
      .min(8, t('password.short'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
        t('password.matches'),
      ),
    passwordConf: Yup.string()
      .required(t('password.comf_required'))
      .oneOf([Yup.ref('password'), null], t('password.comf_match')),
  });
};
