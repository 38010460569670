import React from 'react';
import { EventForm } from '../../components/events/EventForm.jsx';
import { Box, Grid, Container } from '@mui/material';
import BackgoundImage from '../../assets/events/SalesEventsBackgroundPicture.jpg';
import './events.css';
import { useTranslation } from 'react-i18next';
import EventInfoContent from '../../components/events/EventInfoContent.jsx';
import FormContextProvider from '../../context/providers/FormContextProvider';

const IntuSalesEvents = () => {
  const { t } = useTranslation();

  let agenda = [
    {
      topic: 'Opening & Greeting',
      time: '6:00 PM - 6:30 PM',
    },
    {
      topic: 'What is IN2TEC about and how we make a difference',
      time: '6:30 PM - 7:00 PM',
    },
    {
      topic: 'Introduction of current Products & Brands',
      time: '7:00 PM - 8:00 PM',
    },
    {
      topic: 'What is your earning opportunity',
      time: '8:00 PM - 9:00 PM',
    },
    {
      topic: 'Q&A and Networking - Last Call (Food & Drinks)',
      time: '9:00 PM - 10:00 PM',
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        backgroundImage: `url(${BackgoundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container
        maxWidth="auto"
        disableGutters
        component="div"
        sx={{
          backgroundColor: 'var(--color-hero-overlay)',
          height: '100%',
          zIndex: 1,
        }}
      >
        <Grid container direction={{ xs: 'column', md: 'row' }}>
          {/* Event Info */}
          <Grid item xs={12} md={6} sx={{ padding: '4rem 0 8rem 0' }}>
            <EventInfoContent
              eventTitle={t('context.events.SalesEvents.event_title')}
              eventSubTitle={t('context.events.SalesEvents.event_subtitle')}
              eventInfo={t('context.events.SalesEvents.event_info')}
              eventAgenda={agenda}
            />
          </Grid>

          {/* RSVP Form */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              padding: '2rem',
              backgroundColor: 'var(--color-modal-bg)',
            }}
          >
            <FormContextProvider>
              <EventForm formType="sales" buttonLabel={t('buttons.rsvp')} />
            </FormContextProvider>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default IntuSalesEvents;
