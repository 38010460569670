import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { UserPostsList } from '../../context/blog/UserPostsList';
import IntuPage from '../../components/layout/IntuPage';

function UserPosts() {
  const { t } = useTranslation();
  return (
    <IntuPage>
      <IntuForm
        form="Blog"
        formTitle={t('form.post.user.title')}
        formText={t('form.post.user.text')}
      >
        <UserPostsList />
      </IntuForm>
    </IntuPage>

  );
}

export default UserPosts;
