import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Grid, Skeleton } from '@mui/material';
import { convertDataToGeoJSON } from '../../helpers/mapHelperFunctions';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

function HeatMap({ initialZoom, locations, projection, cluster = true }) {
  // Convert Data to GEOJSON Format
  const geojsonData = convertDataToGeoJSON(locations);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(locations[0]?.lng || -74.006);
  const [lat, setLat] = useState(locations[0]?.lat || 40.7128);
  const [isActivityMapLoading, setIsActivityMapLoading] = useState(true);
  const [zoom, setZoom] = useState(initialZoom);

  // Style URLs. Replace these with your actual URLs.
  const styles = {
    globe: 'mapbox://styles/intutecio/clmbwv14801ay01pf80c7hkm6',
    mercator: 'mapbox://styles/intutecio/clnqhhdpn00fc01qw0w2j6y4a',
  };

  // preload locations handler
  useEffect(() => {
    if (!locations || locations.length === 0) return; // Skip map loading if no locations

    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: projection === 'mercator' ? styles.mercator : styles.globe,
      center: [lng, lat],
      zoom: zoom,
      transformRequest: (url, resourceType) => {
        if (resourceType === 'Tile' && url.match('tile')) {
          return {
            url: url,
            headers: { 'Hi-DPI': window.devicePixelRatio },
          };
        }
      },
    });

    map.current.on('load', () => {
      // Adding data source on map load
      map.current.addSource('locations', {
        type: 'geojson',
        data: geojsonData,
        cluster: cluster,
        clusterMaxZoom: 10,
        clusterRadius: 100,
      });

      // Adding heatmap layer on map load
      map.current.addLayer({
        id: 'locations-heatmap',
        type: 'heatmap',
        source: 'locations',
        maxzoom: 15,
        paint: {
          'heatmap-intensity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0,
            1,
            15,
            3,
          ],
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(33,102,172,0)',
            0.2,
            'rgb(202, 222, 154)',
            0.4,
            'rgb(194, 217, 137)',
            0.6,
            'rgb(209,229,240)',
            0.8,
            'rgb(185, 211, 120)',
            1,
            'rgb(168,201,87)',
          ],
          'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 15, 20],
          'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0],
        },
      });

      setIsActivityMapLoading(false);
    });
  }, [locations, projection, lng, lat, cluster]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  // Event Listener
  useEffect(() => {
    if (!map.current) return; // wait for the map to initialize

    const showPopup = (e) => {
      const features = map.current.queryRenderedFeatures(e.point, {
        layers: ['locations-heatmap'],
      });

      if (features.length) {
        const feature = features[0];

        new mapboxgl.Popup()
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            `<h3>${feature.properties.name}</h3><p>${feature.properties.content}</p>`,
          )
          .addTo(map.current);
      }
    };

    map.current.on('click', showPopup);

    return () => {
      map.current.off('click', showPopup);
    };
  }, []);

  return (
    <Grid item container sx={{ height: '600px' }}>
      {locations.length === 0 ? (
        <Grid item xs={12} className="intu__divider">
          <Skeleton
            ariant="rectangular"
            width="100%"
            height="100%"
            animation="wave"
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <div
            ref={mapContainer}
            className="map-container intu__divider"
            style={{
              width: '100%',
              height: '500px',
              marginBottom: 0,
              paddingBottom: 0,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default HeatMap;
