import React, { useEffect, useRef } from 'react';
import { useCallback } from 'react';

import { Grid, Box, Typography, Container } from '@mui/material';
import {
  industryRobotParticleDesktop,
  industryRobotParticleIpad,
  industryRobotParticleMobile,
} from '../../../animations/industryRobotParticles';

import '../headers.css';

// Particle Animations
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import { tsParticles } from 'tsparticles-engine';
import { loadPolygonMaskPlugin } from '@in2tec/tsparticles-mask-plugin';
import useWindowDimensions from '../../../hooks/useWindowDimension';

// Translator
import { useTranslation } from 'react-i18next';

const BlogsHeaderContainer = () => {
  const _isMounted = useRef(true);

  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  // SetUp Particle Options based on screen size
  let particleConfig;
  if (width > 820) {
    particleConfig = industryRobotParticleDesktop;
  } else if (width > 390) {
    particleConfig = industryRobotParticleIpad;
  } else {
    particleConfig = industryRobotParticleMobile;
  }

  // Particle Animations
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await container;
  }, []);

  useEffect(() => {
    _isMounted.current = true;

    const loadMask = async () => {
      try {
        await loadPolygonMaskPlugin(tsParticles);
        // Additional code to run after the plugin has loaded...
      } catch (error) {
        if (_isMounted.current) {
          // Handle error only if component is still mounted...
          console.error('Failed to load the Polygon Mask Plugin:', error);
        }
      }
    };

    loadMask();

    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <Container maxWidth="auto" disableGutters className="intu__divider">
      <Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          textAlign="center"
          sx={{ marginBottom: -0.5 }}
        >
          <Particles
            width="100%"
            height="600px"
            id="tsparticles"
            className="particles"
            options={particleConfig}
            init={particlesInit}
            loaded={particlesLoaded}
          />
          <Box
            sx={{
              width: '100%',
              height: 600,
              backgroundColor: '#a8c957',
              opacity: [0.3, 0.2, 0.1],
            }}
          ></Box>
          <Grid
            item
            container
            direction="column"
            alignContent="center"
            className="heroText"
          >
            <Grid item xs={12}>
              <Typography variant="h1" color="white">
                {t('context.headers.blog.technology')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export { BlogsHeaderContainer };
