// Helper Function is made for component fields where users pass in fieldID or autocompleteID e.g. password, company.name. It return an array

// export const getNestedValue = (obj, path) => {
//   return path.split('.').reduce((acc, part) => acc && acc[part], obj);
// };

export const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => {
    if (acc && typeof acc === 'object' && part in acc) {
      return acc[part];
    }
    return undefined;
  }, obj);
};
