import React from 'react';

import schlegel from '../../assets/brands/schlegel.png';
import disoric from '../../assets/brands/disoric.png';
import sigmatek from '../../assets/brands/sigmatek.png';
import led2work from '../../assets/brands/led2work.png';
import sick from '../../assets/brands/sick.png';
import werma from '../../assets/brands/werma.png';
import InfiniteSlider from '../../components/motion/ImageCarousel'; // Correct import name

const slides = [
  { src: schlegel, alt: 'Schlegel' },
  { src: disoric, alt: 'Di-Soric' },
  { src: led2work, alt: 'Led2Work' },
  { src: sigmatek, alt: 'Sigmatek' },
  { src: sick, alt: 'Sick' },
  { src: werma, alt: 'Werma' },
];

const BrandsInfiniteSliderContainer = () => {
  return <InfiniteSlider slides={slides} />;
};

export default BrandsInfiniteSliderContainer;
