import React from 'react';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';

function ProfileFormLayoutComponent() {
  return (
    <Grid container className="intu__form-page">
      <Grid className="intu__section intu__page_content">
        <Outlet />
      </Grid>
    </Grid>
  );
}

export default ProfileFormLayoutComponent;
