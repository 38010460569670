import { Grid, Typography } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { animate, useMotionValue, useTransform, motion } from 'framer-motion';
import { useEffect } from 'react';
import { ButtonHoverGradiant } from '../../buttons/ButtonHoverGradiant.jsx';
import { GlobeHero } from '../../../animations/globe/GlobeHero.jsx';

const HomeHeaderContent = () => {
  const { t } = useTranslation();

  const count = useMotionValue(0);
  const marketSize = useTransform(count, (value) => {
    const rounded = Math.round(value);
    return `${t('context.headers.home.statement1')} ${rounded} ${t('context.headers.home.statement2')}`;
  });

  useEffect(() => {
    const animation = animate(count, 115, { duration: 2 });

    return animation.stop;
  }, []);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const fName = searchParams.get('fName');
  const lName = searchParams.get('lName');
  const cName = searchParams.get('cName');

  const salesClick = () => {
    navigate('/saleshome', { state: { fName: fName, lName: lName } });
  };

  const manufacturerClick = () => {
    navigate('/mfghome', { state: { cName: cName } });
  };

  return (
    <GlobeHero>
      <Grid className="hero-video">
        <Grid
          item
          container
          direction="column"
          textAlign="center"
          padding="1rem"
        >
          <Grid item xs={12}>
            <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
              {t('context.headers.home.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              color="white"
              sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' } }}
            >
              <motion.div>{marketSize}</motion.div>
            </Typography>
          </Grid>
          <Typography variant="h2">
            {t('context.headers.home.subtitle')}
          </Typography>
          <Grid
            item
            container
            direction="row"
            spacing={{ xs: 2, md: 12 }}
            justifyContent="center"
          >
            <Grid item>
              <ButtonHoverGradiant
                label={t('context.headers.home.button_mfg')}
                onClick={manufacturerClick}
              />
            </Grid>
            <Grid item>
              <ButtonHoverGradiant
                label={t('context.headers.home.button_sales')}
                onClick={salesClick}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GlobeHero>
  );
};

export { HomeHeaderContent };
