import { useTranslation } from 'react-i18next';
import ResetPasswordForm from '../../components/account/forms/reset/ResetPasswordForm';
import { IntuForm } from '../../components/layout/IntuForm';
import IntuPage from '../../components/layout/IntuPage';
import './account.css';

const AccountResetPassword = () => {
  const { t } = useTranslation();
  return (
    <IntuPage
      pageTitle={t('context.account.resetPasswordContext.title')}
      pageDescription={t('context.account.resetPasswordContext.body')}
      hCentered={true}
    >
      <IntuForm>
        <ResetPasswordForm
          formType="accPassReset"
          buttonLabel={t('buttons.request_password')}
          buttonAction="accPassReset"
          showEmail={true}
          showDefinePassword={false}
          showOTP={false}
          showSigninButton={true}
        />
      </IntuForm>
    </IntuPage>
  );
};
export default AccountResetPassword;
