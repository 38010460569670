import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Grid, Skeleton } from '@mui/material';
import {
  convertDataToGeoJSON,
  createColorExpression,
  getUniqueCategories,
  getUniqueCategoriesWithColors,
} from '../../helpers/mapHelperFunctions';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

const intuLightPurple = '#e8d46f';
const intuOrange = '#d76446';
const intuLightBlue = '#6ea4d4';
const intuDarkGreen = '#A8C957';
const intuDarkGrey = '#333333';
const intuMiddleGrey = '#6F7978';

// Style URLs. Replace these with your actual URLs.
const styles = {
  globe: 'mapbox://styles/intutecio/clmbwv14801ay01pf80c7hkm6',
  mercator: 'mapbox://styles/intutecio/clnqhhdpn00fc01qw0w2j6y4a',
};

const ClusteredMap = ({
  locations, // [{name: required, content: `<h3>${title}</h3><p>${content}</p>`, category: 'optional', coordinates: { lat: latValue, lng: lngValue}
  initialZoom,
  projection, // globe or mercator
  cluster = true, // true or false
  center = [-74.006, 40.7128], // Starting position [lng, lat]
}) => {
  const [isMounted, setIsMounted] = useState(false);

  // Convert Data to GEOJSON Format
  const mapContainerRef = useRef(null);
  const geojsonData = convertDataToGeoJSON(locations);

  // An array of colors you want to use for the categories
  const categoryColors = getUniqueCategoriesWithColors(locations);

  // Assuming you have a function to get unique categories from locations
  const uniqueCategories = getUniqueCategories(locations);

  // The default color if no category matches
  const defaultColor = intuDarkGreen;

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: styles[projection] || 'mapbox://styles/mapbox/streets-v11', // Set style based on projection if provided
      center: center,
      zoom: initialZoom,
      attributionControl: false,
    });

    map.on('load', () => {
      // Add source and layer for the points
      map.addSource('points', {
        type: 'geojson',
        data: geojsonData,
        cluster: cluster,
        clusterMaxZoom: 10,
        clusterRadius: 40,
      });

      // Cluster Layer
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'points',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            intuDarkGreen,
            100,
            intuMiddleGrey,
            750,
            intuLightBlue,
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      const colorExpression = createColorExpression(
        uniqueCategories,
        categoryColors,
        defaultColor,
      );
      console.log(colorExpression); // Check the output here

      // Add the individual points layer with category-based color
      map.addLayer({
        id: 'individual-points',
        type: 'circle',
        source: 'points',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': colorExpression,
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });

      map.on('click', 'individual-points', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const properties = e.features[0].properties;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(properties.content) // Use the 'content' property for the popup
          .addTo(map);
      });
    });

    setIsMounted(true);

    return () => map.remove();
  }, [locations]);

  return (
    <Grid container className="intu__divider">
      {!isMounted ? (
        <Skeleton
          ref={mapContainerRef}
          ariant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
        />
      ) : (
        <Grid item xs={12} ref={mapContainerRef} style={{ height: '500px' }} />
      )}
    </Grid>
  );
};

export default ClusteredMap;
