import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { AccountConfirmForm } from '../../components/account/forms/confirm/AccountConfirmForm';
import IntuPage from '../../components/layout/IntuPage';
import './account.css';

function AccountConfirm() {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const userAction = params.get('userAction');

  return (
    <IntuPage
      pageTitle={t('pages.account.AccountConfirm.title')}
      pageDescription={t('pages.account.AccountConfirm.description', {
        type: userAction === 'userLogin' ? t('types.sms') : t('types.email'),
      })}
    >
      <IntuForm form="Account Confirmation">
        <AccountConfirmForm />
      </IntuForm>
    </IntuPage>
  );
}
export default AccountConfirm;
