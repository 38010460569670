// Format Entered Values to Title Case e.g. Products
function toTitleCase(str) {
    // Guard Clause
    if (str === "undefined" || str === null || !str) {
        return str
    } else {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }
}

export { toTitleCase };