import axios from 'axios';
import i18n from '../../i18n';

const BASE_URL = `${process.env.REACT_APP_API_PATH}:${process.env.REACT_APP_API_PORT}`;

axios.defaults.baseURL = BASE_URL;

axios.interceptors.request.use(
  function (config) {
    config.headers['Accept-Language'] = i18n.language;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;