import { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Input from '@mui/material/Input';
import { IntuIconButton } from './inputStyles';

function StyledInput({
  label,
  type,
  startAdornment,
  endAdornment,
  onValueChange,
  value,
  disabled,
  variant = 'outlined',
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  };

  const handleToggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputProps = {
    id: `styled-${label}`,
    disabled: disabled,
    type: type === 'password' && showPassword ? 'text' : type,
    value: value,
    onChange: handleChange,
    startAdornment: startAdornment,
    endAdornment:
      endAdornment ||
      (type === 'password' ? (
        <InputAdornment position="end">
          <IntuIconButton
            aria-label="toggle password visibility"
            onClick={handleToggleVisibility}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IntuIconButton>
        </InputAdornment>
      ) : null),
  };

  return (
    <FormControl fullWidth variant={variant}>
      <InputLabel htmlFor={`styled-${label}`}>{label}</InputLabel>
      {variant === 'outlined' ? (
        <OutlinedInput {...inputProps} label={label} />
      ) : (
        <Input {...inputProps} />
      )}
    </FormControl>
  );
}

export default StyledInput;
