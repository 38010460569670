import './spinner.css';
import { Container, Grid, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LoadingButton from '@mui/lab/LoadingButton';
import { TailSpin } from 'react-loader-spinner';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

export default function Spinner({ text }) {
  const { t } = useTranslation();

  return (
    <div className="overlay content-centered">
      <Container maxWidth="lg" className="spinner">
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sx={{ paddingBottom: '50px' }}
            className="content-centered"
          >
            <TailSpin
              height="80"
              width="80"
              color="#A8C957"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingBottom: '50px' }}>
            <Typography className="wrap content-centered" variant="h2">
              {text ? ReactHtmlParser(text) : t('form.message.prepare_content')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export function PendingSpinner({ text, cta, callback }) {
  const { t } = useTranslation();

  return (
    <div
      className="overlay content-centered"
      style={{ background: 'rgba(0,0,0,0.7)' }}
    >
      <Container maxWidth="lg" className="spinner">
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} pb={5}>
            <Typography className="wrap content-centered" variant="h2">
              {text ? ReactHtmlParser(text) : t('form.message.prepare_content')}
            </Typography>
          </Grid>
          <Grid item xs={12} pb={5}>
            <LoadingButton
              loading={callback ? false : true}
              loadingPosition="end"
              endIcon={<NavigateNextIcon />}
              onClick={callback}
              variant="contained"
              sx={{
                '&.MuiLoadingButton-root': {
                  border: '1px solid #A8C957',
                  color: callback ? 'initial' : '#A8C957',
                },
                '& .MuiLoadingButton-loadingIndicator': {
                  color: '#A8C957',
                },
              }}
            >
              {cta}
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
