import React, { createContext, useEffect, useState } from 'react';
import useResponseHandling from '../../hooks/useResponseHandler';
import {
  addApplications,
  getApplications,
} from '../../routes/applicationsRoutes';
import useAuth from '../../hooks/useAuth';
import useUser from '../../hooks/useUser';
import i18n from '../../i18n';
import { getIndustries, searchIndustries } from '../../routes/industriesRoutes';

export const ApplicationsContext = createContext();

export const ApplicationsProvider = ({ children }) => {
  // Application Dialog States
  const { auth } = useAuth();
  const { user } = useUser();

  const [newApplicationDialogOpen, setNewApplicationDialogOpen] =
    useState(false);
  const [applicationNew, setApplicationNew] = useState({
    application_name: '',
    industry_id: '',
  });
  const [industryOptions, setIndustryOptions] = useState([]);
  const [industryNames, setIndustryNames] = useState('');
  const [applicationOptions, setApplicationOptions] = useState([]);
  const [newApplicationList, setNewApplicationList] = useState([]);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const addNewApplicationsToDatabase = async () => {
    if (newApplicationList.length === 0) {
      return;
    }

    try {
      const response = await addApplications(newApplicationList);

      if (response.statusCode === 200) {
        setNewApplicationList([]);
        return response.data;
      } else {
        handleRegularResponse({
          open: true,
          status: 'warning',
          message: response.message,
        });
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  // Get Industries
  async function handleGetIndustries() {
    try {
      const industriesList = await getIndustries();
      if (industriesList.status === 'success') {
        setIndustryOptions(industriesList.data);
      }
    } catch (error) {
      // handleErrorResponse(err);
      console.error('error', error);
    }
  }

  // Get Industries by Name
  async function handleSearchIndustries(industriesByID) {
    let results = await searchIndustries(industriesByID);
    let resultsArray = [];
    if (results?.length > 1) {
      results.map((industry) => {
        resultsArray.push(industry.industry_name);
      });
      setIndustryNames(resultsArray);
    } else {
      setIndustryNames(results);
    }
  }

  // Pre Load Form
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    //Get Applications from database
    async function handleGetApplicationOptions() {
      try {
        let applicationsList = await getApplications();
        if (applicationsList.status === 'success') {
          setApplicationOptions(applicationsList.data);
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    async function preLoadformInfo() {
      await handleGetApplicationOptions();
      await handleGetIndustries();
      setNewApplicationList([]);
    }

    preLoadformInfo().then(() => {
      controller.abort(signal);
    });
  }, [auth?.user_info?.account_type, user.location, i18n.language]);

  return (
    <ApplicationsContext.Provider
      value={{
        newApplicationDialogOpen,
        setNewApplicationDialogOpen,
        industryOptions,
        setIndustryOptions,
        industryNames,
        setIndustryNames,
        handleGetIndustries,
        handleSearchIndustries,
        applicationNew,
        setApplicationNew,
        newApplicationList,
        applicationOptions,
        setApplicationOptions,
        setNewApplicationList,
        addNewApplicationsToDatabase,
      }}
    >
      {children}
    </ApplicationsContext.Provider>
  );
};
