import { useContext, useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { LayoutBodyContext } from '../../context/providers/LayoutContextProvider';

const IntuPage = ({
  pageTitle, // pageTitle
  pageDescription, // Page Description
  hCentered, // Horizontal center true/false (Boolean)
  vCentered, // Vertical center true/false (Boolean)
  children, // Actual Content as chidlren (React.Element)
}) => {
  const { setLayoutTitle, setLayoutDescription } =
    useContext(LayoutBodyContext);

  // Change Form Title and Description on Language Change
  useEffect(() => {
    setLayoutTitle(pageTitle);
    setLayoutDescription(pageDescription);
  }, [pageTitle, pageDescription]);

  const alignItems = hCentered ? 'center' : 'flex-start';
  const alignText = hCentered ? 'center' : 'left';
  const justifyContent = vCentered ? 'center' : 'flex-start';

  return (
    <Container
      component="section"
      maxWidth="xxl"
      sx={{
        margin: '15rem 0 0 0',
        width: '100%',
      }}
    >
      <Grid
        container
        item
        maxWidth="xxl"
        direction="column"
        alignItems={alignItems}
        justifyContent={justifyContent}
      >
        <Grid item className="title" xs={12} pb="3rem">
          {pageTitle && (
            <Typography variant="h1" textAlign={alignText}>
              {pageTitle}{' '}
            </Typography>
          )}
          {pageDescription && (
            <Typography variant="h5" textAlign={alignText}>
              {pageDescription}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column-reverse"
          justifyContent={justifyContent}
          alignItems={alignItems}
        >
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

export default IntuPage;
