import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.primary.main}`,
  margin: 2,
  boxShadow: theme.shadows[2],
  cursor: 'pointer',
  transition: theme.transitions.create(['background'], {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeInOut,
  }),
  '&:hover': {
    background: theme.palette.primary.main,
  },
}));

// TODO: add long press to buttons
const ArrowControlsContainer = ({ setCameraPosition }) => {
  const handleUpClick = () => {
    setCameraPosition((prevState) => {
      const [x, y, z] = prevState;
      return [x, y, z - 1];
    });
  };

  const handleLeftClick = () => {
    setCameraPosition((prevState) => {
      const [x, y, z] = prevState;
      return [x - 1, y, z];
    });
  };

  const handleDownClick = () => {
    setCameraPosition((prevState) => {
      const [x, y, z] = prevState;
      return [x, y, z + 1];
    });
  };

  const handleRightClick = () => {
    setCameraPosition((prevState) => {
      const [x, y, z] = prevState;
      return [x + 1, y, z];
    });
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr"
      gridTemplateRows="1fr 1fr"
      position="absolute"
      bottom={-75}
      right={40}
    >
      <Box sx={{ pointerEvents: 'none' }} />
      <StyledBox>
        <IconButton
          onClick={handleUpClick}
          sx={{ width: '100%', height: '100%' }}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      </StyledBox>
      <Box sx={{ pointerEvents: 'none' }} />
      <StyledBox>
        <IconButton
          onClick={handleLeftClick}
          sx={{ width: '100%', height: '100%' }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      </StyledBox>
      <StyledBox>
        <IconButton
          onClick={handleDownClick}
          sx={{ width: '100%', height: '100%' }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </StyledBox>
      <StyledBox>
        <IconButton
          onClick={handleRightClick}
          sx={{ width: '100%', height: '100%' }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </StyledBox>
    </Box>
  );
};

export default ArrowControlsContainer;
