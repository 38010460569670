import { useRef } from 'react';
import { Divider, Grid, Typography, useTheme } from '@mui/material';
import { motion, useInView } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import useIntuTheme from '../../../../hooks/useIntuTheme';

export const WhySignUp = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.lead_context',
  });

  // Theme
  const theme = useTheme();
  const { mode } = useIntuTheme();

  return (
    <Grid
      container
      spacing={8}
      direction={{ xs: 'column', md: 'row' }}
      mt={20}
      mb={40}
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid xs={12} item>
        <Typography
          variant="h1"
          align="center"
          sx={{ color: mode === 'dark' ? 'white' : 'black', fontWeight: '600' }}
        >
          {i18n.format(t('sales.how_it_works.title'), 'uppercase')}
        </Typography>
      </Grid>

      {/* Columns 1 */}
      <Grid item xs={12} md={4}>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={
            isInView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }
          }
          transition={{
            duration: 0.8,
            delay: 0.25,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          style={{
            border: `solid 1px ${theme.palette.primary.main}`,
            borderRadius: '10px',
            padding: '1rem',
            height: '100%',
            boxShadow: theme.shadows[10],
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            height="100%"
            flexWrap="nowrap"
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                textAlign="center"
                sx={{ marginBottom: 0 }}
              >
                {i18n.format(t('sales.how_it_works.col1_title'), 'uppercase')}
              </Typography>
            </Grid>
            <Divider flexItem />
            <Grid item xs={12} mt={5}>
              <Typography variant="body1" textAlign="center" mb="1rem">
                {t('sales.how_it_works.col1_point1')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center" mb="1rem">
                {t('sales.how_it_works.col1_point2')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center" mb="0.5rem">
                {t('sales.how_it_works.col1_point3')}
              </Typography>
            </Grid>
          </Grid>
        </motion.div>
      </Grid>
      {/* Column 2 */}
      <Grid item xs={12} md={4}>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={
            isInView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }
          }
          transition={{
            duration: 0.8,
            delay: 0.25,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          style={{
            border: `solid 1px ${theme.palette.primary.main}`,
            borderRadius: '10px',
            padding: '1rem',
            height: '100%',
            boxShadow: theme.shadows[10],
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            height="100%"
            flexWrap="nowrap"
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                textAlign="center"
                sx={{ marginBottom: 0 }}
              >
                {i18n.format(t('sales.how_it_works.col2_title'), 'uppercase')}
              </Typography>
            </Grid>
            <Divider flexItem />
            <Grid item xs={12} mt={5}>
              <Typography variant="body1" textAlign="center" mb="1rem">
                {t('sales.how_it_works.col2_point1')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center" mb="1rem">
                {t('sales.how_it_works.col2_point2')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center" mb="0.5rem">
                {t('sales.how_it_works.col2_point3')}
              </Typography>
            </Grid>
          </Grid>
        </motion.div>
      </Grid>
    </Grid>
  );
};
