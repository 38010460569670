import { useRef, useEffect, useState, useContext } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { TextField, CircularProgress, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import axios from 'axios';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import ProcessingContext from '../../context/providers/ProcessingProvider';

const WebsiteField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'website', // Defines the Name and ID of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'account.WebsiteField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Disables the field
  required = false, // Whether the field is required or not
  type, // The type for the translation
}) => {
  // Field Reference
  const websiteRef = useRef();

  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { t: transValidations } = useTranslation('validation');

  // Formik Context
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    validateField,
    setFieldError,
  } = useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const [loading, setLoading] = useState(false);

  const { isLoading } = useContext(ProcessingContext);

  useEffect(() => {
    if (touched?.fieldID) {
      setLoading(true);
      validateField(fieldID).finally(() => {
        setLoading(false);
      });
    }
  }, [values?.fieldID]);

  function checkForHTTP(url) {
    let newURL;
    if (url.includes("www.") && !url.includes("https://")) {
      newURL = "https://" + url;
      return newURL
    } else {
      return url
    }
  }

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        required={required}
        disabled={disabled}
        autoComplete="off"
        id={fieldID}
        name={fieldID}
        ref={websiteRef}
        className="form-select-field"
        variant="outlined"
        label={
          !disabled
            ? t('label', {
                type: type ? type : 'personal',
              })
            : ''
        }
        placeholder={
          !disabled
            ? t('placeholder', {
                type: type ? type : 'personal',
              })
            : ''
        }
        sx={{ paddingBottom: '50px' }}
        type="text"
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value);
        }}
        onBlur={(e) => {
          const { value, name } = e.target;
          const urlSearch = setTimeout(async () => {
            setLoading(true);
            const newURL = checkForHTTP(value)
            setFieldValue(fieldID, newURL)
            setTimeout(() => {
              validateField(fieldID)
            }, 100)
            try {
              const response = await axios.post(
                '/api/verify/website',
                { url: value },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                  },
                },
              );

              const { status } = response;
              if (status === 200) {
                validateField(name);
              }
            } catch (error) {
              setTimeout(() => {
                setFieldError(name, transValidations('website'));
              });
              console.error('error', error);
            } finally {
              clearTimeout(urlSearch);
              setLoading(false);
              handleBlur(e);
            }
          }, 100);
        }}
        InputProps={{
          endAdornment: loading ? <CircularProgress size={24} /> : null,
        }}
        value={fieldValue || ''}
        error={fieldError && fieldTouched ? true : false}
        helperText={fieldError && fieldTouched ? fieldError : null}
      />
    </FormikFieldWrapper>
  );
};

export default WebsiteField;
