import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationContext } from '../lists/LeadActivityList';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material';
import LocationsMap from '../../../components/maps/LocationsMap';

const LocationDialog = () => {
  // Get Context
  const { locationDetails, setLocationDetails } = useContext(LocationContext);

  const { t } = useTranslation();

  let locations = [
    {
      name: '',
      content: '',
      coordinates: [
        locationDetails?.location?.longitude,
        locationDetails?.location?.latitude,
      ],
    },
  ];

  const handleClose = () => {
    setLocationDetails({
      ...locationDetails,
      open: false,
    });
  };

  return (
    <Grid container>
      <Dialog
        onClose={handleClose}
        open={locationDetails.open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {t('context.admin.intutecLeads.activity_detail')}
        </DialogTitle>
        <DialogContent>
          {/* MAP */}
          {locationDetails?.location ? (
            <LocationsMap
              initialZoom={10}
              locations={locations}
              label={false}
            />
          ) : (
            <Skeleton width={'100%'} height={100} />
          )}
          <Stack spacing={2}>
            <TextField
              disabled
              variant="standard"
              type="text"
              label={t('form.date')}
              value={locationDetails.date}
              sx={{ width: '100%' }}
            />
            <TextField
              disabled
              variant="standard"
              type="text"
              label={t('form.activity.activity')}
              value={locationDetails.action}
              sx={{ width: '100%' }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t('buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export { LocationDialog };
