import React from 'react';
import { Grid } from '@mui/material';
import './headers.css';
import { PostHeaderContent } from '../../context/headers/PostHeaderContent';

const PostHeaderContainer = (props) => {
  return (
    <Grid className="intu__divider">
      <PostHeaderContent
        image={props?.image?.imageBlob}
        title={props?.content?.title}
        formik={props?.formik}
        handlePostImageChange={props?.functions}
        states={props?.states}
      />
    </Grid>
  );
};

export default PostHeaderContainer;
