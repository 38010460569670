import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';

const mui = LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

export const IntuGridStandard = styled(DataGrid)(({ theme }) => ({
  color: 'var)--intu-DarkGreen',
  // border: 'none',
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: 'var(--grid-header-background)',
    color: 'var(--grid-header-text-color)',
    // borderBottom: 'none',
    // border: "none",
    // width: '100%',
  },
  '& .MuiDataGrid-row': {
    // width: "100%",
    // borderBottom: 'none',
    // borderTop: "none"
  },
  // '& .MuiDataGrid-checkboxInput': {
  //     color: "white",
  // },
  // '& .MuiDataGrid-cell': {
  //     borderBottom: 'none'
  // }
}));

export const IntuGridPremium = styled(DataGridPremium)(({ theme }) => ({
  color: 'var)--intu-DarkGreen',
  // border: 'none',
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: 'var(--grid-header-background)',
    color: 'var(--grid-header-text-color)',
    // borderBottom: 'none',
    // border: "none",
    // width: '100%',
  },
  '& .MuiDataGrid-row': {
    // width: "100%",
    // borderBottom: 'none',
    // borderTop: "none"
  },
  // '& .MuiDataGrid-checkboxInput': {
  //     color: "white",
  // },
  // '& .MuiDataGrid-cell': {
  //     borderBottom: 'none'
  // }
}));
