import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAnalyticsPageViewTracker } from '../../hooks/useAnalyticsTracker';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthContext from './AuthProvider';
import {
  useAxiosPrivate,
  useAxiosPrivateData,
} from '../../hooks/axios/useAxiosPrivate';

// Create context
export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  // Get Context
  const { auth, isAdmin } = useContext(AuthContext);

  // Navigation
  const navigate = useNavigate();

  // Set States
  const [productInfo, setProductInfo] = useState({
    _id: '',
    product_name: '',
    product_group: [],
    product_group_options: [],
    mpn: '',
    currency: '',
    list_price: '',
    attributes: [],
    recommended_attributes: [],
    application: '',
    applications: [],
    images: [],
    documents: [],
    files: [],
    status: '',
    group_search: '',
    image: '',
    imageBlob: '',
    image_url: '',
    file: '',
    fileBlob: '',
    file_url: '',
    document: '',
    documentBlob: '',
    document_url: '',
    product_attribute: {
      category: '',
      type: '',
      value: '',
    },
    file_type: '',
    product_types: [],
    product_categories: [],
  });

  // Product Dialog State
  const [productDialog, setProductDialog] = useState({
    open: false,
    dialogTitle: '',
    dialogText: '',
    dialogPrimaryButtonText: '',
    dialogSecondaryButtonText: '',
    dialogAction: '',
  });

  // Viewport
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 767) {
        setMobileView(false);
      } else if (window.innerWidth < 767) {
        setMobileView(true);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
  });

  // SetUp Axios
  const axios = useAxiosPrivate();
  const axiosData = useAxiosPrivateData();

  // Location hook
  const location = useLocation();

  // Navigation
  const host_url = window.location.host;

  // google page view tracking tracking
  let { gaPageViewTracker } = useAnalyticsPageViewTracker();
  gaPageViewTracker({
    type: 'pageview',
    page: document.location.pathname,
    name: 'How it works Page (Sutomized)',
  });

  return (
    <ProductContext.Provider
      value={{
        productInfo,
        setProductInfo,
        productDialog,
        setProductDialog,
        host_url,
        location,
        mobileView,
        setMobileView,
        axios,
        axiosData,
        auth,
        isAdmin,
        navigate,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

// Custom hook to use the product context
export const useProductContext = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error('useProductContext must be used within a ProductProvider');
  }
  return context;
};
