import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, IconButton, Typography, Box } from '@mui/material';
import HeatMap from '../../../components/maps/HeatMap';
import { useFormikContext } from 'formik';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { ObjectId } from 'bson';
import { LocationDialog } from '../dialogs/LocationDialog';
import { DataGrid } from '@mui/x-data-grid';
import useIntuTheme from '../../../hooks/useIntuTheme.js';

export const LocationContext = createContext();

const LeadActivity = () => {
  // Translator
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');

  // Get Lead Context
  const { mobileView } = useIntuTheme;
  const { values } = useFormikContext();
  const [locationDetails, setLocationDetails] = useState({
    title: t('context.admin.intutecLeads.location_details'),
    dialogText: '',
    primaryButtonText: transButtons('close'),
    secondaryButtonText: '',
    dialogAction: 'view',
    open: false,
  });

  const activity = values?.activity;

  if (activity?.length === 0 || activity === 'undefined') {
    return;
  }

  const columns = [
    {
      field: 'date',
      headerName: t('form.date'),
      editable: false,
      resizable: false,
      sortable: true,
      minWidth: mobileView ? 120 : 150,
    },
    {
      field: 'action',
      headerName: t('form.action'),
      flex: 1,
      editable: false,
      resizable: false,
      sortable: false,
      minWidth: mobileView ? 30 : 60,
    },
    {
      field: 'city',
      headerName: t('form.address.city'),
      sortable: false,
      width: 100,
      renderCell: (param) => {
        const location = param.row.location;
        return location?.city;
      },
    },
    {
      field: 'state',
      headerName: t('form.address.state'),
      sortable: false,
      width: 80,
      renderCell: (param) => {
        const location = param.row.location;
        return location?.state;
      },
    },
    {
      field: 'postcode',
      headerName: t('form.address.postcode'),
      sortable: false,
      width: 80,
      renderCell: (param) => {
        const location = param.row.location;
        return location?.postcode;
      },
    },
    {
      field: 'country',
      headerName: t('form.address.country'),
      sortable: false,
      width: 80,
      renderCell: (param) => {
        const location = param.row.location;
        return location?.country;
      },
    },
    {
      field: 'edit',
      headerName: t('buttons.edit'),
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          let activityContent = params.row;

          setTimeout(() => {
            setLocationDetails({
              ...activityContent,
              open: true,
              dialogTitle: t('context.admin.intutecLeads.location_details'),
              dialogText: '',
              primaryButtonText: transButtons('close'),
              secondaryButtonText: '',
              dialogAction: 'view',
            });
          }, '100');
        };

        return (
          <IconButton onClick={onClick} aria-label="location" size="large">
            <MyLocationIcon />
          </IconButton>
        );
      },
    },
  ];

  // Prepare Map Data for all activities
  let locations = [];

  activity.map((item) => {
    if (item?.location?.longitude && item?.location?.latitude) {
      let location = {
        name: item.date,
        content: `${item?.action} on ${item?.date}`,
        coordinates: {
          lat: item?.location.latitude,
          lng: item?.location.longitude,
        },
      };
      return locations.push(location);
    }
  });

  return (
    <LocationContext.Provider value={{ locationDetails, setLocationDetails }}>
      <FormControl>
        <Typography variant="h6">
          {t('context.admin.intutecLeads.lead_activity')}
        </Typography>
        {/* Map */}
        <HeatMap initialZoom={3} locations={locations} label={true} />

        {/* Activity Log */}
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGrid
            density="compact"
            disableRowSelectionOnClick
            disableSelectionOnClick // Set this prop to true to disable checkbox selection on row click
            disableColumnMenu
            isCellEditable={(params) => false}
            getRowId={() => new ObjectId().toString()}
            rows={activity}
            columns={columns}
            sx={{
              color: 'var(--color-text-dark)',
              '&::-webkit-scrollbar': {
                width: 20,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'orange',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'red',
                borderRadius: 2,
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSize={10}
            pageSizeOptions={[5, 10, 25]}
          />
        </Box>
        <LocationDialog />
      </FormControl>
    </LocationContext.Provider>
  );
};

export { LeadActivity };
