import { createContext, useContext, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Fade,
  List,
  ListItem,
  TextField,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import ClearIcon from '@mui/icons-material/Clear';
import { searchCompaniesByName } from '../../../routes/companies.js';
import { useFormikContext } from 'formik';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider.jsx';
import { useTranslation } from 'react-i18next';
import { CompanyProfileContext } from '../../../context/providers/CompanyProfileProvider.jsx';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper.jsx';
import CompanyRelationshipDialog from './CompanyRelationshipDialog.jsx';
import CompanyNameChangeDialog from './CompanyNameChangeDialog.jsx';
import { getNestedValue } from '../../../helpers/getNestedValuesIn.js';

export const CompanyNameFieldContext = createContext({});

const CompanyNameField = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'company.name', // Determine the Name and ID of the neted Text Field
  autoCompleteID = 'company', // Determine the Name and ID of the AutoComplete Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'account.company.CompanyNameField', // KeyPrefix which holds label and placeholder
  transition = true, // Transition of the Field (true = fade in, false = fadeout)
  disabled = false, // Whether the field is disabled
  required = false, // Whether the field is required
  showAdmins = false, // Whether or not to show the company admins above the company name field
  showNameChange = false, // Whether or not to show the Name Change Button
}) => {
  const { transitionInterval } = useContext(IntuThemeContext);
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { values, errors, handleBlur, setFieldValue, touched } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const autoCompleteValue = getNestedValue(values, autoCompleteID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const {
    companyProfile,
    setNewCompany,
    companyClaimer,
    setCompanyClaimer,
    companyOptions,
    setCompanyOptions,
  } = useContext(CompanyProfileContext);

  const businessNameRef = useRef(null);
  const companynameRef = useRef(null);

  const [isLoadingCompanyName, setIsLoadingCompanyName] = useState(false);
  const [companyRelationshipDialogOpen, setCompanyRelationshipDialogOpen] =
    useState(false);
  const [companyNameChangeDialogOpen, setCompanyNameChangeDialogOpen] =
    useState(false);
  const [relationship, setRelationship] = useState('');

  const handleCompanyNameChange = async (e, option, reason) => {
    if (reason === 'createOption') {
      setNewCompany(true);
    } else if (reason === 'removeOption') {
      setNewCompany(false);
    } else if (reason === 'selectOption') {
      setCompanyClaimer({
        ...companyClaimer,
        company_id: option?._id,
        company_name: option?.name,
      });
      setCompanyRelationshipDialogOpen(true);
    }
  };

  const [first, second] = fieldID.split('.');

  return (
    <CompanyNameFieldContext.Provider
      value={{
        companyRelationshipDialogOpen,
        setCompanyRelationshipDialogOpen,
        relationship,
        setRelationship,
        companyNameChangeDialogOpen,
        setCompanyNameChangeDialogOpen,
      }}
    >
      <Grid
        container
        alignItems="center"
        direction={{ xs: 'column', lg: 'row' }}
      >
        {showAdmins && companyProfile?.admins?.length > 0 && (
          <Grid item xs={12} sx={{ width: '100%' }}>
            <Fade
              in={companyProfile?.admins?.length > 0}
              timeout={transitionInterval}
            >
              <Typography className="form-note highlight-text">
                {t('admins.description')}
              </Typography>
            </Fade>
            <List>
              {companyProfile.admins.map((employee, i) => {
                return (
                  <Fade
                    key={i}
                    in={companyProfile.admins.length > 0}
                    timeout={transitionInterval}
                  >
                    <ListItem key={i}>
                      <Chip label={employee?.name} />
                    </ListItem>
                  </Fade>
                );
              })}
            </List>
          </Grid>
        )}
        <Grid item xs={12} lg={12} sx={{ width: '100%' }}>
          <FormikFieldWrapper
            fieldTitle={fieldTitle}
            fieldDescription={fieldDescription}
            transition={transition}
          >
            {showNameChange ? (
              <TextField
                disabled
                autoComplete="off"
                type="text"
                placeholder={t('name.placeholder')}
                label={t('name.label')}
                id={fieldID}
                name={fieldID}
                className="form-select-field"
                sx={{ paddingBottom: '1rem' }}
                ref={businessNameRef}
                inputProps={{
                  autoComplete: 'off',
                }}
                value={fieldValue}
                error={fieldError && fieldTouched}
                helperText={fieldError && fieldTouched ? fieldError : null}
              />
            ) : (
              <Autocomplete
                freeSolo
                disabled={disabled}
                required={required}
                autoComplete={false}
                clearIcon={
                  <ClearIcon sx={{ color: 'var(--intu-lightGrey)' }} />
                }
                id={fieldID}
                name={fieldID}
                className="form-select-field"
                sx={{ paddingBottom: '1rem' }}
                options={companyOptions}
                ref={businessNameRef}
                getOptionLabel={(option) =>
                  option?.name || values[first][second]
                }
                value={autoCompleteValue || values[first][second]}
                onBlur={(e) => {
                  handleBlur(e);
                  setCompanyOptions([]);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={props.key}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: 'flex', width: 44 }}>
                          <BusinessIcon sx={{ color: 'text.secondary' }} />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: 'calc(100% - 44px)',
                            wordWrap: 'break-word',
                          }}
                        >
                          <Box key={option?.name} component="span">
                            {option?.name}
                          </Box>
                          <Typography variant="body2" color="text.secondary">
                            {option?.address?.city}, {option?.address?.state},{' '}
                            {option?.address?.country?.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
                onChange={handleCompanyNameChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={required}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue(fieldID, value);
                      if (value.length > 3) {
                        setIsLoadingCompanyName(true);
                        const companySearch = setTimeout(async () => {
                          const companyOptions =
                            await searchCompaniesByName(value);
                          if (
                            companyOptions?.statusCode === 200 &&
                            companyOptions?.data.length > 0
                          ) {
                            setCompanyOptions(companyOptions.data);
                          }
                          clearTimeout(companySearch);
                        }, 100);
                        setIsLoadingCompanyName(false);
                      }
                    }}
                    inputRef={companynameRef}
                    variant="outlined"
                    placeholder={t('name.placeholder')}
                    label={t('name.label')}
                    value={values?.name}
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'off',
                      type: 'text',
                      endAdornment: (
                        <>
                          {isLoadingCompanyName ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    error={fieldError && fieldTouched ? true : false}
                    helperText={fieldError && fieldTouched ? fieldError : null}
                  />
                )}
              />
            )}
          </FormikFieldWrapper>
        </Grid>
        {/* Company Name Change Button */}
        <Grid item xs={12} lg={4}>
          {showNameChange && companyProfile.isAdmin && (
            <Button
              onClick={() => {
                setCompanyNameChangeDialogOpen(true);
              }}
              color="warning"
            >
              {t('buttons.change_company')}
            </Button>
          )}
        </Grid>
      </Grid>
      <CompanyNameChangeDialog />
      <CompanyRelationshipDialog />
    </CompanyNameFieldContext.Provider>
  );
};

export default CompanyNameField;
