import { Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { RiveAnimationComponent } from '../../../../components/animations/RiveAnimationComponent';
import i18n from 'i18next';
import { useTheme } from '@emotion/react';
import { useIntuTheme } from '../../../../context/providers/IntuThemeProvider';
import { useTranslation } from 'react-i18next';

const IndependentButProtected = () => {
  const theme = useTheme();
  const { mode } = useIntuTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.lead_context',
  });

  return (
    <>
      <Typography
        variant="h2"
        align="center"
        sx={{
          color: mode === 'dark' ? 'white' : 'black',
          fontWeight: '600',
        }}
      >
        {i18n.format(t('sales.independent'), 'uppercase')}
      </Typography>
      <RiveAnimationComponent
        src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/youre_independent_2.riv"
        stateMachines="M34D7BG"
        width={small ? '300px' : '800px'}
        height={small ? '300px' : '800px'}
      />
    </>
  );
};

export { IndependentButProtected };
