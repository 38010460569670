import { Outlet } from 'react-router-dom';
import FormContextProvider from '../../context/providers/FormContextProvider';

function FormProviderComponent() {
  return (
    <FormContextProvider>
      <Outlet />
    </FormContextProvider>
  );
}

export default FormProviderComponent;
