import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import logoLight from '../../assets/logos/logo-dark-full.png';
import logoDark from '../../assets/logos/logo-light-full.png';
import useIntuTheme from '../../hooks/useIntuTheme';

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  const { mode } = useIntuTheme();

  return (
    <Grid
      container
      className="intu__section intu__page_content section__padding-content"
    >
      <Grid
        item
        container
        xs={12}
        direction="column"
        justifyContent="center"
        alignItems="center"
        className=""
      >
        <img
          width="300"
          src={mode === 'dark' ? logoDark : logoLight}
          alt="IN2TEC"
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className=""
      >
        <Typography variant="h1">You are not authorized!</Typography>
        <Typography variant="body1">
          You do not have access to the requested page...
        </Typography>
        <Button variant="contained" type="submit" onClick={goBack}>
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
};

export default UnauthorizedPage;
