import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Typography,
  Grid,
  Paper,
  ListSubheader,
} from '@mui/material';
import useUser from '../../../hooks/useUser.js';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { TermsOfServiceContext } from '../../../context/providers/TermsOfServicecontextProvider';
import { useFormikContext } from 'formik';

const ConsentDialog = ({ children }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useUser();
  const { t } = useTranslation();

  const {
    dialogOpen,
    setDialogOpen,
    dialogType,
    setDialogType,
    dialogTitle,
    setDialogTitle,
  } = useContext(TermsOfServiceContext);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  function handleConsent(consent) {
    let target;
    if (dialogType === 'tos') {
      target = 'tos_accepted';
    } else if (dialogType === 'ppa') {
      target = 'ppa_accepted';
    }

    setFieldValue(target, {
      accepted: consent,
      ip: consent ? user?.location?.ip : null,
      date: consent ? new Date() : null,
      unixTimeStamp: consent ? Math.floor(Date.now() / 1000) : null,
      agent: consent ? navigator.userAgent : null,
    });
    setTimeout(() => {
      setFieldTouched(target, true);
    }, 400);
    setDialogOpen(false);
    setDialogType('');
    setDialogTitle('');
  }

  const CustomPaperComponent = (props) => (
    <Paper
      sx={{
        // p: { color: '#333333' },
        ul: { color: 'white', paddingLeft: '1rem' },
      }}
      {...props}
    >
      {props.children}
      <ListSubheader>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              color="error"
              autoFocus
              onClick={props.onClose}
              sx={{ margin: '5px 5px 5px 5px' }}
            >
              {t('buttons.disagree')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleConsent(true)}
              sx={{ margin: '5px 5px 5px 5px' }}
              autoFocus
            >
              {t('buttons.agree')}
            </Button>
          </Grid>
        </Grid>
      </ListSubheader>
    </Paper>
  );

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="lg"
      open={dialogOpen}
      aria-labelledby="consent-dialog"
      PaperComponent={(props) => (
        <CustomPaperComponent {...props} onClose={() => handleConsent(false)}>
          <DialogTitle
            id="consent-dialog"
            sx={{ backgroundColor: 'background.paper' }}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogContent
            dividers
            style={{
              padding: theme.spacing(3),
              backgroundColor: 'background.paper',
            }}
          >
            <Grid container direction="column">
              {children}
            </Grid>
          </DialogContent>
        </CustomPaperComponent>
      )}
    ></Dialog>
  );
};

export default ConsentDialog;
