import React from 'react';

import { Box, Grid, Container } from '@mui/material';
import BackgoundImage from '../../assets/events/VendorEventsBackgroundPicture.jpg';
import './events.css';
import EventInfoContext from '../../components/events/EventInfoContent.jsx';
import { useTranslation } from 'react-i18next';
import { EventForm } from '../../components/events/EventForm.jsx';
import FormContextProvider from '../../context/providers/FormContextProvider';

function IntuVendorEvents() {
  const { t } = useTranslation();

  let agenda = [
    {
      topic: 'Opening & Greeting',
      time: '6:00 PM - 6:30 PM',
    },
    {
      topic: 'How we make a difference',
      time: '6:30 PM - 7:00 PM',
    },
    {
      topic: 'Current Applictions & Locations we serve',
      time: '7:00 PM - 7:30 PM',
    },
    {
      topic: 'How to get on board',
      time: '7:30 PM - 8:00 PM',
    },
    {
      topic: 'Q&A and Networking - Last Call (Food & Drinks)',
      time: '8:00 PM - 9:00 PM',
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        backgroundImage: `url(${BackgoundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container
        maxWidth="auto"
        disableGutters
        component="div"
        sx={{
          backgroundColor: 'var(--color-hero-overlay)',
          height: '100%',
          zIndex: 1,
        }}
      >
        <Grid container direction={{ xs: 'column', md: 'row' }}>
          {/* Event Info */}
          <Grid item xs={12} md={6} sx={{ padding: '4rem 0 8rem 0' }}>
            <EventInfoContext
              eventTitle={t('context.events.VendorEvents.event_title')}
              eventSubTitle={t('context.events.VendorEvents.event_subtitle')}
              eventInfo={t('context.events.VendorEvents.event_info')}
              eventAgenda={agenda}
            />
          </Grid>

          {/* RSVP Form */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              backgroundColor: 'var(--color-modal-bg)',
              padding: '2rem',
            }}
          >
            <FormContextProvider>
              <EventForm formType="vendor" buttonLabel="RSVP" />
            </FormContextProvider>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default IntuVendorEvents;
