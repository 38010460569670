import * as Yup from 'yup';
import dayjs from 'dayjs';
import i18next from 'i18next';

const eighteenYearsAgo = dayjs().subtract(18, 'year');

export const createUserProfileValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    first_name: Yup.string()
      .min(2, t('short'))
      .max(50, t('long'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.first_required'))
      .required(t('name.first_required')),
    last_name: Yup.string()
      .min(2, t('short'))
      .max(50, t('long'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.last_matches'))
      .required(t('name.last_required')),
    email: Yup.string()
      .required(t('email.user_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('email.invalid'),
      ),
    dob: Yup.date()
      .max(eighteenYearsAgo, t('dob.invalid'))
      .required(t('dob.required')),
    address: Yup.object({
      line1: Yup.string()
        .min(5, t('short'))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short'))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short'))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(3, t('short'))
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
    phone: Yup.object({
      value: Yup.string().required(t('phone.required')),
      info: Yup.object({
        countryCallingCode: Yup.number(t('phone.country_code.invalid'))
          .required(t('phone.country_code.required'))
          .typeError(t('phone.country_code.error')),
        nationalNumber: Yup.string()
          .required(t('phone.required'))
          .min(8, t('phone.min'))
          .max(15, t('phone.max')),
      }),
    }),
    profile_link: Yup.string()
      .required(1, t('profile_required'))
      .url(t('website_invalid'))
      .required(t('website_required')),
    languages: Yup.array().min(1, t('languages_min')),
    applications: Yup.array().min(1, t('application_min')),
    products: Yup.array().min(1, t('product_min')),
  });
};
