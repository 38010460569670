import { useRef, useState, forwardRef, useEffect } from 'react';
import {
  motion,
  useScroll,
  useTransform,
  useMotionValueEvent,
} from 'framer-motion';
import { Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import { DownArrows } from './DownArrows.jsx';
import './styles.css';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';
import { ButtonHoverGradiant } from '../buttons/ButtonHoverGradiant.jsx';
import useIntuTheme from '../../hooks/useIntuTheme.js';

export const ParallaxZoomOut = ({ image, overlay, content, button }) => {
  return (
    <ParallaxContainer image={image} overlay={overlay} button={button}>
      <ParallaxContent content={content} />
    </ParallaxContainer>
  );
};

const ParallaxContainer = ({ image, overlay, children, button }) => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end start'],
  });

  const theme = useTheme();
  const { mode } = useIntuTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const smallOpacity = useTransform(
    scrollYProgress,
    [0, 0.1, 0.15],
    [0.8, 0.8, 0],
  );
  const opacity = useTransform(scrollYProgress, [0, 0.15, 0.18], [0.8, 0.8, 0]);

  return (
    <motion.div>
      <Box className="parallaxZoomOutContainer">
        {/* Dark Overlay */}
        <motion.div
          style={{ opacity: small ? smallOpacity : opacity }}
          className={mode === 'dark' ? 'darkOverlay' : 'whiteOverlay'}
        />

        <ImageOverlay image={image} containerRef={containerRef} />
        <OverlayText
          overlay={overlay}
          containerRef={containerRef}
          button={button}
        />
      </Box>
      <Box
        style={{
          position: 'relative',
        }}
      >
        {children}
      </Box>
    </motion.div>
  );
};

const ImageOverlay = ({ image, containerRef }) => {
  const [animationMode, setAnimationMode] = useState('');
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  });
  const scale = useTransform(scrollYProgress, [0, 0.3], [4, 2]);

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    if (latest > 0.05) {
      setAnimationMode('end');
    } else {
      setAnimationMode('start');
    }
  });

  return (
    <motion.div
      layout
      style={{
        position: animationMode === 'start' ? 'fixed' : 'sticky',
        scale,
        top: '50%',
      }}
      className="imageContainer"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1.5 }}
      variants={{
        hidden: {
          opacity: 0,
          scale: 4,
        },
        visible: {
          opacity: 1,
          scale: 4,
        },
      }}
    >
      <img src={image} alt={image} width="100%"></img>
    </motion.div>
  );
};

const OverlayText = forwardRef((props, ref) => {
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start start', 'end end'],
  });
  const opacity = useTransform(scrollYProgress, [0.15, 0.2], [1, 0]);
  const theme = useTheme();

  const navigate = useNavigate();
  return (
    <motion.div style={{ opacity }} className="textContainer" ref={ref}>
      <Typography
        variant="h1"
        align="center"
        color={theme.palette.primary.contrastText}
      >
        {ReactHtmlParser(props.overlay.title)}
      </Typography>
      <Typography variant="h2" align="center">
        {ReactHtmlParser(props.overlay.subtitle)}
      </Typography>
      <ButtonHoverGradiant
        label="Join Now"
        onClick={() => navigate('/account/signup')}
      />
      <div style={{ marginTop: '30vh' }}>
        <DownArrows />
      </div>
    </motion.div>
  );
});

const ParallaxContent = (content) => {
  const contentRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: contentRef,
    offset: ['start end', 'end start'],
  });
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const medium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <motion.div
      style={{
        marginBottom: '10vh',
        opacity,
        // marginTop: small ? '1vh' : medium ? '2vh' : '5vh',
      }}
      ref={contentRef}
      className="contentContainer"
    >
      {content.content}
    </motion.div>
  );
};
