import { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormBodyContext } from '../../context/providers/FormContextProvider';

export function IntuForm({
  formTitle, // Form Description
  formDescription, // Form Description
  hCentered, // Horizontal center true/false (Boolean)
  vCentered, // Vertical center true/false (Boolean)
  children, // Actual Content as chidlren (React.Element)
}) {
  const { setFormTitle, setFormDescription, showForm } =
    useContext(FormBodyContext);

  // Change Form Title and Description on Language Change
  useEffect(() => {
    setFormTitle(formTitle);
    setFormDescription(formDescription);
  }, [formTitle, formDescription]);

  const alignItems = hCentered ? 'center' : 'flex-start';
  const alignText = hCentered ? 'center' : 'left';
  const justifyContent = vCentered ? 'center' : 'flex-start';

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      sx={{ width: '100%', mb: { xs: 15 } }}
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      <Grid item xs={12} textAlign={alignText}>
        {formTitle && <Typography variant="h3">{formTitle}</Typography>}
        {formDescription && (
          <Typography variant="body1">{formDescription}</Typography>
        )}
      </Grid>
      {showForm && (
        <Grid item xs={12} width="100%">
          {children}
        </Grid>
      )}
    </Grid>
  );
}
