import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  FormControl,
  Button,
  CircularProgress,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  useTheme,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useContext, useRef, useState } from 'react';
import i18n from '../../../i18n';
import { CompanyNameFieldContext } from './CompanyNameField';
import { useTranslation } from 'react-i18next';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { searchCompaniesByName } from '../../../routes/companies';
import { CompanyProfileContext } from '../../../context/providers/CompanyProfileProvider';
import BusinessIcon from '@mui/icons-material/Business';
import AuthContext from '../../../context/providers/AuthProvider';
import { useIntuTheme } from '../../../context/providers/IntuThemeProvider';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(i18n.t('validation.company.new_required_alt'))
    .min(3, i18n.t('validation.company.name_min'))
    .notOneOf(
      [Yup.ref('name_existing'), null],
      i18n.t('validation.company.name_one_of'),
    ),
});

const CompanyNameChangeDialog = () => {
  const { companyNameChangeDialogOpen, setCompanyNameChangeDialogOpen } =
    useContext(CompanyNameFieldContext);

  const {
    companyProfile,
    setCompanyProfile,
    companyOptions,
    setCompanyOptions,
  } = useContext(CompanyProfileContext);

  const { auth, setAuth } = useContext(AuthContext);
  const { t } = useTranslation('fields', {
    keyPrefix: 'account.company.CompanyNameChangeDialog',
  });

  const axios = useAxiosPrivate();
  const [nameValidaded, setNameValidaded] = useState(false);
  const [isLoadingCompanyName, setIsLoadingCompanyName] = useState(false);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const errRef = useRef(null);
  const formRef = useRef(null);

  const theme = useTheme();
  const { mode } = useIntuTheme();

  const initialValues = {
    name: '',
    name_existing: companyProfile.name,
  };

  //   Search fo existing Company Names
  async function handleSearchCompanyName(input) {
    // Validate the name and make sure it does not exist yet
    setIsLoadingCompanyName(true);
    try {
      const getCompany = await searchCompaniesByName(input);
      if (getCompany.statusCode === 200) {
        // Company already Exists
        const filteredName = getCompany.data.filter(
          (company) => company.name === companyProfile.name,
        );
        if (filteredName.length === 0) {
          setNameValidaded(false);
          setCompanyOptions(getCompany.data);
        } else {
          setNameValidaded(true);
          setCompanyOptions([]);
        }
      } else {
        setNameValidaded(true);
        setCompanyOptions(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCompanyName(false);
    }
  }

  //   Handle Company Change
  async function handleCompanyNameChange(values) {
    const controller = new AbortController();
    const { signal } = controller;

    const url = '/api/companies/company/update/name';
    const payload = {
      _id: companyProfile._id,
      name: values.name,
    };

    try {
      const response = await axios.put(url, JSON.stringify(payload), {
        signal,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      const { data, status } = response;

      if (status === 201) {
        const [newCompanyInfo] = data.data;
        // Updete Auth
        setAuth({
          ...auth,
          user_info: {
            ...auth.user_info,
            company: newCompanyInfo,
          },
        });

        // Close Dialog
        setCompanyNameChangeDialogOpen(false);

        // Set Parent Values
        setCompanyProfile({
          ...companyProfile,
          name: newCompanyInfo.name,
        });
      }
      handleRegularResponse({
        open: true,
        status: data.status,
        message: data.message,
      });
    } catch (error) {
      handleErrorResponse(error);
      errRef.current?.focus();
      console.error('error', error);
    } finally {
      controller.abort(signal);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {(formik) => {
        const {
          values,
          setFieldValue,
          errors,
          touched,
          setFieldTouched,
          resetForm,
        } = formik;
        return (
          <Dialog maxWidth="sm" fullWidth open={companyNameChangeDialogOpen}>
            <DialogTitle>{t('title')}</DialogTitle>
            <DialogContent>
              <Grid>
                <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                  <DialogContentText>
                    <Typography variant="body1">{t('description')}</Typography>
                  </DialogContentText>
                </Grid>

                {/* New Company Name */}
                <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    autoComplete="off"
                    className="form-select-field"
                    aria-invalid={errors.name ? 'true' : 'false'}
                    aria-describedby="uidnote"
                    variant="outlined"
                    label={t('form.name.label')}
                    type="text"
                    placeholder={t('form.name.placeholder')}
                    InputProps={{
                      autoComplete: 'off',
                      type: 'text',
                      endAdornment: (
                        <>
                          {isLoadingCompanyName ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                    onChange={(e) => {
                      const input = e.target.value;
                      setFieldValue(e.target.name, input);

                      if (input.length > 3) {
                        const search = setTimeout(async () => {
                          setNameValidaded(false);
                          await handleSearchCompanyName(input);
                          clearTimeout(search);
                        }, 500);
                      }

                      setTimeout(() =>
                        setFieldTouched(e.target.name, true, true),
                      );
                    }}
                    onBlur={async (e) => {
                      // Validate the name and make sure it does not exist yet
                      await handleSearchCompanyName(e.target.value);
                    }}
                    value={values.name}
                    error={touched?.name && errors?.name ? true : false}
                    helperText={
                      touched?.name && errors?.name ? errors?.name : null
                    }
                  />
                </Grid>

                {companyOptions?.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="body1" color="error">
                      {t('messages.error')}
                    </Typography>

                    <List>
                      {companyOptions.map((option, i) => {
                        return (
                          <ListItem key={i}>
                            <Grid container alignItems="center">
                              <Grid item sx={{ display: 'flex', width: 44 }}>
                                <BusinessIcon
                                  sx={{
                                    color:
                                      mode === 'dark'
                                        ? theme.palette.secondary.main
                                        : theme.palette.primary.contrastText,
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                sx={{
                                  width: 'calc(100% - 44px)',
                                  wordWrap: 'break-word',
                                }}
                              >
                                <Box key={option?.name} component="span">
                                  {option?.name}
                                </Box>
                                <Typography
                                  variant="body2"
                                  color={
                                    mode === 'dark'
                                      ? theme.palette.secondary.main
                                      : theme.palette.primary.contrastText
                                  }
                                >
                                  {option?.address?.city},{' '}
                                  {option?.address?.state},{' '}
                                  {option?.address?.country?.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              {/* Cancel */}
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setCompanyNameChangeDialogOpen(false);
                }}
              >
                {t('buttons.cancel')}
              </Button>

              {/* Change Button */}
              <Button
                variant="contained"
                color="warning"
                disabled={
                  !nameValidaded ||
                  errors?.name ||
                  values.name_existing === values.name
                    ? true
                    : false
                }
                type="submit"
                onClick={async (e) => {
                  // Update Company
                  handleCompanyNameChange(values);
                }}
              >
                {t('buttons.submit')}
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default CompanyNameChangeDialog;
