import {
    Box,
    Button,
    Container,
    Typography,
    Grid,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n.js';
import { useNavigate } from 'react-router-dom';
import '../home.css';
import { motion, useInView } from 'framer-motion';
import { ParallaxZoomOut } from '../../../components/motion/ParallaxZoomOut.jsx';
import { ScrollingCards } from '../../../components/motion/ScrollingCards.jsx';
import { ScrollingTextStickyImage } from '../../../components/motion/ScrollingTextStickyImage.jsx';
import { ButtonHoverGradiant } from '../../../components/buttons/ButtonHoverGradiant.jsx';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import GroupsIcon from '@mui/icons-material/Groups';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useContext, useEffect, useRef } from 'react';
import { LayoutBodyContext } from '../../../context/providers/LayoutContextProvider.jsx';
import { RiveAnimationComponent } from '../../../components/animations/RiveAnimationComponent.jsx';
import { SimpleZoomInOutcomponent } from '../../../components/motion/SimpleZoomInOut.jsx';
import useWindowDimensions from '../../../hooks/useWindowDimension.jsx';
import HowItWorksSignUpForm from '../../../components/howitworks/HowItWorksSignUpForm.jsx';

// Content
import { HeaderContent } from '../HeaderContent.jsx';
import { FindSalesRepsContent } from './FindSalesRepsContent.jsx';
import { CardFooter, CardHeader } from './CardHeaderFooter.jsx';
import { ScrollingText } from './ScrollingText.jsx';

import ResponseContext from '../../../context/providers/ResponseProvider.jsx';
import { StatusContent } from '../../../components/status/StatusContent.jsx';

export const ManufacturerHomeContent = () => {
    const { setLayoutTitle } = useContext(LayoutBodyContext);
    const { statusContext } = useContext(ResponseContext);

    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.home.MfgHome',
    });

    const { t: transButtons } = useTranslation('buttons');

    useEffect(() => {
        // Set the Page Title
        setLayoutTitle(t('title'));
    }, []);

    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const fName = params.get('fName') || '';
    const cName = params.get('cName') || '';

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const { height, width } = useWindowDimensions();

    const srcArray = [
        {
            type: 'image',
            src: small
                ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/MfgSearchRepsMobile.png'
                : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/MfgSearchDesktop.png',
            title: t('ParallaxZoomInOut1.title'),
            description: t('ParallaxZoomInOut1.description', {
                company: cName ? cName : i18n.t('your', { ns: 'common' }),
            }),
            progress: [0.5, 0.8, 1],
            zoom: [0, 0.5, 1],
            stickyVal: 0.75,
            titleMT: '20%',
            titleMB: small ? '0' : '10%',
        },
        {
            type: 'image',
            src: small
                ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/MfgOpportunitiesMobile.png'
                : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/MfgOpportunitiesDesktop.png',
            title: t('ParallaxZoomInOut2.title'),
            description: t('ParallaxZoomInOut2.description', {
                name: fName ? ` ${fName}` : '',
            }),
            footerContent: <FindSalesRepsContent />,
            progress: [0.5, 0.8, 1],
            zoom: [0, 0.5, 1.2],
            stickyVal: small ? 0.75 : 0.85,
            titleMT: '20%',
            titleMB: small ? '0' : '10%',
        },
        {
            type: 'image',
            src: small
                ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/MfgDashboardMobile.png'
                : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/MfgDashboardDesktop.png',
            title: t('ParallaxZoomInOut3.title'),
            description: t('ParallaxZoomInOut3.description', {
                companyName: cName ? cName + "'s" : i18n.t('your', { ns: 'common' }),
            }),
            stickyVal: small ? 0.75 : 0.85,
            titleMT: '20%',
            titleMB: small ? '0' : '10%',
            footerContent: (
                <Grid
                    container
                    spacing={2}
                    direction={{ xs: 'column', md: 'row' }}
                    mt={20}
                >
                    {/* Columns 1 */}
                    <Grid item xs={12} md={4}>
                        <motion.div
                            ref={ref}
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={
                                isInView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }
                            }
                            transition={{
                                duration: 0.8,
                                delay: 0.25,
                                ease: [0, 0.71, 0.2, 1.01],
                            }}
                            style={{
                                border: `solid 1px ${theme.palette.primary.main}`,
                                borderRadius: '10px',
                                padding: '1rem',
                                height: '100%',
                                boxShadow: theme.shadows[10],
                            }}
                        >
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="flex-start"
                                height="100%"
                                flexWrap="nowrap"
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h6" textAlign="center">
                                        {t('ParallaxZoomInOut3.column_1_header')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" textAlign="center" mb="1rem">
                                        {t('ParallaxZoomInOut3.column_1_content.title')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" textAlign="center" mb="0.5rem">
                                        {t('ParallaxZoomInOut3.column_1_content.item_1')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" textAlign="center" mb="0.5rem">
                                        {t('ParallaxZoomInOut3.column_1_content.item_2')}
                                    </Typography>
                                    <Typography variant="body1" textAlign="center" mb="0.5rem">
                                        {t('ParallaxZoomInOut3.column_1_content.item_3')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </motion.div>
                    </Grid>
                    {/* Column 2 */}
                    <Grid item xs={12} md={4}>
                        <motion.div
                            ref={ref}
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={
                                isInView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }
                            }
                            transition={{
                                duration: 0.8,
                                delay: 0.25,
                                ease: [0, 0.71, 0.2, 1.01],
                            }}
                            style={{
                                border: `solid 1px ${theme.palette.primary.main}`,
                                borderRadius: '10px',
                                padding: '1rem',
                                height: '100%',
                                boxShadow: theme.shadows[10],
                            }}
                        >
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="flex-start"
                                height="100%"
                                flexWrap="nowrap"
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h6" textAlign="center">
                                        {t('ParallaxZoomInOut3.column_2_header')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" textAlign="center" mb="1rem">
                                        {t('ParallaxZoomInOut3.column_2_content.title')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" textAlign="center" mb="0.5rem">
                                        {t('ParallaxZoomInOut3.column_2_content.item_1')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" textAlign="center" mb="0.5rem">
                                        {t('ParallaxZoomInOut3.column_2_content.item_2')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </motion.div>
                    </Grid>
                    {/* Column 3 */}
                    <Grid item xs={12} md={4}>
                        <motion.div
                            ref={ref}
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={
                                isInView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }
                            }
                            transition={{
                                duration: 0.8,
                                delay: 0.25,
                                ease: [0, 0.71, 0.2, 1.01],
                            }}
                            style={{
                                border: `solid 1px ${theme.palette.primary.main}`,
                                borderRadius: '10px',
                                padding: '1rem',
                                height: '100%',
                                boxShadow: theme.shadows[10],
                            }}
                        >
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="flex-start"
                                height="100%"
                                flexWrap="nowrap"
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h6" textAlign="center">
                                        {t('ParallaxZoomInOut3.column_3_header')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" textAlign="center" mb="1rem">
                                        {t('ParallaxZoomInOut3.column_3_content.title')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" textAlign="center" mb="0.5rem">
                                        {t('ParallaxZoomInOut3.column_3_content.item_1')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" textAlign="center" mb="0.5rem">
                                        {t('ParallaxZoomInOut3.column_3_content.item_2')}
                                    </Typography>
                                    <Typography variant="body1" textAlign="center" mb="0.5rem">
                                        {t('ParallaxZoomInOut3.column_3_content.item_3')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </motion.div>
                    </Grid>
                </Grid>
            ),
        },
    ];

    const StickyImages = [
        {
            type: 'animation',
            src: (
                <RiveAnimationComponent
                    src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/promote.riv"
                    stateMachines="State Machine 1"
                    width={small ? '300px' : '400px'}
                    height={small ? '300px' : '400px'}
                />
            ),
        },
        {
            type: 'animation',
            src: (
                <RiveAnimationComponent
                    src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/MfgDashboard.riv"
                    stateMachines="State Machine 1"
                    width={small ? '300px' : '400px'}
                    height={small ? '300px' : '400px'}
                />
            ),
        },
        {
            type: 'animation',
            src: (
                <RiveAnimationComponent
                    src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/popup_event.riv"
                    stateMachines="State Machine 1"
                    width={small ? '300px' : '400px'}
                    height={small ? '300px' : '400px'}
                />
            ),
        },
        {
            type: 'animation',
            src: (
                <RiveAnimationComponent
                    src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/connection_map.riv"
                    stateMachines="State Machine"
                    width={small ? '300px' : '400px'}
                    height={small ? '300px' : '400px'}
                />
            ),
        },
    ];

    const CardsContent = [
        <TravelExploreIcon
            sx={{ fontSize: { xs: 48, md: 76 }, color: '#4d4d4d' }}
        />,
        <GroupsIcon sx={{ fontSize: { xs: 48, md: 76, color: '#4d4d4d' } }} />,
        <TrendingUpIcon sx={{ fontSize: { xs: 48, md: 76 }, color: '#4d4d4d' }} />,
        <MenuBookIcon sx={{ fontSize: { xs: 48, md: 76 }, color: '#4d4d4d' }} />,
    ].map((icon, index) => {
        return (
            <Grid
                container
                direction={{ xs: 'column', md: 'row' }}
                alignItems="center"
                alignContent="center"
                justifyContent={{ xs: 'space-around', md: 'center' }}
                sx={{ height: '100%' }}
            >
                <Grid
                    xs={6}
                    item
                    container
                    direction={{ xs: 'column', md: 'row' }}
                    alignItems="center"
                    justifyContent={'space-around'}
                    sx={{ height: '65%', width: '90%' }}
                >
                    {/* Header */}
                    <Grid item xs={1}>
                        <Typography
                            sx={{ fontSize: { xs: 48, md: 96 }, color: '#A8C957' }}
                            align="left"
                        >
                            {index + 1}
                        </Typography>
                    </Grid>
                    {/* Icon & Label */}
                    <Grid xs={4} item container direction="column" alignItems="center">
                        <Grid item>{icon}</Grid>
                        <Grid item>
                            <Typography variant="h5" sx={{ color: '#4d4d4d' }}>
                                {i18n.t(`pages.home.MfgHome.CardsContent.title${index + 1}`)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Description */}
                <Grid xs={4} item>
                    <Typography
                        variant={small ? 'body1' : 'h5'}
                        sx={{ color: '#4d4d4d' }}
                        align="center"
                    >
                        {i18n.t(`pages.home.MfgHome.CardsContent.description${index + 1}`)}
                    </Typography>
                </Grid>
            </Grid>
        );
    });

    const ExplainerVideo = [
        {
            type: 'video',
            src: 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/sales_explained_en.mp4',
            title: t('Video.title'),
            description: t('Video.description'),
            progress: [0.5, 0.8, 1],
            zoom: [0, 0.5, 1.2],
            stickyVal: 0.9,
            noPadding: true,
            footerContent: (
                <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: `calc((${width} / ${height}) * 50vh)` }}
                >
                    <Grid item xs={12} md={8}>
                        <ButtonHoverGradiant
                            label={transButtons('join_now')}
                            onClick={() => navigate('/account/signup')}
                        />
                    </Grid>
                </Grid>
            ),
        },
    ];
    return (
        <>
            {/* Hero */}
            <Container maxWidth="auto" disableGutters sx={{ position: 'relative' }}>
                <ParallaxZoomOut
                    image={
                        small
                            ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/MfgDashboardMobile.png'
                            : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/MfgDashboardDesktop.png'
                    }
                    overlay={{
                        title: t('ParallaxZoomOut.title', {
                            company: cName ? cName + "'s" : 'your companies',
                        }),
                    }}
                    content={<HeaderContent />}
                    button={
                        <ButtonHoverGradiant
                            label={transButtons('join_now')}
                            onClick={() => navigate('/account/signup')}
                        />
                    }
                />
            </Container>

            {/* Content */}
            <Grid container direction="column" justifyContent="center">
                {/* Zoom In Zoom Out */}
                <Grid item xs={12}>
                    <SimpleZoomInOutcomponent srcArray={srcArray} />
                </Grid>

                {/* Explainer Video */}
                <Grid
                    container
                    direction="column"
                    sx={{ marginTop: `calc((${width} / ${height}) * 25vh)` }}
                >
                    <Grid item xs={12}>
                        <SimpleZoomInOutcomponent srcArray={ExplainerVideo} />
                    </Grid>
                </Grid>

                {/* Scrolling Cards */}
                <Grid item xs={12}>
                    <ScrollingCards
                        contentArr={CardsContent}
                        header={<CardHeader />}
                        footer={<CardFooter />}
                    />
                </Grid>

                {/* Sticky Images Carousel */}
                <Grid item xs={12} sx={{ marginTop: '25rem' }}>
                    <ScrollingTextStickyImage
                        imgArr={StickyImages}
                        textArr={ScrollingText}
                    />
                </Grid>
                <Box
                    sx={{
                        width: { xs: '80%', sm: '80%', md: '60%' },
                        margin: '0 auto',
                        paddingBottom: { xs: '150px', sm: '150px', md: '100px' },
                    }}
                >
                    {statusContext.open === true ? (
                        <StatusContent />
                    ) : (
                        <>
                            <HowItWorksSignUpForm
                                initialStep={1}
                                initialType="manufacturer"
                                title={t('HowItWorks.title')}
                            />
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography my={5}>{t('HowItWorks.not_convinced')}</Typography>
                                <Button variant="outlined" onClick={() => navigate('/contact')}>
                                    {transButtons('navigation.contact_us')}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Grid>
        </>
    );
};
