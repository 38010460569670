import React, { createContext, useEffect, useState } from 'react';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ auth_info: '', user_info: {} });
  const [isAdmin, setIsAdmin] = useState(false);
  const [checkAuth, setCheckAuth] = useState(false);

  const axios = useAxiosPrivate();

  // Checks for Aythentication when loading the App
  useEffect(() => {
    // Guard cLause
    if (!auth?.auth_info) {
      return;
    }

    const checkIsAdmin = async () => {
      if (!auth?.auth_info || !auth?.user_info) {
        return;
      }

      const url = '/api/users/roles/role/verify';
      const query = { roles: ['admin'] };

      try {
        const request = await axios.get(url, {
          params: query,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.auth_info}`,
          },
          withCredentials: true,
        });

        const response = request?.data;

        if (request?.status === 200) {
          setIsAdmin(response.authorized);
        } else {
          setIsAdmin(false);
        }
      } catch (err) {
        setIsAdmin(false);
      }
    };

    checkIsAdmin();
  }, [auth?.auth_info]);

  return (
    <AuthContext.Provider
      value={{ auth, setAuth, isAdmin, checkAuth, setCheckAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
