import { useContext } from 'react';
import ProcessingContext from '../context/providers/ProcessingProvider';
import ResponseContext from '../context/providers/ResponseProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const focusOnField = (formRef, field) => {
  let errors = formRef.current?.errors;
  console.log(`Current errors ${JSON.stringify(errors)}`);

  let submitCount = formRef.current?.submitCount;
  console.log(`Current submit count ${JSON.stringify(submitCount)}`);

  if (!field) {
    if (!!errors.length && submitCount > 0) {
      const firstElement = document.querySelector(`#${errors[0]}`);
      if (firstElement !== document.activeElement) {
        firstElement.focus();
      }
    }
  } else {
    const element = document.querySelector(`#${field}`);
    element.focus();
  }
};

const useResponseHandling = () => {
  const { setStatusMsg } = useContext(ResponseContext);
  const { setIsLoading } = useContext(ProcessingContext);
  const navigate = useNavigate();

  const { t } = useTranslation('messages');

  const handleErrorResponse = (err, formRef, field) => {
    if (!err.response) {
      // No Server Response
      setStatusMsg({
        open: true,
        status: 'error',
        message: 'No Server Response',
      });
    } else if (err.response?.status === 400) {
      // Missing UserName or Password
      if (err.response?.data?.message) {
        setStatusMsg({
          open: true,
          status: 'error',
          message: err.response.data.message,
        });
      } else {
        setStatusMsg({
          open: true,
          status: 'error',
          message: err.response.statusText,
        });
      }
    } else if (err.response?.status === 401) {
      // Unauthorized and/or Re-authentication Failed
      navigate('/account/signin');
      setStatusMsg({
        open: true,
        status: 'error',
        message: t('responses.401'),
      });
    } else {
      // Other Errors
      setStatusMsg({
        open: true,
        status: 'error',
        message: err.response.statusText,
      });
    }
    setIsLoading({ status: false, type: '', text: '' });

    if (formRef) {
      focusOnField(formRef, field);
    }
  };

  // Response Handling
  const handleRegularResponse = (response) => {
    setIsLoading({ status: false, type: '', text: '' });
    setStatusMsg(response);
  };

  return { handleErrorResponse, handleRegularResponse };
};

export default useResponseHandling;
