import * as Yup from 'yup';
import i18next from 'i18next';

export const createChangeUserEmailValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  return Yup.object().shape({
    new_email: Yup.string()
      .required(t('validation.email.user_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('validation.email.invalid'),
      ),
    userOTP: Yup.string().when('change_password', {
      is: true,
      then: () =>
        Yup.string()
          .required(t('validation.otp.required'))
          .min(4, t('validation.short')),
    }),
  });
};
