import React, { useContext, useEffect, useState } from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import {
  Typography,
  Checkbox,
  Link,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { useFormikContext } from 'formik';
import useUser from '../../../hooks/useUser';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';

const StripeServiceAgreement = ({
  fieldTitle, // Set the Field title
  transition = true, // Whether the Field should be visible,
  required = true, // Whether the Field is required or not
  countryID = 'individual.address.country', // The key of the country field within the parent formik context
  fieldID = 'ssa_accepted', // The key/id of the field within the formik context
  transNS = 'translation', // Translation Name Space
  transPrefix = 'form.consent.ssa', // Translation prefix
}) => {
  const { user } = useUser();

  // Traanslation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  // Context
  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldTouched,
  } = useFormikContext();

  const [accountCountry, setAccountCountry] = useState({
    label: '',
    code: '',
  });

  // Variables
  const userIP = user.location.ip;

  const fieldValue = getNestedValue(values, fieldID);
  const countryValue = getNestedValue(values, countryID);

  useEffect(() => {
    if (!countryValue) {
      setAccountCountry({
        label: user.location.countryLabel,
        code: user.location.country,
      });
    } else {
      setAccountCountry({
        label: countryValue?.label,
        code: countryValue?.code,
      });
    }
  }, [user.location.country, countryValue?.code]);

  return (
    <>
      <FormikFieldWrapper fieldTitle={fieldTitle} transition={transition}>
        <Typography level="body-sm">
          <Trans
            i18nKey={t('note')}
            values={{
              label: accountCountry.label,
            }}
            components={[
              <Link
                className="link"
                href={`https://stripe.com/${accountCountry.code}/legal/ssa#general-terms`}
                target="_blank"
                rel="noopener"
              />,
            ]}
          />
        </Typography>
      </FormikFieldWrapper>

      <FormikFieldWrapper required={required} transition={transition}>
        <FormGroup>
          <FormControlLabel
            checked={fieldValue?.accepted}
            control={
              <Checkbox
                name={fieldID}
                onBlur={handleBlur}
                onChange={(e) => {
                  let consent = e.target.checked;
                  setFieldValue(fieldID, {
                    accepted: consent,
                    ip: consent ? userIP : '',
                    date: consent ? new Date() : '',
                    unixTimeStamp: consent ? Math.floor(Date.now() / 1000) : '',
                    agent: consent ? navigator.userAgent : '',
                  });
                  setTimeout(() => {
                    setFieldTouched(fieldID, true);
                  }, 300);
                }}
                inputProps={{
                  'aria-label': 'controlled',
                }}
              />
            }
            label={<Typography level="body-sm">{t('label')}</Typography>}
          />
        </FormGroup>
      </FormikFieldWrapper>
    </>
  );
};

export default StripeServiceAgreement;
