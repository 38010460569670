import React, { useContext } from 'react';
import { useState, useEffect, useRef } from 'react';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate.js';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsTracker.jsx';

// Global Functions
import { toTitleCase } from '../../helpers/toTitleCase.js';

// Import context
import useAuth from '../../hooks/useAuth.js';

// Formik
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';

// MUI Compoents
import {
  GridToolbar,
  GridRow,
  GridColumnHeaders,
} from '@mui/x-data-grid-premium';
import {
  Typography,
  Skeleton,
  Box,
  Stack,
  FormControl,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Autocomplete,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { classes } from '../../mui/theme.js';
import dayjs from 'dayjs';

// Icons
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

// Response & Loading Handlers
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';

// Translator
import { useTranslation } from 'react-i18next';
import { ObjectId } from 'bson';
import { IntuCompanyContext } from '../../context/providers/IntuCompanyInfoProvider.jsx';
import AssociateActivitySelectionField from '../../components/activity/AssociateActivitySelectionField.jsx';
import DatePickerField from '../../components/date/DatePickerField.jsx';

const IntuContactList = () => {
  // State & Context
  const { auth } = useAuth();
  const { employeeOptions, handleGetEmployees } =
    useContext(IntuCompanyContext);

  const [contacts, setContacts] = useState();
  const effectRun = useRef(false);

  // Translator
  const { t } = useTranslation();
  const { t: transDialogs } = useTranslation('dialogs');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  // Google Event Tracker
  const { gaEventTracker } = useAnalyticsEventTracker();

  // Axios
  const axios = useAxiosPrivate();

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { isLoading, setIsLoading } = useProcessingHandler();

  const [contactDialog, setContactDialog] = useState({
    open: false,
    contactDialogTitle: '',
    contactDialogText: '',
    contactPrimaryButtonText: '',
    contactSecondaryButtonText: '',
    contactDialogAction: '',
  });
  const [contactInfo, setContactInfo] = useState({
    created: '',
    first_name: '',
    last_name: '',
    company: '',
    message: '',
    email: '',
    status: '',
    associate_activity: [],
    activityLog: [],
    associate: {
      _id: '',
      name: '',
      email: '',
    },
    message_reviewed: false,
  });

  const [activityDialog, setActivityDialog] = useState({
    openDialog: false,
    contactID: '',
    activityDialogAction: '',
    activityDialogTitle: '',
    activityDialogText: '',
    activityPrimaryButtonText: '',
    activitySecondaryButtonText: '',
    activityContent: {
      id: '',
      user: '',
      date: '',
      activity: '',
      comment: '',
    },
  });

  // Lead actvivity list
  const contactActivityColumns = [
    {
      field: 'first_name',
      headerName: t('form.first_name'),
      editable: false,
      minWidth: 50,
    },
    {
      field: 'last_name',
      headerName: t('form.last_name'),
      editable: false,
      minWidth: 50,
    },
    {
      field: 'company',
      headerName: t('form.company.company'),
      editable: false,
    },
    {
      field: 'email_label',
      headerName: t('form.email.label_alt'),
      editable: false,
    },
    {
      field: 'status_label',
      headerName: t('form.status.status'),
      flex: 0.3,
      editable: false,
    },
    {
      field: 'associate_label',
      headerName: t('form.associate'),
      flex: 0.3,
      editable: false,
    },
    {
      field: 'message_reviewed',
      headerName: t('form.reviewed'),
      flex: 0.3,
      editable: false,
      renderCell: (params) =>
        params?.message_reviewed ? <CheckIcon /> : <ClearIcon />,
    },
    {
      field: 'edit',
      headerName: t('buttons.edit'),
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          let contactContent = params.row;
          setContactInfo(contactContent);
          setTimeout(
            setContactDialog({
              open: true,
              contactDialogTitle: t('context.admin.dialog.contact_title'),
              contactDialogText: '',
              contactPrimaryButtonText: t('buttons.update'),
              contactSecondaryButtonText: t('buttons.close'),
              contactDialogAction: 'update',
            }),
            '10000',
          );
        };

        return (
          <Button variant="contained" color="tertiary" onClick={onClick}>
            {t('buttons.edit')}
          </Button>
        );
      },
    },
  ];

  const AssociateActivity = (props) => {
    let values = props.values;

    // Associate Activity Table
    const associateActivityColumns = [
      {
        field: 'date',
        headerName: t('form.date'),
        editable: false,
        minWidth: 50,
      },
      {
        field: 'activity',
        headerName: t('form.activity.activity'),
        editable: false,
        minWidth: 50,
      },
      {
        field: 'user',
        headerName: t('form.user'),
        editable: false,
        minWidth: 50,
      },
      {
        field: 'comment',
        headerName: t('form.comment.label'),
        editable: false,
        flex: 0.3,
        minWidth: 200,
      },
      {
        field: 'edit',
        headerName: t('buttons.edit'),
        sortable: false,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            let activityContent = params.row;
            setActivityDialog({
              openDialog: true,
              contactID: values._id,
              activityDialogTitle: transDialogs(
                'AssociateActivityDialog.edit.title',
              ),
              activityDialogText: transDialogs(
                'AssociateActivityDialog.edit.description',
              ),
              activityPrimaryButtonText: transButtons('update', {
                type: transTypes('activity'),
              }),
              activitySecondaryButtonText: transButtons('close'),
              activityDialogAction: 'update',
              activityLog: values.associate_activity,
              activityContent,
            });
          };

          return (
            <Button variant="contained" color="tertiary" onClick={onClick}>
              {t('buttons.edit')}
            </Button>
          );
        },
      },
    ];

    return (
      <>
        {/* Associate Activities Columns */}
        <Box>
          <DataGrid
            isCellEditable={(params) => false}
            getRowId={(row) => row.id}
            rows={values.associate_activity}
            columns={associateActivityColumns}
            components={{
              Row: MemoizedRow,
              ColumnHeaders: MemoizedColumnHeaders,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            sx={{
              '&::-webkit-scrollbar': {
                width: 20,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'orange',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'red',
                borderRadius: 2,
              },
            }}
            pageSizeOptions={[5, 10, 100]}
            checkboxSelection={!mobileView}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
          />
        </Box>
      </>
    );
  };

  const ContactActivityLogForm = () => {
    // Mode "add" or "update"
    let mode = activityDialog.activityDialogAction;

    // Initial Formik
    const formik = useFormik({
      initialValues: {},
      initialTouched: {},
      values: {},
    });

    // Initialize initial Values
    formik.initialValues = {
      ...activityDialog.activityContent,
    };

    // Validation
    var validationSchema = Yup.object();
    validationSchema = Yup.object().shape({
      id: Yup.string().required(t('validation.id_required')),
      comment: Yup.string()
        .min(2, t('validation.short'))
        .max(50, t('validation.long'))
        .required(t('validation.comment_required')),
      date: Yup.date()
        .min(new Date(2023, 1, 1))
        .required(t('validation.date_required')),
      activity: Yup.string().required(t('validation.activity_required')),
    });

    // Set ObjectID for new activity logs
    if (activityDialog.activityDialogAction === 'add') {
      let id = new ObjectId();
      formik.initialValues.id = id;
      formik.initialValues.date = dayjs().format('MM/DD/YYYY');
    }

    function addNewEntry(values, formik, action) {
      // setContactInfo({message_reviewed: true})
      let existingActivities = activityDialog.activityLog;
      let newActivity = values;
      let newActivities;

      // Clear the form state
      setActivityDialog({
        openDialog: false,
        contactID: '',
        activityDialogTitle: '',
        activityDialogText: '',
        activityPrimaryButtonText: '',
        activitySecondaryButtonText: '',
        activityDialogAction: '',
        activityLog: [],
        activityContent: {
          id: '',
          user: '',
          date: '',
          activity: '',
          comment: '',
        },
      });

      if (action === 'add') {
        // add the value to the contactContentActivity Array
        newActivities = [...existingActivities, newActivity];
      } else if (action === 'update') {
        // replace existing activity

        for (let i = 0; i < existingActivities.length; i++) {
          const element = existingActivities[i];

          if (element.id === newActivity.id) {
            existingActivities[i].id = newActivity.id;
            existingActivities[i].date = newActivity.date;
            existingActivities[i].comment = newActivity.comment;
            existingActivities[i].activity = newActivity.activity;
          }
        }
        newActivities = existingActivities;
      }

      // update existing contact in database
      let param = {
        _id: activityDialog.contactID,
        associate_activity: newActivities,
      };

      submitForm('update', param);
    }

    return (
      <Formik
        values={formik.values}
        initialValues={formik.initialValues}
        errors={formik.errors}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => {
          const {
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            isValid,
            resetForm,
          } = formik;
          return (
            <>
              <FormControl fullWidth>
                <Dialog
                  maxWidth="sm"
                  fullWidth
                  open={activityDialog.openDialog}
                >
                  <DialogTitle>
                    <Grid
                      container
                      spacing={{ xs: 12 }}
                      justifyContent="center"
                      direction={{ xs: 'column', sm: 'column', md: 'row' }}
                    >
                      {activityDialog?.activityDialogTitle}
                    </Grid>
                  </DialogTitle>

                  <DialogContent>
                    {isLoading?.status === true &&
                    isLoading?.type === 'skeleton' ? (
                      <Stack>
                        <DialogContentText
                          sx={{ color: 'var(--color-text-error-message)' }}
                        >
                          <Skeleton width="30%" height="30px">
                            <Typography>.</Typography>
                          </Skeleton>
                        </DialogContentText>
                        {/* Date */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />
                        {/* Activity Type */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />
                        {/* Comment */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />
                      </Stack>
                    ) : (
                      <Box>
                        <Stack
                          spacing={{ xs: 3, sm: 3, md: 1 }}
                          direction="column"
                          sx={{ paddingTop: '10px' }}
                          style={classes.root}
                          alignItems="stretch"
                        >
                          <Typography variant="subtitle1" color="intu">
                            {activityDialog?.activityDialogText}
                          </Typography>
                          {/* Activity Date */}
                          <DatePickerField required />

                          {/* Activity Type */}
                          <AssociateActivitySelectionField required />

                          {/* Comments */}
                          <TextField
                            multiline
                            required
                            rows={4}
                            id="headline"
                            name="comment"
                            className="form-select-field"
                            aria-describedby="uidnote"
                            variant="outlined"
                            label={t('form.comment.label')}
                            type="text"
                            placeholder={t('form.comment.placeholder')}
                            onBlur={handleBlur}
                            value={values?.comment}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value);
                            }}
                            inputProps={{ style: { resize: 'vertical' } }}
                            error={
                              errors?.comment && touched?.comment ? true : false
                            }
                            helperText={
                              errors?.comment && touched?.comment
                                ? errors.comment
                                : null
                            }
                          />
                        </Stack>
                      </Box>
                    )}

                    {/* <Typography variant="h1">Initial Values:</Typography>
                      {JSON.stringify(formik.initialValues)}

                      <Typography variant="h1">Values:</Typography>
                      {JSON.stringify(formik.values)}

                      <Typography variant="h1">Touched:</Typography>
                      {JSON.stringify(formik.touched)}

                      <Typography variant="h1">Errors:</Typography>
                      {JSON.stringify(formik.errors)}

                      <Typography variant="h1">Valid:</Typography>
                      {JSON.stringify(formik.isValid)}

                      <Typography variant="h1">Dialog Content</Typography>
                      {JSON.stringify(activityDialog)} */}
                  </DialogContent>
                  <DialogActions>
                    {/* Add or update activity */}
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        addNewEntry(values, formik, mode);
                        setContactInfo({ message_reviewed: true });
                      }}
                      disabled={!isValid}
                    >
                      {activityDialog.activityPrimaryButtonText}
                    </Button>

                    {/* Close activity log */}
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        resetForm();
                        setActivityDialog({
                          openDialog: false,
                          contactID: '',
                          activityDialogTitle: '',
                          activityDialogText: '',
                          activityPrimaryButtonText: '',
                          activitySecondaryButtonText: '',
                          activityDialogAction: '',
                          activityLog: [],
                          activityContent: {
                            id: '',
                            user: '',
                            date: '',
                            activity: '',
                            comment: '',
                          },
                        });
                      }}
                    >
                      {activityDialog?.activitySecondaryButtonText}
                    </Button>
                  </DialogActions>
                </Dialog>
              </FormControl>
            </>
          );
        }}
      </Formik>
    );
  };

  // Set Up Form refs
  const errRef = useRef();

  // Initial Values
  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    ...contactInfo,
    contactDialogOpen: contactDialog.open,
    contactDialogTitle: contactDialog.contactDialogTitle,
    contactDialogText: contactDialog.contactDialogText,
    contactPrimaryButtonText: contactDialog.contactPrimaryButtonText,
    contactSecondaryButtonText: contactDialog.contactSecondaryButtonText,
    contactDialogAction: contactDialog.contactDialogAction,
    fetchData: false,
    activityDialogOpen: activityDialog.openDialog,
    activityDialogTitle: activityDialog.activityDialogTitle,
    activityDialogText: activityDialog.activityDialogText,
    activityPrimaryButtonText: activityDialog.activityPrimaryButtonText,
    activitySecondaryButtonText: activityDialog.activitySecondaryButtonText,
    activityDialogAction: activityDialog.activityDialogAction,
  };

  // Validation
  var validationSchema = Yup.object();
  validationSchema = Yup.object().shape({
    contactDialogOpen: Yup.boolean(),
    first_name: Yup.string().when('contactDialogOpen', {
      is: true,
      then: () =>
        Yup.string()
          .min(2, t('validation.short'))
          .max(50, t('validation.long'))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
            t('validation.name.first_matches'),
          )
          .required(t('validation.name.first_required')),
    }),
    last_name: Yup.string().when('contactDialogOpen', {
      is: true,
      then: () =>
        Yup.string()
          .min(2, t('validation.short'))
          .max(50, t('validation.long'))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
            t('validation.name.last_matches'),
          )
          .required(t('validation.last_required')),
    }),
    email: Yup.string().when('intro_type', {
      is: 'Email',
      then: () =>
        Yup.string()
          .required(t('validation.email.intro_required'))
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            t('validation.email.invalid'),
          ),
    }),
    company: Yup.string().when('type', {
      is: 'manufacturer',
      then: () =>
        Yup.string()
          .label(t('form.company.name.alt_label'))
          .required(t('validation.company.name_required')),
    }),
    associate: Yup.object().when('contactDialogOpen', {
      is: true,
      then: () =>
        Yup.object({
          name: Yup.string().required(t('validation.associate_required')),
        }),
    }),
    status: Yup.string().when('contactDialogOpen', {
      is: true,
      then: () =>
        Yup.string()
          .label(t('form.status.status'))
          .required(t('validation.status_required')),
    }),
  });

  const MemoizedRow = React.memo(GridRow);
  const MemoizedColumnHeaders = React.memo(GridColumnHeaders);

  const refreshContacts = async () => {
    setIsLoading({ status: true, type: 'skeleton', text: '' });

    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const request = await axios.get('/api/contacts/list', { signal });
      // Structure User Data to comply with data grid requirements

      const response = request.data;

      if (request.status === 200) {
        let contacts = response.data;

        if (response.data.length > 0) {
          let contactsArray = [];
          contacts.map((contact) => {
            contactsArray.push({
              _id: contact._id,
              activity: contact?.activity ? contact.activity : [],
              associate: contact?.associate ? contact.associate : {},
              associate_label: contact.associate?.name
                ? contact.associate.name
                : '',
              associate_activity: contact?.associate_activity
                ? contact.associate_activity
                : [],
              company: contact.company,
              created: contact?.created,
              email: contact.email,
              first_name: contact.first_name,
              industry: contact?.industry,
              last_name: contact.last_name,
              status: contact?.status ? contact?.status : '',
              status_label: contact?.status && toTitleCase(contact.status),
              message: contact.message ? contact.message : '',
              message_reviewed: contact.message_reviewed,
              phone: contact.phone,
            });
            return null;
          });
          setContacts(contactsArray);
        }
      }
    } catch (err) {
      if (err.name === 'CanceledError') return;
      console.log(err);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  };

  // Viewport
  const [mobileView, setMobileView] = useState(false);

  // TODO: fix this useEffect, it runs on every render which means we are adding a new event listener every time
  // Also, this type type of logic should be handled farther up the component tree
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 767) {
        setMobileView(false);
      } else if (window.innerWidth < 767) {
        setMobileView(true);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
  });

  // Pre-Load Info
  useEffect(() => {
    setIsLoading({ status: true, type: 'skeleton', text: '' });

    const controller = new AbortController();
    const signal = controller.signal;

    // Get Employees from Database
    // async function handleGetEmployees() {
    //   try {
    //     const payload = {
    //       priviliges: ['sales'],
    //     };

    //     const employeesList = await getIntutecEmployees(payload);
    //     if (employeesList.status === 'success') {
    //       setEmployeeOptions(employeesList.data);
    //     }
    //   } catch (err) {
    //     handleErrorResponse(err);
    //   }
    // }

    // Get all Leads from Database
    const getContacts = async () => {
      try {
        const request = await axios.get('/api/contacts/list', { signal });
        // Structure User Data to comply with data grid requirements

        const response = request.data;

        if (request.status === 200) {
          let contacts = response.data;

          if (response.data.length > 0) {
            let contactsArray = [];
            contacts.map((contact) => {
              contactsArray.push({
                _id: contact._id,
                activity: contact?.activity ? contact.activity : [],
                associate: contact?.associate ? contact.associate : {},
                associate_label: contact.associate?.name
                  ? contact.associate.name
                  : '',
                associate_activity: contact?.associate_activity
                  ? contact.associate_activity
                  : [],
                company: contact.company,
                created: contact?.created,
                email: contact.email,
                first_name: contact.first_name,
                industry: contact?.industry,
                last_name: contact.last_name,
                status: contact?.status ? contact?.status : '',
                status_label: contact?.status && toTitleCase(contact.status),
                message: contact.message,
                message_reviewed: contact.message_reviewed,
                phone: contact.phone,
              });
              return null;
            });
            setContacts(contactsArray);
          }
          setIsLoading({ status: false, type: '', text: '' });
        }
      } catch (err) {
        if (err.name === 'CanceledError') return;
        setIsLoading({ status: false, type: '', text: '' });
        console.error(err);
      }
    };

    // Check if useEffect has run the first time
    if (!effectRun.current) {
      getContacts();
      handleGetEmployees();
    }
    return () => {
      setIsLoading({ status: false, type: '', text: '' });
      // controller.abort()
    };
  }, []);

  // Handle form submission process
  async function submitForm(action, values) {
    if (!action) {
      return;
    }

    let url = '/api/contacts/update';
    let payload = {
      _id: values._id,
      first_name: values.first_name,
      last_name: values.last_name,
      company: values.company,
      email: values.email,
      phone: values.phone,
      associate: values.associate,
      status: values.status,
      associate_activity: values.associate_activity,
      // host_url,
    };

    try {
      const response = await axios.put(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      const responseData = response.data;
      const { data, status, message } = responseData;

      if (status === 200) {
        gaEventTracker({
          category: 'Contact Management',
          action: 'Event',
          label: 'Contact Updated',
        });

        // Update User Info
        setContactInfo(data.data.value);

        refreshContacts();
      }
      handleRegularResponse({
        open: true,
        status,
        message,
      });
    } catch (err) {
      handleErrorResponse(err);
      errRef.current?.focus();
    }
  }

  return (
    <Grid
      container
      className="content"
      sx={{ marginBottom: '8rem' }}
      spacing={5}
    >
      <Grid item className="content" xs={12}>
        <Formik
          values={formik.values}
          initialValues={formik.initialValues}
          errors={formik.errors}
          validationSchema={validationSchema}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => {
            const {
              values,
              setFieldValue,
              errors,
              touched,
              handleBlur,
              resetForm,
            } = formik;
            return (
              <>
                {isLoading?.status === true &&
                isLoading?.type === 'skeleton' ? (
                  <Stack direction="column" spacing={-1}>
                    <Stack spacing={1} direction="row" height={50}>
                      <Typography width="25%">
                        {t('form.first_name')}
                      </Typography>
                      <Typography width="25%">{t('form.last_name')}</Typography>
                      <Typography width="25%">
                        {t('form.company.company')}
                      </Typography>
                    </Stack>
                    <Stack spacing={1} direction="row" height={50}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                    </Stack>
                    <Stack spacing={1} direction="row" height={50}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                    </Stack>
                    <Stack spacing={1} direction="row" height={50}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                    </Stack>
                    <Stack spacing={1} direction="row" height={50}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                    </Stack>
                    <Stack spacing={1} direction="row" height={50}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                    </Stack>
                    <Stack spacing={1} direction="row" height={50}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                    </Stack>
                    <Stack spacing={1} direction="row" height={50}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="25%"
                      />
                    </Stack>
                  </Stack>
                ) : !contacts?.length ? (
                  <Grid item className="title content-centered" xs={12}>
                    <Typography variant="h5">
                      {t('form.message.no_contacts')}
                    </Typography>
                  </Grid>
                ) : (
                  <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                      isCellEditable={(params) => false}
                      getRowId={(row) => row._id}
                      rows={contacts}
                      columns={contactActivityColumns}
                      columnVisibilityModel={{
                        // Hide columns status and traderName, the other columns will remain visible
                        company: !mobileView,
                        status_label: !mobileView,
                        type_label: !mobileView,
                        origin_label: !mobileView,
                        associate_label: !mobileView,
                      }}
                      components={{
                        Row: MemoizedRow,
                        ColumnHeaders: MemoizedColumnHeaders,
                      }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                        columns: {
                          columnVisibilityModel: {
                            first_name: !mobileView,
                          },
                        },
                      }}
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: 20,
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'orange',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: 'red',
                          borderRadius: 2,
                        },
                      }}
                      pageSizeOptions={[5, 10, 100]}
                      checkboxSelection={!mobileView}
                      disableRowSelectionOnClick
                      slots={{ toolbar: GridToolbar }}
                    />
                  </Box>
                )}

                <Form role="form">
                  <FormControl fullWidth>
                    {/* Add/Edit Contact dialog */}
                    <Dialog maxWidth="md" fullWidth open={contactDialog.open}>
                      <DialogTitle>
                        <Grid
                          container
                          spacing={{ xs: 12 }}
                          justifyContent="center"
                          direction={{ xs: 'column', sm: 'column', md: 'row' }}
                        >
                          <Grid
                            item
                            container
                            xs={7}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {values.contactDialogTitle}
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            xs={5}
                            spacing={1}
                            alignItems="center"
                            justifyContent="flex-end"
                            sx={{
                              fontSize: '1.0rem',
                              color: 'var(--color-highlight)',
                            }}
                          >
                            <Grid item xs={7}>
                              {values?.created && `Created ${values?.created}`}
                            </Grid>
                          </Grid>
                        </Grid>
                      </DialogTitle>
                      <DialogContent>
                        {isLoading?.status === true &&
                        isLoading?.type === 'skeleton' ? (
                          // Skeleton
                          <Stack
                            spacing={2}
                            style={classes.root}
                            sx={{ paddingBottom: '10px' }}
                          >
                            <DialogContentText
                              sx={{ color: 'var(--color-text-error-message)' }}
                            >
                              <Skeleton width="30%" height="30px">
                                <Typography>.</Typography>
                              </Skeleton>
                            </DialogContentText>

                            {/* Associate */}
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="30px"
                            />

                            {/* Personal Details */}
                            <Grid item xs={12}>
                              <Stack
                                spacing={{ xs: 3, sm: 3, md: 1 }}
                                direction="row"
                                sx={{ paddingTop: '10px' }}
                                style={classes.root}
                                alignItems="stretch"
                              >
                                {/* First Name */}
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height="30px"
                                />

                                {/* Last Name */}
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height="30px"
                                />
                              </Stack>
                            </Grid>

                            {/* Company */}
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="30px"
                            />

                            {/* Email */}
                            {values.intro_type === 'Email' && (
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="30px"
                              />
                            )}

                            {/* Message */}
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="100px"
                            />

                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="30px"
                            />

                            {/* Lead Status */}
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="30px"
                            />
                          </Stack>
                        ) : (
                          //  Form
                          <Stack
                            spacing={2}
                            style={classes.root}
                            sx={{ paddingBottom: '10px' }}
                          >
                            <DialogContentText
                              sx={{ color: 'var(--color-text-error-message)' }}
                            >
                              {values.contactDialogText}
                            </DialogContentText>

                            <Typography variant="h5">
                              {t('form.contact_details')}
                            </Typography>
                            {/* Associate */}
                            <Autocomplete
                              id="associate"
                              options={employeeOptions?.sort(
                                (a, b) => -b.name.localeCompare(a.name),
                              )}
                              getOptionLabel={(option) =>
                                (option?.name &&
                                  option?.name + ' (' + option?.email + ')') ||
                                ''
                              }
                              isOptionEqualToValue={(option, value) =>
                                option?.email === value?.email
                              }
                              className="form-select-field"
                              onBlur={handleBlur}
                              clearOnBlur={false}
                              value={values.associate}
                              onChange={(e, options, reason, element) => {
                                if (reason === 'selectOption') {
                                  setFieldValue('associate', element.option);
                                } else if (reason === 'clear') {
                                  setFieldValue('associate', {
                                    name: '',
                                    email: '',
                                    _id: '',
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  margin="dense"
                                  label={t('form.associate')}
                                  type="text"
                                  variant="standard"
                                  error={
                                    errors?.associate?.name && touched.associate
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors?.associate?.name &&
                                    touched?.associate
                                      ? errors.associate?.name
                                      : null
                                  }
                                />
                              )}
                            />

                            {/* Personal Details */}
                            <Grid item xs={12}>
                              <Stack
                                spacing={{ xs: 3, sm: 3, md: 1 }}
                                direction="row"
                                sx={{ paddingTop: '10px' }}
                                style={classes.root}
                                alignItems="stretch"
                              >
                                {/* First Name */}
                                <TextField
                                  required
                                  id="first_name"
                                  name="first_name"
                                  className="form-select-field"
                                  aria-invalid={
                                    errors.first_name ? 'true' : 'false'
                                  }
                                  aria-describedby="uidnote"
                                  variant="standard"
                                  label={t('form.first_name')}
                                  type="text"
                                  placeholder={t('form.first_name')}
                                  onChange={(e) => {
                                    setFieldValue(
                                      e.target.name,
                                      e.target.value,
                                    );
                                  }}
                                  inputProps={{
                                    autoComplete: 'given-name',
                                  }}
                                  onBlur={handleBlur}
                                  value={values?.first_name}
                                  error={
                                    errors.first_name && touched.first_name
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors.first_name && touched.first_name
                                      ? errors.first_name
                                      : null
                                  }
                                />

                                {/* Last Name */}
                                <TextField
                                  required
                                  id="last_name"
                                  name="last_name"
                                  className="form-select-field"
                                  aria-invalid={
                                    errors.last_name ? 'true' : 'false'
                                  }
                                  aria-describedby="uidnote"
                                  variant="standard"
                                  label={t('form.last_name')}
                                  type="text"
                                  placeholder={t('form.last_name')}
                                  onChange={(e) => {
                                    setFieldValue(
                                      e.target.name,
                                      e.target.value,
                                    );
                                  }}
                                  inputProps={{
                                    autoComplete: 'family-name',
                                  }}
                                  onBlur={handleBlur}
                                  value={values?.last_name}
                                  error={
                                    errors.last_name && touched.last_name
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors.last_name && touched.last_name
                                      ? errors.last_name
                                      : null
                                  }
                                />
                              </Stack>
                            </Grid>

                            {/* Company */}
                            <TextField
                              required={values.type === 'manufacturer'}
                              id="company"
                              name="company"
                              className="form-select-field"
                              aria-invalid={errors.company ? 'true' : 'false'}
                              aria-describedby="uidnote"
                              variant="standard"
                              label={t('form.company.company')}
                              margin="dense"
                              type="text"
                              placeholder={t('form.company.company')}
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values?.company}
                              error={
                                errors.company && touched.company ? true : false
                              }
                              helperText={
                                errors?.company && touched?.company
                                  ? errors.company
                                  : null
                              }
                            />

                            {/* Email */}
                            <TextField
                              required
                              disabled
                              id="email"
                              name="email"
                              autoComplete="off"
                              className="form-select-field"
                              aria-invalid={errors.email ? 'true' : 'false'}
                              aria-describedby="uidnote"
                              variant="standard"
                              label={t('form.email.label_alt')}
                              type="text"
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              onBlur={handleBlur}
                              placeholder={t('form.email.placeholder')}
                              inputProps={{
                                autoComplete: 'off',
                              }}
                              value={values.email}
                              error={
                                errors.email && touched.email ? true : false
                              }
                              helperText={
                                errors.email && touched.email
                                  ? errors.email
                                  : null
                              }
                            />

                            {/* Message */}
                            <TextField
                              multiline
                              disabled
                              required
                              rows={4}
                              id="headline"
                              name="message"
                              className="form-select-field"
                              aria-describedby="uidnote"
                              variant="standard"
                              label={t('form.message.label')}
                              type="text"
                              onBlur={handleBlur}
                              value={values?.message}
                              inputProps={{ style: { resize: 'vertical' } }}
                            />

                            {/* Status */}
                            <TextField
                              select
                              type="text"
                              id="status"
                              label={t('form.status.contact_label')}
                              name="status"
                              variant="standard"
                              value={values?.status}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              error={
                                errors?.status && touched?.status ? true : false
                              }
                              helperText={
                                errors?.status && touched?.status
                                  ? errors?.status
                                  : null
                              }
                            >
                              <MenuItem key={0} value="">
                                {t('form.status.select')}
                              </MenuItem>
                              <MenuItem key={1} value="pending">
                                {t('form.status.pending')}
                              </MenuItem>
                              <MenuItem key={2} value="followup">
                                {t('form.status.follow_up')}
                              </MenuItem>
                              <MenuItem key={3} value="converted">
                                {t('form.status.convert_lead')}
                              </MenuItem>
                              <MenuItem key={4} value="closed">
                                {t('form.status.closed')}
                              </MenuItem>
                            </TextField>

                            {/* Associate Activity Log */}
                            {values?.associate_activity > '' && (
                              <AssociateActivity values={values} />
                            )}

                            {/* Contact Activity entry Form */}
                            <ContactActivityLogForm />

                            {/* Add new associate activity */}
                            <Button
                              variant="contained"
                              color="info"
                              onClick={(e) => {
                                setActivityDialog({
                                  openDialog: true,
                                  contactID: values?._id,
                                  activityDialogTitle: transDialogs(
                                    'AssociateActivityDialog.add.title',
                                  ),
                                  activityDialogText: transDialogs(
                                    'AssociateActivityDialog.add.description',
                                  ),
                                  activityPrimaryButtonText: transButtons(
                                    'add',
                                    { type: transTypes('activity') },
                                  ),
                                  activitySecondaryButtonText:
                                    transButtons('close'),
                                  activityDialogAction: 'add',
                                  activityLog: values?.associate_activity,
                                  activityContent: {
                                    id: '',
                                    user: auth.user_info.full_name,
                                    date: '',
                                    activity: '',
                                    comment: '',
                                  },
                                });
                              }}
                            >
                              {t('buttons.add_activity')}
                            </Button>
                          </Stack>
                        )}

                        {/* <Typography variant="h1">Initial Values:</Typography>
                          {JSON.stringify(formik.initialValues)}

                          <Typography variant="h1">Values:</Typography>
                          {JSON.stringify(formik.values)}

                          <Typography variant="h1">Touched:</Typography>
                          {JSON.stringify(formik.touched)}

                          <Typography variant="h1">Errors:</Typography>
                          {JSON.stringify(formik.errors)}

                          <Typography variant="h1">Valid:</Typography>
                          {JSON.stringify(formik.isValid)}

                          <Typography variant="h1">Toggle State</Typography>
                          {JSON.stringify(contactDialog)} */}
                      </DialogContent>

                      <DialogActions>
                        {/* Cancel */}
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={() => {
                            resetForm();
                            setContactInfo({
                              created: '',
                              first_name: '',
                              last_name: '',
                              company: '',
                              message: '',
                              email: '',
                              status: '',
                              associate_activity: [],
                              associate: {
                                _id: '',
                                name: '',
                                email: '',
                              },
                            });
                            setContactDialog({
                              open: false,
                              contactDialogTitle: '',
                              contactDialogText: '',
                              contactPrimaryButtonText: '',
                              contactSecondaryButtonText: '',
                              contactDialogAction: '',
                            });
                          }}
                        >
                          {contactDialog.contactSecondaryButtonText}
                        </Button>

                        {/* Add/Update Button */}
                        <LoadingButton
                          variant="contained"
                          size="medium"
                          endIcon={<SendIcon />}
                          loading={values.sendForm}
                          loadingPosition="end"
                          color="primary"
                          disabled={
                            Object.keys(touched).length === 0 ||
                            Object.keys(errors).length > 0
                          }
                          type="submit"
                          onClick={async (e) => {
                            setFieldValue('sendForm', true);
                            let action = contactDialog.contactDialogAction;

                            await submitForm(action, values, formik)
                              .then((response) => {
                                if (response?.status === 'success') {
                                  setContactDialog({
                                    open: false,
                                    contactDialogTitle: '',
                                    contactDialogText: '',
                                    contactPrimaryButtonText: '',
                                    contactSecondaryButtonText: '',
                                    contactDialogAction: '',
                                    sendForm: false,
                                  });
                                } else {
                                  setFieldValue('sendForm', false);
                                }
                              })
                              .catch((err) => {
                                console.log(err);
                                setFieldValue('sendForm', false);
                              });
                          }}
                        >
                          {contactDialog.contactPrimaryButtonText}
                        </LoadingButton>
                      </DialogActions>
                    </Dialog>
                  </FormControl>
                </Form>

                {/* CTA */}
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction={{ xs: 'column', sm: 'column', md: 'row' }}
                >
                  <Grid
                    container
                    item
                    xs={4}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ paddingRight: '20px' }}
                  >
                    <Stack
                      spacing={1}
                      direction={{ xs: 'column', sm: 'row' }}
                      style={classes.root}
                      sx={{ paddingBottom: '50px' }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        color="info"
                        onClick={() => {
                          refreshContacts();
                        }}
                        sx={{ minWidth: 150, maxHeight: 40 }}
                      >
                        {t('buttons.refresh_contacts')}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export { IntuContactList };
