import React, { useContext, useRef } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { MenuItem, Skeleton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../helpers/getNestedValuesIn';

const BusinessTypeField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'business_type', // Defines the ID and Name of the Field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
}) => {
  // Translation
  const { t } = useTranslation('fields', {
    keyPrefix: 'BusinessTypeField',
  });

  // Field Ref
  const businessTypeRef = useRef();

  // Context
  const { values, setFieldValue, touched, errors, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        select
        disabled={disabled}
        required={required}
        type="text"
        name={fieldID}
        id={fieldID}
        variant="outlined"
        label={t('label')}
        className="form-select-field"
        placeholder={t('placeholder')}
        labelid="business-type-label"
        inputRef={businessTypeRef}
        onBlur={(e) => {
          handleBlur(e);
        }}
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value);
        }}
        sx={{ width: '100%' }}
        value={fieldValue}
        error={fieldError && fieldTouched ? true : false}
        helperText={fieldError && fieldTouched ? fieldError : null}
      >
        <MenuItem key={0} value="individual">
          {t('options.individual')}
        </MenuItem>
        <MenuItem key={1} value="company">
          {t('options.company')}
        </MenuItem>
      </TextField>
    </FormikFieldWrapper>
  );
};

export default BusinessTypeField;
