import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';
import useAuth from '../../../../hooks/useAuth';
import { useContext, useRef } from 'react';
import { useTheme } from '@emotion/react';
import { useIntuTheme } from '../../../../context/providers/IntuThemeProvider';
import { CompanyEmployeesListContext } from './EmployeesList';
import ResponseContext from '../../../../context/providers/ResponseProvider';
import { createInitialEmployeeDetailsValues } from './InitialValues';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { CompanyProfileContext } from '../../../../context/providers/CompanyProfileProvider';
import { searchUsers } from '../../../../routes/usersRoutes';
import {
  associateUserToCompany,
  unAssociateUserFromCompany,
} from '../../../../routes/employeeRoutes';
import { SearchUserField } from '../../SearchUserField';

export const EmployeeDetailsDialog = () => {
  const { values: parentValues } = useFormikContext();

  const { employeeDialog, setEmployeeDialog } = useContext(
    CompanyEmployeesListContext,
  );

  const {
    employeeDetails,
    setEmployeeDetails,
    companyProfile,
    setCompanyProfile,
    getCompanyProfile,
  } = useContext(CompanyProfileContext);

  const { auth, setAuth } = useAuth();

  const { setErrorDialog } = useContext(ResponseContext);

  const { t } = useTranslation('dialogs', {
    keyPrefix: 'CompanyEmployeeDialog',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');
  const { t: transType } = useTranslation('types');

  const theme = useTheme();
  const { mode } = useIntuTheme();

  const errRef = useRef();
  const formRef = useRef();

  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();

  const axios = useAxiosPrivate();

  const initialValues = createInitialEmployeeDetailsValues(employeeDetails);

  const handleEmployeeUpdate = async ({
    values,
    helpers, //formik functions
    action, // Type of Change
  }) => {
    // Set Form in Submitting State

    helpers.isSubmitting = true;

    let message;

    const currentEmployees = [...companyProfile.employees];
    let newEmployeesList = [];
    let blockedUsers = companyProfile.blocked_users
      ? [...companyProfile.blocked_users]
      : [];

    if (action === 'addUser') {
      message = t('form.company.dialog.added_user_msg', {
        role: values.role,
        company: values.company,
      });
      // Search for User By Email
      const searchPayload = {
        key: 'email',
        values: [values.email],
      };
      const searchRes = await searchUsers(searchPayload);

      const { data: searchData, statusCode: searchStatusCode } = searchRes;

      if (searchStatusCode !== 200) {
        setErrorDialog({
          open: true,
          title: transMessages('global.error'),
          message: transMessages('CompanyEmployeeDialog.userNotFound'),
        });
      }

      // Update Values
      let newEmployee = {
        _id: searchData[0]._id,
        role: values.role,
      };

      newEmployeesList = [...companyProfile.employees];

      // Update above user's company
      const updatePayload = {
        company_id: companyProfile._id,
        employees: [newEmployee],
      };

      const updateCompanyAssociation =
        await associateUserToCompany(updatePayload);

      if (updateCompanyAssociation.statusCode !== 200) {
        handleRegularResponse({
          open: true,
          status: updateCompanyAssociation.status,
          message: updateCompanyAssociation.message,
        });
      }

      if (updateCompanyAssociation.statusCode === 200) {
        newEmployeesList.push(newEmployee);
        setCompanyProfile({ ...companyProfile, newEmployeesList });
      }
    } else if (action === 'updateUser') {
      currentEmployees.forEach((employee) => {
        if (employee._id === values._id) {
          employee.role = values.role;
        }
      });

      // check if there is more than one admin
      let admin_count = 0;
      for (let i = 0; i < currentEmployees.length; i++) {
        if (currentEmployees[i].role === 'admin') admin_count++;
      }

      // Guard Clause Exit when there is only one admin
      if (admin_count === 0) {
        setCompanyProfile({ ...companyProfile, currentEmployees });
        setErrorDialog({
          open: true,
          title: transMessages('global.error'),
          message: transMessages('CompanyEmployeeDialog.employeeCount'),
        });
        return;
      }
      // If employee is blocked, remove user from company, add to companys blocked list and remove company from user's profile
      else if (values.role === 'blocked') {
        blockedUsers.push(values._id);
        const payload = {
          company_id: companyProfile._id,
          employees: [values._id],
        };
        const updateCompanyAssociation =
          await unAssociateUserFromCompany(payload);

        if (updateCompanyAssociation.statusCode !== 200) {
          handleRegularResponse({
            open: true,
            status: updateCompanyAssociation.status,
            message: updateCompanyAssociation.message,
          });
        }
        if (updateCompanyAssociation.statusCode === 200) {
          newEmployeesList = [...currentEmployees].filter(
            (employee) => employee.role !== 'blocked',
          );
        }
      } else {
        newEmployeesList = [...currentEmployees];
        setCompanyProfile({ ...companyProfile, currentEmployees });
        message = t('form.company.dialog.updated_user_msg', {
          company: values.name,
          role: values.role,
        });
      }
    } else if (action === 'removeUser') {
      newEmployeesList = currentEmployees
        .filter((employee) => {
          return employee._id !== values.employee_id;
        })
        .map((employee) => {
          return {
            _id: employee._id,
            role: employee.role,
          };
        });
      message = t('form.company.dialog.removed_user_msg', {
        role: values.employee_role,
        company: values.name,
      });
    }

    // Update Company Employees
    const url = '/api/companies/company/update';
    const payload = {
      _id: companyProfile._id,
      employees: newEmployeesList,
      blocked_users: blockedUsers,
      notification: {
        send: true,
        message: message,
        recipients: companyProfile.admins,
      },
    };

    try {
      const response = await axios.put(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      const { data, status } = response;

      if (data && status === 200) {
        const newCompanyInfo = {
          ...data.data,
        };

        // Updete Auth
        setAuth({
          ...auth,
          company_info: newCompanyInfo,
        });

        const profile = await getCompanyProfile(data.data._id);

        setCompanyProfile({
          ...profile,
        });

        // Updtae Change Name Field
        helpers.setValues({
          ...values,
          id: '',
          name: '',
          email: '',
          role: '',
          employee_change_type: '',
        });

        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
      errRef.current?.focus();
    } finally {
      helpers.isSubmitting = false;
      setEmployeeDialog(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      // validateOnMount={true}
      // validateOnChange={true}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {(formik) => {
        const {
          values,
          setFieldValue,
          setValues,
          errors,
          touched,
          resetForm,
          isValid,
          setTouched,
          handleBlur,
        } = formik;
        return (
          <Dialog
            maxWidth="sm"
            fullWidth
            open={employeeDialog ? employeeDialog.open : false}
          >
            <form>
              <DialogTitle className="intu__form-title">
                {t(`title.${employeeDialog?.action}`)}
              </DialogTitle>
              <DialogContent className="intu__form-content">
                <Grid>
                  <Grid>
                    <Typography>
                      {t(`description.${employeeDialog?.action}`)}
                    </Typography>
                  </Grid>
                  {employeeDialog?.action !== 'add' &&
                    <Grid className="intu__form-bullets">
                      <Trans
                        t={t}
                        i18nKey={'description.content'}
                        components={[<li />, <li />, <li />, <li />, <ul />]}
                      >
                        <ul>
                          <li>Users have a verified relation to your company.</li>
                          <li>
                            Super Users can see and edit company campaigns,
                            products and reports
                          </li>
                          <li>
                            Administrators can change company data, add, remove or
                            edit employees
                          </li>
                          <li>
                            Blocked users are former employees which might still
                            associate themselves with you, but havent changed
                            their personal profile, including their relationship
                            to your company.
                          </li>
                        </ul>
                      </Trans>
                    </Grid>
                  }
                </Grid>
                <FormControl margin="dense" fullWidth>
                  <SearchUserField />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  {/* Employee Name */}
                  <TextField
                    disabled
                    id="name"
                    name="name"
                    autoComplete="off"
                    margin="dense"
                    aria-invalid={errors.name ? 'true' : 'false'}
                    aria-describedby="uidnote"
                    variant="standard"
                    label={t('form.name.label')}
                    type="text"
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        '-webkit-text-fill-color':
                          mode === 'dark'
                            ? theme.palette.disabled.main
                            : theme.palette.grey.main,
                      },
                    }}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        name: e.target.value,
                      });
                    }}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={values.name}
                  />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  {/* Employee Email */}
                  <TextField
                    disabled
                    id="email"
                    name="email"
                    autoComplete="off"
                    margin="dense"
                    aria-invalid={errors.employee_email ? 'true' : 'false'}
                    aria-describedby="uidnote"
                    variant="standard"
                    label={t('form.email.label')}
                    type="text"
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        '-webkit-text-fill-color':
                          mode === 'dark'
                            ? theme.palette.disabled.main
                            : theme.palette.grey.main,
                      },
                    }}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        email: e.target.value,
                      });
                    }}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={values.email}
                  />
                </FormControl>

                {/* Employee Role */}
                <FormControl margin="dense" fullWidth variant="standard">
                  <InputLabel id="role-label" shrink>
                    {t('form.role.label')}
                  </InputLabel>
                  <Select
                    labelId="role-label"
                    required
                    id="role"
                    name="role"
                    margin="dense"
                    aria-invalid={errors.role ? true : false}
                    aria-describedby="uidnote"
                    variant="standard"
                    type="text"
                    onChange={(e) => {
                      setValues({
                        ...values,
                        role: e.target.value,
                        employee_role_change: true,
                      });
                    }}
                    onBlur={handleBlur}
                    value={values?.role}
                    error={errors?.role && touched?.role ? true : false}
                  >
                    <MenuItem className="input-light" key={0} value="">
                      {t('form.role.options.select')}
                    </MenuItem>
                    <MenuItem className="input-light" key={1} value="associate">
                      {t('form.role.options.user')}
                    </MenuItem>
                    <MenuItem className="input-light" key={2} value="admin">
                      {t('form.role.options.admin')}
                    </MenuItem>
                    {companyProfile.employees.length > 1 && (
                      <MenuItem className="input-light" key={3} value="blocked">
                        {t('form.role.options.blocked')}
                      </MenuItem>
                    )}
                  </Select>
                  {errors?.role && touched?.role ? (
                    <FormHelperText error>{errors.role}</FormHelperText>
                  ) : null}
                </FormControl>
              </DialogContent>
              <DialogActions>
                {/* Cancel */}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setValues({
                      ...values,
                      id: '',
                      name: '',
                      email: '',
                      role: '',
                    });
                    setEmployeeDialog(false);
                  }}
                >
                  {transButtons('cancel')}
                </Button>

                {/* Change Button */}
                {employeeDialog.action === 'edit' && (
                  <LoadingButton
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    color="warning"
                    disabled={
                      values?.employee_role_change !== true ||
                        errors?.employee_role
                        ? true
                        : false
                    }
                    type="submit"
                    onClick={() =>
                      handleEmployeeUpdate({
                        values: values,
                        helpers: formik,
                        action: 'updateUser',
                      })
                    }
                  >
                    {transButtons('update')}
                  </LoadingButton>
                )}

                {/* Add Button */}
                {employeeDialog.action === 'add' && (
                  <LoadingButton
                    loadingPosition="start"
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="warning"
                    disabled={
                      values?.employee_role_change !== true ||
                        errors?.employee_role
                        ? true
                        : false
                    }
                    type="submit"
                    onClick={() =>
                      handleEmployeeUpdate({
                        values: values,
                        helpers: formik,
                        action: 'addUser',
                      })
                    }
                  >
                    {transButtons('add', { type: transType('user') })}
                  </LoadingButton>
                )}
              </DialogActions>
            </form>
          </Dialog>
        );
      }}
    </Formik>
  );
};
