import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

const IntuAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const IntuDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  height: theme.components.MuiAppBar.styleOverrides.root.height,
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export { IntuAppBar, IntuDrawerHeader };
