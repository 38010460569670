import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  Box,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import { UserProfileContext } from '../../../../context/providers/UserProfileProvider';
import { Formik, useFormikContext } from 'formik';
import { createFilterOptions } from '@mui/base';

import BusinessIcon from '@mui/icons-material/Business';
import ClearIcon from '@mui/icons-material/Clear';
import HailIcon from '@mui/icons-material/Hail';

import { useTranslation } from 'react-i18next';
import { createChangeCompanyAssociationInitialValues } from './initialValues';
import { createChangeCompanyAssociationValidationSchema } from './validationSchema';
import { CompanyProfileContext } from '../../../../context/providers/CompanyProfileProvider';
import {
  searchCompaniesByName,
  getCompanyDetails,
} from '../../../../routes/companies';
import IntuIconButton from '../../../buttons/IntuIconButton';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import ProcessingContext from '../../../../context/providers/ProcessingProvider.jsx';
import ResponseContext from '../../../../context/providers/ResponseProvider.jsx';

const ChangeCompanyAssociationDialog = () => {
  // Translation
  const { t } = useTranslation('fields', {
    keyPrefix: 'account.individual.CompanyNameField',
  });
  const { t: transDialogs } = useTranslation('dialogs');
  const { t: transMessages } = useTranslation('messages');
  const { t: transFields } = useTranslation('fields');

  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { isLoading, setIsLoading } = useContext(ProcessingContext);

  const {
    changeCompanyDialog,
    setChangeCompanyDialog,
    userProfile,
    removeUserFromCompany,
    addUserToCompany,
  } = useContext(UserProfileContext);

  const { companyOptions, setCompanyOptions, setNewCompanyDialog } = useContext(
    CompanyProfileContext,
  );

  const { setFieldValue: setParentFieldValue } = useFormikContext();

  const [isLoadingCompany, setIsLoadingCompany] = useState(false);

  const handleClose = () => {
    setChangeCompanyDialog(false);
  };

  const formRef = useRef();

  // Mui Options
  const filter = createFilterOptions();
  const companySearchRef = useRef();

  //   Initial Values
  const initialValues = createChangeCompanyAssociationInitialValues({
    userProfile,
  });

  // Validatio  Schema
  const validationSchema = createChangeCompanyAssociationValidationSchema();

  // Error Handler
  const { setErrorDialog } = useContext(ResponseContext);

  const handleAddNewCompany = () => {
    // Add new application when user hits return button
    // setNewCompany(true);
    setNewCompanyDialog(true);
  };

  // Handle User Removal Only (Wehn User claims no company)
  const handleCompanyRemoval = async ({
    old_company, // Current Company Details
    helpers, // Formik Functions
  }) => {
    if (!old_company._id) {
      return;
    }

    try {
      try {
        setIsLoading({
          status: true,
          text: transMessages('ChangeCompanyAssociationDialog.loading.remove', {
            company: old_company.name,
          }),
          type: 'spinner',
        });

        const request = await getCompanyDetails(old_company._id);

        const response = request?.data;
        if (request.statusCode === 200) {
          const companyInfo = response;

          // Filter Admins
          const companyAdmins = companyInfo.employees.filter(
            (employee) => employee.role === 'admin',
          );

          let isAdmin = false;
          companyAdmins.forEach((admin) => {
            if (admin._id === userProfile._id) {
              isAdmin = true;
            }
          });

          if (companyAdmins.length <= 1 && isAdmin) {
            // Not enough admins -> return error dialog
            return setTimeout(() => {
              setIsLoading({
                status: false,
                text: '',
                type: 'spinner',
              });

              setErrorDialog({
                open: true,
                title: transMessages('IndividualCompanyNameField.admin_title'),
                message: transMessages('IndividualCompanyNameField.admin_msg'),
              });
            }, 300);
          }

          const userData = {
            ...userProfile,
            company: '',
          };

          const employeeData = {
            company_id: companyInfo._id,
            employees: [userProfile._id],
            notification: true,
          };

          const update = await removeUserFromCompany({
            update: false,
            values: employeeData,
            userData: userData,
          });

          helpers.resetForm();
          if (update.statusCode === 200) {
            setParentFieldValue('company', { _id: '', name: '' });
            setChangeCompanyDialog(false);
          }
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setIsLoading({
        status: false,
        text: '',
        type: 'spinner',
      });
    }
  };

  // Handle Company Change
  const handleCompanyNameChange = async ({
    new_company = { id: '', name: '' }, // The Company Object,
    old_company = userProfile,
    helpers, // Formik Functions
  }) => {
    if (!new_company._id) {
      return;
    }

    if (new_company?._id === old_company._id) {
      return handleRegularResponse({
        open: true,
        status: 'error',
        message: transMessages(
          'ChangeCompanyAssociationDialog.loading.remove',
          {
            old_company: old_company.name,
            new_company: new_company.name,
          },
        ),
      });
    }

    try {
      // Remove user from old company
      if (old_company?._id) {
        try {
          setIsLoading({
            status: true,
            text: transMessages(
              'ChangeCompanyAssociationDialog.loading.remove',
              {
                company: old_company.name,
              },
            ),
            type: 'spinner',
          });

          const request = await getCompanyDetails(old_company._id);

          const response = request?.data;
          if (request.statusCode === 200) {
            const companyInfo = response;

            // Filter Admins
            const companyAdmins = companyInfo.employees.filter(
              (employee) => employee.role === 'admin',
            );

            let isAdmin = false;
            companyAdmins.forEach((admin) => {
              if (admin._id === userProfile._id) {
                isAdmin = true;
              }
            });

            if (companyAdmins.length <= 1 && isAdmin) {
              // Not enough admins -> return error dialog
              return setTimeout(() => {
                setIsLoading({
                  status: false,
                  text: '',
                  type: 'spinner',
                });

                setErrorDialog({
                  open: true,
                  title: transMessages(
                    'IndividualCompanyNameField.admin_title',
                  ),
                  message: transMessages(
                    'IndividualCompanyNameField.admin_msg',
                  ),
                });
              }, 300);
            }

            const userData = {
              ...userProfile,
              company: '',
            };

            const employeeData = {
              company_id: companyInfo._id,
              employees: [userProfile._id],
              notification: true,
            };

            const update = await removeUserFromCompany({
              update: false,
              values: employeeData,
              userData: userData,
            });
          }
        } catch (error) {
          handleErrorResponse(error);
        }
      }

      // Add user to new company
      if (new_company?._id) {
        setIsLoading({
          status: true,
          text: transMessages('ChangeCompanyAssociationDialog.loading.add', {
            company: old_company.name,
          }),
          type: 'spinner',
        });
        try {
          const request = await getCompanyDetails(new_company._id);

          const response = request?.data;
          if (request.statusCode === 200) {
            const companyInfo = response;
            let newEmployeesList = [];

            newEmployeesList.push({
              _id: userProfile._id,
              role: 'associate',
            });

            const userData = {
              ...userProfile,
              company: new_company._id,
            };

            const employeeData = {
              company_id: companyInfo._id,
              employees: newEmployeesList,
              notification: true,
            };

            const update = await addUserToCompany({
              update: true,
              values: employeeData,
              userData: userData,
            });

            helpers.resetForm();
            if (update.statusCode === 200) {
              const companyData = update.data;
              setParentFieldValue('company', {
                _id: companyData._id,
                name: companyData.name,
              });
              setCompanyOptions([]);
              setChangeCompanyDialog(false);
            }
          } else {
            // Handle cases where company could not be found
            handleErrorResponse(request);
          }
        } catch (error) {
          handleErrorResponse(error);
        } finally {
          setIsLoading({
            status: false,
            text: '',
            type: 'skeleton',
          });
        }
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  return (
    <Formik
      //   values={userProfile}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {(formik) => {
        const {
          values,
          setFieldValue,
          handleBlur,
          resetForm,
          isValid,
          errors,
          touched,
        } = formik;
        // console.log('User Profile Values', values);
        return (
          <Dialog
            open={changeCompanyDialog}
            onClose={handleClose}
            PaperProps={{
              component: 'form',
              onSubmit: (event) => {
                event.preventDefault();

                handleClose();
              },
            }}
          >
            <DialogTitle>
              {transDialogs('ChangeCompanyAssociationDialog.title')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ marginBottom: '1rem', color: 'white' }}>
                {transDialogs('ChangeCompanyAssociationDialog.description')}
              </DialogContentText>
              <Autocomplete
                required
                autoComplete
                disabled={isLoading.status}
                clearIcon={
                  <ClearIcon sx={{ color: 'var(--intu-lightGrey)' }} />
                }
                id="new_company"
                name="new_company"
                ref={companySearchRef}
                className="form-select-field"
                sx={{ paddingBottom: '50px' }}
                options={companyOptions}
                noOptionsText={transFields('global.label.no_option', {
                  type: transFields('global.label.company'),
                })}
                label={'Compannjjjj'}
                getOptionLabel={(option) => option?.name || ''}
                value={values?.company}
                handleHomeEndKeys
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid
                          item
                          sx={{
                            display: 'flex',
                            width: 44,
                          }}
                        >
                          <BusinessIcon sx={{ color: 'text.secondary' }} />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: 'calc(100% - 44px)',
                            wordWrap: 'break-word',
                          }}
                        >
                          <Box key={option?.name} component="span">
                            {option?.name}
                          </Box>
                          {option.address && (
                            <Typography variant="body2" color="text.secondary">
                              {option?.address?.city}, {option?.address?.state},{' '}
                              {option?.address?.country?.label}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  // Filter out the option with the old_company._id
                  const oldCompanyId = values?.old_company?._id;
                  return filtered.filter(
                    (option) => option._id !== oldCompanyId,
                  );
                }}
                onChange={async (e, option) => {
                  setFieldValue('new_company', option);
                }}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={async (e) => {
                      let input = e.target.value;

                      if (input.length > 3) {
                        setIsLoadingCompany(true);
                        let companySearch = setTimeout(async () => {
                          try {
                            const companyOptions =
                              await searchCompaniesByName(input);

                            if (companyOptions.statusCode === 200) {
                              setCompanyOptions(companyOptions.data);
                            }
                          } catch (error) {
                            console.log(error);
                          } finally {
                            clearTimeout(companySearch);
                            setIsLoadingCompany(false);
                          }
                        }, 100);
                      }

                      setFieldValue('company.name', input);
                    }}
                    variant="outlined"
                    label={t('label')}
                    placeholder={t('placeholder')}
                    InputProps={{
                      ...params.InputProps,
                      type: 'text',
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingCompany ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    error={
                      touched?.new_company && errors?.new_company?._id
                        ? true
                        : false
                    }
                    helperText={
                      touched?.new_company && errors?.new_company?._id
                        ? errors?.new_company?._id
                        : null
                    }
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={2} sx={{ marginLeft: '5px' }}>
                  {/* Add Company */}
                  <IntuIconButton
                    variant="contained"
                    tooltipTitle="add"
                    tooltipType="company"
                    type="add"
                    onClick={() => {
                      handleAddNewCompany();
                    }}
                  />
                </Grid>

                <Grid item xs={8} container justifyContent="flex-end">
                  {/* On my Own */}
                  {values.old_company._id && (
                    <IntuIconButton
                      disabled={isLoading.status || values?.new_company?._id}
                      variant="contained"
                      tooltipTitle="on_my_own"
                      type="info"
                      IconComponent={HailIcon}
                      onClick={() => {
                        handleCompanyRemoval({
                          old_company: values.old_company,
                          helpers: formik,
                        });
                      }}
                    />
                  )}

                  {/* Cancel */}
                  <IntuIconButton
                    disabled={isLoading.status}
                    variant="outlined"
                    tooltipTitle="cancel"
                    type="cancel"
                    onClick={() => {
                      setCompanyOptions([]);
                      resetForm();
                      handleClose();
                    }}
                  />

                  {/* Confirm Change */}
                  <IntuIconButton
                    disabled={!isValid || isLoading.status}
                    variant="contained"
                    type="success"
                    tooltipTitle="confirm"
                    onClick={() => {
                      handleCompanyNameChange({
                        new_company: values.new_company,
                        old_company: values.old_company,
                        helpers: formik,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export { ChangeCompanyAssociationDialog };
