import * as React from 'react';
import { useContext } from 'react';

// Formik
import { Formik, Form, useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useProcessingHandler } from '../../../hooks/useProcessingHandler';
import { ObjectId } from 'bson';
import { useTranslation } from 'react-i18next';

import {
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Dialog,
  Stack,
  Skeleton,
  DialogContentText,
  Typography,
  Box,
  Button,
  DialogActions,
} from '@mui/material';
import dayjs from 'dayjs';
import { classes } from '../../../mui/theme';
import DatePickerField from '../../../components/date/DatePickerField';
import AssociateActivitySelectionField from '../../../components/activity/AssociateActivitySelectionField';
import MessageFieldRegular from '../../../components/account/MessageFieldRegular';
import { ManageLeadContext } from '../../../context/providers/leads/ManageLeadContextProvider.jsx';

const AssociateActivityDialog = () => {
  //  Get Context
  const { isLoading } = useProcessingHandler();
  const { activityDialog, setActivityDialog, saveLead } =
    useContext(ManageLeadContext);
  const { values: parentValues } = useFormikContext();

  // Translator
  const { t } = useTranslation();

  // Mode "add" or "update"
  const mode = activityDialog.activityDialogAction;

  // Initial Formik
  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  // Initialize initial Values
  formik.initialValues = {
    ...activityDialog.activityContent,
  };

  // Validation
  var validationSchema = Yup.object();
  validationSchema = Yup.object().shape({
    _id: Yup.string().required(t('validation.id_required')),
    comment: Yup.string()
      .min(2, t('validation.short'))
      .max(400, t('validation.comment_max'))
      .required(t('validation.comment_required')),
    date: Yup.date()
      .min(new Date(2023, 1, 1))
      .required(t('validation.date_required')),
    activity: Yup.string().required(t('validation.activity_required')),
  });

  // Set ObjectID for new activity logs
  if (activityDialog.activityDialogAction === 'add') {
    const id = new ObjectId();
    formik.initialValues._id = id;
    formik.initialValues.date = dayjs().format('MM/DD/YYYY');
  }

  function addNewEntry(values, formik, action) {
    const existingActivities = activityDialog.activityLog;
    const newActivity = values;
    let newActivities;
    // Clear the form state
    setActivityDialog({
      openDialog: false,
      leadID: '',
      activityDialogTitle: '',
      activityDialogText: '',
      activityPrimaryButtonText: '',
      activitySecondaryButtonText: '',
      activityDialogAction: '',
      activityLog: [],
      activityContent: {
        _id: '',
        user: '',
        date: '',
        activity: '',
        comment: '',
      },
    });

    if (action === 'add') {
      // add the value to the leadContentActivity Array
      newActivities = existingActivities
        ? [...existingActivities, newActivity]
        : [newActivity];
    } else if (action === 'update') {
      // replace existing activity

      for (let i = 0; i < existingActivities.length; i++) {
        const element = existingActivities[i];

        if (element?._id === newActivity?._id) {
          existingActivities[i]._id = newActivity._id;
          existingActivities[i].date = newActivity.date;
          existingActivities[i].comment = newActivity.comment;
          existingActivities[i].activity = newActivity.activity;
        }
      }
      newActivities = existingActivities;
    }

    // update existing lead in database
    const param = {
      _id: activityDialog.leadID,
      associate_activity: newActivities,
    };
    saveLead('update', param, formik);
  }

  return (
    <Formik
      values={formik.values}
      initialValues={formik.initialValues}
      errors={formik.errors}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(formik) => {
        const {
          values,
          setFieldValue,
          errors,
          touched,
          handleBlur,
          isValid,
          setFieldTouched,
          resetForm,
          isSubmitting,
          setSubmitting,
        } = formik;
        return (
          <Form>
            <FormControl>
              <Dialog maxWidth="sm" fullWidth open={activityDialog.openDialog}>
                <DialogTitle>
                  <Grid
                    container
                    spacing={{ xs: 12 }}
                    justifyContent="center"
                    direction={{ xs: 'column', sm: 'column', md: 'row' }}
                  >
                    {activityDialog?.activityDialogTitle}
                  </Grid>
                </DialogTitle>

                <DialogContent>
                  {isLoading?.status === true &&
                  isLoading?.type === 'skeleton' ? (
                    <Stack>
                      <DialogContentText
                        sx={{ color: 'var(--color-text-error-message)' }}
                      >
                        <Skeleton width="30%" height="30px">
                          <Typography>.</Typography>
                        </Skeleton>
                      </DialogContentText>
                      {/* Date */}
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="30px"
                      />
                      {/* Activity Type */}
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="30px"
                      />
                      {/* Comment */}
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="30px"
                      />
                    </Stack>
                  ) : (
                    <Box>
                      <Stack
                        spacing={{ xs: 3, sm: 3, md: 1 }}
                        direction="column"
                        sx={{ paddingTop: '10px' }}
                        style={classes.root}
                        alignItems="stretch"
                      >
                        <Typography variant="subtitle1" color="intu">
                          {activityDialog?.activityDialogTitle}
                        </Typography>

                        {/* Activity Date */}
                        <DatePickerField required />

                        {/* Activity Type */}
                        <AssociateActivitySelectionField required />

                        {/* Activity Description  */}
                        <MessageFieldRegular
                          required
                          fieldID="comment"
                          transTypeID="activity"
                        />
                      </Stack>
                    </Box>
                  )}
                </DialogContent>
                <DialogActions>
                  {/* Add or update activity */}
                  <Button
                    variant="contained"
                    onClick={(e) => addNewEntry(values, formik, mode)}
                    disabled={!isValid || isSubmitting}
                  >
                    {activityDialog.activityPrimaryButtonText}
                  </Button>

                  {/* Close activity log */}
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      resetForm();
                      setActivityDialog({
                        openDialog: false,
                        leadID: '',
                        activityDialogTitle: '',
                        activityDialogText: '',
                        activityPrimaryButtonText: '',
                        activitySecondaryButtonText: '',
                        activityDialogAction: '',
                        activityLog: [],
                        activityContent: {
                          _id: '',
                          user: '',
                          date: '',
                          activity: '',
                          comment: '',
                        },
                      });
                    }}
                  >
                    {activityDialog?.activitySecondaryButtonText}
                  </Button>
                </DialogActions>
              </Dialog>
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};

export { AssociateActivityDialog };
