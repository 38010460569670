import axios from 'axios';

const BASE_URL  = `${process.env.REACT_APP_API_PATH}:${process.env.REACT_APP_API_PORT}`;

export default axios.create({
    baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
    withCredentials: true
});

export const axiosPrivateData = axios.create({
    baseURL: BASE_URL,
    headers: { 
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
    },
    withCredentials: true
});

