import React from 'react';
import { ScrollingCards } from '../../../../components/motion/ScrollingCards';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { ButtonHoverGradiant } from '../../../../components/buttons/ButtonHoverGradiant';
import i18n from 'i18next';

// Mui Icons
import EditNoteIcon from '@mui/icons-material/EditNote';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import BadgeIcon from '@mui/icons-material/Badge';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useIntuTheme } from '../../../../context/providers/IntuThemeProvider';
import { useTranslation } from 'react-i18next';

const HowToBecomeASalesRep = () => {
  // Theme
  const theme = useTheme();
  const { mode } = useIntuTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.lead_context',
  });

  // Navigate
  const navigate = useNavigate();

  const salesCardContent = [
    <EditNoteIcon sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }} />,
    <SwitchAccountIcon
      sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }}
    />,
    <BadgeIcon sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }} />,
    <ManageSearchIcon
      sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }}
    />,
    <RequestPageIcon sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }} />,
  ].map((icon, index) => {
    return (
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        <Grid
          item
          xs={10}
          container
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          justifyContent={{ xs: 'center', md: 'space-around' }}
          textAlign="center"
        >
          {/* Icon & Label */}
          <Grid item xs={5} container direction="column" alignItems="center">
            <Grid item>{icon}</Grid>
            <Grid item>
              <Typography variant="h5" align="center" sx={{ color: '#4d4d4d' }}>
                {t(`sales.cards.title${index + 1}`)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <Typography
              variant={small ? 'body1' : 'h6'}
              sx={{ color: '#4d4d4d' }}
              align="center"
            >
              {t(`sales.cards.desc${index + 1}`)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  const JoinNow = () => {
    return (
      <ButtonHoverGradiant
        label={i18n.t('join_now', { ns: 'buttons' })}
        onClick={() => navigate('/account/signup')}
      />
    );
  };

  return (
    <ScrollingCards
      contentArr={salesCardContent}
      header={
        <Grid
          container
          direction="column"
          justifyContent="center"
          wrap="nowrap"
        >
          <Grid item xs={12}>
            <Typography
              variant="h1"
              sx={{
                color: mode === 'dark' ? 'white' : 'black',
                fontWeight: '600',
              }}
            >
              {i18n.format(t('sales.cards.title'), 'uppercase')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{t('sales.cards.subtitle')}</Typography>
          </Grid>
        </Grid>
      }
      footer={
        <Grid mb={10}>
          <JoinNow />
        </Grid>
      }
    />
  );
};

export { HowToBecomeASalesRep };
