import { Grid } from '@mui/material';
import { BlogFooterContent } from '../../context/footer/BlogFooterContent';

const BlogFooterContainer = ({ content }) => {
  return (
    <Grid>
      <BlogFooterContent content={content} />
    </Grid>
  );
};

export default BlogFooterContainer;
