import { useState, useRef, useEffect, useContext } from 'react';
import { SketchPicker, ChromePicker } from 'react-color';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { Box, IconButton, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';
import { useFormikContext } from 'formik';
import { CompanyProfileContext } from '../../context/providers/CompanyProfileProvider';

const applyColor = (editorState, color) => {
  const selection = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const contentStateWithEntity = currentContent.createEntity(
    'COLOR',
    'MUTABLE',
    { color },
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newContentState = Modifier.applyEntity(
    currentContent,
    selection,
    entityKey,
  );

  const newState = EditorState.push(
    editorState,
    newContentState,
    'apply-entity',
  );

  return RichUtils.toggleInlineStyle(newState, `COLOR-${color}`);
};

export const IntuColorPickerRichTextControl = ({
  editorState,
  setEditorState,
}) => {
  const [color, setColor] = useState('#000');

  const handleChangeComplete = (newColor) => {
    setColor(newColor.hex);
    const newState = applyColor(editorState, newColor.hex);
    setEditorState(newState);
  };

  return (
    <div style={{ position: 'relative' }}>
      <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
    </div>
  );
};
export const IntuColorPickerField = ({
  defaultColor = '#000',
  fieldID = 'colors', //Name of the Target Field
  formik, //Formik Context including values, errors, setFieldValue, touched etc.
  disabled = false, //Whether the Field is disabled or not
  required = true, // Whether the fidl is required or not
  transition = true, // Whether to display the field ot not
}) => {
  const [color, setColor] = useState(defaultColor);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const pickerRef = useRef(null);

  // const { setFieldValue, errors, touched } = formik;
  const { setFieldValue, errors, touched } = useFormikContext();

  const { transitionInterval } = useContext(IntuThemeContext);
  const { companyProfile, setCompanyProfile } = useContext(CompanyProfileContext);

  const handleChangeComplete = (newColor) => {
    let newFieldID = fieldID.replace("colors.", "")
    setColor(newColor.hex);
    setFieldValue(fieldID, newColor.hex);
    setCompanyProfile({
      ...companyProfile,
      colors: {
        ...companyProfile.colors,
        [newFieldID]: newColor.hex
      }
    })
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setDisplayColorPicker(false);
    }
  };

  useEffect(() => {
    if (displayColorPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [displayColorPicker]);

  return (
    transition && (
      <Box position="relative">
        <Box display="flex" alignItems="center">
          <Box
            style={{
              backgroundColor: color,
              width: 35,
              height: 35,
              border: '1px solid #000',
              marginRight: 8,
              cursor: 'pointer',
            }}
            onClick={handleClick}
          />
          <TextField
            value={color}
            onChange={(e) => setColor(e.target.value)}
            variant="outlined"
            margin="dense"
            disabled={disabled}
            required={required}
            error={
              errors.fieldID && touched.fieldID ? true : false
            }
            helperText={
              errors.fieldID && touched.fieldID
                ? errors.fieldID
                : null
            }
          />
        </Box>
        {displayColorPicker && (
          <Box
            position="absolute"
            zIndex={1000}
            top={40} // Adjust the top value to position the picker
            left={0} // Adjust the left value to position the picker
            ref={pickerRef}
          >
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                style={{
                  color: 'var(--color-inputbox-border-error)',
                }}
                onClick={handleClose}
              >
                <CancelIcon />
              </IconButton>
            </Box>
            <ChromePicker
              color={color}
              onChangeComplete={handleChangeComplete}
            />
          </Box>
        )}
      </Box>
    )
  );
};
