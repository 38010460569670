import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContactUsForm from '../../components/contact/ContactUsForm';
import PageHeader from '../../components/layout/PageHeader';
import IntutecLocationsMap from '../../components/maps/IntutecLocationsMap';
import './contact.css';
import IntuPage from '../../components/layout/IntuPage';
import { IntuForm } from '../../components/layout/IntuForm';
import { createContext, useState } from 'react';

export const ContactFormContext = createContext();

function ContactUs() {
  const { t } = useTranslation();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <ContactFormContext.Provider
      value={{
        isSubmitted,
        setIsSubmitted,
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <PageHeader body={<IntutecLocationsMap />} />
        </Grid>
        <Grid item xs={12}>
          <IntuPage
            pageTitle={isSubmitted ? '' : t('pages.ContactUs.title')}
            pageDescription={
              isSubmitted ? '' : t('pages.ContactUs.description')
            }
            hCentered={mobile}
          >
            <IntuForm>
              <ContactUsForm />
            </IntuForm>
          </IntuPage>
        </Grid>
      </Grid>
    </ContactFormContext.Provider>
  );
}
export default ContactUs;
