
import React, { useState, useEffect} from 'react';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

// charts
import * as am5 from "@amcharts/amcharts5";
import { BarChartContext } from "../../charts/barChart";

// Mui
import { Grid } from '@mui/material';

// Uinique Chart ID
import { v4 as uuid } from 'uuid';

// Intu Page Editor Components
import { ColorPickerField } from '@in2tec/page-editor';


const xyChartPlugin = {
  Renderer: ({ data }) => {

    const id = uuid();
    const [chartData, setChartData] = useState([]);

    // Viewport
    const [mobileView, setMobileView] = useState(false)
    useEffect(() => {
      function handleResize() {
        if (window.innerWidth > 1000) {
          setMobileView(false);
        } else if (window.innerWidth < 1000) {
          setMobileView(true);
        }
      };

      handleResize();
      window.addEventListener('resize', handleResize)
    })

    useEffect(() => {

      // This effect will run whenever the data prop changes
      // You can update the chart here with the new data
      if (data.seriesData?.length > 0) {
        // Check if custom Color is specified

        // const formattedWorkbook = [{ year: 2016, mainHeaderEnglish: "Pearls of Wisdom", categoryCode: 3 }, { year: 2017, mainHeaderEnglish: "VENuS Satellite", categoryCode: 2 }, { year: 2017, mainHeaderEnglish: "Hope for millions", categoryCode: 1 }, { year: 2012, mainHeaderEnglish: "green electricity Pioneer", categoryCode: 2 }],
          let results = Array.from(
            data.seriesData.reduce((m, { seriesName, ...o }) =>
              m.set(seriesName, [...m.get(seriesName) || [], o]), new Map),
            ([seriesName, data]) => ({ seriesName, data })
          );

        // console.log(result);

        let seriesData = []
        for (let i = 0; i < results.length; i++) {
          let result = results[i];
          let dataPoints = [];

          if (result.data?.length > 0) {


            for (let s = 0; s < result.data?.length; s++) {
              let element = result.data[s];

              if (element?.color > '') {
                element = {
                  ...element,
                  columnSettings: {
                    fill: am5.color(element.color),
                    stroke: am5.color(element.color)
                  }
                }
              }
              delete element.color
              dataPoints.push(element)
            }
          }
          let series = {
            ...result,
            data: dataPoints
          }
          seriesData.push(series)

          // console.log(seriesData);
        }

        let settings = {
          type: data.chartType,
          minValue: data?.minValue,
          maxValue: data?.maxValue,
          maxPrecision: data?.maxPrecision,
          showCursor: data?.showCursor,
          showLegend: data.showLegend || false,
          showDataLabels: data.showDataLabels || false,
          dataLabelPositionX: data.dataLabelPositionX,
          dataLabelPositionY: data.dataLabelPositionY,
          dataLabelColor: data.dataLabelColor,
          label: data?.dataLabel,
          seriesLabelRotation: data?.seriesLabelRotation,
          seriesLabelSize: data?.seriesLabelSize,
          chartHeight: data?.chartHeight
        }

        // console.log(settings);

        setChartData({
          id,
          title: data?.title,
          description: data?.description,
          settings,
          data: seriesData     
        });

        // console.log(chartData)
      }
    }, [data]);

    if (data.seriesData?.length > 0) {
      return (
        <Grid container sx={{marginTop: "10px"}}>
          <Grid item xs={12}>
            <BarChartContext
              chartId={chartData?.id}
              title={chartData?.title}
              description={chartData?.description}
              data={chartData?.data}
              settings={chartData?.settings}
              mobileView={mobileView}
            />
          </Grid>
        </Grid>
      );
    }
  },
  id: 'AmChartsXyChart',
  title: 'XY Chart',
  icon: <LeaderboardIcon />,
  description: 'Create a XY Column or Line Chart',
  version: 1,
  controls: [
    {
      title: "Chart Settings",
      controls: {
        type: 'autoform',
        columnCount: 1,
        schema: {
          properties: {
            title: {
              type: "string",
              uniforms: {
                label: "Chart Title",
                placeholder: "Give your Chart a Title",
              },
            },
            description: {
              type: 'string',
              uniforms: {
                label: "Chart Description",
                placeholder: "Describe your chart!",
                multiline: true,
                rows: 4,
              },
            },
            chartType: {
              type: 'string',
              default: 'Bar Chart',
              enum: ['Bar Chart', 'Line Chart'],
            },
            showLegend: {
              type: 'boolean',
              default: false,
              uniforms: {
                label: "Show Chart Legend",
              },
            },
            showCursor: {
              type: 'boolean',
              default: false,
              uniforms: {
                label: "Show Cursor on Chart",
              },
            },
            dataLabel: {
              type: "string",
              default: '%',
              uniforms: {
                label: "Data Label",
                placeholder: "e.g. US$, %, EUR",
              },
            },
            minValue: {
              type: "integer",
              default: 0,
              uniforms: {
                label: "Min. Value",
              },
            },
            maxValue: {
              type: "integer",
              default: 100,
              uniforms: {
                label: "Max. Value",
              },
            },
            maxPrecision: {
              type: "integer",
              default: 10,
              uniforms: {
                label: "Max. Precision",
              },
            },
            chartHeight: {
              type: "integer",
              default: 350,
              uniforms: {
                label: "Height",
              },
            },
          },
        },
      },
    },
    {
      title: "Chart Data",
      controls: {
        type: 'autoform',
        columnCount: 1,
        schema: {
          properties: {
            seriesData: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  seriesName: {
                    type: "string",
                    uniforms: {
                      label: "Series Name",
                      placeholder: "Enter the Series Name",
                    },
                  },
                  category: {
                    type: 'string',
                  },
                  value: {
                    type: 'number',
                    default: 0,
                  },
                  color: {
                    type: 'string',
                    default: '',
                    uniforms: {
                      component: ColorPickerField,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    {
      title: "Labels & Legend",
      controls: {
        type: 'autoform',
        columnCount: 1,
        schema: {
          properties: {
            showDataLabels: {
              type: 'boolean',
              default: true,
              uniforms: {
                label: "Show Data Labels",
              },
            },
            dataLabelColor: {
              type: 'string',
              default: '',
              uniforms: {
                component: ColorPickerField,
              },
            },
            dataLabelPositionX: {
              type: 'number',
              default: 0.5,
            },
            dataLabelPositionY: {
              type: 'number',
              default: 1.03,
            },
            seriesLabelRotation: {
              type: "integer",
              default: -30,
              uniforms: {
                label: "Series Label Rotation",
              },
            },
            seriesLabelSize: {
              type: "integer",
              default: 12,
              uniforms: {
                label: "Series Label Size",
              },
            },
          }
        }
      }
    }
  ]
};

export default xyChartPlugin;
