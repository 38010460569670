import { useState, useEffect, useRef, useContext } from 'react';
import useAuth from '../../../../hooks/useAuth.js';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsTracker.jsx';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../hooks/axios/useAxios.js';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Materail UI
import { Grid, Stack, FormControl, TextField, Button } from '@mui/material';
import { classes } from '../../../../mui/theme.js';

// Response & Loading Handlers
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler.js';
import useUser from '../../../../hooks/useUser.js';

// Translator
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n.js';
import { FormBodyContext } from '../../../../context/providers/FormContextProvider.jsx';
import AuthContext from '../../../../context/providers/AuthProvider.jsx';

// FORM CONTENT
const ResetChangePasswordForm = (props) => {
  // Google Event Tracker
  const { gaEventTracker } = useAnalyticsEventTracker();

  // Form Body Context
  const { setFormTitle, setFormDescription, setShowForm } =
    useContext(FormBodyContext);

  // State & Context
  const { setAuth } = useContext(AuthContext);
  const { user } = useUser();

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  const navigate = useNavigate();
  const host_url = window.location.host;
  const [userID, setUserID] = useState('');
  const [userOTP, setUserOTP] = useState('');

  // Translator
  const { t } = useTranslation();

  // Setup Initial Values for Form Validation
  const formType = props.formType;
  var validationSchema = Yup.object();
  var initialValues = {
    form: props.formType,
    action: props.buttonAction,
  };

  // Initial Values
  if (formType === 'accPassReset') {
    //Initial Values
    initialValues.email = '';

    // Validation Schema
    validationSchema = Yup.object().shape({
      email: Yup.string()
        .required(t('validation.email.pass_reset_required'))
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          t('validation.email.invalid'),
        ),
    });
  } else if (formType === 'accPassChange') {
    initialValues.id = userID;
    initialValues.userOTP = userOTP;
    initialValues.userPass = '';
    initialValues.userPassConf = '';

    validationSchema = Yup.object().shape({
      id: Yup.string().required(),
      userPass: Yup.string()
        .required(t('validation.password.required'))
        .min(8, t('validation.password.short'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
          t('validation.password.matches'),
        ),
      userPassConf: Yup.string()
        .required(t('validation.password.comf_required'))
        .oneOf(
          [Yup.ref('userPass'), null],
          t('validation.password.comf_match'),
        ),
    });
  }

  // Set Up From refs
  const userRef = useRef();
  const passRef = useRef();
  const formRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userID = params.get('userID');
    const userOTP = params.get('userOTP');

    if (formType === 'accPassChange') {
      if (userID) {
        setUserID(userID);
      }
      if (userOTP) {
        setUserOTP(userOTP);
      }
    }
  }, [formType, i18n.language]);

  //Form Fields visibility
  const EmailField = !props.showEmail ? false : true;
  const PasswordFields = !props.showDefinePassword ? false : true;
  const showSigninButton = props.showSigninButton ? false : true;

  //handle form submission process
  async function submitForm(values, actions) {
    let id = values.id;
    let email = values.email;
    let password = values.userPass;
    let otp = values.userOTP;
    let action = values.action;

    //reset password
    if (action === 'accPassReset') {
      const url = `/api/users/account/reset/password/request`;
      const payload = {
        email,
        host_url: host_url,
      };
      try {
        setIsLoading({
          status: true,
          type: 'spinner',
          text: t('context.account.resetPasswordContext.loading'),
        });
        const request = await axios.post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        const response = request?.data;

        if (response) {
          //Handle Response
          if (request.status === 200) {
            // password resetted
            gaEventTracker({
              category: 'Account Management',
              action: 'Event',
              label: 'Password Reset',
            });

            // Update Form Body
            setFormTitle(
              t('context.account.resetPasswordContext.response.success.title'),
            );
            setFormDescription(response.message);
            setShowForm(false);

            // Trigger Status Bar
            handleRegularResponse({
              open: true,
              status: response.status,
              message: response.message,
            });
          } else if (request.status === 230) {
            // user is pending
            actions.resetForm();
            navigate(response.navigate_to, { replace: true });
            handleRegularResponse({
              open: true,
              status: response.status,
              message: response.message,
            });
          } else if (request.status === 231) {
            actions.resetForm();
            handleRegularResponse({
              open: true,
              status: 'error',
              message: response.message,
            });
          } else {
            // all other responses
            handleRegularResponse({
              open: true,
              status: 'error',
              message: response.message,
            });
          }
        }
      } catch (err) {
        handleErrorResponse(err);
      } finally {
        setIsLoading({ status: false, type: '', text: '' });
      }
    } else if (action === 'accPassChange') {
      const url = '/api/users/account/reset/password/confirm';
      const payload = {
        _id: id,
        host_url: host_url,
        new_password: password,
        otp: otp,
      };

      try {
        setIsLoading({
          status: true,
          type: 'spinner',
          text: t('context.account.changePasswordContext.loading'),
        });
        const request = await axios.post(url, payload);

        const response = request?.data;

        // reset successful
        if (request.status === 200) {
          // Password Reset Successfull
          gaEventTracker({
            category: 'Account Management',
            action: 'Event',
            label: 'User Logged In',
          });
          let image = '';

          // Set Profile Picture
          if (response.user_info?.profile_picture?.mimetype) {
            image = `data:${response.user_info.profile_picture.mimetype};base64,${response.user_info.profile_picture.buffer.toString('base64')}`;
          }

          let authInfo = response.auth_info;

          let userInfo = response.user_info;
          userInfo = { ...userInfo, profile_picture_view_url: image };

          // Set User Auth State
          setAuth({ auth_info: authInfo, user_info: userInfo });

          // Navigate to user dashboard
          navigate(response.navigate_to, { replace: true });
        } else if (request.status === 246) {
          // Password Reset Link expired

          // Update Form Body
          setFormTitle(
            t('context.account.changePasswordContext.response.error.title'),
          );
          setFormDescription(response.message);
          setShowForm(false);

          // Trigger Status Bar
          handleRegularResponse({
            open: true,
            status: response.status,
            message: response.message,
          });
        } else {
          // all other responses
          handleRegularResponse({
            open: true,
            status: 'error',
            message: response.message,
          });
        }
      } catch (err) {
        handleErrorResponse(err);
      } finally {
        setIsLoading({ status: false, type: '', text: '' });
      }
    }
  }

  return (
    <Grid item className="content" xs={12}>
      {(props.formType === 'accPassReset' ||
        props.formType === 'accPassChange') && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => submitForm(values, actions)}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
          innerRef={formRef}
        >
          {(formik) => {
            const {
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
            } = formik;
            return (
              <>
                <Form
                  className="form-horizontal"
                  role="form"
                  onSubmit={handleSubmit}
                >
                  <FormControl fullWidth>
                    <Stack spacing={4} sx={{ paddingBottom: '50px' }}>
                      <Stack spacing={4} style={classes.root}>
                        {/* Email address & Phone Number */}
                        <>
                          {EmailField && (
                            <TextField
                              required
                              id="email"
                              name="email"
                              ref={userRef}
                              autoComplete="off"
                              className="form-select-field"
                              aria-invalid={errors.email ? 'true' : 'false'}
                              aria-describedby="uidnote"
                              variant="outlined"
                              label={t(
                                'form.account.password.reset.email.label',
                              )}
                              type="text"
                              placeholder={t(
                                'form.account.password.reset.email.placeholder',
                              )}
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              inputProps={{
                                autoComplete: 'off',
                              }}
                              onBlur={handleBlur}
                              value={values?.email}
                              error={
                                errors?.email && touched?.email ? true : false
                              }
                              helperText={
                                errors?.email && touched?.email
                                  ? errors?.email
                                  : null
                              }
                            />
                          )}
                        </>
                      </Stack>

                      {/* User Password and Confirmation */}
                      <Stack
                        spacing={3}
                        direction={{ xs: 'column' }}
                        style={classes.root}
                      >
                        {PasswordFields && (
                          <>
                            <TextField
                              required
                              ref={passRef}
                              style={classes.root}
                              id="userPass"
                              name="userPass"
                              className="form-select-field"
                              aria-invalid={errors.userPass ? 'true' : 'false'}
                              aria-describedby="uidnote"
                              variant="outlined"
                              label={t(
                                'form.account.password.change.password.label',
                              )}
                              type="password"
                              placeholder={t(
                                'form.account.password.change.password.placeholder',
                              )}
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              inputProps={{
                                autoComplete: 'off',
                              }}
                              onBlur={handleBlur}
                              value={values.userPass}
                              error={
                                errors.userPass && touched.userPass
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.userPass && touched.userPass
                                  ? errors.userPass
                                  : null
                              }
                            />

                            <TextField
                              required
                              id="userPassConf"
                              name="userPassConf"
                              className="form-select-field"
                              aria-invalid={
                                errors.userPassConf ? 'true' : 'false'
                              }
                              aria-describedby="uidnote"
                              variant="outlined"
                              label={t(
                                'form.account.password.change.passwordConf.label',
                              )}
                              type="password"
                              placeholder={t(
                                'form.account.password.change.passwordConf.placeholder',
                              )}
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              inputProps={{
                                autoComplete: 'off',
                              }}
                              onBlur={handleBlur}
                              value={values.userPassConf}
                              error={
                                errors.userPassConf && touched.userPassConf
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.userPassConf && touched.userPassConf
                                  ? errors.userPassConf
                                  : null
                              }
                            />
                          </>
                        )}
                      </Stack>
                    </Stack>

                    {/* CTA  */}
                    <Grid
                      container
                      spacing={1}
                      direction={{ xs: 'column', sm: 'row' }}
                      style={classes.root}
                      sx={{ paddingBottom: '50px' }}
                    >
                      <Grid item sx={{ marginLeft: 'auto' }}>
                        <Button
                          variant="reset"
                          type="submit"
                          disabled={!isValid}
                        >
                          {props.buttonLabel}
                        </Button>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </Grid>
  );
};

export default ResetChangePasswordForm;
