import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ResponseContext from '../../context/providers/ResponseProvider';

const ErrorDialog = () => {
  const { errorDialog, setErrorDialog } = useContext(ResponseContext);

  // Translator
  const { t } = useTranslation();

  // Handle Error Dialog
  function handleConfirmError() {
    setErrorDialog({ open: false, title: '', message: '' });
  }

  return (
    <Dialog
      open={errorDialog.open}
      onClose={handleConfirmError}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{errorDialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorDialog.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={handleConfirmError}
          autoFocus
        >
          {t('buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
