function formatString(str) {
    // Step 1: Replace underscores with spaces
    let result = str.replace(/_/g, ' ');

    // Step 2: Insert spaces before uppercase letters (for camelCase)
    result = result.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Step 3: Capitalize the first letter of each word
    result = result.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');

    return result;
}

export default formatString;