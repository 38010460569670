import { IntuUserList } from '../../components/admin/IntuUserList';
import { IntuForm } from '../../components/layout/IntuForm';
import { useTranslation } from 'react-i18next';
import IntuPage from '../../components/layout/IntuPage';

const IntuUsers = () => {
  const { t } = useTranslation();
  return (
    <IntuPage>
      <IntuForm
        form="Users Management"
        formTitle={t('pages.admin.intuUsers.title')}
        formDescription={t('pages.admin.intuUsers.description')}
      >
        <IntuUserList />
      </IntuForm>
    </IntuPage>
  );
};

export default IntuUsers;
