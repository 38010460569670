import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { Outlet } from 'react-router-dom';
import { getIntutecEmployees } from '../../routes/employeeRoutes';
import AuthContext from './AuthProvider';
import useResponseHandling from '../../hooks/useResponseHandler';

export const IntuCompanyContext = createContext();

const IntuCompanyInfoProvider = ({ children }) => {
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const { handleErrorResponse } = useResponseHandling();

  const { auth, isAdmin } = useContext(AuthContext);

  async function handleGetEmployees() {
    if (!auth.auth_info || isAdmin !== true) {
      return setEmployeeOptions([]);
    }

    try {
      const payload = {
        priviliges: ['sales'],
      };

      const employeesList = await getIntutecEmployees(payload);
      if (employeesList.status === 'success') {
        setEmployeeOptions(employeesList.data);
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  }

  useEffect(() => {
    const preLoadContext = async () => {
      await handleGetEmployees();
    };

    preLoadContext();
  }, [isAdmin]);

  return (
    <IntuCompanyContext.Provider
      value={{ employeeOptions, setEmployeeOptions, handleGetEmployees }}
    >
      {children}
    </IntuCompanyContext.Provider>
  );
};

export { IntuCompanyInfoProvider };
