import { useContext } from 'react';
import axios from './axios/axios'; // Ensure this is correctly pointing to your axios instance
import AuthContext from '../context/providers/AuthProvider';

const useRefreshToken = () => {
  const { setAuth, setCheckAuth } = useContext(AuthContext);

  const authInfo = async () => {
    try {
      const response = await axios.get('/api/users/account/re-authenticate', {
        withCredentials: true,
      });

      const { data, status } = response;

      // Handle Unauthorized Response
      if (status === 200) {
        const authInfo = data.auth_info;
        const userInfo = data.user_info;

        // Set Profile Picture
        let image = '';
        if (data.user_info?.profile_picture?.mimetype) {
          image = `data:${data.user_info.profile_picture.mimetype};base64,${data.user_info.profile_picture.buffer.toString('base64')}`;
        }
        userInfo.profile_picture_view_url = image;

        // Set User Auth State
        setAuth({ auth_info: authInfo, user_info: userInfo });
      } else {
        setAuth({ auth_info: null, user_info: {} });
      }

      return data.auth_info;
    } catch (error) {
      if (error?.response?.status !== 401)
        console.error('Error during re-authentication', error);
    } finally {
      setCheckAuth(true);
    }
  };

  return authInfo;
};

export default useRefreshToken;
