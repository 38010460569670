import * as Yup from 'yup';
import i18next from 'i18next';

export const createChangeCompanyAssociationValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    new_company: Yup.object({
      _id: Yup.string().required(t('company.select')),
    }),
  });
};
