import { getUserCountryDetails } from '../../../helpers/getUserCountryDetails';
import { getUserPhoneDetails } from '../../../helpers/getUserPhoneDetails';

export const createInitialValuesNewCompanyForm = ({ user, auth, info }) => {
  //Guard Clause
  if (!user || !auth) {
    return;
  }
  const countryCode = user.location.country || 'US';

  return {
    type: auth?.user_info?.account_type,
    _id: '',
    name: info?.name || '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postcode: '',
      country: getUserCountryDetails(user),
      coordinates: { latitude: '', longitude: '' },
    },
    website: 'https://',
    email: '',
    phone: getUserPhoneDetails(countryCode),
    employees: [
      {
        _id: auth?.user_info?._id,
        role: 'admin',
      },
    ],
    isSubsidiary: false,
    parent_id: '',
    brands: [],
    products: [],
    applications: [],
    tos_accepted: {
      accepted: false,
      ip: '',
      date: '',
      unixTimeStamp: '',
      agent: '',
    },
    ppa_accepted: {
      accepted: false,
      ip: '',
      date: '',
      unixTimeStamp: '',
      agent: '',
    },
    ssa_accepted: {
      accepted: false,
      ip: '',
      date: '',
      unixTimeStamp: '',
      agent: '',
    },
  };
};
