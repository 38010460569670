import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { RiveAnimationComponent } from '../../../components/animations/RiveAnimationComponent.jsx';
import useIntuTheme from '../../../hooks/useIntuTheme.js';
import { useTranslation } from 'react-i18next';

export const WhatMakesUsDifferent = ({ title, description }) => {
  const theme = useTheme();
  const { mode } = useIntuTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.lead_context',
  });
  return (
    <Grid
      container
      alignItems="flex-start"
      direction="column"
      sx={{ padding: { xs: '10px 0px 50px 0px', md: '50px 0px 50px 20px' } }}
    >
      <Grid
        container
        className="intu__divider"
        spacing={8}
        justifyContent="center"
        alignItems="center"
        direction={{ xs: 'column', md: 'row' }}
        sx={{ padding: '50px 20px 0 20px' }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h2"
            color="primary"
            align="center"
            sx={{
              color: mode === 'dark' ? 'white' : 'black',
              fontWeight: '600',
            }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item xs={12} textAlign="center">
          <Typography variant="body" align="center">
            {description}
          </Typography>
        </Grid>

        {/* Current */}
        <Grid item container xs={12} md={6} justifyContent="center">
          <Typography variant="h2" align="center">
            {t('what_makes_us_diff_chart1')}
          </Typography>
          <RiveAnimationComponent
            src={
              mode === 'dark'
                ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/current_tree_dark_mode.riv'
                : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/current_tree_light_mode.riv'
            }
            stateMachines="State Machine 1"
            width={small ? '350px' : '800px'}
            height={small ? '400px' : '900px'}
          />
        </Grid>
        <Grid item container xs={12} md={6} justifyContent="center">
          <Typography variant="h2" align="center">
            {t('what_makes_us_diff_chart2')}
          </Typography>
          <RiveAnimationComponent
            src={
              mode === 'dark'
                ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/in2tec_dark_mode%201.riv'
                : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/in2tec_white_mode%201.riv'
            }
            stateMachines="State Machine 1"
            width={small ? '350px' : '800px'}
            height={small ? '400px' : '900px'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
