import { Grid, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
  motion,
  useScroll,
  useTransform,
  useMotionValueEvent,
} from 'framer-motion';
import { RiveAnimationComponent } from '../animations/RiveAnimationComponent';
import { VideoPlayer } from '../../plugins/VideoPlayer.js';

const SingleZoomInOutComponent = ({
  src,
  type,
  stateMachines,
  autoplay,
  title,
  description,
  footerContent,
  stickyVal,
  progress = [0.5, 0.8, 1],
  zoom = [0, 0.1, 1],
  titleMT,
  titleMB,
  noPadding,
  height,
  width,
}) => {
  const headerRef = useRef(null);
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end'],
  });
  const footerScrollProgress = useTransform(scrollYProgress, progress, zoom);
  const [sticky, setSticky] = useState(true);
  const opacity = useTransform(scrollYProgress, [0, 0.7], [0, 1]);

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange((latest) => {
      // console.log('Single Component Scroll Progress:', latest);
    });
    return () => unsubscribe();
  }, [scrollYProgress]);

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    if (scrollYProgress.current > stickyVal) {
      setSticky(false);
    } else {
      setSticky(true);
    }
  });

  return (
    <Grid
      container
      sx={{ padding: noPadding ? '0' : '0 2rem' }}
      direction="column"
      justifyContent="center"
      alignContent="center"
    >
      {/* Title */}
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          position: sticky ? 'sticky' : 'relative',
          top: '20%',
          marginTop: titleMT,
          marginBottom: titleMB,
        }}
      >
        <motion.div
          ref={headerRef}
          style={{
            opacity: opacity,
            marginBottom: '3rem',
          }}
          layout
        >
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="h1">{title}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body">{description}</Typography>
            </Grid>
          </Grid>
        </motion.div>
      </Grid>
      {/* Content */}
      <Grid
        item
        xs={12}
        sx={{
          width:
            type === 'video' && small
              ? '100%'
              : type === 'video' && !small
                ? '90%'
                : '100%',
        }}
      >
        <motion.div
          ref={ref}
          style={{
            scale: scrollYProgress,
            opacity: scrollYProgress,
            top: '20%',
          }}
        >
          {type === 'animation' ? (
            <RiveAnimationComponent
              src={src}
              stateMachines={stateMachines}
              autoplay={autoplay}
              width={width}
              height={height}
            />
          ) : type === 'image' ? (
            <Box
              component="img"
              sx={{
                width: '100%',
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
              }}
              alt={title}
              src={src}
            />
          ) : (
            <VideoPlayer videoSource={src} controls={true} />
          )}
        </motion.div>
      </Grid>
      {/* Footer */}
      {footerContent && (
        <Grid item xs={12}>
          <motion.div
            ref={headerRef}
            style={{
              opacity: footerScrollProgress,
              marginTop: '3rem',
            }}
          >
            <Grid item xs={12}>
              {footerContent}
            </Grid>
          </motion.div>
        </Grid>
      )}
    </Grid>
  );
};

export const SimpleZoomInOutcomponent = ({ srcArray }) => {
  return srcArray.map((element, index) => {
    return <SingleZoomInOutComponent key={index} {...element} />;
  });
};
