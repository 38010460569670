import { axiosPrivate } from '../hooks/axios/axios';
import i18n from '../i18n.js';

// Get Employees from Database
const getIntutecEmployees = async (payload) => {
  const controller = new AbortController();
  const signal = controller.signal;

  if (!payload) {
    throw new Error(i18n.t('routes.req_fields.payload'));
  }

  try {
    const url = '/api/intutec/employees/list';
    const request = await axiosPrivate.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    const response = request?.data;

    if (request.status === 200) {
      let employees = response.data;
      let employeeOptions = [];

      employees.forEach((employee) => {
        let newEmployee = {
          name: employee.first_name + ' ' + employee.last_name,
          email: employee.email,
          _id: employee._id,
        };
        employeeOptions.push(newEmployee);
      });

      // Update User Info
      return {
        status: 'success',
        message: i18n.t('form.message.actions.search.200', {
          type: i18n.t('types.employees'),
        }),
        data: employeeOptions,
      };
    }
  } catch (err) {
    return err;
  }
};

// Takes company_id (required)
// Takes employees as an array of objects, each must include _id and role
// Optional takes notification = true when admins should be informed by email
const associateUserToCompany = async (param) => {
  const controller = new AbortController();
  const signal = controller.signal;

  if (!param) {
    throw new Error(i18n.t('routes.req_fields.payload'));
  }

  const companyId = param?.company_id;
  if (!companyId) {
    throw new Error(i18n.t('routes.req_fields.employees'));
  }

  const employees = param?.employees;
  if (!employees || !Array.isArray(param.employees)) {
    throw new Error(i18n.t('routes.req_fields.employees'));
  }

  const notification = param?.notification;

  const payload = {
    company_id: companyId,
    employees: employees,
    notification: notification,
  };

  try {
    const url = '/api/companies/employees/employee/add';
    const request = await axiosPrivate.post(url, JSON.stringify(payload));

    const response = request?.data;

    return response;
  } catch (error) {
    throw new Error(error);
  } finally {
    controller.abort(signal);
  }
};

// Takes company_id (required)
// Takes employees as an array of string which is reqired
// Optional takes notification = true when admins should be informed by email
const unAssociateUserFromCompany = async (param) => {
  const controller = new AbortController();
  const signal = controller.signal;

  if (!param) {
    throw new Error(i18n.t('routes.req_fields.payload'));
  }

  const companyId = param?.company_id;
  if (!companyId) {
    throw new Error(i18n.t('routes.req_fields.employees'));
  }

  const employees = param?.employees;
  if (!employees || !Array.isArray(param.employees)) {
    throw new Error(i18n.t('routes.req_fields.employees'));
  }

  const notification = param?.notification;

  const payload = {
    company_id: companyId,
    employees: employees,
    notification: notification,
  };
  console.log(payload)
  try {
    const url = '/api/companies/employees/employee/remove';
    const request = await axiosPrivate.delete(url, {
      data: payload,
      signal: signal,
    });

    const response = request?.data;

    return response;
  } catch (error) {
    throw new Error(error);
  } finally {
    controller.abort(signal);
  }
};

export {
  getIntutecEmployees,
  associateUserToCompany,
  unAssociateUserFromCompany,
};
