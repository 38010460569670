export const createChangeCompanyAssociationInitialValues = ({
  userProfile,
}) => {
  return {
    new_company: {
      _id: '',
      name: '',
    },
    old_company: userProfile?.company || {},
  };
};
