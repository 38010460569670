import { useRef, useState, useEffect } from 'react';
import { motion, useScroll, AnimatePresence } from 'framer-motion';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { ObjectId } from 'bson';
import './styles.css';

export const ScrollingTextStickyImage = ({
  imgArr,
  textArr,
  button,
  reverse,
}) => {
  // Normal View
  const [imageIndex, setImageIndex] = useState('');
  const scrollingTextStickyImageRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollingTextStickyImageRef,
    offset: ['start start', 'end end'],
  });
  const arrLength = imgArr.length;
  const scrollVar = 1 / arrLength;

  const [fullScreen, setFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  // Set image array index depending on scroll value
  useEffect(() => {
    scrollYProgress.on('change', (e) => {
      setImageIndex(Math.floor(scrollYProgress.current / scrollVar));
    });
  });

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  // Create new array of images and text for mobile view
  function InterleavedArrayRenderer(imgArr, textArr) {
    const newArr = imgArr.map((src) => {
      if (src.type === 'animation') {
        return <>{src.src}</>;
      }
      return [
        <div
          className={`image-container ${fullScreen ? 'fullscreen' : ''}`}
          onClick={toggleFullScreen}
        >
          <img src={src.src} alt="src" width="100%" />
        </div>,
      ];
    });

    // Create a new array that interleaves items from array1 and array2
    const interleavedArray = reverse
      ? newArr.reduce((acc, item, index) => {
          acc.push(item);
          if (index < textArr.length) {
            acc.push(textArr[index]);
          }
          return acc;
        }, [])
      : textArr.reduce((acc, item, index) => {
          acc.push(item);
          if (index < newArr.length) {
            acc.push(newArr[index]);
          }
          return acc;
        }, []);
    return interleavedArray;
  }

  const interleavedArray = InterleavedArrayRenderer(
    imgArr,
    textArr,
    toggleFullScreen,
  );

  return (
    <motion.div ref={scrollingTextStickyImageRef}>
      <Grid
        container
        justifyContent="space-around"
        sx={{
          height: `${arrLength * 100}vh`,
          position: 'relative',
          //   marginTop: small ? '-10vh' : '-30vh',
        }}
      >
        {/* Mobile View */}
        {small ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            {interleavedArray.map((item, index) => {
              if (index % 2 === 0) {
                const nextItem = interleavedArray[index + 1];
                return (
                  <motion.div
                    key={item + index}
                    style={{ height: '105vh', width: '90%' }}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.25 }}
                  >
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item key={index + item}>
                        {item}
                      </Grid>
                      <Grid item key={index + nextItem + index}>
                        {nextItem}
                      </Grid>
                    </Grid>
                  </motion.div>
                );
              }
            })}
            <Grid sx={{ marginTop: '-15vh' }}>{button}</Grid>
          </Grid>
        ) : (
          <>
            <Grid xs={5} item>
              <StickyImage imgArr={imgArr} index={imageIndex} />
            </Grid>
            <Grid xs={5} item container alignContent="center">
              <TextScroll textArr={textArr} index={imageIndex} />
            </Grid>
            <Grid xs={12} item>
              {button}
            </Grid>
          </>
        )}
      </Grid>
    </motion.div>
  );
};

const StickyImage = ({ imgArr, index }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div style={{ width: '100%', position: 'sticky', top: '15%' }}>
      <AnimatePresence mode="sync">
        {imgArr.map((item, i) => {
          const isImage = item.type === 'image';
          return (
            <motion.div
              key={new ObjectId()}
              style={{
                height: '60vh',
                top: '20vh',
                left: '0',
                width: '100%',
                position: 'absolute',
                borderRadius: '25px',
                ...(isImage
                  ? {
                      backgroundImage: `url(${item.src})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }
                  : {}),
              }}
              initial={{ opacity: 0 }}
              animate={index === i ? { opacity: 1 } : { opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, type: 'tween', ease: 'easeInOut' }}
            >
              {!isImage ? item.src : null}
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
};

const TextScroll = ({ textArr }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div style={{ marginTop: small ? '0' : '30vh' }}>
      {!small &&
        textArr.map((text, i) => {
          return <TextContent text={text} key={i} index={i} />;
        })}
    </div>
  );
};

const TextContent = ({ text }) => {
  return (
    <Grid container>
      <motion.div
        style={{
          height: '90vh',
        }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        {text}
      </motion.div>
    </Grid>
  );
};
