import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate';
import useAuth from '../../../hooks/useAuth';
import useUser from '../../../hooks/useUser';
import useResponseHandling from '../../../hooks/useResponseHandler';
import MobileOTPField from '../MobileOTPField';
import { useFormikContext } from 'formik';
import { createContext, useContext } from 'react';
import MobileNotificationCheckBox from './MobileNotificationCheckBox';
import { LoadingButton } from '@mui/lab';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import { TwoFactorVerificationContext } from '../../../context/providers/TwoFactorVerificationContextProvider';
import MobileNumberField from '../MobileNumberField';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';

const TwoFactorVerificationFieldContentContext = createContext();

const TwoFactorVerificationFieldContent = ({
  dialog, // Whether to show the Change Mobile Phone Number Dialog
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  transition,
}) => {
  const { otpSent, isConfirmingOTP } = useContext(TwoFactorVerificationContext);

  const { values } = useFormikContext();

  return (
    <Stack
      spacing={4}
      sx={{
        paddingTop: '30px',
        paddingBottom: '30px',
      }}
    >
      {/* Mobile Phone Number */}
      <MobileNumberField
        fieldTitle={!dialog && fieldTitle}
        fieldDescription={!dialog && fieldDescription}
        fieldID="mobile"
        required={true}
        transition={transition}
        variant={dialog ? 'standard' : 'outlined'}
        disabled={
          otpSent || isConfirmingOTP || values?.mobile_verified?.accepted
            ? true
            : false
        }
      />

      {/* OTP Verification Field */}
      <MobileOTPField
        fieldID="otp"
        required={true}
        transition={otpSent}
        disabled={!otpSent ? true : false}
        variant={dialog ? 'standard' : 'outlined'}
      />
      {/* Mobile Verification Checkbox  */}
      <MobileNotificationCheckBox
        required={true}
        disabled={!otpSent ? true : false}
        transition={otpSent}
        variant={dialog ? 'standard' : 'outlined'}
      />
    </Stack>
  );
};

const TwoFactorVerificationFormActions = ({ fieldID }) => {
  const {
    values,
    setValues,
    setFieldValue,
    errors,
    setErrors,
    setFieldTouched,
  } = useContext(TwoFactorVerificationFieldContentContext);

  const {
    otp,
    isSendingOTP,
    setIsOTPSending,
    otpSent,
    setOTPSent,
    isConfirmingOTP,
    setIsConfirmingOTP,
  } = useContext(TwoFactorVerificationContext);

  const axios = useAxiosPrivate();
  const { auth } = useAuth();
  const { user } = useUser();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  //SetUp Trnaslation
  const { t } = useTranslation('buttons', {
    keyPrefix: 'two-factor-authentication',
  });

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);

  const handleConfirmClick = async () => {
    if (!values?.otp) {
      return window.alert(t('responses.no_alert'));
    }
    setIsConfirmingOTP(true);

    const controller = new AbortController();
    const { signal } = controller;
    const url = '/api/verify/account/auth/confirm';
    const payload = {
      _id: auth.user_info._id,
      otp: values?.otp,
      mobile: values.mobile,
      mobile_verified: {
        accepted: true,
        ip: user?.location?.ip,
        date: new Date(),
        unixTimeStamp: Math.floor(Date.now() / 1000),
        agent: navigator.userAgent,
      },
      account_notifications: values?.account_notifications,
      userDashboard: true,
    };

    try {
      const response = await axios.put(url, JSON.stringify(payload), {
        signal,
      });

      const { data, status } = response;

      if (status === 200) {
        setValues({
          ...values,
          individual: {
            ...values.individual,
            mobile: values.mobile,
          },
          mobile_verified: {
            accepted: true,
            ip: user?.location?.ip,
            date: new Date(),
            unixTimeStamp: Math.floor(Date.now() / 1000),
            agent: navigator.userAgent,
          },
          otp: '',
        });
        setErrors({
          errors,
          mobile_verified: {},
        });

        setOTPSent(false);

        // setTwoFactorVerificationDialog(false);

        setTimeout(() => {
          setFieldTouched('mobile_verified', true, true);
        }, 100);

        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setFieldValue('otp', '');
      setIsConfirmingOTP(false);
    }
  };

  const handleRequestClick = async () => {
    if (!fieldValue?.value) {
      return console.log(t('messages.no_alert'));
    }
    setIsOTPSending(true);

    const controller = new AbortController();
    const signal = controller.signal;
    let url = '/api/verify/account/auth/request';
    let payload = {
      mobile_number: values?.mobile?.value,
    };

    try {
      const request = await axios.put(url, JSON.stringify(payload), {
        signal,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      const data = request?.data;

      if (request.status === 200) {
        setOTPSent(true);
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      setOTPSent(false);
      handleErrorResponse(err);
    } finally {
      setIsOTPSending(false);
    }
  };

  return (
    <>
      <Stack spacing={2}>
        {/* Request OTP Button */}
        <FormikFieldWrapper
          transition={
            !fieldError?.info?.nationalNumber &&
            !values?.mobile_verified?.accepted &&
            !otpSent
          }
        >
          <LoadingButton
            variant="outlined"
            color="warning"
            disabled={
              fieldError && fieldError?.info?.nationalNumber ? true : false
            }
            onClick={() =>
              handleRequestClick({ values, setFieldValue, setValues })
            }
            sx={{ mr: 1 }}
            loading={isSendingOTP}
            startIcon={<PhoneIphoneIcon />}
          >
            {t('request')}
          </LoadingButton>
        </FormikFieldWrapper>
        {/* Confirm OTP Button */}
        <FormikFieldWrapper transition={otpSent}>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={!otpSent || values?.otp?.length < 4 ? true : false}
            onClick={() =>
              handleConfirmClick({ values, setValues, setFieldValue })
            }
            sx={{ mr: 1 }}
            loading={isConfirmingOTP}
            startIcon={<PhoneIphoneIcon />}
          >
            {t('confirm')}
          </LoadingButton>
        </FormikFieldWrapper>
        {/* Change Mobile Phone Number Button */}
        <FormikFieldWrapper transition={otpSent}>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => {
              // clear OTP & otp send status
              setOTPSent(false);
              setValues({
                ...values,
                otp: '',
              });
            }}
            sx={{ mr: 1 }}
          >
            {t('change')}
          </Button>
        </FormikFieldWrapper>
      </Stack>
    </>
  );
};

const TwoFactorVerificationField = ({ dialog, ...props }) => {
  const {
    values,
    setValues,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
    setErrors,
  } = useFormikContext();

  const {
    otpSent,
    twoFactorVerificationDialog,
    setTwoFactorVerificationDialog,
  } = useContext(TwoFactorVerificationContext);

  function handleTwoFactorVerificationDialogClose() {
    setTwoFactorVerificationDialog(false);
  }

  //SetUp Trnaslation
  const { t } = useTranslation();

  return (
    <TwoFactorVerificationFieldContentContext.Provider
      value={{
        values,
        setValues,
        setFieldValue,
        touched,
        setFieldTouched,
        errors,
        setErrors,
      }}
    >
      {dialog ? (
        <Dialog
          open={twoFactorVerificationDialog}
          onClose={handleTwoFactorVerificationDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <TwoFactorVerificationFieldContent {...props} />
          </DialogContent>
          <DialogActions>
            <TwoFactorVerificationFormActions fieldID={props.fieldID} />

            {/* Cancel and OK Button */}
            {values?.mobile_verified?.accepted && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleTwoFactorVerificationDialogClose}
                autoFocus
              >
                {t('buttons.ok')}
              </Button>
            )}

            {!values?.mobile_verified?.accepted && !otpSent && (
              <Button
                variant="outlined"
                color="error"
                onClick={handleTwoFactorVerificationDialogClose}
                autoFocus
              >
                {t('buttons.cancel', { type: '' })}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      ) : (
        <>
          <TwoFactorVerificationFieldContent {...props} />
          <TwoFactorVerificationFormActions {...props} />
        </>
      )}
    </TwoFactorVerificationFieldContentContext.Provider>
  );
};

export default TwoFactorVerificationField;
