import React from 'react';
import './headers.css';
import investorBanner from '../../../assets/how-it-works/manufacturers.png';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  Typography,
  Container,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

const InvestorHeaderContent = (props) => {
  const leadInfo = props?.leadInfo;
  const companyName = leadInfo?.company;
  const benefits = leadInfo?.benefit;

  let image;
  if (leadInfo?.company_banner_url) {
    image = leadInfo.company_banner_url;
  } else {
    image = investorBanner;
  }

  const { t } = useTranslation();

  return (
    <Container maxWidth="auto" disableGutters>
      <Grid className="intu__divider">
        <Grid
          container
          className="article"
          sx={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            minHeight: { xs: '350px', md: '550px' },
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Grid
            item
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'var(--color-hero-overlay)', // grey with 70% opacity
              zIndex: 1,
            }}
          />

          <Grid
            item
            xs={12}
            sx={{
              position: 'relative',
              padding: '0 10px 0 10px',
              zIndex: 2,
            }}
          >
            <Typography variant="h1" className="header" sx={{ color: 'white' }}>
              {/* Welcome {companyName}!<br />
                We are excited to share more information about IN2TEC with you! */}
              <Trans
                i18nKey="context.headers.investor.title"
                values={{ companyName: companyName }}
              />
            </Typography>
          </Grid>

          {/* Benefits */}
          <Grid
            container
            direction={{ xs: 'column', md: 'row' }}
            alignItems="center"
            sx={{
              minWidth: 300,
              width: 'auto',
              height: 'auto',
              position: 'relative',
              zIndex: 2,
            }}
          >
            {benefits?.map((benefit, index) => (
              <Grid item key={index}>
                <Box
                  sx={{
                    width: '100%',
                    height: 150,
                    minWidth: 275,
                    maxWidth: 350,
                    margin: 1,
                    color: 'var(--color-card-text)',
                    backgroundColor: 'var(--color-card-background)',
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      color="var(--intu-darkGrey)"
                    >
                      {benefit?.title}
                    </Typography>
                    <Divider variant="fullWidth" />
                    <Typography variant="body">{benefit?.content}</Typography>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export { InvestorHeaderContent };
