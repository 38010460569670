export const industryRobotParticleDesktop = {
    pauseOnBlur: false,
    fullScreen: false,
    interactivity: {
        events: {
            onHover: {
                enable: true,
                mode: "bubble"
            },
            resize: true
        },
        modes: {
            bubble: {
                distance: 40,
                duration: 3,
                opacity: 8,
                size: 10,
                speed: 10
            }
        }
    },
    particles: {
        color: {
            value: ["#A8C957", "#EDEDED", "#33333"]
        },
        links: {
            color: "random",
            distance: 40,
            enable: true,
            opacity: 0.8,
            width: 1
        },
        move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            speed: 1
        },
        number: {
            value: 200
        },
        opacity: {
            animation: {
                enable: true,
                speed: 2,
                sync: false
            },
            value: { min: 0.3, max: 0.8 }
        },
        shape: {
            type: "circle"
        },
        size: {
            value: 1
        }
    },
    polygon: {
        draw: {
            enable: true,
            lineColor: "#a9c957b1",
            lineWidth: 1
        },
        enable: true,
        move: {
            radius: 2
        },
        position: {
            x: 60,
            y: 80
        },
        inline: {
            arrangement: "equidistant"
        },
        scale: 0.9,
        type: "inline",
        url: "./industryRobot.svg"
    },
    background: {
        color: "#333333",
        opacity: 1,
        position: "fixed",
        repeat: "no-repeat",
        size: "cover"
    }
}

export const industryRobotParticleIpad = {
    pauseOnBlur: false,
    fullScreen: false,
    interactivity: {
        events: {
            onHover: {
                enable: true,
                mode: "bubble"
            },
            resize: true
        },
        modes: {
            bubble: {
                distance: 40,
                duration: 2,
                opacity: 8,
                size: 6,
                speed: 3
            }
        }
    },
    particles: {
        color: {
            value: ["#A8C957", "#EDEDED", "#33333"]
        },
        links: {
            color: "random",
            distance: 40,
            enable: true,
            opacity: 0.8,
            width: 1
        },
        move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            speed: 1
        },
        number: {
            value: 200
        },
        opacity: {
            animation: {
                enable: true,
                speed: 2,
                sync: false
            },
            value: { min: 0.3, max: 0.8 }
        },
        shape: {
            type: "circle"
        },
        size: {
            value: 1
        }
    },
    polygon: {
        draw: {
            enable: true,
            lineColor: "#a9c957b1",
            lineWidth: 1
        },
        enable: true,
        move: {
            radius: 2
        },
        position: {
            x: 65,
            y: 70
        },
        inline: {
            arrangement: "equidistant"
        },
        scale: 0.7,
        type: "inline",
        url: "./industryRobot.svg"
    },
    background: {
        color: "#333333",
        opacity: 1,
        position: "50% 50%",
        repeat: "no-repeat",
        size: "cover"
    }
}


export const industryRobotParticleMobile = {
    pauseOnBlur: false,
    fullScreen: false,
    interactivity: {
        events: {
            onHover: {
                enable: true,
                mode: "bubble"
            },
            resize: true
        },
        modes: {
            bubble: {
                distance: 40,
                duration: 2,
                opacity: 8,
                size: 6,
                speed: 3
            }
        }
    },
    particles: {
        color: {
            value: ["#A8C957", "#EDEDED", "#33333"]
        },
        links: {
            color: "random",
            distance: 25,
            enable: true,
            opacity: 0.8,
            width: 1
        },
        move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            speed: 1
        },
        number: {
            value: 200
        },
        opacity: {
            animation: {
                enable: true,
                speed: 2,
                sync: false
            },
            value: { min: 0.3, max: 0.8 }
        },
        shape: {
            type: "circle"
        },
        size: {
            value: 1
        }
    },
    polygon: {
        draw: {
            enable: true,
            lineColor: "#a9c957b1",
            lineWidth: 1
        },
        enable: true,
        move: {
            radius: 2
        },
        position: {
            x: 75,
            y: 70
        },
        inline: {
            arrangement: "equidistant"
        },
        scale: 0.7,
        type: "inline",
        url: "./industryRobot.svg"
    },
    background: {
        color: "#333333",
        opacity: 1,
        position: "50% 50%",
        repeat: "no-repeat",
        size: "cover"
    }
}