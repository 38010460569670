// Handin a number and return it string formatted with a Symbol

export const formatValueAsStringWithSign = (value, sign) => {
  if (!value || !sign) {
    return;
  }
  const formattedNumber = value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${formattedNumber} ${sign}`;
};
